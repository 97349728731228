import actionType from './ActionTypes'

// L -loading
// S- success
// F- fail

//[---- CHECK VERSION-----]
export function CheckAppVersionL(bool) {
  return {
    type: actionType.CHECKAPPVERSION_L,
    isLoading: bool
  }
}

export function CheckAppVersionS(data) {
  return {
    type: actionType.CHECKAPPVERSION_S,
    data
  }
}

export function CheckAppVersionF(error,data) {
  return {
    type: actionType.CHECKAPPVERSION_F,
    data,
    error
  }
}


// LOGIN FUNCTIONS
export function LoginAttempt(bool) {
  return {
    type: actionType.LOGIN_A,
    isLoading: bool
  }
}
export function LoginSuccess(data) {
  return {
    type: actionType.LOGIN_S,
    data
  }
}
export function LoginFailed(error,data) {
  return {
    type: actionType.LOGIN_F,
    data,
    error
  }
}

// FORGET PASSWORD FUNCTIONS
export function ForgetPasswordA(bool) {
  return {
    type: actionType.FORGET_PASSWORD_A,
    isLoading: bool
  }
}
export function ForgetPasswordS(data) {
  return {
    type: actionType.FORGET_PASSWORD_S,
    data
  }
}
export function ForgetPasswordF(error,data) {
  return {
    type: actionType.FORGET_PASSWORD_F,
    data,
    error
  }
}

// REGISTRAION FUNCTIONS
export function RegistrationAttempt(bool) {
  return {
    type: actionType.REGISTRATION_A,
    isLoading: bool
  }
}
export function RegistrationSuccess(data) {
  return {
    type: actionType.REGISTRATION_S,
    data
  }
}
export function RegistrationFailed(error,data) {
  return {
    type: actionType.REGISTRATION_F,
    data,
    error
  }
}

// MOBILE VERIFICATION FUNCTIONS
export function VerifyCustomerAttempt(bool) {
  return {
    type: actionType.VERIFY_CUSTOMER_A,
    isLoading: bool
  }
}
export function VerifyCustomerSuccess(data) {
  return {
    type: actionType.VERIFY_CUSTOMER_S,
    data
  }
}
export function VerifyCustomerFailed(error,data) {
  return {
    type: actionType.VERIFY_CUSTOMER_F,
    data,
    error
  }
}

// RESEND MOBILE VERIFICATION FUNCTIONS
export function ResendMobileVerificationCodeAttempt(bool) {
  return {
    type: actionType.RESEND_VERIFICATION_CODE_A,
    isLoading: bool
  }
}
export function ResendMobileVerificationCodeSuccess(data) {
  return {
    type: actionType.RESEND_VERIFICATION_CODE_S,
    data
  }
}
export function ResendMobileVerificationCodeFailed(error,data) {
  return {
    type: actionType.RESEND_VERIFICATION_CODE_F,
    data,
    error
  }
}

// SOCIAL LOGIN/REGISTRAION FUNCTIONS
export function SocialSignUpAttempt(bool) {
  return {
    type: actionType.SOCIAL_SIGNUP_A,
    isLoading: bool
  }
}
export function SocialSignUpSuccess(data) {
  return {
    type: actionType.SOCIAL_SIGNUP_S,
    data
  }
}
export function SocialSignUpFailed(error,data) {
  return {
    type: actionType.SOCIAL_SIGNUP_F,
    data,
    error
  }
}

// ADD UPDATE SOCIAL CUSTOMER FUNCTIONS
export function addUpdateSocialCustomerAttempt(bool) {
  return {
    type: actionType.ADDUPDATE_SOCIAL_CUSTOMER_A,
    isLoading: bool
  }
}
export function addUpdateSocialCustomerSuccess(data) {
  return {
    type: actionType.ADDUPDATE_SOCIAL_CUSTOMER_S,
    data
  }
}
export function addUpdateSocialCustomerFailed(error,data) {
  return {
    type: actionType.ADDUPDATE_SOCIAL_CUSTOMER_F,
    data,
    error
  }
}

// GET ALL CATEGORIES FUNCTIONS
export function GetCategoriesAttempt(bool) {
  return {
    type: actionType.GET_CATEGORIES_A,
    isLoading: bool
  }
}
export function GetCategoriesSuccess(data) {
  return {
    type: actionType.GET_CATEGORIES_S,
    data
  }
}
export function GetCategoriesFailed(error,data) {
  return {
    type: actionType.GET_CATEGORIES_F,
    data,
    error
  }
}

// GET CATEGORIES MENU ITEMS FUNCTIONS
export function GetCategoriesMenuItemsAttempt(bool) {
  return {
    type: actionType.GET_CATEGORIES_MENUITEMS_A,
    isLoading: bool
  }
}
export function GetCategoriesMenuItemsSuccess(data) {
  return {
    type: actionType.GET_CATEGORIES_MENUITEMS_S,
    data
  }
}
export function GetCategoriesMenuItemsFailed(error,data) {
  return {
    type: actionType.GET_CATEGORIES_MENUITEMS_F,
    data,
    error
  }
}


// DELETE USER ACCOUNT FUNCTIONS
export function DeleteAccountAttempt(bool) {
  return {
    type: actionType.DELETE_ACCOUNT_A,
    isLoading: bool
  }
}
export function DeleteAccountSuccess(data) {
  return {
    type: actionType.DELETE_ACCOUNT_S,
    data
  }
}
export function DeleteAccountFailed(error,data) {
  return {
    type: actionType.DELETE_ACCOUNT_F,
    data,
    error
  }
}

// COMPANY LINK ACCOUNT FUNCTIONS
export function CompanyAccountLinkA(bool) {
  return {
    type: actionType.LINK_ACCOUNT_A,
    isLoading: bool
  }
}
export function CompanyAccountLinkS(data) {
  return {
    type: actionType.LINK_ACCOUNT_S,
    data
  }
}
export function CompanyAccountLinkF(error,data) {
  return {
    type: actionType.LINK_ACCOUNT_F,
    data,
    error
  }
}

// ORDER LIST FUNCTIONS
export function OrderSListA(bool) {
  return {
    type: actionType.ORDERS_LIST_A,
    isLoading: bool
  }
}
export function OrderSListS(data) {
  return {
    type: actionType.ORDERS_LIST_S,
    data
  }
}
export function OrderSListF(error,data) {
  return {
    type: actionType.ORDERS_LIST_F,
    data,
    error
  }
}

// ORDER DETAILS FUNCTIONS
export function OrderDetailsA(bool) {
  return {
    type: actionType.ORDER_DETAILS_A,
    isLoading: bool
  }
}
export function OrderDetailsS(data) {
  return {
    type: actionType.ORDER_DETAILS_S,
    data
  }
}
export function OrderDetailsF(error,data) {
  return {
    type: actionType.ORDER_DETAILS_F,
    data,
    error
  }
}

// LOCATION FUNCTIONS
export function GetLocationA(bool) {
  return {
    type: actionType.LOCATION_A,
    isLoading: bool
  }
}
export function GetLocationS(data) {
  return {
    type: actionType.LOCATION_S,
    data
  }
}
export function GetLocationF(error,data) {
  return {
    type: actionType.LOCATION_F,
    data,
    error
  }
}

// CHECK SPECIAL OFFERS FUNCTIONS
export function CheckSpecialOfferA(bool) {
  return {
    type: actionType.CHECK_SPECIAL_OFFERS_A,
    isLoading: bool
  }
}
export function CheckSpecialOfferS(data) {
  return {
    type: actionType.CHECK_SPECIAL_OFFERS_S,
    data
  }
}
export function CheckSpecialOfferF(error,data) {
  return {
    type: actionType.CHECK_SPECIAL_OFFERS_F,
    data,
    error
  }
}

// COMPANY CREDIT SCORE FUNCTIONS
export function CheckCompanyCreditScoreA(bool) {
  return {
    type: actionType.CHECK_COMPANY_CREDIT_SCORE_A,
    isLoading: bool
  }
}
export function CheckCompanyCreditScoreS(data) {
  return {
    type: actionType.CHECK_COMPANY_CREDIT_SCORE_S,
    data
  }
}
export function CheckCompanyCreditScoreF(error,data) {
  return {
    type: actionType.CHECK_COMPANY_CREDIT_SCORE_F,
    data,
    error
  }
}

// COMPANY CREDIT SCORE FUNCTIONS
export function AddCompanyAddressA(bool) {
  return {
    type: actionType.ADD_COMPNAY_ADDRESS_A,
    isLoading: bool
  }
}
export function AddCompanyAddressS(data) {
  return {
    type: actionType.ADD_COMPNAY_ADDRESS_S,
    data
  }
}
export function AddCompanyAddressF(error,data) {
  return {
    type: actionType.ADD_COMPNAY_ADDRESS_F,
    data,
    error
  }
}

// GET DELIVERY ADDRESS FUNCTIONS
export function GetDeliveryAddressA(bool) {
  return {
    type: actionType.GET_DELIVERY_ADDRESS_A,
    isLoading: bool
  }
}
export function GetDeliveryAddressS(data) {
  return {
    type: actionType.GET_DELIVERY_ADDRESS_S,
    data
  }
}
export function GetDeliveryAddressF(error,data) {
  return {
    type: actionType.GET_DELIVERY_ADDRESS_F,
    data,
    error
  }
}

// DELETE ADDRESS FUNCTIONS
export function DeleteDeliveryAddressA(bool) {
  return {
    type: actionType.DELETE_DELIVERY_ADDRESS_A,
    isLoading: bool
  }
}
export function DeleteDeliveryAddressS(data) {
  return {
    type: actionType.DELETE_DELIVERY_ADDRESS_S,
    data
  }
}
export function DeleteDeliveryAddressF(error,data) {
  return {
    type: actionType.DELETE_DELIVERY_ADDRESS_F,
    data,
    error
  }
}


// ADD DELIVERY ADDRESS FUNCTIONS
export function AddDeliveryAddressA(bool) {
  return {
    type: actionType.ADD_DELIVERY_ADDRESS_A,
    isLoading: bool
  }
}
export function AddDeliveryAddressS(data) {
  return {
    type: actionType.ADD_DELIVERY_ADDRESS_S,
    data
  }
}
export function AddDeliveryAddressF(error,data) {
  return {
    type: actionType.ADD_DELIVERY_ADDRESS_F,
    data,
    error
  }
}

// GET APP MESSAGE FUNCTIONS
export function GetAppMessageA(bool) {
  return {
    type: actionType.APP_MESSAGE_A,
    isLoading: bool
  }
}
export function GetAppMessageS(data) {
  return {
    type: actionType.APP_MESSAGE_S,
    data
  }
}
export function GetAppMessageF(error,data) {
  return {
    type: actionType.APP_MESSAGE_F,
    data,
    error
  }
}

// ADD ORDERS FUNCTIONS
export function AddOrdersA(bool) {
  return {
    type: actionType.ADD_ORDERS_A,
    isLoading: bool
  }
}
export function AddOrdersS(data) {
  return {
    type: actionType.ADD_ORDERS_S,
    data
  }
}
export function AddOrdersF(error,data) {
  return {
    type: actionType.ADD_ORDERS_F,
    data,
    error
  }
}


// CHECK MENU ITEMS TIMES FUNCTIONS
export function CheckMenuItemTimesA(bool) {
  return {
    type: actionType.CHECK_ITEM_TIME_A,
    isLoading: bool
  }
}
export function CheckMenuItemTimesS(data) {
  return {
    type: actionType.CHECK_ITEM_TIME_S,
    data
  }
}
export function CheckMenuItemTimesF(error,data) {
  return {
    type: actionType.CHECK_ITEM_TIME_F,
    data,
    error
  }
}


// GET SAVED CARDS FUNCTIONS
export function GetSavedCardsA(bool) {
  return {
    type: actionType.GET_SAVED_CARD_A,
    isLoading: bool
  }
}
export function GetSavedCardsS(data) {
  return {
    type: actionType.GET_SAVED_CARD_S,
    data
  }
}
export function GetSavedCardsF(error,data) {
  return {
    type: actionType.GET_SAVED_CARD_F,
    data,
    error
  }
}

// GET REORDER INFO FUNCTIONS
export function GetReOrderInfoA(bool) {
  return {
    type: actionType.GET_REORDER_INFO_A,
    isLoading: bool
  }
}
export function GetReOrderInfoS(data) {
  return {
    type: actionType.GET_REORDER_INFO_S,
    data
  }
}
export function GetReOrderInfoF(error,data) {
  return {
    type: actionType.GET_REORDER_INFO_F,
    data,
    error
  }
}

// GET ITEM DETAILS FUNCTIONS
export function GetItemDetailsA(bool) {
  return {
    type: actionType.GET_ITEM_DETAILS_A,
    isLoading: bool
  }
}
export function GetItemDetailsS(data) {
  return {
    type: actionType.GET_ITEM_DETAILS_S,
    data
  }
}
export function GetItemDetailsF(error,data) {
  return {
    type: actionType.GET_ITEM_DETAILS_F,
    data,
    error
  }
}


// ADD USER FEEDBACK FUNCTIONS
export function AddFeedbackA(bool) {
  return {
    type: actionType.ADD_FEEDBACK_A,
    isLoading: bool
  }
}
export function AddFeedbackS(data) {
  return {
    type: actionType.ADD_FEEDBACK_S,
    data
  }
}
export function AddFeedbackF(error,data) {
  return {
    type: actionType.ADD_FEEDBACK_F,
    data,
    error
  }
}

// GET CARD LIST FUNCTIONS
export function GetCardListA(bool) {
  return {
    type: actionType.GET_CARD_LIST_A,
    isLoading: bool
  }
}
export function GetCardListS(data) {
  return {
    type: actionType.GET_CARD_LIST_S,
    data
  }
}
export function GetCardListF(error,data) {
  return {
    type: actionType.GET_CARD_LIST_F,
    data,
    error
  }
}


// ADD CARD DETAILS FUNCTIONS
export function AddCardDetailsA(bool) {
  return {
    type: actionType.ADD_CARD_DETAILS_A,
    isLoading: bool
  }
}
export function AddCardDetailsS(data) {
  return {
    type: actionType.ADD_CARD_DETAILS_S,
    data
  }
}
export function AddCardDetailsF(error,data) {
  return {
    type: actionType.ADD_CARD_DETAILS_F,
    data,
    error
  }
}

// DELETE CARD DETAILS FUNCTIONS
export function DeleteCardDetailA(bool) {
  return {
    type: actionType.DELETE_CARD_DETAILS_A,
    isLoading: bool
  }
}
export function DeleteCardDetailS(data) {
  return {
    type: actionType.DELETE_CARD_DETAILS_S,
    data
  }
}
export function DeleteCardDetailF(error,data) {
  return {
    type: actionType.DELETE_CARD_DETAILS_F,
    data,
    error
  }
}

// GET WAITER LISTING FUNCTIONS
export function WaiterListA(bool) {
  return {
    type: actionType.WAITER_LIST_A,
    isLoading: bool
  }
}
export function WaiterListS(data) {
  return {
    type: actionType.WAITER_LIST_S,
    data
  }
}
export function WaiterListF(error,data) {
  return {
    type: actionType.WAITER_LIST_F,
    data,
    error
  }
}


// GET WAITER DETAILS FUNCTIONS
export function WaiterDetailsA(bool) {
  return {
    type: actionType.WAITER_DETAIL_A,
    isLoading: bool
  }
}
export function WaiterDetailsS(data) {
  return {
    type: actionType.WAITER_DETAIL_S,
    data
  }
}
export function WaiterDetailsF(error,data) {
  return {
    type: actionType.WAITER_DETAIL_F,
    data,
    error
  }
}



// GET AVOCADO DETAILS FUNCTIONS
export function GetAvocadoDetailsA(bool) {
  return {
    type: actionType.GET_AVOCADO_DETAILS_A,
    isLoading: bool
  }
}
export function GetAvocadoDetailsS(data) {
  return {
    type: actionType.GET_AVOCADO_DETAILS_S,
    data
  }
}
export function GetAvocadoDetailsF(error,data) {
  return {
    type: actionType.GET_AVOCADO_DETAILS_F,
    data,
    error
  }
}


// CHECK VALID DELIVERY ADDRESS
export function CheckDeliveryAddressA(bool) {
  return {
    type: actionType.CHECK_DELIVERY_ADDRESS_A,
    isLoading: bool
  }
}
export function CheckDeliveryAddressS(data) {
  return {
    type: actionType.CHECK_DELIVERY_ADDRESS_S,
    data
  }
}
export function CheckDeliveryAddressF(error,data) {
  return {
    type: actionType.CHECK_DELIVERY_ADDRESS_F,
    data,
    error
  }
}


// CANCEL ORDER
export function CancelOrderA(bool) {
  return {
    type: actionType.CANCEL_ORDER_A,
    isLoading: bool
  }
}
export function CancelOrderS(data) {
  return {
    type: actionType.CANCEL_ORDER_S,
    data
  }
}
export function CancelOrderF(error,data) {
  return {
    type: actionType.CANCEL_ORDER_F,
    data,
    error
  }
}

// RESTAURANT INFO 
export function GetRestoInfoA(bool) {
  return {
    type: actionType.GET_RESTO_INFO_A,
    isLoading: bool
  }
}
export function GetRestoInfoS(data) {
  return {
    type: actionType.GET_RESTO_INFO_S,
    data
  }
}
export function GetRestoInfoF(error,data) {
  return {
    type: actionType.GET_RESTO_INFO_F,
    data,
    error
  }
}



// APPLY VOUCHER FUNCTION
export function   ApplyVoucherCodeA(bool) {
  return {
    type: actionType.APPLY_VOUCHER_CODE_A,
    isLoading: bool
  }
}
export function ApplyVoucherCodeS(data) {
  return {
    type: actionType.APPLY_VOUCHER_CODE_S,
    data
  }
}
export function ApplyVoucherCodeF(error,data) {
  return {
    type: actionType.APPLY_VOUCHER_CODE_F,
    data,
    error
  }
}


// RESET PASSWORD FUNCTION
export function ResetPasswordA(bool) {
  return {
    type: actionType.RESET_PASSWORD_A,
    isLoading: bool
  }
}
export function ResetPasswordS(data) {
  return {
    type: actionType.RESET_PASSWORD_S,
    data
  }
}
export function ResetPasswordF(error,data) {
  return {
    type: actionType.RESET_PASSWORD_F,
    data,
    error
  }
}

// OFFICE REGISTRATION FUNCTIONS
export function OfficeRegistrationAttempt(bool) {
  return {
    type: actionType.OFFICE_REGISTER_A,
    isLoading: bool
  }
}
export function OfficeRegistrationSuccess(data) {
  return {
    type: actionType.OFFICE_REGISTER_S,
    data
  }
}
export function OfficeRegistrationFailed(error,data) {
  return {
    type: actionType.OFFICE_REGISTER_F,
    data,
    error
  }
}

// DELIVERY CHARGE FUNCTIONS
export function DeliveryChargeAttempt(bool) {
  return {
    type: actionType.DELIVERY_CHARGE_A,
    isLoading: bool
  }
}
export function DeliveryChargeSuccess(data) {
  return {
    type: actionType.DELIVERY_CHARGE_S,
    data
  }
}
export function DeliveryChargeFailed(error,data) {
  return {
    type: actionType.DELIVERY_CHARGE_F,
    data,
    error
  }
}

// CHECK NEXT DAY ORDER FUNCTIONS
export function CheckNextDayOrderAttempt(bool) {
  return {
    type: actionType.CHECK_NEXTDAY_ORDER_A,
    isLoading: bool
  }
}
export function CheckNextDayOrderSuccess(data) {
  return {
    type: actionType.CHECK_NEXTDAY_ORDER_S,
    data
  }
}
export function CheckNextDayOrderFailed(error,data) {
  return {
    type: actionType.CHECK_NEXTDAY_ORDER_F,
    data,
    error
  }
}

// GET COMPANY LIST FUNCTIONS
export function GetCompanyListAttempt(bool) {
  return {
    type: actionType.GET_COMPANY_LIST_A,
    isLoading: bool
  }
}
export function GetCompanyListSuccess(data) {
  return {
    type: actionType.GET_COMPANY_LIST_S,
    data
  }
}
export function GetCompanyListFailed(error,data) {
  return {
    type: actionType.GET_COMPANY_LIST_F,
    data,
    error
  }
}

// REMOVE LINKED COMPANY FUNCTIONS
export function RemoveLinkedCompanyAttempt(bool) {
  return {
    type: actionType.REMOVE_LINKED_COMPANY_A,
    isLoading: bool
  }
}
export function RemoveLinkedCompanySuccess(data) {
  return {
    type: actionType.REMOVE_LINKED_COMPANY_S,
    data
  }
}
export function RemoveLinkedCompanyFailed(error,data) {
  return {
    type: actionType.REMOVE_LINKED_COMPANY_F,
    data,
    error
  }
}

// GET BEST OFFERS FUNCTIONS
export function GetBestOffersAttempt(bool) {
  return {
    type: actionType.GET_BEST_OFFERS_A,
    isLoading: bool
  }
}
export function GetBestOffersSuccess(data) {
  return {
    type: actionType.GET_BEST_OFFERS_S,
    data
  }
}
export function GetBestOffersFailed(error,data) {
  return {
    type: actionType.GET_BEST_OFFERS_F,
    data,
    error
  }
}


// GET Table Booking sloat
export function GetTableBookingSloatAttempt(bool) {
  return {
    type: actionType.GET_TABLE_BOOKIG_SLOAT_A,
    isLoading: bool
  }
}
export function GetTableBookingSloatSuccess(data) {
  return {
    type: actionType.GET_TABLE_BOOKIG_SLOAT_S,
    data
  }
}
export function GetTableBookingSloatFailed(error,data) {
  return {
    type: actionType.GET_TABLE_BOOKIG_SLOAT_F,
    data,
    error
  }
}

// Add Table Booking sloat with paynow
export function addTableReservationAttempt(bool) {
  return {
    type: actionType.ADD_TABLE_RESERVATION_A,
    isLoading: bool
  }
}
export function addTableReservationSuccess(data) {
  return {
    type: actionType.ADD_TABLE_RESERVATION_S,
    data
  }
}
export function addTableReservationFailed(error,data) {
  return {
    type: actionType.ADD_TABLE_RESERVATION_F,
    data,
    error
  }
}

// get Table Booking History
export function getTableBookingHistoryByCustomerAttempt(bool) {
  return {
    type: actionType.GET_TABLE_BOOKING_HISTORY_BY_CUSTOMER_A,
    isLoading: bool
  }
}
export function getTableBookingHistoryByCustomerSuccess(data) {
  return {
    type: actionType.GET_TABLE_BOOKING_HISTORY_BY_CUSTOMER_S,
    data
  }
}
export function getTableBookingHistoryByCustomerFailed(error,data) {
  return {
    type: actionType.GET_TABLE_BOOKING_HISTORY_BY_CUSTOMER_F,
    data,
    error
  }
}

// Cancel Reservation Table Booking
export function updateTableReservationBookingRequestAttempt(bool) {
  return {
    type: actionType.UPDATE_TABLE_RES_BOOKING_REQUEST_A,
    isLoading: bool
  }
}
export function updateTableReservationBookingRequestSuccess(data) {
  return {
    type: actionType.UPDATE_TABLE_RES_BOOKING_REQUEST_S,
    data
  }
}
export function updateTableReservationBookingRequestFailed(error,data) {
  return {
    type: actionType.UPDATE_TABLE_RES_BOOKING_REQUEST_F,
    data,
    error
  }
}


// Cancel Reservation Table Booking
export function getCustomerDetailsAttempt(bool) {
  return {
    type: actionType.GET_CUSTOMER_DETAIL_A,
    isLoading: bool
  }
}
export function getCustomerDetailsSuccess(data) {
  return {
    type: actionType.GET_CUSTOMER_DETAIL_S,
    data
  }
}
export function getCustomerDetailsFailed(error,data) {
  return {
    type: actionType.GET_CUSTOMER_DETAIL_F,
    data,
    error
  }
}

// get virtual waiting
export function getTableVirtualWaitingByCustomerAttempt(bool) {
  return {
    type: actionType.GET_VIRTUAL_QUEUE_LIST_A,
    isLoading: bool
  }
}
export function getTableVirtualWaitingByCustomerSuccess(data) {
  return {
    type: actionType.GET_VIRTUAL_QUEUE_LIST_S,
    data
  }
}
export function getTableVirtualWaitingByCustomerFailed(error,data) {
  return {
    type: actionType.GET_VIRTUAL_QUEUE_LIST_F,
    data,
    error
  }
}

// Add virtual waiting
export function addTableVirtualWaitingAttempt(bool) {
  return {
    type: actionType.ADD_VIRTUAL_QUEUE_A,
    isLoading: bool
  }
}
export function addTableVirtualWaitingSuccess(data) {
  return {
    type: actionType.ADD_VIRTUAL_QUEUE_S,
    data
  }
}
export function addTableVirtualWaitingFailed(error,data) {
  return {
    type: actionType.ADD_VIRTUAL_QUEUE_F,
    data,
    error
  }
}

// Cancel virtual waiting
export function deleteTableVirtualWaitingAttempt(bool) {
  return {
    type: actionType.CANCEL_VIRTUAL_QUEUE_A,
    isLoading: bool
  }
}
export function deleteTableVirtualWaitingSuccess(data) {
  return {
    type: actionType.CANCEL_VIRTUAL_QUEUE_S,
    data
  }
}
export function deleteTableVirtualWaitingFailed(error,data) {
  return {
    type: actionType.CANCEL_VIRTUAL_QUEUE_F,
    data,
    error
  }
}


// get member offer list
export function getMemberofferListAttempt(bool) {
  return {
    type: actionType.GET_MEMBER_OFFER_LIST_A,
    isLoading: bool
  }
}
export function getMemberofferListSuccess(data) {
  return {
    type: actionType.GET_MEMBER_OFFER_LIST_S,
    data
  }
}
export function getMemberofferListFailed(error,data) {
  return {
    type: actionType.GET_MEMBER_OFFER_LIST_F,
    data,
    error
  }
}

// get getLoyaltyRewardLevelList
export function getLoyaltyRewardLevelListAttempt(bool) {
  return {
    type: actionType.GET_LOYALTY_REWARD_LIST_A,
    isLoading: bool
  }
}
export function getLoyaltyRewardLevelListSuccess(data) {
  return {
    type: actionType.GET_LOYALTY_REWARD_LIST_S,
    data
  }
}
export function getLoyaltyRewardLevelListFailed(error,data) {
  return {
    type: actionType.GET_LOYALTY_REWARD_LIST_F,
    data,
    error
  }
}

// get getLoyaltyRewardLevel hiostory List
export function getMemberOfferTransactionListAttempt(bool) {
  return {
    type: actionType.GET_LOYALTY_REWARD_HISTORY_LIST_A,
    isLoading: bool
  }
}
export function getMemberOfferTransactionListSuccess(data) {
  return {
    type: actionType.GET_LOYALTY_REWARD_HISTORY_LIST_S,
    data
  }
}
export function getMemberOfferTransactionListFailed(error,data) {
  return {
    type: actionType.GET_LOYALTY_REWARD_HISTORY_LIST_F,
    data,
    error
  }
}

// get special offer list
export function getBestOfferListAttempt(bool) {
  return {
    type: actionType.GET_BEST_OFFER_LIST_A,
    isLoading: bool
  }
}
export function getBestOfferListSuccess(data) {
  return {
    type: actionType.GET_BEST_OFFER_LIST_S,
    data
  }
}
export function getBestOfferListFailed(error,data) {
  return {
    type: actionType.GET_BEST_OFFER_LIST_F,
    data,
    error
  }
}


// get special offer list
export function applyCouponCodeByMemberOfferCodeAttempt(bool) {
  return {
    type: actionType.GET_MEMBER_OFFER_DISCOUNT_A,
    isLoading: bool
  }
}
export function applyCouponCodeByMemberOfferCodeSuccess(data) {
  return {
    type: actionType.GET_MEMBER_OFFER_DISCOUNT_S,
    data
  }
}
export function applyCouponCodeByMemberOfferCodeFailed(error,data) {
  return {
    type: actionType.GET_MEMBER_OFFER_DISCOUNT_F,
    data,
    error
  }
}

// get special offer list
export function addRemoveFavItemAttempt(bool) {
  return {
    type: actionType.ADD_REMOVE_FAV_ITEM_A,
    isLoading: bool
  }
}
export function addRemoveFavItemSuccess(data) {
  return {
    type: actionType.ADD_REMOVE_FAV_ITEM_S,
    data
  }
}
export function addRemoveFavItemFailed(error,data) {
  return {
    type: actionType.ADD_REMOVE_FAV_ITEM_F,
    data,
    error
  }
}

// get fav item
export function getFavItemAttempt(bool) {
  return {
    type: actionType.GET_FAV_ITEM_A,
    isLoading: bool
  }
}
export function getFavItemSuccess(data) {
  return {
    type: actionType.GET_FAV_ITEM_S,
    data
  }
}
export function getFavItemFailed(error,data) {
  return {
    type: actionType.GET_FAV_ITEM_F,
    data,
    error
  }
}

// getLoyaltyPointList
export function getLoyaltyPointListAttempt(bool) {
  return {
    type: actionType.GET_LOYALTY_POINT_LIST_A,
    isLoading: bool
  }
}
export function getLoyaltyPointListSuccess(data) {
  return {
    type: actionType.GET_LOYALTY_POINT_LIST_S,
    data
  }
}
export function getLoyaltyPointListFailed(error,data) {
  return {
    type: actionType.GET_LOYALTY_POINT_LIST_F,
    data,
    error
  }
}