/**
 *  A - Attempt or loading for progress dialog
 *  S - success
 *  F- failed
 */

export default {

  /*CHECK version*/
  CHECKAPPVERSION_L: 'CHECKAPPVERSION_L',
  CHECKAPPVERSION_S: 'CHECKAPPVERSION_S',
  CHECKAPPVERSION_F: 'CHECKAPPVERSION_F',

  
  // LOGIN TYPES
  LOGIN_A: 'LOGIN_A',
  LOGIN_S: 'LOGIN_S',
  LOGIN_F: 'LOGIN_F',

  //FORGET PASSWORD 
  FORGET_PASSWORD_A: 'FORGET_PASSWORD_A',
  FORGET_PASSWORD_S: 'FORGET_PASSWORD_S',
  FORGET_PASSWORD_F: 'FORGET_PASSWORD_F',

  // REGISTRATION TYPES
  REGISTRATION_A: 'REGISTRATION_A',
  REGISTRATION_S: 'REGISTRATION_S',
  REGISTRATION_F: 'REGISTRATION_F',

  // VERIFY CUSTOMER TYPES
  VERIFY_CUSTOMER_A: 'VERIFY_CUSTOMER_A',
  VERIFY_CUSTOMER_S: 'VERIFY_CUSTOMER_S',
  VERIFY_CUSTOMER_F: 'VERIFY_CUSTOMER_F',

  // RESEND VERIFICATION CODE
  RESEND_VERIFICATION_CODE_A: 'RESEND_VERIFICATION_CODE_A',
  RESEND_VERIFICATION_CODE_S: 'RESEND_VERIFICATION_CODE_S',
  RESEND_VERIFICATION_CODE_F: 'RESEND_VERIFICATION_CODE_F',

  // SOCIAL LOGIN/REGISTRATION TYPES
  SOCIAL_SIGNUP_A: 'SOCIAL_SIGNUP_A',
  SOCIAL_SIGNUP_S: 'SOCIAL_SIGNUP_S',
  SOCIAL_SIGNUP_F: 'SOCIAL_SIGNUP_F',

  // ADD UPDATE SOCIAL CUSTOMER TYPES
  ADDUPDATE_SOCIAL_CUSTOMER_A: 'ADDUPDATE_SOCIAL_CUSTOMER_A',
  ADDUPDATE_SOCIAL_CUSTOMER_S: 'ADDUPDATE_SOCIAL_CUSTOMER_S',
  ADDUPDATE_SOCIAL_CUSTOMER_F: 'ADDUPDATE_SOCIAL_CUSTOMER_F',

  //GET ALL CATEGORIES
  GET_CATEGORIES_A: 'GET_CATEGORIES_A',
  GET_CATEGORIES_S: 'GET_CATEGORIES_S',
  GET_CATEGORIES_F: 'GET_CATEGORIES_F',

  //GET CATEGORIES MENU ITEMS
  GET_CATEGORIES_MENUITEMS_A: 'GET_CATEGORIES_MENUITEMS_A',
  GET_CATEGORIES_MENUITEMS_S: 'GET_CATEGORIES_MENUITEMS_S',
  GET_CATEGORIES_MENUITEMS_F: 'GET_CATEGORIES_MENUITEMS_F',


  //DELETE USER ACCOUNT 
  DELETE_ACCOUNT_A: 'DELETE_ACCOUNT_A',
  DELETE_ACCOUNT_S: 'DELETE_ACCOUNT_S',
  DELETE_ACCOUNT_F: 'DELETE_ACCOUNT_F',  


  //LINK COMPANY ACCOUNT 
  LINK_ACCOUNT_A: 'LINK_ACCOUNT_A',
  LINK_ACCOUNT_S: 'LINK_ACCOUNT_S',
  LINK_ACCOUNT_F: 'LINK_ACCOUNT_F',


  //ORDER LISTING
  ORDERS_LIST_A: 'ORDERS_LIST_A',
  ORDERS_LIST_S: 'ORDERS_LIST_S',
  ORDERS_LIST_F: 'ORDERS_LIST_F',

  //ORDER DETAILS LISTING
  ORDER_DETAILS_A: 'ORDER_DETAILS_A',
  ORDER_DETAILS_S: 'ORDER_DETAILS_S',
  ORDER_DETAILS_F: 'ORDER_DETAILS_F',

  //SELECT LOCATION LISTING
  LOCATION_A: 'LOCATION_A',
  LOCATION_S: 'LOCATION_S',
  LOCATION_F: 'LOCATION_F',

  //SPECIAL OFFERS
  CHECK_SPECIAL_OFFERS_A: 'CHECK_SPECIAL_OFFERS_A',
  CHECK_SPECIAL_OFFERS_S: 'CHECK_SPECIAL_OFFERS_S',
  CHECK_SPECIAL_OFFERS_F: 'CHECK_SPECIAL_OFFERS_F',

  //COMPANY CREDIT SCORE TYPE
  CHECK_COMPANY_CREDIT_SCORE_A: 'CHECK_COMPANY_CREDIT_SCORE_A',
  CHECK_COMPANY_CREDIT_SCORE_S: 'CHECK_COMPANY_CREDIT_SCORE_S',
  CHECK_COMPANY_CREDIT_SCORE_F: 'CHECK_COMPANY_CREDIT_SCORE_F',

  //ADD COMPANY ADDRESS
  ADD_COMPNAY_ADDRESS_A: 'ADD_COMPNAY_ADDRESS_A',
  ADD_COMPNAY_ADDRESS_S: 'ADD_COMPNAY_ADDRESS_S',
  ADD_COMPNAY_ADDRESS_F: 'ADD_COMPNAY_ADDRESS_F',

  //GET DELIVERY ADDRESS
  GET_DELIVERY_ADDRESS_A: 'GET_DELIVERY_ADDRESS_A',
  GET_DELIVERY_ADDRESS_S: 'GET_DELIVERY_ADDRESS_S',
  GET_DELIVERY_ADDRESS_F: 'GET_DELIVERY_ADDRESS_F',

  //DELETE DELIVERY ADDRESS
  DELETE_DELIVERY_ADDRESS_A: 'DELETE_DELIVERY_ADDRESS_A',
  DELETE_DELIVERY_ADDRESS_S: 'DELETE_DELIVERY_ADDRESS_S',
  DELETE_DELIVERY_ADDRESS_F: 'DELETE_DELIVERY_ADDRESS_F',

  //ADD COMPANY ADDRESS
  ADD_DELIVERY_ADDRESS_A: 'ADD_DELIVERY_ADDRESS_A',
  ADD_DELIVERY_ADDRESS_S: 'ADD_DELIVERY_ADDRESS_S',
  ADD_DELIVERY_ADDRESS_F: 'ADD_DELIVERY_ADDRESS_F',

  //SELECT APP MESSAGE LISTING
  APP_MESSAGE_A: 'APP_MESSAGE_A',
  APP_MESSAGE_S: 'APP_MESSAGE_S',
  APP_MESSAGE_F: 'APP_MESSAGE_F',

  //ADD ORDERS
  ADD_ORDERS_A: 'ADD_ORDERS_A',
  ADD_ORDERS_S: 'ADD_ORDERS_S',
  ADD_ORDERS_F: 'ADD_ORDERS_F',

  //CHECK ITEM TIME
  CHECK_ITEM_TIME_A: 'CHECK_ITEM_TIME_A',
  CHECK_ITEM_TIME_S: 'CHECK_ITEM_TIME_S',
  CHECK_ITEM_TIME_F: 'CHECK_ITEM_TIME_F',

  //GET SAVED CARDS
  GET_SAVED_CARD_A: 'GET_SAVED_CARD_A',
  GET_SAVED_CARD_S: 'GET_SAVED_CARD_S',
  GET_SAVED_CARD_F: 'GET_SAVED_CARD_F',

  //GET REORDER INFO
  GET_REORDER_INFO_A: 'GET_REORDER_INFO_A',
  GET_REORDER_INFO_S: 'GET_REORDER_INFO_S',
  GET_REORDER_INFO_F: 'GET_REORDER_INFO_F',

  //GET ITEMS DETAILS
  GET_ITEM_DETAILS_A: 'GET_ITEM_DETAILS_A',
  GET_ITEM_DETAILS_S: 'GET_ITEM_DETAILS_S',
  GET_ITEM_DETAILS_F: 'GET_ITEM_DETAILS_F',

  //ADD USER FEEDBACK
  ADD_FEEDBACK_A: 'ADD_FEEDBACK_A',
  ADD_FEEDBACK_S: 'ADD_FEEDBACK_S',
  ADD_FEEDBACK_F: 'ADD_FEEDBACK_F',

  //GET CARD LIST LISTING
  GET_CARD_LIST_A: 'GET_CARD_LIST_A',
  GET_CARD_LIST_S: 'GET_CARD_LIST_S',
  GET_CARD_LIST_F: 'GET_CARD_LIST_F',

  //ADD CARD DETAILS LISTING
  ADD_CARD_DETAILS_A: 'ADD_CARD_DETAILS_A',
  ADD_CARD_DETAILS_S: 'ADD_CARD_DETAILS_S',
  ADD_CARD_DETAILS_F: 'ADD_CARD_DETAILS_F',

  //DELETE CARD LISTING
  DELETE_CARD_DETAILS_A: 'DELETE_CARD_DETAILS_A',
  DELETE_CARD_DETAILS_S: 'DELETE_CARD_DETAILS_S',
  DELETE_CARD_DETAILS_F: 'DELETE_CARD_DETAILS_F',

  //WAITER LIST LISTING
  WAITER_LIST_A: 'WAITER_LIST_A',
  WAITER_LIST_S: 'WAITER_LIST_S',
  WAITER_LIST_F: 'WAITER_LIST_F',

  //WAITER DETAILS
  WAITER_DETAIL_A: 'WAITER_DETAIL_A',
  WAITER_DETAIL_S: 'WAITER_DETAIL_S',
  WAITER_DETAIL_F: 'WAITER_DETAIL_F',


  //GET AVOCADO DETAILS
  GET_AVOCADO_DETAILS_A: 'GET_AVOCADO_DETAILS_A',
  GET_AVOCADO_DETAILS_S: 'GET_AVOCADO_DETAILS_S',
  GET_AVOCADO_DETAILS_F: 'GET_AVOCADO_DETAILS_F',

  //CHECK DELIVERY ADDRESS
  CHECK_DELIVERY_ADDRESS_A: 'CHECK_DELIVERY_ADDRESS_A',
  CHECK_DELIVERY_ADDRESS_S: 'CHECK_DELIVERY_ADDRESS_S',
  CHECK_DELIVERY_ADDRESS_F: 'CHECK_DELIVERY_ADDRESS_F',

  //Cancel order 
  CANCEL_ORDER_A: 'CANCEL_ORDER_A',
  CANCEL_ORDER_S: 'CANCEL_ORDER_S',
  CANCEL_ORDER_F: 'CANCEL_ORDER_F',
  
  //RESTAURANT INFO 
  GET_RESTO_INFO_A: 'GET_RESTO_INFO_A',
  GET_RESTO_INFO_S: 'GET_RESTO_INFO_S',
  GET_RESTO_INFO_F: 'GET_RESTO_INFO_F',

  //VOUCHER CODE
  APPLY_VOUCHER_CODE_A: 'APPLY_VOUCHER_CODE_A',
  APPLY_VOUCHER_CODE_S: 'APPLY_VOUCHER_CODE_S',
  APPLY_VOUCHER_CODE_F: 'APPLY_VOUCHER_CODE_F',

  //RESSET PASSWORD
  RESET_PASSWORD_A: 'RESET_PASSWORD_A',
  RESET_PASSWORD_S: 'RESET_PASSWORD_S',

  //OFFICE REGISTRATION
  OFFICE_REGISTER_A: 'OFFICE_REGISTER_A',
  OFFICE_REGISTER_S: 'OFFICE_REGISTER_S',
  OFFICE_REGISTER_F: 'OFFICE_REGISTER_F',

  //Calculate Delivery Charges
  DELIVERY_CHARGE_A: 'DELIVERY_CHARGE_A',
  DELIVERY_CHARGE_S: 'DELIVERY_CHARGE_S',
  DELIVERY_CHARGE_F: 'DELIVERY_CHARGE_F',
  
  //Check next day order
  CHECK_NEXTDAY_ORDER_A: 'CHECK_NEXTDAY_ORDER_A',
  CHECK_NEXTDAY_ORDER_S: 'CHECK_NEXTDAY_ORDER_S',
  CHECK_NEXTDAY_ORDER_F: 'CHECK_NEXTDAY_ORDER_F',
  
  //Get Company List
  GET_COMPANY_LIST_A: 'GET_COMPANY_LIST_A',
  GET_COMPANY_LIST_S: 'GET_COMPANY_LIST_S',
  GET_COMPANY_LIST_F: 'GET_COMPANY_LIST_F',
  
  //REMOVE LINKED COMPANY
  REMOVE_LINKED_COMPANY_A: 'REMOVE_LINKED_COMPANY_A',
  REMOVE_LINKED_COMPANY_S: 'REMOVE_LINKED_COMPANY_S',
  REMOVE_LINKED_COMPANY_F: 'REMOVE_LINKED_COMPANY_F',
  
  //REMOVE LINKED COMPANY
  GET_BEST_OFFERS_A: 'GET_BEST_OFFERS_A',
  GET_BEST_OFFERS_S: 'GET_BEST_OFFERS_S',
  GET_BEST_OFFERS_F: 'GET_BEST_OFFERS_F',

  //REMOVE LINKED COMPANY
  GET_TABLE_BOOKIG_SLOAT_A: 'GET_TABLE_BOOKIG_SLOAT_A',
  GET_TABLE_BOOKIG_SLOAT_S: 'GET_TABLE_BOOKIG_SLOAT_S',
  GET_TABLE_BOOKIG_SLOAT_F: 'GET_TABLE_BOOKIG_SLOAT_F',

  //REMOVE LINKED COMPANY
  ADD_TABLE_RESERVATION_A: 'ADD_TABLE_RESERVATION_A',
  ADD_TABLE_RESERVATION_S: 'ADD_TABLE_RESERVATION_S',
  ADD_TABLE_RESERVATION_F: 'ADD_TABLE_RESERVATION_F',

  //REMOVE LINKED COMPANY
  GET_TABLE_BOOKING_HISTORY_BY_CUSTOMER_A: 'GET_TABLE_BOOKING_HISTORY_BY_CUSTOMER_A',
  GET_TABLE_BOOKING_HISTORY_BY_CUSTOMER_S: 'GET_TABLE_BOOKING_HISTORY_BY_CUSTOMER_S',
  GET_TABLE_BOOKING_HISTORY_BY_CUSTOMER_F: 'GET_TABLE_BOOKING_HISTORY_BY_CUSTOMER_F',

  //REMOVE LINKED COMPANY
  UPDATE_TABLE_RES_BOOKING_REQUEST_A: 'UPDATE_TABLE_RES_BOOKING_REQUEST_A',
  UPDATE_TABLE_RES_BOOKING_REQUEST_S: 'UPDATE_TABLE_RES_BOOKING_REQUEST_S',
  UPDATE_TABLE_RES_BOOKING_REQUEST_F: 'UPDATE_TABLE_RES_BOOKING_REQUEST_F',

  //GET CUSTOMER DETAILS
  GET_CUSTOMER_DETAIL_A: 'GET_CUSTOMER_DETAIL_A',
  GET_CUSTOMER_DETAIL_S: 'GET_CUSTOMER_DETAIL_S',
  GET_CUSTOMER_DETAIL_F: 'GET_CUSTOMER_DETAIL_F',
  

    //GET CUSTOMER DETAILS
    GET_VIRTUAL_QUEUE_LIST_A: 'GET_VIRTUAL_QUEUE_LIST_A',
    GET_VIRTUAL_QUEUE_LIST_S: 'GET_VIRTUAL_QUEUE_LIST_S',
    GET_VIRTUAL_QUEUE_LIST_F: 'GET_VIRTUAL_QUEUE_LIST_F',

    //GET CUSTOMER DETAILS
    ADD_VIRTUAL_QUEUE_A: 'ADD_VIRTUAL_QUEUE_A',
    ADD_VIRTUAL_QUEUE_S: 'ADD_VIRTUAL_QUEUE_S',
    ADD_VIRTUAL_QUEUE_F: 'ADD_VIRTUAL_QUEUE_F',

    //GET CUSTOMER DETAILS
    CANCEL_VIRTUAL_QUEUE_A: 'CANCEL_VIRTUAL_QUEUE_A',
    CANCEL_VIRTUAL_QUEUE_S: 'CANCEL_VIRTUAL_QUEUE_S',
    CANCEL_VIRTUAL_QUEUE_F: 'CANCEL_VIRTUAL_QUEUE_F',


    //GET Member offers list
    GET_MEMBER_OFFER_LIST_A: 'GET_MEMBER_OFFER_LIST_A',
    GET_MEMBER_OFFER_LIST_S: 'GET_MEMBER_OFFER_LIST_S',
    GET_MEMBER_OFFER_LIST_F: 'GET_MEMBER_OFFER_LIST_F',

    //GET Member offers list
    GET_LOYALTY_REWARD_LIST_A: 'GET_LOYALTY_REWARD_LIST_A',
    GET_LOYALTY_REWARD_LIST_S: 'GET_LOYALTY_REWARD_LIST_S',
    GET_LOYALTY_REWARD_LIST_F: 'GET_LOYALTY_REWARD_LIST_F',


    //GET Member offers list
    GET_LOYALTY_REWARD_HISTORY_LIST_A: 'GET_LOYALTY_REWARD_HISTORY_LIST_A',
    GET_LOYALTY_REWARD_HISTORY_LIST_S: 'GET_LOYALTY_REWARD_HISTORY_LIST_S',
    GET_LOYALTY_REWARD_HISTORY_LIST_F: 'GET_LOYALTY_REWARD_HISTORY_LIST_F',

    //GET Member offers list
    GET_BEST_OFFER_LIST_A: 'GET_BEST_OFFER_LIST_A',
    GET_BEST_OFFER_LIST_S: 'GET_BEST_OFFER_LIST_S',
    GET_BEST_OFFER_LIST_F: 'GET_BEST_OFFER_LIST_F',

     //GET Member offers list
     GET_MEMBER_OFFER_DISCOUNT_A: 'GET_MEMBER_OFFER_DISCOUNT_A',
     GET_MEMBER_OFFER_DISCOUNT_S: 'GET_MEMBER_OFFER_DISCOUNT_S',
     GET_MEMBER_OFFER_DISCOUNT_F: 'GET_MEMBER_OFFER_DISCOUNT_F',

     //addRemoveFavItem
     ADD_REMOVE_FAV_ITEM_A: 'ADD_REMOVE_FAV_ITEM_A',
     ADD_REMOVE_FAV_ITEM_S: 'ADD_REMOVE_FAV_ITEM_S',
     ADD_REMOVE_FAV_ITEM_F: 'ADD_REMOVE_FAV_ITEM_F',

     //addRemoveFavItem
     GET_FAV_ITEM_A: 'GET_FAV_ITEM_A',
     GET_FAV_ITEM_S: 'GET_FAV_ITEM_S',
     GET_FAV_ITEM_F: 'GET_FAV_ITEM_F',

         //  getLoyaltyPointList
    GET_LOYALTY_POINT_LIST_A : 'GET_LOYALTY_POINT_LIST_A',
    GET_LOYALTY_POINT_LIST_S : 'GET_LOYALTY_POINT_LIST_S',
    GET_LOYALTY_POINT_LIST_F : 'GET_LOYALTY_POINT_LIST_F',
};

