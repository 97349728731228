import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";

import Constants from "../constants/baseConstants";
import { Strings } from "../constants/Localization";
import Modal from "react-bootstrap/Modal";
import { getSecureData } from "../Utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

class AddressPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      arrayAddress: this.props.addressList.AddressLists,
      selectedIndex: null,
      languageType: 1,
    };
  }

  componentDidMount() {
    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
    let session = getSecureData(Constants.PRE_MESSAGE_LIST);
    let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }
  }

  onDeleteAddress(index) {
    let objAdd = this.state.arrayAddress[index];
    let addressId = objAdd?.deliveryaddressId;
    this.props.deleteAddressAPI(addressId).then(() => {
      console.log("deleteAddressAPI response ----- ", this.props.data.result);
      if (this.props.data.status == 1) {
        setTimeout(() => {
          var tempArray = this.state.arrayAddress;
          let index = tempArray.indexOf(objAdd);
          if (index >= -1) {
            tempArray.splice(index, 1);
          }
          this.setState({ arrayAddress: tempArray });
          this.props.onDeleteAddress();
        }, 500);
      } else {
      }
    });
  }

  onSelect(i) {
    console.log("selected index ----- ", i);
    this.setState({ selectedIndex: i });
  }

  renderAddressList() {
    let arr = [];
    if (!this.state.arrayAddress) {
      arr.push(<label className="noData">{Strings.no_record_address}</label>);
      return arr;
    }
    this.state.arrayAddress.map((address, index) => {
      arr.push(
        // <div>
        //   <input type='radio' id={index} name='rd' onClick={()=>this.onSelect(index)}/>
        //   <div>
        //       <label>{address.address1}</label><br/>
        //       {address.address2 ? <label>Comment: {address.address2}</label> : null }
        //       <button onClick={()=>this.onDeleteAddress(index)}>Delete</button>
        //   </div>
        // </div>
        <li key={index}>
          <div onClick={() => this.onSelect(index)}>
            {/* <div className="custom-control custom-radio custom-control-inline">
                      <input type="radio" className="custom-control-input" name="customRadio1400" id="customRadio1400" checked={this.state.selectedIndex == index ? true : false} />
                      <label className="custom-control-label" for="customRadio1400">{address.address1}{address.address2 ? <span><br/>Comment: {address.address2}</span> : null }</label>
                    </div> */}

            <button className="checked-btn">
              {/* <img src={this.state.selectedIndex == index ? '/images/tick.png' : '/images/untick.png'}/> */}
              {this.state.selectedIndex == index ? (
                <FontAwesomeIcon size="20px" icon={faCheckCircle} />
              ) : (
                <i class="fa fa-circle-o" aria-hidden="true"></i>
              )}
            </button>
            <label className="container1">
              {/* {address.address2
                ? address.address2
                : // <span>
                  //   <br />
                  //   Comment: {address.address2}
                  // </span>
                  null}{" "} */}
              {address.address1} {address.zipcode ? address.zipcode : null}
            </label>
            <a
              href="#"
              className="bin"
              onClick={() => this.onDeleteAddress(index)}
            >
              <img src="/images/delete.png" />
            </a>
          </div>
        </li>
      );
    });
    return arr;
  }

  onSelectAddressClick() {
    if (!this.state.arrayAddress) {
      return;
    }

    console.log(
      "this.state.arrayAddress[this.state.selectedIndex].deliveryaddressId",
      this.state.arrayAddress[this.state.selectedIndex]
    );

    this.props.onHideAddressView(
      this.state.arrayAddress[
        this.state.selectedIndex ? this.state.selectedIndex : 0
      ]
    );
    return this.props
      .checkDeliveryAddressID(
        this.state.languageType,
        this.state.arrayAddress[
          this.state.selectedIndex ? this.state.selectedIndex : 0
        ]?.deliveryaddressId
      )
      .then(() => {
        if (this.props.data.status == 1) {
          setTimeout(() => {
            if (this.props.data.result.delivery) {
              //valid address
              this.props.onHideAddressView(
                this.state.arrayAddress[
                  this.state.selectedIndex ? this.state.selectedIndex : 0
                ]
              );
            }
          }, 100);
        } else {
          alert(this.props.data.message);
        }
      });
  }

  onAddNewAddress() {
    this.props.onAddNewAddress();
  }

  render() {
    return (
      <Modal
        show={this.props.isShow}
        onHide={() => {
          this.props.onHideAddressView();
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <div className="pop-header">
          
          <button type="button" className="close" data-dismiss="pop" onClick={()=>this.props.onClose({isHide: false})}>&times;</button>
        </div> */}
        </Modal.Header>
        <Modal.Body>
          {/* <div>
            <label>Select Address</label>
            {this.renderAddressList()}
            <button onClick={()=>{this.onSelectAddressClick()}}>Select Address</button>
            <button onClick={()=>{this.onAddNewAddress()}}>Add New Address</button>
        </div> */}
          <div className="address-popup">
            <h3>{Strings.select_address}</h3>

            <div className="address plate-div">
              <ul>{this.renderAddressList()}</ul>
            </div>

            <div className="price-btns">
              <a
                href="#"
                className="btn btn-primary-popup btn-small"
                onClick={() => {
                  this.onAddNewAddress();
                }}
              >
                {Strings.str_add_new_address}s
              </a>
              <a
                href="#"
                className="btn btn-primary-popup btn-small"
                onClick={() => {
                  this.onSelectAddressClick();
                }}
              >
                {Strings.select_address}
              </a>
            </div>
            {/* <div className="alertButtonDiv">
                      <a className={this.state.arrayAddress ? "btn btn-primary btn-small poupButton" : "btn btn-primary btn-small poupButton fullWidth"} onClick={()=>{this.onAddNewAddress()}} tabIndex={1}>{Strings.str_add_new_address}</a>
                      {this.state.arrayAddress ? <a className="btn btn-primary btn-small poupButton" onClick={()=>{this.onSelectAddressClick()}} tabIndex={2}>{Strings.select_address}</a> : null }
                    </div> */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  console.log("isLoaging Address Popup ------- " + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  deleteAddressAPI: (addressId) =>
    dispatch(HGActions.initDeleteDeliveryAddressAPI(addressId)),
  checkDeliveryAddressID: (languageType, deliveryaddressId) =>
    dispatch(
      HGActions.initCheckDeliveryAddressAPI(languageType, deliveryaddressId)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressPopup);
