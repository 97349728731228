import React, { Component } from "react";
import Constant from "../constants/baseConstants";
import { setSecureData, getSecureData } from "../Utils/utils";
import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";
import FooterMenu from "../Components/FooterMenu";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      locationArray: [],
      languageType: 1,
    };
  }

  componentWillMount() {
    this.fetchLocationList();
  }

  fetchLocationList() {
    this.props.locationListApi(this.state.languageType).then(() => {
      if (this.props.data.status == 1) {
        if (this.props.data.result != null) {
          var locationList = this.props.data.result;
          if (locationList != null && locationList.length > 0) {
            if (getSecureData(Constant.LOCATIONOBJECT)) {
              let objLocation = locationList.filter((obj) => {
                return (
                  obj.locationId ==
                  getSecureData(Constant.LOCATIONOBJECT).locationId
                );
              });

              this.setState({ locationObj: objLocation[0] }, () => {
                setSecureData(Constant.LOCATIONOBJECT, objLocation[0]);
                //   this.setLocationData()
              });
            } else {
              if (locationList.length == 1) {
                this.setState({ locationObj: locationList[0] }, () => {
                  setSecureData(Constant.LOCATIONOBJECT, locationList[0]);
                  // this.setLocationData()
                });
              } else {
                setSecureData(Constant.LOCATIONLISTOBJECT, locationList);
                this.setState({ locationArray: locationList, isVisible: true });
              }
            }
          }
        }
      } else {
        // alert(this.props.data.message)
      }
    });
  }
  renderAccountMenu() {
    var cId = 0;

    console.log(
      "localStorage.hasOwnProperty(Constant.USEROBJECT)",
      localStorage.hasOwnProperty(Constant.USEROBJECT)
    );
    if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
      // get the key's value from localStorage
      // let value = localStorage.getItem(Constant.USEROBJECT);
      let value = getSecureData(Constant.USEROBJECT);
      console.log("value=" + value);
      if (value !== undefined) {
        if (value !== null)
          // || value !== undefined)
          cId = value.customerId;
      }
    }

    if (cId > 0) {
      return (
        <>
          <div className="col">
            <div class="input-group-btn">
              <button
                className="btn btn-outline-light btnHomeOutline"
                onClick={() => {
                  window.location.href = "/MyAccount";
                }}
              >
                My Account
              </button>
            </div>
          </div>
          <div className="col">
            <div class="input-group-btn">
              <button
                className="btn btn-outline-light btnHomeOutline"
                onClick={() => {
                  window.location.href = "/MenuItems";
                }}
              >
                Order Online
              </button>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="col">
            <div class="input-group-btn">
              <button
                className="btn btn-outline-light btnHomeOutline"
                onClick={() => {
                  window.location.href = "/SignUp";
                }}
              >
                SIGN UP
              </button>
            </div>
          </div>
          {/* <div className="col">
                    <div class="input-group-btn">
                        <button className="btn btn-outline-light btnHomeOutline" onClick={() => {window.location.href="/SignIn"}} >LOGIN</button>
                    </div>
                </div> */}

          <div className="col">
            <div class="input-group-btn">
              <button
                className="btn btn-outline-light btnHomeOutline"
                onClick={() => {
                  window.location.href = "/MenuItems";
                }}
              >
                Skip
              </button>
            </div>
          </div>
        </>
      );
    }
  }
  render() {
    return (
      <>
        <div className="homePage">
          <div className="homepage-inner-center">
            <div className="inner-img-logo">
              <img src="./images/logo/culto-logo.png" alt="logo" />
            </div>
            <div className="inner-div-content">
              <div className="row">
                {/* <div className="col">
                                <div className="input-group">
                                    <input type="text" className="form-control homeSearch" placeholder="ENTER YOUR POSTCODE" />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary btnPostSearch" type="button">
                                            <img className="btn-img-search" src="./images/input-search.png" />
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                {/* <div className="col"> */}
                {this.renderAccountMenu()}
                {/* </div> */}
              </div>
            </div>
          </div>
          {/* <div className="homepage-footer">
                    <div className="hmapover-social">
                        <ul>
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="#"><i class="fab fa-tripadvisor"></i></a></li>
                        </ul>
                    </div>
                    <div className="footer-address" >
                        <p>(T) +44 (0) 113 3454434</p>
                        <p>(E) info@cultoitalian.co.uk</p>
                        <p>97 Stainbeek Road, Leeds, LS7 2PR</p>
                    </div>
                </div> */}
        </div>
        <FooterMenu />
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log("isLoaging ResetPassword ------- " + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  locationListApi: (languageType) =>
    dispatch(HGActions.GetLocationListApi(languageType)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
