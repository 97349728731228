import React, { Component } from "react";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as HGActions from "../Redux/actions/HGActions";
import { Strings } from "../constants/Localization";
import FooterMenu from "../Components/FooterMenu";
import Constant from "../constants/baseConstants";
import HeaderSignin from "../Components/HeaderSignin";
import HeaderWhiteMenuHalf from "../Components/HeaderWhiteMenuHalf";
import CMS from "../Components/CMS";
import { setSecureData, getSecureData } from "../Utils/utils";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="form-control"
  >
    {children}
    &#x25bc;signupwrap
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    // const [value, setValue] = useState('');
    const value = "";
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul>
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

class SignUp extends Component {
  constructor(props) {
    super(props);

    var userName = this.props.location.name;
    var emailId = this.props.location.emailId;
    var isFromSocialMedia = this.props.location.isFromSocialMedia;
    console.log("userName :" + userName + "  " + emailId);
    this.state = {
      name: "",
      countryCode: "+44",
      phoneNumber: "",
      email: "",
      password: "",
      birthDate: "",
      gender: 1,
      languageType: 1, //default language type
      isTandCSelect: false,
      isConsentCSelect: false,

      errors: {
        nameError: "",
        countryCodeError: "",
        phoneNumberError: "",
        emailError: "",
        passwordError: "",
        locationError: "",
        tAndCError: "",
        consentError: "",
        birthDateError: "",
      },

      isSocialSignUp: false,
      locationArray: [],
      locationIndex: -1,

      isShowCMS: false,
      cmsHref: "",
    };
    setTimeout(() => {
      if (isFromSocialMedia) {
        this.setState({ name: userName, email: emailId });
      }
    }, 200);
  }

  componentDidMount() {
    AOS.init({
      duration: 1000,
    });
    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
    let session = getSecureData(Constant.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
    let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }

    setTimeout(() => {
      this.props.locationListApi(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          if (this.props.data.result != null) {
            var locationList = this.props.data.result;
            if (locationList != null && locationList.length > 0) {
              // localStorage.setItem(Constant.LOCATIONLISTOBJECT, JSON.stringify(locationList))
              setSecureData(Constant.LOCATIONLISTOBJECT, locationList);
              this.setState({ locationArray: locationList }, () => {
                setSecureData(
                  Constant.LOCATIONOBJECT,
                  this.state.locationArray[0]
                );
              });
            }
          }
        } else {
          alert(this.props.data.message);
        }
        //CALL CHECK VERSION API
        // if (!localStorage.hasOwnProperty(Constant.CHECKINFO)) {
        //     this.props.callCheckVersion(0, this.state.languageType).then(() => {
        //         if (this.props.data.status == 1) {
        //             setTimeout(() => {
        //                 // localStorage.setItem(Constant.CHECKINFO, JSON.stringify(this.props.data.result))
        //                 setSecureData(Constant.CHECKINFO, this.props.data.result)
        //             }, 500)
        //         } else {
        //         }
        //     })
        // }
      });
    }, 200);
  }

  //Handle change language click event
  //English language type: 1
  //Spanish language type: 2
  languageChangeClickEvent = (type) => {
    console.log("sign up page language type :" + type);
    // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
    setSecureData(Constant.PRE_LANGUAGE_TYPE, type);
    this.setState({ languageType: type });
    setTimeout(() => {
      this.props.AppMessageApi(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          var messageListArray = this.props.data.result;
          if (messageListArray != null && messageListArray.length > 0) {
            console.log("message list  :" + JSON.stringify(messageListArray));
            // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
            setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
            // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
            let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
            // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
            setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType);
            // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
            let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
            console.log("language type  :" + languageType);
            if (languageType == 1) {
              //English language type: 1
              Strings.setLanguage("en"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "en-GB",
              });
            } else {
              Strings.setLanguage("es"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "es",
              });
            }
            Strings.setContent(localMessage);
            // this.setState({ languageType: languageType })
            window.location.reload(false);
          }
        } else {
          alert(this.props.error);
        }
      });
    }, 700);
  };

  onSignUpClick = (event) => {
    event.preventDefault();

    // this.props.history.push({pathname: '/MobileVerification'})
    // return
    var nmError =
      this.state.name.length == 0 ? Strings.error_msg_name_react : "";
    var ccodeerror =
      this.state.countryCode == "0" ? Strings.error_msg_country_code_react : "";
    var phError =
      this.state.phoneNumber.length < 9 ? Strings.error_msg_phone_no_react : "";
    var emlError =
      this.state.email.length == 0 ? Strings.error_msg_email_react : "";
    var passError =
      this.state.password.length == 0 ? Strings.error_msg_password_react : "";
    var locError = ""; //this.state.locationIndex == -1 ? Strings.please_select_location : '';
    var terror = this.state.isTandCSelect ? "" : Strings.msgTandC;
    var cerror = this.state.isConsentCSelect ? "" : Strings.msgConsent;
    var birthDateError =
      this.state.birthDate == "" ? Strings.error_msg_birth_react : "";

    if (emlError.length == 0) {
      validEmailRegex.test(this.state.email) == false
        ? (emlError = Strings.error_msg_valid_email_react)
        : (emlError = "");
    }

    this.setState({
      errors: {
        locationError: locError,
        nameError: nmError,
        countryCodeError: ccodeerror,
        phoneNumberError: phError,
        emailError: emlError,
        passwordError: passError,
        tAndCError: terror,
        consentError: cerror,
        birthDateError: birthDateError,
      },
    });

    setTimeout(() => {
      if (
        this.state.errors.nameError == "" &&
        this.state.errors.countryCodeError == "" &&
        this.state.errors.phoneNumberError == "" &&
        this.state.errors.emailError == "" &&
        this.state.errors.passwordError == "" &&
        this.state.errors.locationError == "" &&
        this.state.errors.tAndCError == "" &&
        this.state.errors.consentError == "" &&
        this.state.errors.birthDateError == ""
      ) {
        console.info("Valid form");

        this.props
          .SignUpAPICall(
            getSecureData(Constant.LOCATIONOBJECT).locationId,
            0,
            this.state.name,
            this.state.countryCode,
            this.state.phoneNumber,
            this.state.email,
            this.state.password,
            this.state.languageType,
            this.state.gender,
            this.state.birthDate
          )
          .then(() => {
            console.log("SIGNUP RESPONSE --------- ", this.props.data);
            if (this.props.data.status == 1) {
              if (this.props.data.result != null) {
                setTimeout(() => {
                  let result = this.props.data.result;
                  setSecureData(Constant.USEROBJECT, this.props.data.result);
                  this.props.history.push({ pathname: "/MenuItems" });
                  // console.log("SIGNUP RESPONSE --------- ", JSON.stringify(result))
                  // if (result.isMobileVerify == true) {

                  // } else {
                  //     this.props.history.push({ pathname: '/MobileVerification', customerId: result.customerId, phone: this.state.countryCode + this.state.phoneNumber, message: this.props.data.message })
                  // }
                }, 100);
              }
            } else {
              alert(this.props.data.message);
              console.log("sign up status else...");
            }
          });
      } else {
        console.error("Not valid form");
      }
    }, 100);
  };

  onSelectLocation(e) {
    if (e != "") {
      this.setState({ locationIndex: e });
      setSecureData(Constant.LOCATIONOBJECT, this.state.locationArray[e]);
    } else {
      this.setState({ locationIndex: -1 });
    }
  }

  renderLocationList() {
    var arrReturn = [];
    this.state.locationArray.map((location, index) => {
      arrReturn.push(
        <Dropdown.Item eventKey={index}>{location.locationName}</Dropdown.Item>
      );
    });

    return (
      <Dropdown onSelect={(e) => this.onSelectLocation(e)}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {this.state.locationIndex == -1
            ? Strings.lbl_select_location_react
            : this.state.locationArray[this.state.locationIndex].locationName}
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={CustomMenu}
          style={{ height: "150px", overflow: "scroll" }}
        >
          {arrReturn}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  onMobileEnter(str) {
    if (str.match("^[0-9]*$") != null) {
      this.setState({ phoneNumber: str });
    }
  }

  onTandC(i) {
    if (i == 1) {
      this.setState({ isTandCSelect: !this.state.isTandCSelect });
    } else if (i == 2) {
      this.setState({ isConsentCSelect: !this.state.isConsentCSelect });
    }
  }

  render() {
    const responseGoogle = (response) => {
      if (response.error == null) {
        var res = response.profileObj;
        console.log("google response success----- ", res);
        if (res == null) {
          return;
        }
        this.setState({ name: res.name, email: res.email });
        return;
        // this.props.googleSignUpCall(res.email,3,res.googleId,this.state.languageType).then(() => {
        //     console.log("GOOGLE SIGNUP RESPONSE --------- ",this.props.data)
        //     if (this.props.data.status == 1) {
        //         if (this.props.data.result != null) {
        //             setTimeout(() => {
        //                 console.log("GOOGLE - SIGNUP RESPONSE --------- ",JSON.stringify(this.props.data.result))
        //             }, 100)
        //         }
        //     } else {
        //         alert(this.props.data.message)
        //         console.log("sign up status else...")
        //     }
        // })
      } else {
        console.log("google response failed----- ", response.error);
      }
    };

    const loginWithFacebook = (response) => {
      console.log("login with facebook  :" + JSON.stringify(response));
    };

    return (
      <div className="loginpage white nonsticy">
        {this.props.isLoading ? (
          <div class="loadermain" style={{ display: "block" }}>
            <div class="loader"></div>
          </div>
        ) : (
          <div class="loadermain" style={{ display: "none" }}>
            <div class="loader"></div>
          </div>
        )}
        {/* <HeaderSignin logoType={1} headerLbl={Strings.strHeaderSignUp} isCategory={true} /> */}
        <HeaderWhiteMenuHalf
          isHideOrderType={true}
          logoType={1}
          headerLbl={Strings.strHeaderSignUp}
          changeLanguageEvent={this.languageChangeClickEvent}
        />
        <CMS
          isShow={this.state.isShowCMS}
          href={this.state.cmsHref}
          onDisable={() => this.setState({ isShowCMS: false })}
        />
        {/* <HeaderWhiteMenu
                    changeLanguageEvent={this.languageChangeClickEvent} /> */}

        {/* <h2>{this.props.match.params.id}</h2> */}
        <div class="content_wrap sgn">
          <div class="container">
            <div class="loginwrap sign-btm-spc ">
              {/* <h1>{Strings.btn_signup_react}</h1> */}
              <div class="row">
                <div class="col-md-12 col-lg-12  max-560">
                  <div className="loginTitle">
                    <h1 class="borderTitleBottom">
                      <a href="/">Home </a>&gt;&gt; SignUp
                    </h1>
                  </div>
                  <div className="loginTitle mb-5">
                    <h2>SIGNUP</h2>
                  </div>
                  <div class="form-group">
                    <label>{Strings.lbl_name_react}*</label>
                    <input
                      type="text"
                      class="form-control"
                      maxLength={64}
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      tabIndex="1"
                    />
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.nameError}
                      </span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>{Strings.lbl_email_react}*</label>
                    <input
                      type="text"
                      class="form-control"
                      maxLength={64}
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      tabIndex="3"
                    />
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.emailError}
                      </span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>{Strings.lbl_phone_number_react}*</label>
                    <div class="row">
                      <div class="col-5 col-md-2 pr-0 smDiv">
                        <input
                          readonly
                          type="text"
                          className="form-control text-center"
                          value="+44"
                          style={{ pointerEvents: "none" }}
                        />
                      </div>
                      <div class="col-7 col-md-10 lmDiv">
                        <input
                          type="text"
                          class="form-control"
                          maxLength={11}
                          value={this.state.phoneNumber}
                          onChange={(e) => this.onMobileEnter(e.target.value)}
                          tabIndex="2"
                        />
                      </div>
                    </div>
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.countryCodeError}
                      </span>
                      <span className="errorMsg">
                        {this.state.errors.phoneNumberError}
                      </span>
                    </div>
                  </div>

                  <div className="form-group BirthDate">
                    <label>Birth Date*</label>
                    {/* <input type="date" name="birthDate" className="form-control" value={this.state.birthDate}  onChange={e => this.setState({ birthDate: e.target.value })} /> */}
                    <DatePicker
                      onChange={(date) => {
                        this.setState({ birthDate: date });
                      }}
                      selected={this.state.birthDate}
                      name="birthDate"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                    />
                    {/* <i className="fa fa-calendar calenderIcon" aria-hidden="true"></i> */}
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.birthDateError}
                      </span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>{Strings.lbl_password_react_sign_in}*</label>
                    <input
                      type="password"
                      class="form-control"
                      maxLength={64}
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      tabIndex="4"
                    />
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.passwordError}
                      </span>
                    </div>
                  </div>

                  {/* <div className="form-group">
                                            <label>Gender:*</label>
                                            <br/>
                                            <div class="form-check form-check-inline">
                                                <input className="form-check-input" id="inlineRadio1" type="radio" name="gender"  value={1} onChange={e => this.setState({ gender: 1 })} checked={this.state.gender === 1} />
                                                <label className="form-check-label" for="inlineRadio1">Male</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input className="form-check-input" id="inlineRadio2" type="radio" name="gender"  value={2} onChange={e => this.setState({ gender: 2 })} checked={this.state.gender === 2} />
                                                <label className="form-check-label" for="inlineRadio2">Female</label>
                                            </div>
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.emailError}</span>
                                        </div>
                                    </div> */}
                  <div className="form-group">
                    <label>Gender*</label>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        className="custom-control-input"
                        id="inlineRadio1"
                        type="radio"
                        name="gender"
                        value={1}
                        onChange={(e) => this.setState({ gender: 1 })}
                        checked={this.state.gender === 1}
                      />
                      <label
                        onClick={() => {
                          this.setState({ gender: 1 });
                        }}
                        className="form-check-label"
                        for="inlineRadio1"
                      >
                        <i
                          class={
                            this.state.gender == 1
                              ? "fas fa-check-circle"
                              : "fa fa-circle-o"
                          }
                          aria-hidden="true"
                        >
                          {" "}
                        </i>{" "}
                        {"  "} Male
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        className="custom-control-input"
                        id="inlineRadio2"
                        type="radio"
                        name="gender"
                        value={2}
                        onChange={(e) => this.setState({ gender: 2 })}
                        checked={this.state.gender === 2}
                      />
                      <label
                        onClick={() => {
                          this.setState({ gender: 2 });
                        }}
                        className="form-check-label"
                        for="inlineRadio2"
                      >
                        <i
                          class={
                            this.state.gender == 2
                              ? "fas fa-check-circle"
                              : "fa fa-circle-o"
                          }
                          aria-hidden="true"
                        >
                          {" "}
                        </i>{" "}
                        {"  "}Female
                      </label>
                    </div>
                    {/* <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.emailError}</span>
                                        </div> */}
                  </div>
                  {/* <button className="checked-btn" onClick={() => { this.onTandC(1) }}><img src={this.state.isTandCSelect ? "/images/tick.png" : "/images/untick.png"} /></button><label className="container1 optValue">{Strings.lblTandC}</label>
                                    <div className="errorDiv">
                                        <span className='errorMsg'>{this.state.errors.tAndCError}</span>
                                    </div>
                                    <button className="checked-btn" onClick={() => { this.onTandC(2) }}><img src={this.state.isConsentCSelect ? "/images/tick.png" : "/images/untick.png"} /></button><label className="container1 optValue">{Strings.lblConsent}</label>
                                    <div className="errorDiv">
                                        <span className='errorMsg'>{this.state.errors.consentError}</span>
                                    </div> */}
                  <div class="form-group">
                    <div className="custom-control custom-radio custom-control-inline newc">
                      {this.state.isTandCSelect ? (
                        <i
                          class="fa fa-check-circle"
                          aria-hidden="true"
                          onClick={() => {
                            this.onTandC(1);
                          }}
                        >
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="customRadio1"
                            id="customRadio1"
                            checked={this.state.isTandCSelect ? true : false}
                          />
                        </i>
                      ) : (
                        <i
                          class="fa fa-circle-o"
                          aria-hidden="true"
                          onClick={() => {
                            this.onTandC(1);
                          }}
                        >
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="customRadio1"
                            id="customRadio1"
                            checked={this.state.isTandCSelect ? true : false}
                          />
                        </i>
                      )}

                      <label
                        className="custom-control-label hlink"
                        onClick={() => {
                          this.setState({
                            cmsHref:
                              "http://culto.smartrestaurants.com/frmtermsandcondition.aspx",
                            // isShowCMS: true,
                          });
                          return window
                            .open(
                              "http://culto.smartrestaurants.com/frmtermsandcondition.aspx",
                              "_blank"
                            )
                            .focus();
                        }}
                      >
                        {Strings.lblTandC}
                      </label>
                    </div>
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.tAndCError}
                      </span>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline newc">
                      {this.state.isConsentCSelect ? (
                        <i
                          onClick={() => {
                            this.onTandC(2);
                          }}
                          class="fa fa-check-circle"
                          aria-hidden="true"
                        >
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="customRadio2"
                            id="customRadio2"
                            checked={this.state.isConsentCSelect ? true : false}
                          />
                        </i>
                      ) : (
                        <i
                          onClick={() => {
                            this.onTandC(2);
                          }}
                          class="fa fa-circle-o"
                          aria-hidden="true"
                        >
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="customRadio2"
                            id="customRadio2"
                            checked={this.state.isConsentCSelect ? true : false}
                          />
                        </i>
                      )}

                      <label
                        className="custom-control-label hlink"
                        onClick={() => {
                          this.setState({
                            cmsHref:
                              "http://culto.smartrestaurants.com/frmPrivacyNotice.aspx",
                            // isShowCMS: true,
                          });
                          return window
                            .open(
                              "http://culto.smartrestaurants.com/frmPrivacyNotice.aspx",
                              "_blank"
                            )
                            .focus();
                        }}
                      >
                        {Strings.lblConsent}
                      </label>
                    </div>
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.consentError}
                      </span>
                    </div>
                  </div>
                  {/* <div class="form-group">
                                        <label>{Strings.lbl_select_location_react}*</label>
                                        {this.renderLocationList()}
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.locationError}</span>
                                        </div>
                                    </div> */}
                  <div class="signup mt-5">
                    {/* <a href="#" class="btn btn-primary btn-big" onClick={this.onSignUpClick} tabIndex="5">{Strings.btn_signup_react}</a> */}
                    <a
                      href="#"
                      className="btn btn-primary btn-large btnSignUpForm"
                      onClick={this.onSignUpClick}
                      tabIndex="5"
                    >
                      {Strings.btn_signup_react}
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-5 text-center">
                <div className="col">
                  <h4>
                    <a href="/signin"> ALREADY REGISTERED? SIGN IN </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("isLoaging SignUp ------- " + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  SignUpAPICall: (
    locationId,
    customerId,
    name,
    countrycode,
    phone,
    email,
    password,
    languageType,
    gender,
    birthDate
  ) =>
    dispatch(
      HGActions.initRegistrationCall(
        locationId,
        customerId,
        name,
        countrycode,
        phone,
        email,
        password,
        languageType,
        gender,
        birthDate
      )
    ),
  googleSignUpCall: (email, sMediaFlag, smediaId, languageType) =>
    dispatch(
      HGActions.initSocialSignUpCall(email, sMediaFlag, smediaId, languageType)
    ),
  socialCustomerAddUpdate: (
    name,
    countrycode,
    phone,
    email,
    password,
    sMediaFlag,
    smediaId
  ) =>
    dispatch(
      HGActions.initAddUpdateSocialCustomerCall(
        name,
        countrycode,
        phone,
        email,
        password,
        sMediaFlag,
        smediaId
      )
    ),
  locationListApi: (languageType) =>
    dispatch(HGActions.GetLocationListApi(languageType)),
  AppMessageApi: (languageType) =>
    dispatch(HGActions.GetAppMessageListApi(languageType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
