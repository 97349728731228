import React, { Component } from "react";
import Constants from "../constants/baseConstants";
import Modal from "react-bootstrap/Modal";

import {
  Route,
  NavLink,
  BrowserRouter as Router,
  Switch,
  Link,
  history,
} from "react-router-dom";
import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";
import { Strings } from "../constants/Localization";
import Constant from "../constants/baseConstants";
import { setSecureData, getSecureData } from "../Utils/utils";
import { Dropdown } from "react-bootstrap";

class ForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode: "+44",
      phoneNumber: "",
      password: "",
      forgetPhoneNo: "",
      forgetCountryCode: "+44",
      socialId: "",
      loginType: 0,

      errors: {
        countryCodeError: "",
        phoneNumberError: "",
        passwordError: "",
        forgetCountryCodeError: "",
        forgetPhoneNumberError: "",
      },

      languageType: 1,
    };
  }

  onEnterPassword(event) {
    if (event.key === "Enter") {
      this.onSignInClick();
    }
  }

  onMobileEnter(str, type) {
    console.log(str);
    if (str.match("^[0-9]*$") != null) {
      if (type == 1) {
        this.setState({ phoneNumber: str });
      } else if (type == 2) {
        this.setState({ forgetPhoneNo: str });
      }
    }
  }

  onEnterForgotPassword = (event) => {
    console.log(event);
    if (event.key === "Enter") {
      this.validateForgetPassword();
    }
  };

  //Call Forget Passoword Api
  validateForgetPassword = () => {
    // event.preventDefault();

    var ccodeerror =
      this.state.forgetCountryCode == "0"
        ? Strings.error_msg_country_code_react
        : "";
    var phError =
      this.state.forgetPhoneNo.length < 9
        ? Strings.error_msg_phone_no_react
        : "";

    this.setState({
      errors: {
        forgetCountryCodeError: ccodeerror,
        forgetPhoneNumberError: phError,
      },
    });

    setTimeout(() => {
      if (
        this.state.errors.forgetCountryCodeError == "" &&
        this.state.errors.forgetPhoneNumberError == ""
      ) {
        this.props
          .forgetPasswordApi(
            this.state.forgetPhoneNo,
            this.state.forgetCountryCode,
            this.state.languageType
          )
          .then(() => {
            setTimeout(() => {
              if (this.props.data.status == 1) {
                alert(this.props.data.message);
              } else {
                alert(this.props.error);
              }
            }, 100);
          });
      }
    }, 100);
  };

  render() {
    return (
      <>
        <Modal
          show={this.props.isShowForm}
          onHide={() => {
            this.props.onResponse();
          }}
          size="sm"
          animation={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="forgetModelWrap">
              <p>
                <label className="dont forgetModelWrapTitle">
                  {Strings.lbl_forget_password_react}
                </label>
              </p>
              <div className="row">
                <div className="col-md-7 col-lg-5 login">
                  <div className="form-group">
                    {/* <label>{Strings.lbl_phone_number_react}</label> */}
                    <div className="row">
                      <div className="col-5 col-md-3 pr-0 smDiv">
                        {/* <label type="text" className="form-control lblPhone">+44</label> */}
                        <input
                          readonly
                          type="text"
                          className="form-control text-center"
                          value="+44"
                          style={{ pointerEvents: "none" }}
                        />
                      </div>
                      <div className="col-7 col-md-9 lmDiv">
                        <input
                          type="text"
                          placeholder={Strings.lbl_forget_phone_number_react}
                          className="form-control"
                          maxLength={11}
                          onKeyPress={(event) =>
                            this.onEnterForgotPassword(event)
                          }
                          name="forgetPhoneNo"
                          value={this.state.forgetPhoneNo}
                          onChange={(e) => {
                            this.onMobileEnter(e.target.value, 2);
                          }}
                        />
                      </div>
                    </div>
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.forgetCountryCodeError}
                      </span>
                      <span className="errorMsg">
                        {this.state.errors.forgetPhoneNumberError}
                      </span>
                    </div>
                  </div>

                  <div className="form-group cust_sign_flex  sign-col">
                    <a
                      href="javascript:void(0);"
                      className="greenBtn mediumBtnLbl"
                      id="btnSignIn"
                      onClick={this.validateForgetPassword}
                    >
                      {Strings.Submit_web}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* Show forget password dialog*/}
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log("isLoaging SignIn ------- " + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  forgetPasswordApi: (forgetPhoneNo, forgetCountryCode, languageType) =>
    dispatch(
      HGActions.CallForgetPasswordApi(
        forgetPhoneNo,
        forgetCountryCode,
        languageType
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetForm);
