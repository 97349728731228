import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";

import Constants from "../constants/baseConstants";
import { Strings } from "../constants/Localization";
import Modal from "react-bootstrap/Modal";

import { setSecureData, getSecureData } from "../Utils/utils";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

var postalCode = "";
// var houseNo = "";
class AddAddressPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      findAddress: "",
      googleAddress: "",
      showForm: false,
      addressLine1: "",
      lat: 0.0,
      long: 0.0,
      languageType: 1,
      zipcode: "",
      errors: {
        addressError: "",
        zipcodeError: "",
        txtCommentError: "",
      },
    };
  }

  componentDidMount() {
    console.log(
      "is edit address --------- ",
      this.props.isEdit,
      this.props.addressObj
    );

    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
    let session = getSecureData(Constants.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
    let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }

    if (this.props.isEdit) {
      if (this.props.addressObj) {
        // console.log("dddnnn", this.props.addressObj);
        this.setState({
          address: this.props.addressObj.address1,
          addressLine1: this.props.addressObj.address2,
          lat: this.props.addressObj.latitude,
          long: this.props.addressObj.longitude,
          zipcode: this.props.addressObj.zipcode,
        });
      }
    }
  }

  handleChange = (address) => {
    // console.log("dddnnn address", address);

    if (address.length == 0) {
      this.setState({ zipcode: "" });
    }
    this.setState({
      address,
      findAddress: address,
      errors: {
        addressError: "",
        zipcodeError: "",
        txtCommentError: "",
      },
    });
  };
  handleChange2 = (address) => {
    // console.log("dddnnn address", address);
    if (address.length == 0) {
      this.setState({ zipcode: "" });
    }
    this.setState({
      address,
      findAddress: address,
      // showForm: false,
      errors: {
        addressError: "",
        zipcodeError: "",
        txtCommentError: "",
      },
    });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      // .then(results => console.log(results))
      // .catch(error => console.error(error));
      .then((results) => {
        this.setState({
          googleAddress: JSON.stringify(results[0]),
        });
        const arr3 = results[0].address_components.map((add) => {
          if (
            add.types.includes("street_number") ||
            add.types.includes("route")
          ) {
            return add["long_name"];
          } else {
            return;
          }
        });

        const arr4 = arr3.filter((element) => {
          return element !== undefined;
        });
        const houseNo = arr4.join(" ");

        // const addressRoute =
        //   results[0].address_components.find((c) =>
        //     c.types.includes("route")
        //   ) || {};
        postalCode =
          results[0].address_components.find((c) =>
            c.types.includes("postal_code")
          ) || {};
        // houseNo =
        //   results[0].address_components.find((c) =>
        //     c.types.includes("street_number")
        //   ) || {};
        const arr = results[0].address_components.map((add) => {
          if (
            add.types.includes("street_number") ||
            add.types.includes("postal_code") ||
            add.types.includes("route")
          ) {
            return;
          } else {
            if (
              add.types.includes("administrative_area_level_2") ||
              add.types.includes("administrative_area_level_1")
            ) {
            } else {
              return add["long_name"];
            }
          }
        });
        const arr2 = arr.filter((element) => {
          return element !== undefined;
        });

        const addressWithoutHouseAndZip = arr2.join(" ");

        // addressWithoutHouseAndZip = addressWithoutHouseAndZipRoute.long_name + addressWithoutHouseAndZipTown.long_name
        // addressWithoutHouseAndZipRoute =
        //   results[0].address_components.find((c) =>
        //     c.types.includes("route")
        //   ) || {};
        // addressWithoutHouseAndZipTown =
        //   results[0].address_components.find((c) =>
        //     c.types.includes("postal_town")
        //   ) || {};
        // addressWithoutHouseAndZipTown =
        //   results[0].address_components.find((c) =>
        //     c.types.includes("postal_town")
        //   ) || {};
        console.log("addressresults", results, addressWithoutHouseAndZip);
        // console.log("addressWithoutHouseAndZip", addressWithoutHouseAndZip);
        this.setState({
          zipcode: postalCode.long_name ? postalCode.long_name : "",
        });
        this.setState({
          addressLine1: houseNo,
        });
        // address formate set from here dhruvin
        this.setState({ address: addressWithoutHouseAndZip });
        this.setState({ findAddress: addressWithoutHouseAndZip });
        this.setState({ showForm: true });
        //dhruvin editing
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        console.log("Success", latLng, address);
        this.setState({
          // address set from here dhruvin
          // address: address,
          lat: latLng.lat,
          long: latLng.lng,
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  onAddAddress() {
    var adderr = !this.state.address ? Strings.error_msg_address : "";
    var ziparr = !this.state.zipcode ? Strings.error_msg_postal_code : "";
    var comerr = !this.state.addressLine1 ? Strings.error_msg_comment_text : "";
    console.log("checkEditLEngth", adderr, ziparr, comerr);
    this.setState({
      errors: {
        addressError: adderr,
        zipcodeError: ziparr,
        txtCommentError: comerr,
      },
    });

    setTimeout(() => {
      if (
        this.state.errors.addressError == "" &&
        this.state.errors.zipcodeError == "" &&
        this.state.errors.txtCommentError == ""
      ) {
        let locationId = getSecureData(Constants.LOCATIONOBJECT).locationId;
        var objAddress = {};
        objAddress["address1"] = this.state.addressLine1
          ? this.state.addressLine1 + ", " + this.state.address
          : this.state.address;
        objAddress["address2"] = this.state.addressLine1;
        objAddress["geoCodeAdress"] = this.state.address;
        objAddress["landmark"] = "";
        objAddress["city"] = "";
        objAddress["zipcode"] = this.state.zipcode;
        objAddress["latitude"] = this.state.lat;
        objAddress["longitude"] = this.state.long;
        objAddress["iscompanyAddress"] = false;
        objAddress["customerId"] = getSecureData(
          Constants.USEROBJECT
        ).customerId;
        objAddress["googleAddress"] = this.state.googleAddress;

        if (this.props.isEdit) {
          if (this.props.addressObj) {
            objAddress["deliveryaddressId"] =
              this.props.addressObj.deliveryaddressId;
          }
        }

        this.props
          .addDeliveryAddressAPI(
            // this.state.googleAddress,
            locationId,
            objAddress,
            this.state.languageType
          )
          .then(() => {
            if (this.props.data.status == 1) {
              setTimeout(() => {
                console.log(
                  "add delivery address response ----- ",
                  this.props.data.result
                );
                this.props.onHideAddAddress(this.props.data.result);
              }, 500);
            } else {
              console.log(
                "add delivery address status else ----- ",
                this.props.data.result
              );
              alert(this.props.data.message);
            }
          });
      }
    }, 500);
  }

  render() {
    return (
      <Modal
        show={this.props.isShow}
        onHide={() => {
          this.props.onHideAddAddress();
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="location-popup">
            <PlacesAutocomplete
              value={this.state.findAddress}
              onChange={this.handleChange2}
              onSelect={this.handleSelect}
              searchOptions={{ componentRestrictions: { country: ["uk"] } }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="location-popup">
                  {/* <h5>Your Location</h5> */}
                  <div className="Loc">
                    <div className="form-group loc-input">
                      <span>Address finder:</span>
                      <input
                        type="text"
                        className="form-control"
                        value="Enter Location"
                        // placeholder={Strings.enter_address}
                        {...getInputProps({
                          placeholder: Strings.enter_address,
                          className: "form-control",
                        })}
                      />
                    </div>
                    <div className="autocomplete-dropdown-container">
                      {/* <span>this is dropdown:</span> */}
                      {loading && <div>{Strings.str_loading}</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              margin: "0px 5px",
                              borderBottom: "solid 1px",
                              marginBottom: "10px",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              margin: "0px 5px",
                              borderBottom: "solid 1px",
                              marginBottom: "10px",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                    {this.state.showForm ? (
                      <>
                        <div className="form-group addressLine1">
                          <span>{Strings.address_line_1}:</span>
                          <input
                            type="text"
                            className="form-control"
                            // placeholder={Strings.address_comment}
                            // placeholder={Strings.address_line_1}
                            placeholder={Strings.enter_address}
                            value={this.state.addressLine1}
                            onChange={(e) =>
                              this.setState({ addressLine1: e.target.value })
                            }
                          />
                          <div className="errorDiv">
                            <span className="errorMsg">
                              {this.state.errors.txtCommentError}
                            </span>
                          </div>
                        </div>
                        <div className="form-group coment">
                          <span>{Strings.address_line_2}:</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={Strings.enter_address}
                            value={this.state.address}
                            // onChange={this.handleChange}
                            onChange={(e) =>
                              this.setState({ address: e.target.value })
                            }
                          />
                          <div className="errorDiv">
                            <span className="errorMsg">
                              {this.state.errors.addressError}
                            </span>
                          </div>
                        </div>
                        <div className="form-group potalcode">
                          <span>{Strings.postal_code}:</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={Strings.address_postal_code}
                            value={this.state.zipcode}
                            // readonly="readonly"
                            onChange={(e) =>
                              this.setState({ zipcode: e.target.value })
                            }
                          />
                          <div className="errorDiv">
                            <span className="errorMsg">
                              {this.state.errors.zipcodeError}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div style={{ textAlign: "center" }}>
                      {/* <a href="#" className="btn btn-primary-popup btn-small" onClick={()=>{this.onAddAddress()}}>{Strings.str_add_address}</a> */}
                      <a
                        href="#"
                        className="btn btn-primary-popup btn-small"
                        onClick={() => {
                          this.onAddAddress();
                        }}
                      >
                        {this.props.isEdit
                          ? Strings.str_edit_address
                          : Strings.str_add_address}
                      </a>
                    </div>
                  </div>
                  {/* <input
                  {...getInputProps({
                    placeholder: 'Enter address',
                    className: 'location-search-input',
                  })}
                /> */}
                </div>
              )}
            </PlacesAutocomplete>
            {/* <input type='text' placeholder='Comment' value={this.state.addressLine1} onChange={(e)=>this.setState({addressLine1: e.target.value})}/>
            <button onClick={()=>{this.onAddAddress()}}>Add Address</button> */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  console.log(
    "isLoaging add Address Popup ------- " + state.HGReducers.isLoading
  );
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addDeliveryAddressAPI: (
    // googleAddress,
    locationId,
    DeliveryAddressInfo,
    languageType
  ) =>
    dispatch(
      HGActions.initAddDeliveryAddressAPI(
        // googleAddress,
        locationId,
        DeliveryAddressInfo,
        languageType
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAddressPopup);
