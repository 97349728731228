import React, { Component } from "react";
import {
  Route,
  NavLink,
  BrowserRouter as Router,
  Switch,
  Link,
  history,
} from "react-router-dom";

import HeaderWhiteMenuHalf from "../Components/HeaderWhiteMenuHalf";
import FooterMenu from "../Components/FooterMenu";

import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";

import moment from "moment";
import Modal from "react-bootstrap/Modal";
import ItemPopup from "../Components/ItemPopup";
import AlertDialog from "../Components/AlertDialog";
import AddressPopup from "../Components/AddressPopup";
import AddAddressPopup from "../Components/AddAddressPopup";
import PaymentMethod from "../Components/PaymentMethod";
import LoginForm from "../Components/LoginForm";
import SignUpForm from "../Components/SignUpForm";
import ForgetForm from "../Components/ForgetForm";
import Constants from "../constants/baseConstants";
import { Strings } from "../constants/Localization";
import { getPriceFormat, bindhttps, getCurrencySign } from "../Utils/utils";
import { setSecureData, getSecureData } from "../Utils/utils";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import AOS from "aos";
import "aos/dist/aos.css";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="form-control"
  >
    {children}
    &#x25bc;
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    // const [value, setValue] = useState('');
    const value = "";
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {/* <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => alert(e.target.value)}
            value={value}
        /> */}
        <ul>
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

var billamountWithoutDeliveryCharge = 0;
var voucherDiscount = 0;
var cUKTIME1 = "";
var finalOrderType = "";
var finalTotalCheck = 0;
var memberOfferDiscountVoucher = 0;

class CheckoutDelivery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isItemPopupVisible: false,

      arrayCartData: [],
      editItem: {},
      editAt: 0,

      eraseItem: {},

      isAddressVisible: false,
      isAddAddressVisible: false,

      isAlertVisible: false,
      isMultiButtom: false,
      alertMessage: "",
      btnLeft: "",
      btnRight: "",
      alertType: 0,

      objCompanyCredit: {},
      objCompanyAddress: {},
      isCompanyChecked: false,

      objDeliveryAddresses: {},
      selectedAddress: {},
      orderTimeType: 1,
      uktime: "",

      isPaymentMethodVisible: false,
      isPayPal: false,
      paymentMode: 0,

      customTime: "00:00",

      objCheckInfo: {},
      objOffers: {},
      languageType: 1,

      isAvocadoChecked: false,
      objAvocadoDetails: {},

      isPayByCash: false,
      isPayByCard: false,

      isVoucherApplied: false,
      strVoucher: "",
      strNotes: "",
      objVoucher: {},

      objDeliveryCharge: {},
      deliveryChargeResult: null,
      oType: 0,

      /* comment for order type */
      orderType:
        getSecureData(Constants.ORDERTYPE) == "Delivery" ||
        getSecureData(Constants.ORDERTYPE) == "Delivery Order"
          ? 2
          : getSecureData(Constants.ORDERTYPE) == "TakeAway" ||
            getSecureData(Constants.ORDERTYPE) == "TakeAway Order"
          ? 1
          : 3,
      isLoginEnabled: false,
      isSignUpEnabled: false,
      isForgetEnabled: false,

      orderExtraTime: 0,
      currentTime: "",
      checkTimeApi: false,
      tableNo: "",
      tablePreBookingObj: JSON.parse(
        localStorage.getItem("tablePreBookingObj")
      ),
      orderDetailId: 0,
      offerDetails:
        getSecureData(Constants.MEMBEROFFERS) != undefined
          ? getSecureData(Constants.MEMBEROFFERS)
          : {},
      offerType: getSecureData(Constants.MEMBEROFFERS) != undefined ? 1 : 3,
      memberOfferDiscount:
        getSecureData(Constants.MEMBEROFFERS) != undefined
          ? getSecureData(Constants.MEMBEROFFERS).MemberOfferCode
          : "",
      getMemberofferData: [],
      isMemebrDiscountVoucherApplied:
        getSecureData(Constants.MEMBEROFFERS) != undefined ? true : false,
      memberOfferDiscountVoucher: {},
      isMemberVoucherApplied: false,
      showOfferModal: false,
      offerLevelPoint: 0,
      level: 0,
      discountString: "Discount",
      isAlertVisibleForReorder: false,
      onOrderTypeSelection: 1,
      onOrderTypeClearCart: true,
      specialOfferId: 0,
      isAlertVisibleTable: false,
      locationArray: [],
    };
  }

  componentDidMount() {
    //set by default order type TakeAway
    /* comment for order type */
    // setSecureData(Constants.ORDERTYPE, 'TakeAway')

    console.log("getCatData", getSecureData(Constants.USERCART));
    // console.log("tablePreBookingObj",getSecureData(Constants.PRE_ORDER_ID))

    if (this.state.tablePreBookingObj != null) {
      this.onOrderTypeSelection(3, "no");
    }
    this.setState({ orderTypePro: this.state.orderType });

    this.componentRefreshFun();
  }

  componentRefreshFun() {
    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
    let session = getSecureData(Constants.PRE_MESSAGE_LIST);
    let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }

    this.setState({
      arrayCartData: getSecureData(Constants.USERCART),
      objCheckInfo: getSecureData(Constants.CHECKINFO),
    });

    if (getSecureData(Constants.USEROBJECT) != undefined) {
      this.setState({ userLoginCheck: true });
      if (
        getSecureData(Constants.LOCATIONOBJECT) != null &&
        getSecureData(Constants.LOCATIONOBJECT) != undefined
      ) {
        // this.getMemberofferList()
      }
    }
  }

  componentDidUpdate() {
    if (this.props.cartUpdate) {
      this.componentWillMountRenderFunction();
      this.props.onResponse();
      this.onOrderTypeSelection(getSecureData(Constants.ORDERTYPEID), "no");
    }
  }

  componentWillMount() {
    // this.componentWillMountRenderFunction()
    this.getRestInfo();
    console.log("componentWillMount1");
  }

  componentWillMountRenderFunction() {
    AOS.init({
      duration: 1000,
    });
    console.log(
      "tablePreBookingObj1",
      JSON.parse(localStorage.getItem("tablePreBookingObj"))
    );

    // if (getSecureData(Constants.ISCANCEL)) {
    //     let oId = getSecureData(Constants.PLACEDORDEROBJECT).orderId

    //     var str = {
    //         "orderId": oId,
    //         "paymentstatus": "fail",
    //         "transactionID": "",
    //         "transactionfrom": 1,
    //         "response": "Manually Cancelled",
    //     }
    //     this.props.callCancelAPI(str, 1).then(() => {
    //         if (this.props.data.status == 1) {
    //             if (this.props.data.result != null) {
    //                 this.setState({ alertType: 0, isAlertVisible: true, alertMessage: this.props.data.message, isMultiButtom: false, btnLeft: Strings.Ok_web })
    //                 console.log('callCancelAPI status if---- ---- ', this.props.data.message)
    //                 // setTimeout(() => {
    //                 localStorage.removeItem(Constants.PLACEDORDEROBJECT)
    //                 localStorage.removeItem(Constants.ISCANCEL)

    //             }
    //         } else {
    //             alert(this.props.data.message)
    //             this.setState({ alertType: 0, isAlertVisible: true, alertMessage: this.props.data.message, isMultiButtom: false, btnLeft: Strings.Ok_web })
    //             console.log('callCancelAPI status 2 ---- ', this.props.data)
    //         }
    //     })
    //     .catch((error) => {

    //     })
    // }

    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
    let session = getSecureData(Constants.PRE_MESSAGE_LIST);
    let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }

    // this.setState({arrayCartData: JSON.parse(localStorage.getItem(Constants.USERCART)), objCheckInfo: JSON.parse(localStorage.getItem(Constants.CHECKINFO)) })
    this.setState(
      {
        arrayCartData: getSecureData(Constants.USERCART),
        objCheckInfo: getSecureData(Constants.CHECKINFO),
      },
      () => {
        console.log(
          "getSecureData(Constants.CHECKINFO) ----------------------- ",
          this.state.arrayCartData,
          getSecureData(Constants.CHECKINFO)
        );
        if (!this.state.arrayCartData) {
          return;
        }
        setTimeout(() => {
          this.getRestInfo();
        }, 500);
      }
    );

    if (getSecureData(Constants.USEROBJECT) != undefined) {
      this.setState({ userLoginCheck: true });

      if (
        getSecureData(Constants.LOCATIONOBJECT) != null &&
        getSecureData(Constants.LOCATIONOBJECT) != undefined
      ) {
        // this.getMemberofferList()
      }
    }
  }

  getRestInfo = () => {
    this.props
      .getRestoInfo(this.state.languageType)
      .then(() => {
        console.log(
          "resto info api response -------------------- ",
          this.props.data
        );
        if (this.props.data.status == 1) {
          let uktime = moment(
            this.props.data.result.ukdatetime,
            "YYYY-MM-dd HH:mm:ss"
          ).format("HH:mm");
          // this.setState({currentTime: this.props.data.result.ukdatetime})

          setSecureData(Constants.GETRESTOINFO, this.props.data.result);
          var extra = 0;
          if (this.state.orderType == 2) {
            //delivery
            extra = getSecureData(Constants.LOCATIONOBJECT).ordersubmittime;
            console.log("dhruvin checking extra2", extra);
          } else if (this.state.orderType == 1) {
            //takeaway
            extra = getSecureData(Constants.LOCATIONOBJECT).takeawayextratime;
          } else if (this.state.orderType == 3) {
            //SitDown
            extra = getSecureData(Constants.LOCATIONOBJECT).bookingTimeLimit;
          }
          uktime = moment(uktime, "HH:mm").add(extra, "m").format("HH:mm");

          this.setState(
            {
              currentTime: this.props.data.result.ukdatetime,
              uktime: uktime,
              customTime: uktime,
              isPayByCash: this.props.data.result.objRestaurant.payByCash,
              isPayByCard: this.props.data.result.objRestaurant.payByCard,
            },
            () => {
              // if (getSecureData(Constants.ORDERTYPE) == 'TakeAway') {

              if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
                let userObject = getSecureData(Constants.USEROBJECT);
                if (userObject != null) {
                  let customer = getSecureData(Constants.USEROBJECT).customerId;
                  let id = getSecureData(Constants.LOCATIONOBJECT).locationId;
                  this.callGetDeliveryAddress(id, customer);
                } else {
                }
              } else {
              }

              // } else {
              //     this.callCompanyAPI()
              // }
            }
          );
        } else {
          // if (getSecureData(Constants.ORDERTYPE) == 'TakeAway') {
          if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
            let userObject = getSecureData(Constants.USEROBJECT);
            if (userObject != null) {
              let customer = getSecureData(Constants.USEROBJECT).customerId;
              let id = getSecureData(Constants.LOCATIONOBJECT).locationId;
              this.callGetDeliveryAddress(id, customer);
            } else {
            }
          } else {
          }
          // } else {
          //     this.callCompanyAPI()
          // }
        }
      })
      .catch(() => {
        // if (getSecureData(Constants.ORDERTYPE) == 'TakeAway') {
        // this.onOffers()

        console.log(
          "getSecureData(Constants.LOCATIONOBJECT)checkout",
          getSecureData(Constants.LOCATIONOBJECT)
        );
        if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
          let userObject = getSecureData(Constants.USEROBJECT);
          if (userObject != null) {
            let customer = getSecureData(Constants.USEROBJECT).customerId;
            let id = getSecureData(Constants.LOCATIONOBJECT).locationId;
            this.callGetDeliveryAddress(id, customer);
          } else {
          }
        } else {
        }
        // } else {
        //     this.callCompanyAPI()
        // }
      });
  };

  //Handle change language click event
  //English language type: 1
  //Spanish language type: 2
  languageChangeClickEvent = (type) => {
    console.log("category page language type :" + type);
    // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, type)
    setSecureData(Constants.PRE_LANGUAGE_TYPE, type);
    this.setState({ languageType: type });
    setTimeout(() => {
      this.props.AppMessageApi(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          var messageListArray = this.props.data.result;
          if (messageListArray != null && messageListArray.length > 0) {
            console.log("message list  :" + JSON.stringify(messageListArray));
            // localStorage.setItem(Constants.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
            setSecureData(Constants.PRE_MESSAGE_LIST, messageListArray);
            // let localMessage = JSON.parse(localStorage.getItem(Constants.PRE_MESSAGE_LIST));
            let localMessage = getSecureData(Constants.PRE_MESSAGE_LIST);
            // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, this.state.languageType)
            setSecureData(Constants.PRE_LANGUAGE_TYPE, this.state.languageType);
            // let languageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
            let languageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
            console.log("language type  :" + languageType);
            if (languageType == 1) {
              //English language type: 1
              Strings.setLanguage("en"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "en-GB",
              });
            } else {
              Strings.setLanguage("es"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "es",
              });
            }
            Strings.setContent(localMessage);
            // this.setState({ languageType: languageType })
            window.location.reload(false);
          }
        } else {
          alert(this.props.error);
        }
      });
    }, 700);
  };

  callCompanyAPI() {
    setTimeout(() => {
      if (this.state.arrayCartData == null) {
        return;
      } else if (this.state.arrayCartData.length == 0) {
        return;
      }
      let itemSum = 0;
      this.state.arrayCartData.map((item) => {
        itemSum += item.totalPrice;
      });

      // let customer = JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId
      // let id = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId
      let customer = getSecureData(Constants.USEROBJECT).customerId;
      let id = getSecureData(Constants.LOCATIONOBJECT).locationId;

      this.props
        .getCompanyCreditAPI(customer, this.state.languageType)
        .then((response) => {
          console.log("new response of company credit api ------------- ");
          if (this.props.data.status == 1) {
            setTimeout(() => {
              console.log(
                " Credit APi response object ----- ",
                JSON.stringify(this.props.data.result.objScoreDetails)
              );

              //Check company is linked or not, if linked then call add company address api
              if (this.props.data.result.objScoreDetails.isLinked) {
                this.setState({
                  objCompanyCredit: this.props.data.result,
                  isCompanyChecked: true,
                });
                var addressObj = {};
                addressObj["address1"] = this.props.data.result.geoCodeAdress;
                addressObj["address2"] = "";
                addressObj["geoCodeAdress"] =
                  this.props.data.result.geoCodeAdress;
                addressObj["landmark"] = "";
                addressObj["city"] = "";
                addressObj["zipcode"] = "";
                addressObj["latitude"] = this.props.data.result.latitude;
                addressObj["longitude"] = this.props.data.result.longitude;
                addressObj["iscompanyAddress"] = true;
                addressObj["labaltext"] = this.props.data.result.labaltext;
                addressObj["customerId"] = customer;
                console.log("addressObj ----- ", addressObj);

                //Call add company address api
                this.props
                  .addCompnayAddressAPI(id, addressObj, this.state.languageType)
                  .then(() => {
                    if (this.props.data.status == 1) {
                      // setTimeout(() => {
                      // this.setState({objCompanyAddress: this.props.data.result,selectedAddress: this.props.data.result})
                      this.setState(
                        { objCompanyAddress: this.props.data.result },
                        () => {
                          this.callGetDeliveryAddress(id, customer);
                        }
                      );
                      // }, 100)
                    } else {
                      this.callGetDeliveryAddress(id, customer);
                    }
                  });
              } else {
                this.setState(
                  {
                    objCompanyCredit: this.props.data.result,
                    isCompanyChecked: false,
                  },
                  () => {
                    this.callGetDeliveryAddress(id, customer);
                  }
                );
              }
            }, 100);
          } else {
            this.callGetDeliveryAddress(id, customer);
          }
        })
        .catch((err) => {
          this.callGetDeliveryAddress(id, customer);
        });
    }, 100);
  }

  callGetDeliveryAddress(id, customer) {
    //Call get delivery addresses api
    this.props
      .getDeliveryAddressAPI(id, customer, this.state.languageType)
      .then(() => {
        if (this.props.data.status == 1) {
          // setTimeout(() => {
          let uktime = moment(
            this.props.data.result.ukdatetime,
            "YYYY-MM-dd HH:mm:ss"
          ).format("HH:mm");
          var extra = 0;
          if (this.state.orderType == 2) {
            //delivery
            extra = getSecureData(Constants.LOCATIONOBJECT).ordersubmittime;
          } else if (this.state.orderType == 1) {
            //takeaway
            extra = getSecureData(Constants.LOCATIONOBJECT).takeawayextratime;
          } else if (this.state.orderType == 3) {
            //SitDown
            extra = getSecureData(Constants.LOCATIONOBJECT).bookingTimeLimit;
          }
          uktime = moment(uktime, "HH:mm").add(extra, "m").format("HH:mm");

          if (this.props.data.result.AddressLists) {
            if (this.props.data.result.AddressLists.length > 0) {
              // this.setState({uktime: uktime, customTime: uktime ,objDeliveryAddresses: this.props.data.result,selectedAddress: this.props.data.result.AddressLists[0]})
              // this.setState({ uktime: uktime, customTime: uktime, objDeliveryAddresses: this.props.data.result })//removed pre selected delivery address
              //Unset custom time if previous sets
              this.setState({
                uktime: uktime,
                objDeliveryAddresses: this.props.data.result,
              }); //removed pre selected delivery address
            } else {
              // this.setState({ uktime: uktime, customTime: uktime, objDeliveryAddresses: this.props.data.result, selectedAddress: {} })
              //Unset custom time if previous sets
              this.setState({
                uktime: uktime,
                objDeliveryAddresses: this.props.data.result,
                selectedAddress: {},
              });
            }
          } else {
            // this.setState({ uktime: uktime, customTime: uktime, objDeliveryAddresses: this.props.data.result, selectedAddress: {} })
            //Unset custom time if previous sets
            this.setState({
              uktime: uktime,
              objDeliveryAddresses: this.props.data.result,
              selectedAddress: {},
            });
          }

          // }, 500)
        } else {
        }

        setTimeout(() => {
          this.onOffers();
        }, 500);
      });
  }

  onOffers() {
    if (this.state.arrayCartData == null) {
      return;
    }
    if (this.state.arrayCartData.length == 0) {
      return;
    }
    let itemSum = 0;
    this.state.arrayCartData.map((item) => {
      itemSum += item.totalPrice;
    });
    // let customer = JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId
    let customer = getSecureData(Constants.USEROBJECT).customerId;
    this.props
      .getOffersAPI(itemSum, customer, this.state.languageType)
      .then(() => {
        console.log("getOffersAPI RESPONSE --------- ", this.props.data);
        if (this.props.data.status == 1) {
          if (this.props.data.result != null) {
            //Offers available
            this.setState({ objOffers: this.props.data.result });
          }
        } else if (this.props.data.status == 2) {
          if (this.props.data.result != null) {
            //No Offers available
            this.setState({ objOffers: this.props.data.result });
          }
        } else {
        }
        this.getAvocado();
      });
  }

  getAvocado() {
    //Call get avocado details API
    let customer = getSecureData(Constants.USEROBJECT).customerId;
    this.props
      .getAvocadoDetailsAPI(this.state.languageType, customer)
      .then(() => {
        if (this.props.data.status == 1) {
          this.setState({ objAvocadoDetails: this.props.data.result }, () => {
            this.calculateFinalPrice();
          });
        }

        setTimeout(() => {
          this.onApplyVoucher();

          // if (this.state.isCompanyChecked) {
          //     this.callDeliveryCharge()
          // }
        }, 500);
      });
  }

  calculateFinalPrice() {
    var finalTotal = 0;

    let itemTotal = 0;
    this.state.arrayCartData.map((item) => {
      itemTotal += item.totalPrice;
    });
    finalTotal = itemTotal;

    var discount = 0;
    var discountValue = "";
    if (this.state.objOffers) {
      //Check offers available or not
      if (this.state.objOffers.isfirstOrder == 1) {
        //Check discount type 1=Percentage 2=Amount
        if (this.state.objOffers.firstOrder.discountType == 1) {
          let str = this.state.objOffers.firstOrder.discountPercentage;
          let arrStr = str.split(".");
          if (arrStr[1] == 0) {
            discountValue = "(" + arrStr[0] + "%)";
          } else {
            discountValue = "(" + str + "%)";
          }
          let temp = (finalTotal * Number(str)) / 100;
          discount = temp;
          finalTotal -= temp; //Number(this.state.objOffers.firstOrder.discount)
        } else {
          discount = Number(this.state.objOffers.firstOrder.discount);
          finalTotal -= Number(this.state.objOffers.firstOrder.discount);
        }
      }
    }

    // let objLocation = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT))
    let objLocation = getSecureData(Constants.LOCATIONOBJECT);

    //Count Company discount
    var cmpDiscount = 0;
    let obj = this.state.objCompanyCredit;
    let compy = obj.objScoreDetails;
    if (compy) {
      if (compy.isLinked && getSecureData(Constants.ORDERTYPE) == "Delivery") {
        if (this.state.isCompanyChecked) {
          if (finalTotal >= obj.objScoreDetails.perDayCredit) {
            finalTotal -= obj.objScoreDetails.perDayCredit;
            cmpDiscount = obj.objScoreDetails.perDayCredit;
          } else {
            cmpDiscount = finalTotal;
            finalTotal = 0;
          }
        } else {
        }
      }
    }

    {
      /*Voucher code comment*/
    }
    //Count voucher discount
    if (this.state.isVoucherApplied) {
      if (finalTotal >= this.state.objVoucher.discout) {
        voucherDiscount = this.state.objVoucher.discout;
        finalTotal -= this.state.objVoucher.discout;
      } else {
        voucherDiscount = finalTotal;
        finalTotal -= finalTotal;
      }
    } else {
      voucherDiscount = 0;
    }

    if (this.state.isMemberVoucherApplied) {
      voucherDiscount = 0;
      if (finalTotal >= this.state.memberOfferDiscountVoucher.discout) {
        memberOfferDiscountVoucher =
          this.state.memberOfferDiscountVoucher.discout;
        finalTotal -= this.state.memberOfferDiscountVoucher.discout;
      } else {
        memberOfferDiscountVoucher = finalTotal;
        finalTotal -= finalTotal;
      }
    } else {
      memberOfferDiscountVoucher = 0;
    }

    console.log("Calculating", voucherDiscount, memberOfferDiscountVoucher);
    //Count avocado point
    var avocadoDiscount = 0;
    if (
      this.state.objAvocadoDetails.useAvocadoPoint >=
      this.state.objAvocadoDetails.minredemptionrequired
    ) {
      if (this.state.isAvocadoChecked) {
        if (
          finalTotal >=
          this.state.objAvocadoDetails.minredemptionrequired *
            this.state.objAvocadoDetails.discountof1avocado
        ) {
          finalTotal -=
            this.state.objAvocadoDetails.minredemptionrequired *
            this.state.objAvocadoDetails.discountof1avocado;
          avocadoDiscount =
            this.state.objAvocadoDetails.minredemptionrequired *
            this.state.objAvocadoDetails.discountof1avocado;
        } else {
          avocadoDiscount = finalTotal;
          finalTotal -= finalTotal;
        }
      }
    }

    //Count packaging charges
    var pCharge = Number(objLocation.packagingCharge);
    finalTotal += pCharge;

    //set billamount without delivery charge
    billamountWithoutDeliveryCharge = finalTotal;

    var dCharge = 0;
    if (
      getSecureData(Constants.ORDERTYPE) == "Delivery" ||
      getSecureData(Constants.ORDERTYPE) == "Delivery Order"
    ) {
      // dCharge = getSecureData(Constants.CHECKINFO).deliveryCharge
      // finalTotal += dCharge
      if (this.state.isCompanyChecked) {
        if (this.state.objDeliveryCharge.deliveryCharges) {
          dCharge = Number(this.state.objDeliveryCharge.deliveryCharges);
          finalTotal += dCharge;
        }
      } else {
        // if (this.state.objDeliveryCharge.deliveryCharges) {
        if (
          this.state.selectedAddress.address1 &&
          this.state.objDeliveryCharge.deliveryCharges
        ) {
          // if (this.state.deliveryChargeResult.status == 1) {
          dCharge = Number(this.state.objDeliveryCharge.deliveryCharges);
          finalTotal += dCharge;
          // }
        }
      }
    }

    finalTotalCheck = finalTotal;

    return (
      <div className="total-div">
        <div className="row">
          <div className="col1">{Strings.sub_total}</div>
          <div className="col2">{getPriceFormat(itemTotal.toFixed(2))}</div>
        </div>
        {discount > 0 ? (
          <div className="row">
            <div className="col1">
              {Strings.lbl_discount_react}
              {discountValue}
            </div>
            <div className="col2">- {getPriceFormat(discount.toFixed(2))}</div>
          </div>
        ) : null}

        {/*Voucher code comment*/}
        {voucherDiscount > 0 ? (
          <div className="row">
            <div className="col1">
              {/* {Strings.str_offer_discount} */}
              Discount
            </div>
            <div className="col2">
              - {getPriceFormat(voucherDiscount.toFixed(2))}
            </div>
          </div>
        ) : null}
        {voucherDiscount > 0 ? (
          <div className="row">
            <div className="col1">
              <label className="OfferLabel">
                ({this.state.objVoucher.offerText})
              </label>
            </div>
          </div>
        ) : null}

        {memberOfferDiscountVoucher > 0 ? (
          <div className="row">
            <div className="col1">
              {/* {Strings.str_offer_discount} */}
              Reward Discount ({this.state.memberOfferDiscountVoucher.offerText}
              )
            </div>
            <div className="col2">
              - {getPriceFormat(memberOfferDiscountVoucher.toFixed(2))}
            </div>
          </div>
        ) : null}
        {/* { memberOfferDiscountVoucher > 0 ? <div className="row">                
                        <div className="col1">
                            <label className="OfferLabel">({this.state.memberOfferDiscountVoucher.offerText})</label>
                        </div>
                    </div> : null} */}

        {/* {(this.state.isCompanyChecked && JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery' && cmpDiscount > 0) ? <div className="row">                 */}
        {(this.state.isCompanyChecked &&
          getSecureData(Constants.ORDERTYPE) == "Delivery") ||
        (getSecureData(Constants.ORDERTYPE) == "Delivery Order" &&
          cmpDiscount > 0) ? (
          <div className="row">
            <div className="col1">{Strings.str_company_discount}:</div>
            <div className="col2">
              - {getPriceFormat(cmpDiscount.toFixed(2))}
            </div>
          </div>
        ) : null}
        {this.state.isAvocadoChecked ? (
          <div className="row">
            <div className="col1">{Strings.str_redeemed_avocados}</div>
            <div className="col2">
              - {getPriceFormat(avocadoDiscount.toFixed(2))}
            </div>
          </div>
        ) : null}
        {/* {(JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery' && dCharge > 0) ? <div className="row">                 */}
        {getSecureData(Constants.ORDERTYPE) == "Delivery" ||
        (getSecureData(Constants.ORDERTYPE) == "Delivery Order" &&
          dCharge > 0) ? (
          <div className="row">
            <div className="col1">{Strings.lbl_delivery_charge_react}:</div>
            <div className="col2">{getPriceFormat(dCharge.toFixed(2))}</div>
          </div>
        ) : null}
        {pCharge > 0 ? (
          <div className="row">
            {" "}
            <div className="col1">{Strings.str_packaging_costs}:</div>
            <div className="col2">{getPriceFormat(pCharge.toFixed(2))}</div>
          </div>
        ) : null}

        {this.state.tablePreBookingObj != null ? (
          <div className="row">
            <div className="col1">{Strings.str_booking_deposit}:</div>
            <div className="col2">
              -{" "}
              {getPriceFormat(
                this.state.tablePreBookingObj.bookingDepositAmount > finalTotal
                  ? finalTotal.toFixed(2)
                  : this.state.tablePreBookingObj.bookingDepositAmount.toFixed(
                      2
                    )
              )}
            </div>
          </div>
        ) : null}

        {this.state.tablePreBookingObj != null ? (
          <div className="row total-last">
            <div className="col1">{Strings.Total_web}</div>
            <div className="col2">
              {getPriceFormat(
                this.state.tablePreBookingObj.bookingDepositAmount < finalTotal
                  ? (
                      finalTotal -
                      this.state.tablePreBookingObj.bookingDepositAmount
                    ).toFixed(2)
                  : (0).toFixed(2)
              )}
            </div>
          </div>
        ) : (
          <div className="row total-last">
            <div className="col1">{Strings.Total_web}</div>
            <div className="col2">{getPriceFormat(finalTotal.toFixed(2))}</div>
          </div>
        )}
      </div>
    );
  }

  onCompanyCheck() {
    if (this.state.objDeliveryAddresses.AddressLists != null) {
      if (this.state.objDeliveryAddresses.AddressLists.length == 0) {
        this.setState({
          isCompanyChecked: !this.state.isCompanyChecked,
          selectedAddress: {},
        });
      } else {
        // this.setState({isCompanyChecked: !this.state.isCompanyChecked, selectedAddress: this.state.objDeliveryAddresses.AddressLists[0]})
        this.setState(
          {
            isCompanyChecked: !this.state.isCompanyChecked,
            selectedAddress: {},
          },
          () => {
            if (this.state.isCompanyChecked) {
              this.callDeliveryCharge();
            }
          }
        ); //removed pre selected delivery address
      }
    } else {
      this.setState({
        isCompanyChecked: !this.state.isCompanyChecked,
        selectedAddress: {},
      });
    }
  }

  renderAddressView() {
    return this.renderUserAddress();

    if (getSecureData(Constants.ORDERTYPE) == "TakeAway") {
      return;
    }

    var arrReturn = [];
    let objScore = this.state.objCompanyCredit.objScoreDetails;
    if (objScore) {
      //Check company is linked or not
      if (objScore.isLinked) {
        //Check if company address as elected as delivery address or not
        if (this.state.isCompanyChecked) {
          return (
            <div>
              <div className="radio-div">
                <a
                  onClick={() => {
                    this.onCompanyCheck();
                  }}
                >
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="customRadioo"
                      id="customRadio2"
                      checked={this.state.isCompanyChecked}
                    />
                    <label
                      className="custom-control-label order-select"
                      for="customRadio2"
                    >
                      {this.state.objCompanyCredit.labaltext}
                    </label>
                  </div>
                </a>
              </div>
              <p>{this.state.objCompanyCredit.geoCodeAdress}</p>
            </div>
          );
        } else {
          //if not selected company address as delivery address then render normal delivery view
          return (
            <div>
              <div className="radio-div">
                <div className="custom-control custom-radio custom-control-inline">
                  <a
                    onClick={() => {
                      this.onCompanyCheck();
                    }}
                  >
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="customRadioC1"
                      id="customRadio2"
                      checked={this.state.isCompanyChecked}
                    />
                  </a>
                  <label
                    className="custom-control-label order-select"
                    for="customRadio2"
                  >
                    {this.state.objCompanyCredit.labaltext}
                  </label>
                </div>
              </div>
              {this.renderUserAddress()}
            </div>
          );
        }
      } else {
        //if not linked then render normal delivery address view
        return this.renderUserAddress();
      }
    }
    return arrReturn;
  }

  renderUserAddress() {
    let objAddress = this.state.objDeliveryAddresses;
    var arrReturn = [];
    /*//Check address list available or not
        if (objAddress.AddressLists) {
            if(objAddress.AddressLists.length > 0) {
                //push selected address view 
                arrReturn.push(
                    <div >
                        <p>{this.state.selectedAddress.address1}<br/>{this.state.selectedAddress.address2}</p>
                        <div className="checkout margin0">
                            <div className="check-div margin0">
                                <div className="single-prod margin0">
                                    <div className="buttons-checkout">
                                        <a href="#" className="btn btn-medium trans-btn" onClick={()=>this.setState({isAddressVisible:!this.state.isAddressVisible})}>{Strings.change_add_web}</a>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            //push only add address button 
            arrReturn.push(
                <div className="checkout margin0">
                    <div className="check-div margin0">
                        <div className="single-prod margin0">
                            <div className="buttons-checkout">
                                <a href="#" className="btn btn-medium trans-btn" onClick={()=>{this.setState({isAddAddressVisible:true})}}>{Strings.str_add_address}</a>  
                            </div>
                        </div>
                    </div>
                </div>
            )
        }*/
    arrReturn.push(
      <div key={0}>
        {this.state.selectedAddress.address1 != null ? (
          <p>
            {this.state.selectedAddress.address1}
            <br />
            {this.state.selectedAddress.address2}
          </p>
        ) : null}
        <div className="checkout margin0">
          <div className="check-div margin0">
            <div className="single-prod margin0">
              <div className="buttons-checkout">
                <a
                  href="javascript:void(0)"
                  className="btn btn-medium trans-btn selectAddress"
                  onClick={() => {
                    if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
                      let userObject = getSecureData(Constants.USEROBJECT);
                      if (userObject != null) {
                        this.setState({ isAddressVisible: true });
                      } else {
                        // this.setState({ isAlertVisible: true, alertType: 4, alertMessage: Strings.msgLogin, isMultiButtom: true, btnLeft: Strings.btn_signin_react, btnRight: Strings.btn_cancel  })
                        this.setState({ isLoginEnabled: true });
                      }
                    } else {
                      this.setState({ isLoginEnabled: true });
                      // this.setState({ isAlertVisible: true, alertType: 4, alertMessage: Strings.msgLogin, isMultiButtom: true, btnLeft: Strings.btn_signin_react, btnRight: Strings.btn_cancel  })
                    }
                  }}
                >
                  {Strings.select_address}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return arrReturn;
  }

  // onQtyChanged(type, item, index) {
  //     var quantity = item.totalQty
  //     if (type == 0) {
  //         if (quantity > 1) {
  //             quantity -= 1
  //         }
  //     } else {
  //         quantity += 1
  //     }
  //     var tempArray = this.state.arrayCartData
  //     var tempPrice = item.PriceList[0].price
  //     tempPrice = tempPrice * quantity;

  //     tempArray[index].totalQty = quantity;
  //     tempArray[index].totalPrice = tempPrice;

  //     this.setState({ arrayCartData: tempArray }, () => {
  //         // localStorage.setItem(Constants.USERCART,JSON.stringify(this.state.arrayCartData))
  //         setSecureData(Constants.USERCART, this.state.arrayCartData)
  //         setTimeout(() => {
  //             this.callDeliveryCharge()
  //         }, 100);
  //     })

  // }

  onQtyChanged(type, item, index) {
    console.log();
    var quantity = item.totalQty;
    var totalPrice = item.totalPrice;
    var singlePrice = totalPrice / quantity;
    singlePrice = singlePrice.toFixed(2);

    if (type == 0) {
      if (quantity > 1) {
        quantity -= 1;
      }
    } else {
      quantity += 1;
    }

    var tempArray = this.state.arrayCartData;
    var tempPrice = item.PriceList[0].price;
    // var tempPrice = item.totalPrice
    tempPrice = singlePrice * quantity;

    tempArray[index].totalQty = quantity;
    tempArray[index].totalPrice = tempPrice;

    this.setState({ arrayCartData: tempArray }, () => {
      // localStorage.setItem(Constants.USERCART,JSON.stringify(this.state.arrayCartData))
      setSecureData(Constants.USERCART, this.state.arrayCartData);
      setTimeout(() => {
        this.callDeliveryCharge();
      }, 100);
    });
  }

  onQtyChangedLoyalty(type, item, index) {
    console.log("onQtyChangedLoyalty", item);
    // return
    var offerLevelPoint = getSecureData("offerLevelPoint");

    console.log("offerLevelPoint", offerLevelPoint);
    var quantity = item.totalQty;
    var rewardpoints = item.rewardpoints;
    var singlePoint = rewardpoints * quantity;
    // singlePoint =  Math.round(singlePoint)
    // console.log("singlePoint", singlePoint)
    if (type == 0) {
      if (quantity > 1) {
        quantity -= 1;

        offerLevelPoint = offerLevelPoint + rewardpoints;
      }
    } else {
      if (offerLevelPoint >= rewardpoints) {
        quantity += 1;
        offerLevelPoint = offerLevelPoint - rewardpoints;
      } else {
        this.setState({
          alertType: 111,
          isAlertVisible: true,
          alertMessage: "You have not sufficient point.",
          isMultiButton: false,
          btnLeft: Strings.Ok_web,
        });
        return;
      }
    }
    offerLevelPoint = Math.round(offerLevelPoint);
    console.log("offerLevelPoint-minus", offerLevelPoint);
    var tempArray = this.state.arrayCartData;
    // var tempPrice = item.PriceList[0].price
    // var tempPrice = item.totalPrice
    // var tempPoint = singlePoint * quantity;

    tempArray[index].totalQty = quantity;
    // tempArray[index].rewardpoints = tempPoint;

    this.setState({ arrayCartData: tempArray }, () => {
      // localStorage.setItem(Constants.USERCART,JSON.stringify(this.state.arrayCartData))
      setSecureData(Constants.USERCART, this.state.arrayCartData);
      setSecureData("offerLevelPoint", offerLevelPoint);
      setTimeout(() => {
        this.callDeliveryCharge();
      }, 100);
    });
  }

  onEraseClick(item) {
    this.setState({
      eraseItem: item,
      isAlertVisible: true,
      alertMessage: Strings.msg_erase_item,
      btnLeft: Strings.yes,
      btnRight: Strings.str_no,
      isMultiButtom: true,
      alertType: 1,
    });
    return;
    var tempArray = this.state.arrayCartData;
    let index = tempArray.indexOf(item);
    if (index >= -1) {
      tempArray.splice(index, 1);
    }
    this.setState({ arrayCartData: tempArray });
    setTimeout(() => {
      localStorage.setItem(
        Constants.USERCART,
        JSON.stringify(this.state.arrayCartData)
      );
    }, 100);
  }

  onEditClick(item, index) {
    //Check item added from reorder or menuitems list
    if (item.isReOrderedItem) {
      this.props
        .getItemDetailsAPI(item.menuitemId, this.state.languageType)
        .then(() => {
          if (this.props.data.status == 1) {
            let cartItem = item;
            let menuDetails = this.props.data.result;

            let cartPriceList = cartItem.PriceList.filter(
              (price) => price.isSelect == true
            );

            let menuPriceList = menuDetails.PriceList.filter(
              (price) => price.typeid == cartPriceList[0].typeid
            );

            let optList = cartPriceList[0].OptionList.filter(
              (opt) => opt.isSelect == true
            );
            var arrOpt = [];
            optList.map((opt) => arrOpt.push(opt.typeid));
            console.log(" Option List  ----- ", cartItem);

            var arrayOption = [];
            menuPriceList[0].OptionList.map((options) => {
              if (arrOpt.includes(options.typeid)) {
                options["isSelect"] = true;
              }
              arrayOption.push(options);
            });

            menuPriceList[0].isSelect = true;
            menuPriceList[0].OptionList = arrayOption;
            menuDetails.totalPrice = cartItem.totalPrice;
            menuDetails.totalQty = cartItem.totalQty;
            menuDetails.PriceList = menuPriceList;

            this.setState({
              editItem: menuDetails,
              isItemPopupVisible: true,
              editAt: index,
            });
          } else {
          }
        });
    } else
      this.setState({
        editItem: item,
        isItemPopupVisible: true,
        editAt: index,
      });
  }

  renderPriceType(index) {
    let obj = this.state.arrayCartData[index].PriceList.filter(
      (data) => data.isSelect == true
    );
    return <p>{obj[0].type}</p>;
  }

  // renderOptions(index) {
  //     var arrayTitle = []
  //     let objPriceList = this.state.arrayCartData[index].PriceList.filter((data) => (data.isSelect == true))
  //     let objOptList = objPriceList[0].OptionList.filter((data) => (data.isSelect == true))

  //     objOptList.map((data) => {
  //         var temp = arrayTitle.filter(obj => (obj == data.labelText))
  //         if (temp.length == 0) {
  //             arrayTitle.push(data.labelText)
  //         }
  //     })

  //     var arrData = []
  //     arrayTitle.map((data, i) => {
  //         var str = []
  //         let obj = objOptList.filter(flt => (flt.labelText == data && flt.isSelect == true))
  //         obj.map((opt) => {
  //             str.push(opt.type)
  //         })
  //         arrData.push(
  //             <div key={i} className='optSubOption'>
  //                 <h5 className='optTitle'>{data}</h5>
  //                 <p className='optValue'>{str.join(', ')}</p>
  //             </div>
  //         )
  //     })
  //     return arrData
  // }

  renderOptions = (index) => {
    var arrayTitle = [];

    // if(this.state.arrayCartData[index].PriceList.length > 0) {
    //     return []
    // }
    let subOption;
    let objPriceList = this.state.arrayCartData[index].PriceList.filter(
      (data) => data.isSelect == true
    );
    let objOptList = objPriceList[0].OptionList.filter(
      (data) => data.isMultiselect == true
    );
    subOption = objOptList.filter((data) => data.isMultiselect == true);

    objOptList.map((data) => {
      var temp = arrayTitle.filter((obj) => obj == data.labelText);
      // if (temp.length == 0) {
      arrayTitle.push(data.labelText);
      // }
    });

    var arrData = [];
    arrayTitle.map((data, i) => {
      var str = [];
      var subOptionFlt = [];

      let obj = subOption.map((flt) => {
        if (flt.labelText == data) {
          // return flt.subOption
          subOptionFlt = flt.subOption;
        }
      });

      subOptionFlt.map((opt) => {
        if (opt.isSelect == true) {
          str.push(opt.type);
        }
      });

      // console.log("renderOptions", data, subOption,subOptionFlt)
      // return

      arrData.push(
        str.length > 0 ? (
          <div key={i} className="optSubOption">
            <h5 className="optTitle">{data}</h5>
            <p className="optValue">{str.join(", ")}</p>
          </div>
        ) : null
      );
    });

    return arrData;
  };

  renderPriceList(index) {
    // if(this.state.arrayCartData[index].PriceList.length > 0) {
    //     return []
    // }

    let objPriceList = this.state.arrayCartData[index].PriceList.filter(
      (data) => data.isSelect == true
    );

    return objPriceList[0].type.replace("Price", "Regular");
  }

  renderCartItems() {
    if (this.state.arrayCartData == null) {
      return <label>{Strings.cart_empty}</label>;
    }
    if (this.state.arrayCartData.length == 0) {
      return <label>{Strings.cart_empty}</label>;
    }

    var arrayItem = [];
    this.state.arrayCartData.map((items, index) => {
      arrayItem.push(
        <div className="row single-prod" key={index}>
          <div className="prod-img col-md-8">
            <div class="row">
              <div class="col-md-8">
                <h2
                  class="prdTitle"
                  onClick={() => {
                    this.onEditClick(items, index);
                  }}
                >
                  {items.menuItemName}
                </h2>
                {/* <label class="prdDesc">{items.description}</label> */}
                {this.renderOptions(index)}
              </div>
              <div class="col-md-4">
                <div class="itemFooter checkout">
                  <div class="QtyDiv">
                    <button
                      href=""
                      type="button"
                      class="btn"
                      data-toggle="modal"
                      data-target="#myModal"
                      onClick={() => {
                        this.onQtyChanged(0, items, index);
                      }}
                    >
                      <img src="../images/minus-icon.png" />
                    </button>
                    <label class="QTYLabel">{items.totalQty}</label>
                    <button
                      href=""
                      type="button"
                      class="btn"
                      data-toggle="modal"
                      data-target="#myModal"
                      onClick={() => {
                        this.onQtyChanged(1, items, index);
                      }}
                    >
                      <img src="../images/plus-icon.png" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="quontity">
              {/* <div className="form-group">
                         <input type="text" className="form-control" maxLength={2} value={items.totalQty} onChange={e => { this.onQtyChanged(e.target.value, index) }} />
                        </div> */}
              {/* <div className="price">{getPriceFormat(items.totalPrice.toFixed(2))}</div> */}
              {/* // By pratik Date:11-12-2020 */}
              <label className="lbl-inr">{getCurrencySign()}</label>
              <div className="prdPriceAmt">{items.totalPrice.toFixed(2)}</div>

              {/* <div className="buttons-checkout menuItem"> */}
              <a
                href="#"
                className="btn-delete"
                onClick={() => {
                  this.onEraseClick(items);
                }}
              >
                <FontAwesomeIcon size="20px" icon={faTrashAlt} />{" "}
                {/* {Strings.str_erase} */}
              </a>
              {/* </div> */}
            </div>
          </div>
        </div>
      );
    });
    return arrayItem;
  }

  renderBoxCartItems() {
    if (this.state.arrayCartData == null) {
      return <label>{Strings.cart_empty}</label>;
    }
    if (this.state.arrayCartData.length == 0) {
      return <label>{Strings.cart_empty}</label>;
    }

    var arrayItem = [];
    this.state.arrayCartData.map((items, index) => {
      arrayItem.push(
        <div className="cartProductDiv">
          <div class="row">
            <div class="col   ">
              <figure class="itemside">
                <figcaption class="info">
                  {items.isLoyaltyOfferItem == undefined ||
                  items.isLoyaltyOfferItem == false ? (
                    <>
                      <a
                        href="#"
                        class="title"
                        onClick={() => {
                          this.onEditClick(items, index);
                        }}
                      >
                        {items.menuItemName}{" "}
                      </a>
                      <p>
                        ({this.renderPriceList(index)}){" "}
                        {this.renderOptions(index)}
                      </p>
                    </>
                  ) : (
                    <a href="javascript:void(0)" className="title">
                      {items.menuItemName}{" "}
                    </a>
                  )}
                </figcaption>
              </figure>
            </div>
            <div class="col   text-right spinerDelete">
              <a
                href="#"
                class="btn btn-light"
                onClick={() => {
                  this.onEraseClick(items);
                }}
              >
                {" "}
                <i class="fa fa-times"></i>{" "}
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col    ">
              {items.isLoyaltyOfferItem == undefined ||
              items.isLoyaltyOfferItem == false ? (
                <div class="price h5">
                  {" "}
                  {getCurrencySign()} {items.totalPrice.toFixed(2)}{" "}
                </div>
              ) : (
                <div class="price h5">
                  {items.totalQty * items.rewardpoints} Points
                </div>
              )}
            </div>
            <div class="col    text-right spinerPlusMinus">
              {items.isLoyaltyOfferItem == undefined ||
              items.isLoyaltyOfferItem == false ? (
                <div class="input-group input-spinner">
                  <div class="input-group-prepend">
                    <button
                      class="btn btn-light"
                      type="button"
                      id="button-plus"
                      onClick={() => {
                        this.onQtyChanged(0, items, index);
                      }}
                    >
                      {" "}
                      <i class="fa fa-minus"></i>{" "}
                    </button>
                  </div>
                  <input
                    type="text"
                    readOnly
                    class="form-control"
                    value={items.totalQty}
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-light"
                      type="button"
                      id="button-minus"
                      onClick={() => {
                        this.onQtyChanged(1, items, index);
                      }}
                    >
                      {" "}
                      <i class="fa fa-plus"></i>{" "}
                    </button>
                  </div>
                </div>
              ) : (
                <div class="input-group input-spinner">
                  <div class="input-group-prepend">
                    <button
                      class="btn btn-light"
                      type="button"
                      id="button-plus"
                      onClick={() => {
                        this.onQtyChangedLoyalty(0, items, index);
                      }}
                    >
                      {" "}
                      <i class="fa fa-minus"></i>{" "}
                    </button>
                  </div>
                  <input
                    type="text"
                    readOnly
                    class="form-control"
                    value={items.totalQty}
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-light"
                      type="button"
                      id="button-minus"
                      onClick={() => {
                        this.onQtyChangedLoyalty(1, items, index);
                      }}
                    >
                      {" "}
                      <i class="fa fa-plus"></i>{" "}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
    return arrayItem;
  }
  onPopupClose(popup) {
    console.log("popup close ---- ", popup);
    // this.setState({isItemPopupVisible: false, arrayCartData: JSON.parse(localStorage.getItem(Constants.USERCART)),isAlertVisible: popup.isHide, alertMessage: Constants.msgItemUpdated, btnLeft: Strings.Ok_web, isMultiButtom: false, alertType:0 })
    this.setState(
      {
        isItemPopupVisible: false,
        arrayCartData: getSecureData(Constants.USERCART),
        isAlertVisible: popup.isHide,
        alertMessage: Constants.msgItemUpdated,
        btnLeft: Strings.Ok_web,
        isMultiButtom: false,
        alertType: 111,
      },
      () => {
        if (popup.isHide) this.callDeliveryCharge();
      }
    );
  }

  callchecknextday = async (type) => {
    let userObject = getSecureData(Constants.USEROBJECT);
    let locationObject = getSecureData(Constants.LOCATIONOBJECT);
    await this.props
      .checkNextDayOrder(
        userObject.customerId,
        this.state.objCompanyCredit.objScoreDetails.companyId
          ? this.state.objCompanyCredit.objScoreDetails.companyId
          : 0,
        locationObject.locationId,
        this.state.orderTimeType == 1
          ? this.state.uktime
          : this.state.customTime,
        this.state.languageType
      )
      .then(() => {
        if (this.props.data.status == 1) {
          if (this.props.data.result.isNextDayOrder) {
            console.log("it is next day order -------- ");
            this.setState({
              isAlertVisible: true,
              isMultiButtom: true,
              alertMessage: Strings.str_msg_next_day_order_confirm,
              btnLeft: Strings.btn_confirm,
              btnRight: Strings.btn_cancel,
              alertType: 3,
              oType: type,
            });
          } else {
            console.log("order today -------- ");
            this.callAddOrder(type);
          }
        } else {
          alert(this.props.data.message);
        }
      })
      .catch((errro) => {});
  };

  onPaymentSelection(type) {
    console.log("finalTotalCheck", type, finalTotalCheck);
    if (type == 3) {
      if (this.state.tablePreBookingObj != null) {
        if (
          this.state.tablePreBookingObj.bookingDepositAmount >=
            finalTotalCheck &&
          getSecureData(Constants.LOCATIONOBJECT).isTableDepositDiscount
        ) {
          this.setState(
            { isPaymentMethodVisible: false, isPayPal: false, paymentMode: 1 },
            () => {
              if (this.state.isCompanyChecked) {
                this.callchecknextday(type);
              } else {
                this.callAddOrder(type);
              }
            }
          );
        } else {
          this.setState(
            { isPaymentMethodVisible: true, isPayPal: true, paymentMode: 2 },
            () => {
              if (this.state.isCompanyChecked) {
                this.callchecknextday(type);
              } else {
                this.callAddOrder(type);
              }
            }
          );
        }
      } else {
        this.setState(
          { isPaymentMethodVisible: false, isPayPal: true, paymentMode: 2 },
          () => {
            if (this.state.isCompanyChecked) {
              this.callchecknextday(type);
            } else {
              this.callAddOrder(type);
            }
          }
        );
      }
    } else if (type == 1) {
      this.setState(
        { isPaymentMethodVisible: false, isPayPal: false, paymentMode: 1 },
        () => {
          if (this.state.isCompanyChecked) {
            this.callchecknextday(type);
          } else {
            this.callAddOrder(type);
          }
        }
      );
    } else {
      this.setState({ isPaymentMethodVisible: false });
    }
  }

  callAddOrder(type) {
    let cartItems = getSecureData(Constants.USERCART);

    console.log("cartItems", cartItems, this.state.arrayCartData);
    if (
      cartItems == null ||
      cartItems == undefined ||
      this.state.arrayCartData == undefined
    ) {
      this.setState({
        isAlertVisibleTable: true,
        alertMessage: "Your cart is empty.",
        isMultiButtom: false,
        btnLeft: "Ok",
      });
      return;
    }

    setTimeout(() => {
      //Place order
      if (type == 1 || type == 3) {
        var deliveryObject = {};

        // if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery') {
        if (
          getSecureData(Constants.ORDERTYPE) == "Delivery" ||
          getSecureData(Constants.ORDERTYPE) == "Delivery Order"
        ) {
          if (this.state.isCompanyChecked) {
            deliveryObject["deliveryaddressId"] =
              this.state.objCompanyAddress.deliveryaddressId;
            deliveryObject["address1"] = this.state.objCompanyAddress.address1;
            deliveryObject["address2"] = this.state.objCompanyAddress.address2;
            deliveryObject["landmark"] = this.state.objCompanyAddress.landmark;
            deliveryObject["city"] = this.state.objCompanyAddress.city;
            deliveryObject["zipcode"] = this.state.objCompanyAddress.zipcode;
          } else {
            if (this.state.selectedAddress["address1"] == null) {
              this.setState({
                isAlertVisible: true,
                alertMessage: Strings.select_add_delivery_address,
                isMultiButtom: false,
                btnLeft: Strings.Ok_web,
                alertType: 0,
              });
              return;
            } else {
              deliveryObject["deliveryaddressId"] =
                this.state.selectedAddress.deliveryaddressId;
              deliveryObject["address1"] = this.state.selectedAddress.address1;
              deliveryObject["address2"] = this.state.selectedAddress.address2;
              deliveryObject["landmark"] = this.state.selectedAddress.landmark;
              deliveryObject["city"] = this.state.selectedAddress.city;
              deliveryObject["zipcode"] = this.state.selectedAddress.zipcode;
            }
          }
        }

        // let userObject = JSON.parse(localStorage.getItem(Constants.USEROBJECT))
        // let locationObject = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT))
        // let cartItems = JSON.parse(localStorage.getItem(Constants.USERCART))
        let userObject = getSecureData(Constants.USEROBJECT);
        let locationObject = getSecureData(Constants.LOCATIONOBJECT);

        //
        var arrCustomer = [];
        let customerItem = cartItems.map((item) => {
          let dict = {};
          dict["menuitemId"] = item.catId;
          dict["qty"] = item.totalQty;
          dict["subparameterid"] = 0;
          arrCustomer.push(dict);
        });
        var cartdetail = {};
        cartdetail["CustomeOfferMenuItem"] = arrCustomer;
        cartdetail["restaurantId"] = 147;
        cartdetail["locationId"] = locationObject.locationId;

        //
        var arrOrderItems = [];
        cartItems.map((item) => {
          // console.log('Item data ----- ',item)

          var objItem = {};
          let selectedPrice = item.PriceList.filter(
            (priceData) => priceData.isSelect == true
          );

          var price = {};
          price["typeid"] = selectedPrice[0].typeid;
          price["type"] = selectedPrice[0].type;
          price["price"] = selectedPrice[0].price;

          var optList = [];
          // selectedPrice[0].OptionList.map(option => {

          //     let dict = {}
          //     dict['type'] = option.type
          //     dict['labelText'] = option.labelText
          //     dict['thumbimgurl'] = option.thumbimgurl
          //     dict['typeid'] = option.typeid
          //     dict['sizepid'] = option.sizepid
          //     dict['parameterId'] = option.parameterId
          //     dict['price'] = option.price
          //     dict['isSelect'] = option.isSelect
          //     dict['isSelectAll'] = option.isSelectAll

          //     if (option.isSelect) {
          //         optList.push(dict)
          //     } else {
          //         if (option.isSelectAll) {
          //             optList.push(dict)
          //         }
          //     }
          // })

          let optionlist = selectedPrice[0].OptionList;
          for (let i = 0; i < optionlist.length; i++) {
            if (optionlist[i].subOption.length > 0) {
              for (let j = 0; j < optionlist[i].subOption.length; j++) {
                let dict = {};
                dict["customeOfferDiscount"] =
                  optionlist[i].subOption[j].customeOfferDiscount;
                dict["flag"] = optionlist[i].subOption[j].flag;
                dict["isCompulsory"] = optionlist[i].subOption[j].isMultiselect;
                dict["isSelect"] = optionlist[i].subOption[j].isSelect;
                dict["isSelectAll"] = optionlist[i].subOption[j].isSelectAll;
                dict["istoppings"] = optionlist[i].subOption[j].istoppings;
                dict["labelText"] = optionlist[i].subOption[j].labelText;
                dict["maxselection"] = optionlist[i].subOption[j].maxselection;
                dict["menuitemid"] = optionlist[i].subOption[j].menuitemid;

                dict["parameterId"] = optionlist[i].subOption[j].parameterId;
                dict["price"] = optionlist[i].subOption[j].price;
                dict["selectallmsg"] = optionlist[i].subOption[j].selectallmsg;
                dict["showIn"] = optionlist[i].subOption[j].showIn;
                dict["showinblock"] = optionlist[i].subOption[j].showinblock;
                dict["sizepid"] = optionlist[i].subOption[j].sizepid;
                dict["sortorder"] = optionlist[i].subOption[j].sortorder;
                dict["subparsortorder"] =
                  optionlist[i].subOption[j].subparsortorder;
                dict["thumbimgurl"] = optionlist[i].subOption[j].thumbimgurl;

                dict["type"] = optionlist[i].subOption[j].type;
                dict["typeid"] = optionlist[i].subOption[j].typeid;

                if (optionlist[i].subOption[j].isSelect) {
                  optList.push(dict);
                } else {
                  if (optionlist[i].subOption[j].isSelectAll) {
                    optList.push(dict);
                  }
                }
              }
            }
          }

          objItem["itemname"] = item.menuItemName;
          objItem["orderitemId"] = item.menuitemId;
          objItem["catId"] = item.catId;
          objItem["qty"] = item.totalQty;
          objItem["rewardpoints"] = 0;
          objItem["ToppingsList"] = [];
          objItem["price"] = price;
          objItem["OptionList"] = optList;

          if (
            item.isLoyaltyOfferItem != undefined &&
            item.isLoyaltyOfferItem == true
          ) {
            objItem["isLoyaltyOfferItem"] = true;
            objItem["rewardpoints"] = item.rewardpoints;
            objItem["type"] = "";
          } else {
            objItem["isLoyaltyOfferItem"] = false;
          }

          arrOrderItems.push(objItem);
        });

        let tDate = moment().format("YYYY-MM-DD HH:mm");

        var OrderInfo = {};

        OrderInfo["appVersion"] = 0;
        OrderInfo["appName"] = 147;

        //total amount logic same as calculate method
        var finalTotal = 0;
        let itemTotal = 0;
        this.state.arrayCartData.map((item) => {
          itemTotal += item.totalPrice;
        });
        finalTotal = itemTotal;
        var discountVal = 0;
        if (this.state.objOffers) {
          //Check offers available or not
          if (this.state.objOffers.isfirstOrder == 1) {
            //Check discount type 1=Percentage 2=Amount
            if (this.state.objOffers.firstOrder.discountType == 1) {
              let str = this.state.objOffers.firstOrder.discountPercentage;
              let temp = (finalTotal * Number(str)) / 100;
              discountVal = temp;
            } else {
              discountVal = Number(this.state.objOffers.firstOrder.discount);
            }
          }
        }

        OrderInfo["discout"] = discountVal;

        OrderInfo["deliveryaddress"] = deliveryObject;
        OrderInfo["packagingCharge"] = locationObject.packagingCharge;
        OrderInfo["contactno"] = userObject.countryCode
          ? userObject.countryCode + userObject.phone
          : "+44" + userObject.phone;
        OrderInfo["appfor"] = "WEB";
        OrderInfo["offerItems"] = [];
        OrderInfo["apnToken"] = "";
        OrderInfo["locationId"] = locationObject.locationId;
        OrderInfo["paymentmode"] = this.state.paymentMode;
        OrderInfo["redeempoints"] = 0;

        OrderInfo["orderId"] = -1;
        OrderInfo["specialOfferId"] =
          memberOfferDiscountVoucher > 0
            ? this.state.memberOfferDiscountVoucher.specialOfferId
            : 0;
        OrderInfo["contactname"] = userObject.firstname;
        OrderInfo["ispaypal"] = this.state.isPayPal;
        OrderInfo["note"] = this.state.strNotes;
        OrderInfo["specialOfferDescription"] = "";

        OrderInfo["payAmountByCompany"] = this.state.isCompanyChecked
          ? this.state.objCompanyCredit.objScoreDetails.perDayCredit
          : 0.0;
        OrderInfo["OrderedItems"] = arrOrderItems;
        OrderInfo["customerId"] = userObject.customerId;
        {
          /* comment for order type */
        }
        // OrderInfo['ordertype'] = getSecureData(Constants.ORDERTYPE) == 'TakeAway' ? 1 : 2  //TAEKAWAY=1,DELIVERY=2
        OrderInfo["ordertype"] = this.state.orderType; //getSecureData(Constants.ORDERTYPE) == 'TakeAway' ? 1 : 2  //TAEKAWAY=1,DELIVERY=2
        OrderInfo["companyId"] = this.state.isCompanyChecked
          ? this.state.objCompanyCredit.objScoreDetails.companyId
          : 0;
        OrderInfo["cartdetail"] = cartdetail;
        OrderInfo["tableNo"] = this.state.tableNo;
        OrderInfo["locationId"] = locationObject.locationId;

        {
          /*Voucher code comment*/
        }
        OrderInfo["specialOfferTitle"] = this.state.isVoucherApplied
          ? this.state.objVoucher.offerText
          : memberOfferDiscountVoucher > 0
          ? this.state.memberOfferDiscountVoucher.offerText
          : "";
        OrderInfo["offercodeDiscount"] = this.state.isVoucherApplied
          ? this.state.objVoucher.discout
          : memberOfferDiscountVoucher > 0
          ? this.state.memberOfferDiscountVoucher.discout
          : 0.0;

        // if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'TakeAway') {
        if (
          getSecureData(Constants.ORDERTYPE) == "TakeAway" ||
          getSecureData(Constants.ORDERTYPE) == "SitDown"
        ) {
          OrderInfo["deliveryCharges"] = 0;
        } else {
          // OrderInfo['deliveryCharges'] = getSecureData(Constants.CHECKINFO).deliveryCharge
          OrderInfo["deliveryCharges"] = this.state.objDeliveryCharge
            .deliveryCharges
            ? this.state.objDeliveryCharge.deliveryCharges
            : 0;
        }
        if (this.state.isAvocadoChecked) {
          OrderInfo["avacadoRedeemDiscount"] =
            this.state.objAvocadoDetails.discountof1avocado *
            this.state.objAvocadoDetails.minredemptionrequired;
          OrderInfo["avacadoRedeemPoint"] =
            this.state.objAvocadoDetails.minredemptionrequired;
        }

        //preTableBooking order
        OrderInfo["orderdate"] = tDate;
        OrderInfo["contacttime"] =
          this.state.orderTimeType == 1
            ? this.state.uktime
            : this.state.customTime;
        OrderInfo["billamountWithoutDeliveryCharge"] =
          billamountWithoutDeliveryCharge;
        if (this.state.tablePreBookingObj != null) {
          OrderInfo["bookingId"] = this.state.tablePreBookingObj.bookingId;
          OrderInfo["bookingDipositAmount"] =
            this.state.tablePreBookingObj.bookingDepositAmount;
          OrderInfo["orderdate"] = this.state.tablePreBookingObj.contactDate;
          OrderInfo["contacttime"] = this.state.tablePreBookingObj.contactTime;

          if (getSecureData(Constants.LOCATIONOBJECT).isTableDepositDiscount) {
            OrderInfo["bookingDipositAmount"] =
              this.state.tablePreBookingObj.bookingDepositAmount >
              finalTotalCheck
                ? finalTotalCheck
                : this.state.tablePreBookingObj.bookingDepositAmount;
            OrderInfo["billamountWithoutDeliveryCharge"] =
              this.state.tablePreBookingObj.bookingDepositAmount >
              finalTotalCheck
                ? 0
                : finalTotalCheck -
                  this.state.tablePreBookingObj.bookingDepositAmount;
            // billamountWithoutDeliveryCharge = OrderInfo['billamountWithoutDeliveryCharge']
          } else {
            OrderInfo["bookingDipositAmount"] = 0;
          }

          // billamountWithoutDeliveryCharge = OrderInfo['billamountWithoutDeliveryCharge']
        }

        console.log("OrderInfoData", OrderInfo, this.state.isPayPal);
        // return true;
        // MenuTime CheckingB eforeOrder PlaceRes
        this.props
          .MenuTimeCheckingBeforeOrderPlace(OrderInfo, this.state.languageType)
          .then(() => {
            if (this.props.data.status == 1) {
              // add order
              this.props
                .addOrderAPI(OrderInfo, this.state.languageType)
                .then(() => {
                  if (this.props.data.status == 1) {
                    setTimeout(() => {
                      let response = this.props.data.result;
                      console.log(
                        "order response ========================= ",
                        response
                      );

                      // var str = response.message + '\n' + Strings.str_your_order_is + ' : ' + response.orderNo
                      // this.setState({ alertType: 2, isAlertVisible: true, alertMessage: str, isMultiButtom: false, btnLeft: Strings.Ok_web })
                      // localStorage.removeItem(Constants.USERCART);
                      // return
                      localStorage.removeItem("tablePreBookingObj");
                      setSecureData(Constants.PRE_ORDER_ID, response.orderId);
                      localStorage.removeItem(Constants.USERCART);
                      // //Check payment method type
                      if (this.state.isPayPal) {
                        // this.props.history.push('/Categories')
                        console.log("checkPAyapl", this.state.isPayPal);
                        setSecureData(Constants.PLACEDORDEROBJECT, response);
                        setTimeout(() => {
                          // this.props.history.push({ pathname: 'PaymentCardList' })
                          window.location.href = "/PaymentCardList";
                        }, 100);
                      } else {
                        console.log("checkPAyaplelse", this.state.isPayPal);
                        var str =
                          response.message +
                          "\n" +
                          Strings.str_your_order_is +
                          " : " +
                          response.orderNo;
                        this.setState({
                          alertType: 5,
                          isAlertVisible: true,
                          alertMessage: str,
                          isMultiButtom: true,
                          btnLeft: Strings.Ok_web,
                          btnRight: "View Order",
                        });
                        // localStorage.removeItem(Constants.USERCART);
                        //Move on category screen when click on ok popup

                        // localStorage.removeItem(Constants.USEROBJECT);
                        // localStorage.removeItem(Constants.ORDERTYPE);
                        // localStorage.removeItem(Constants.CHECKINFO);
                        // localStorage.removeItem(Constants.PRE_CARD_LIST);
                        // localStorage.removeItem(Constants.PRE_WAITER_ITEM);
                        // localStorage.removeItem(Constants.PRE_CAT_ID);
                      }
                    }, 500);
                  } else {
                    console.log(" add order api else ", this.props.data);
                    this.setState({
                      isAlertVisible: true,
                      alertType: 0,
                      alertMessage: this.props.data.message,
                      isMultiButtom: false,
                      btnLeft: Strings.Ok_web,
                    });
                  }
                });
            } else {
              console.log(
                " MenuTimeCheckingBeforeOrderPlace api else ",
                this.props.data
              );
              this.setState({
                isAlertVisible: true,
                alertType: 0,
                alertMessage: this.props.data.message,
                isMultiButtom: false,
                btnLeft: Strings.Ok_web,
              });
            }
          });
      }
    }, 200);
  }

  onLeftClick() {
    //alertType  0 == normal, 1 == erase, 2 == order place message,
    if (this.state.alertType == 0) {
      this.setState({ isAlertVisible: false });
    } else if (this.state.alertType == 1) {
      var tempArray = this.state.arrayCartData;
      let index = tempArray.indexOf(this.state.eraseItem);
      if (index >= -1) {
        tempArray.splice(index, 1);
      }
      this.setState({ arrayCartData: tempArray, isAlertVisible: false });

      setTimeout(() => {
        // localStorage.setItem(Constants.USERCART,JSON.stringify(this.state.arrayCartData))
        setSecureData(Constants.USERCART, this.state.arrayCartData);
        this.setState({
          eraseItem: {},
          isAlertVisible: true,
          alertMessage: Strings.msg_item_erased,
          btnLeft: Strings.Ok_web,
          isMultiButtom: false,
          alertType: 0,
        });

        setTimeout(() => {
          let cartItems = getSecureData(Constants.USERCART);
          // if (cartItems.length != 0) {
          this.callDeliveryCharge();
          // }
        }, 300);
      }, 100);
    } else if (this.state.alertType == 2) {
      this.setState({ isAlertVisible: false });
      // this.props.history.push('/')
      window.location.href = "/myOrders";
      return;
    } else if (this.state.alertType == 3) {
      this.setState(
        {
          isAlertVisible: false,
        },
        () => {
          this.callAddOrder(this.state.oType);
        }
      );
    } else if (this.state.alertType == 4) {
      this.setState({ isAlertVisible: false, isLoginEnabled: true }, () => {
        // this.props.history.push('/SignIn')
      });
    } else if (this.state.alertType == 5) {
      this.setState({ isAlertVisible: false });
      window.location.href = "/menuItems";
      return;
    } else if (this.state.alertType == 111) {
      this.setState({ isAlertVisible: false });
    } else if (this.state.alertType == 101) {
      if (finalOrderType == Strings.Delivery_web) {
        setSecureData(Constants.ORDERTYPE, finalOrderType);
      } else if (finalOrderType == Strings.lbl_takeaway_react) {
        setSecureData(Constants.ORDERTYPE, finalOrderType);
      } else if (finalOrderType == Strings.str_collect_drink) {
        setSecureData(Constants.ORDERTYPE, Strings.str_sitDown);
      }

      this.setState(
        {
          isAlertVisible: false,
          orderType:
            getSecureData(Constants.ORDERTYPE) == "Delivery" ||
            getSecureData(Constants.ORDERTYPE) == "Delivery Order"
              ? 2
              : getSecureData(Constants.ORDERTYPE) == "TakeAway"
              ? 1
              : 3,
          uktime: cUKTIME1,
          customTime: cUKTIME1,
        },
        () => {
          localStorage.removeItem(Constants.USERCART);
          window.location.reload();
        }
      );
    } else {
      this.setState({ isAlertVisible: false });
    }
  }

  onRightClick() {
    if (this.state.alertType == 111) {
      this.setState({ isAlertVisible: false });
    } else if (this.state.alertType == 5) {
      setTimeout(() => {
        window.location.href = "/OrderDetails";
      }, 500);
    } else {
      this.setState({ isAlertVisible: false });
    }
  }

  onCloseAddress(address) {
    if (address) {
      this.setState(
        {
          isAddressVisible: !this.state.isAddressVisible,
          selectedAddress: address,
        },
        () => {
          let cartItems = getSecureData(Constants.USERCART);
          if (
            getSecureData(Constants.ORDERTYPE) === "Delivery" ||
            getSecureData(Constants.ORDERTYPE) === "Delivery Order"
          ) {
            setTimeout(() => {
              this.callDeliveryCharge();
            }, 100);
          }
        }
      );
    } else {
      this.setState({ isAddressVisible: !this.state.isAddressVisible });
    }
  }

  onDeleteAddress() {
    this.setState({ isAddressVisible: false });
    // let customer = JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId
    // let id = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId
    let customer = getSecureData(Constants.USEROBJECT).customerId;
    let id = getSecureData(Constants.LOCATIONOBJECT).locationId;
    let cartItems = getSecureData(Constants.USERCART);
    if (
      cartItems == null ||
      cartItems == undefined ||
      this.state.arrayCartData == undefined
    ) {
    } else {
      this.callGetDeliveryAddress(id, customer);
    }
  }

  onAddNewAddress(address) {
    if (address) {
      console.log("address added ----- ", address);
      this.setState({ isAddAddressVisible: false });

      // let customer = JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId
      // let id = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId
      let customer = getSecureData(Constants.USEROBJECT).customerId;
      let id = getSecureData(Constants.LOCATIONOBJECT).locationId;
      this.callGetDeliveryAddress(id, customer);
    } else {
      console.log("address closed ----- ", address);
      this.setState({ isAddAddressVisible: false });
    }
  }

  onAddAddress() {
    this.setState({ isAddressVisible: false, isAddAddressVisible: true });
  }

  onTimeSelect(type, e) {
    var val = e; //.target.value

    let hrs = moment(this.state.customTime, "HH:mm").format("HH");
    let mns = moment(this.state.customTime, "HH:mm").format("mm");

    if (type == 1) {
      this.setState({ customTime: val + ":" + mns });
    } else {
      val < 10 ? (val = 0 + "" + val) : (val = val);
      this.setState({ customTime: hrs + ":" + val });
    }
  }

  renderTimeView(type) {
    let hrs = moment(this.state.uktime, "HH:mm").format("HH");
    let mns = moment(this.state.uktime, "HH:mm").format("mm");

    var arrMM = [];
    var arrHH = [];

    if (type == 1) {
      for (var i = 0; i < 24; i++) {
        // arrHH.push(<option value={i} selected={hrs == i ? true : false}>{i}</option>)
        arrHH.push(
          <Dropdown.Item eventKey={i} key={i}>
            {i}
          </Dropdown.Item>
        );
      }
      return arrHH;
    } else {
      for (var i = 0; i < 60; i++) {
        // arrMM.push(<option value={i} selected={mns == i ? true : false}>{i}</option>)
        arrMM.push(
          <Dropdown.Item eventKey={i} key={i}>
            {i}
          </Dropdown.Item>
        );
      }
      return arrMM;
    }

    /*if (this.state.orderTimeType == 2) {
            return(
                // <div className="select-time">
                //     <label>Select time</label>
                //     <div className="form-group">
                //         <input type="text" className="form-control" placeholder="20:00"/>
                //     </div>
                // </div>
                <div className="select-time">
                    <label>Selecy time</label>
                    <div className="form-group"><input type="text" className="form-control" placeholder="20:00"/>
                        
                    </div>
                </div> 
            )
        }*/
  }
  onCheckOutClickedEstimatedTimePop() {
    ///////////////////
    this.props.getRestoInfo(this.state.languageType).then(() => {
      if (this.props.data.status == 1) {
        let uktime = moment(
          this.props.data.result.ukdatetime,
          "YYYY-MM-dd HH:mm:ss"
        ).format("HH:mm");
        // this.setState({currentTime: this.props.data.result.ukdatetime})

        setSecureData(Constants.GETRESTOINFO, this.props.data.result);
        var extra = 0;
        if (this.state.orderType == 2) {
          //delivery
          extra = getSecureData(Constants.LOCATIONOBJECT).ordersubmittime;
          console.log("dhruvin checking extra2", extra);
        } else if (this.state.orderType == 1) {
          //takeaway
          extra = getSecureData(Constants.LOCATIONOBJECT).takeawayextratime;
        } else if (this.state.orderType == 3) {
          //SitDown
          extra = getSecureData(Constants.LOCATIONOBJECT).bookingTimeLimit;
        }
        uktime = moment(uktime, "HH:mm").add(extra, "m").format("HH:mm");

        this.setState(
          {
            currentTime: this.props.data.result.ukdatetime,
            uktime: uktime,
            customTime: uktime,
          },
          () => {
            this.setState({
              isAlertVisible: true,
              alertType: 0,
              alertMessage: `Please note your new estimated collection time is ${this.state.uktime}`,
              isMultiButtom: false,
              btnLeft: Strings.Ok_web,
            });
          }
        );
      }
    });
    ///////////////////
    this.onCheckOutClicked();
  }

  onCheckOutClicked() {
    // dhruvin popup for estimated time
    // this.getRestInfo();

    // let uktime = moment(this.state.currentTime, "HH:mm").format("HH:mm");

    // var extra = 0;
    // if (this.state.orderType == 2) {
    //   //delivery
    //   extra = getSecureData(Constants.LOCATIONOBJECT).ordersubmittime;
    // } else if (this.state.orderType == 1) {
    //   //takeaway
    //   extra = getSecureData(Constants.LOCATIONOBJECT).takeawayextratime;
    // } else if (this.state.orderType == 3) {
    //   //SitDown
    //   extra = getSecureData(Constants.LOCATIONOBJECT).bookingTimeLimit;
    // }
    // uktime = moment(uktime, "HH:mm").add(extra, "m").format("HH:mm");
    // this.setState({
    //   uktime: uktime,
    // });

    // if (this.state.isAlertVisible == false) {
    //   this.setState({
    //     isAlertVisible: true,
    //     alertType: 0,
    //     alertMessage: `Please note your new estimated collection time is ${this.state.uktime}`,
    //     isMultiButtom: false,
    //     btnLeft: Strings.Ok_web,
    //   });
    //   return;
    // }

    if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
      let userObject = getSecureData(Constants.USEROBJECT);
      if (userObject == null) {
        this.setState({ isLoginEnabled: true });
        return;
      }
    } else {
      this.setState({ isLoginEnabled: true });
      return;
    }

    let cartItems = getSecureData(Constants.USERCART);
    if (
      cartItems == null ||
      cartItems == undefined ||
      this.state.arrayCartData == undefined
    ) {
      this.setState({
        isAlertVisible: true,
        alertType: 0,
        alertMessage:
          "Your shopping basket is empty!, Please add item in basket.",
        isMultiButtom: false,
        btnLeft: Strings.Ok_web,
      });
      return;
    }

    console.log(
      "getSecureData(Constants.ORDERTYPE)",
      getSecureData(Constants.ORDERTYPE),
      billamountWithoutDeliveryCharge
    );
    // if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'TakeAway') {
    if (
      getSecureData(Constants.ORDERTYPE) == "TakeAway" ||
      getSecureData(Constants.ORDERTYPE) == "TakeAway Order"
    ) {
      console.log("order type takeaway");
      if (this.state.orderTimeType == 2) {
        let uk = moment(this.state.uktime, "HH:mm");
        let custom = moment(this.state.customTime, "HH:mm");
        console.log(
          "diffffff takeaway ------------------- ",
          custom.diff(uk, "minutes")
        );
        // if(!uk.isBefore(custom)) {
        if (custom.diff(uk, "minutes") < 0) {
          this.setState({
            isAlertVisible: true,
            alertType: 0,
            alertMessage: Strings.past_time,
            isMultiButtom: false,
            btnLeft: Strings.Ok_web,
          });
          return;
        } else {
          if (billamountWithoutDeliveryCharge > 0)
            this.setState({ isPaymentMethodVisible: true });
          else {
            this.onPaymentSelection(1); //pay by cash by default with amount 0
          }
        }
      } else {
        if (billamountWithoutDeliveryCharge > 0)
          this.setState({ isPaymentMethodVisible: true });
        else {
          this.onPaymentSelection(1); //pay by cash by default with amount 0
        }
      }
    } else if (
      getSecureData(Constants.ORDERTYPE) == "Delivery" ||
      getSecureData(Constants.ORDERTYPE) == "Delivery Order"
    ) {
      if (this.state.orderTimeType == 2) {
        let uk = moment(this.state.uktime, "HH:mm");
        let custom = moment(this.state.customTime, "HH:mm");
        console.log(
          "diffffff ------------------- ",
          custom.diff(uk, "minutes")
        );
        // if(!uk.isBefore(custom)) {
        if (custom.diff(uk, "minutes") < 0) {
          this.setState({
            isAlertVisible: true,
            alertType: 0,
            alertMessage: Strings.past_time,
            isMultiButtom: false,
            btnLeft: Strings.Ok_web,
          });
          return;
        } else if (!this.state.isCompanyChecked) {
          console.log("33333333333 ----------------- ");
          if (this.state.selectedAddress["address1"] == null) {
            this.setState({
              isAlertVisible: true,
              alertMessage: Strings.select_add_delivery_address,
              isMultiButtom: false,
              btnLeft: Strings.Ok_web,
              alertType: 0,
            });
            return;
          } else if (
            this.state.deliveryChargeResult != null &&
            this.state.deliveryChargeResult.status != 1
          ) {
            this.setState({
              isAlertVisible: true,
              alertType: 0,
              alertMessage: this.state.deliveryChargeResult.message,
              isMultiButtom: false,
              btnLeft: Strings.Ok_web,
            });
            return;
          } else {
            let dChrge = this.state.objDeliveryCharge.deliveryCharges
              ? this.state.objDeliveryCharge.deliveryCharges
              : 0;
            //Add delivery charge in bill amount and check total is zero
            if (billamountWithoutDeliveryCharge + dChrge > 0)
              this.setState({ isPaymentMethodVisible: true });
            else {
              this.onPaymentSelection(1); //pay by cash by default with amount 0
            }
          }
        } else {
          console.log("check111111 ----------------- ");
          if (
            this.state.deliveryChargeResult != null &&
            this.state.deliveryChargeResult.status != 1
          ) {
            this.setState({
              isAlertVisible: true,
              alertType: 0,
              alertMessage: this.state.deliveryChargeResult.message,
              isMultiButtom: false,
              btnLeft: Strings.Ok_web,
            });
            return;
          } else {
            let dChrge = this.state.objDeliveryCharge.deliveryCharges
              ? this.state.objDeliveryCharge.deliveryCharges
              : 0;
            //Add delivery charge in bill amount and check total is zero
            if (billamountWithoutDeliveryCharge + dChrge > 0)
              this.setState({ isPaymentMethodVisible: true });
            else {
              this.onPaymentSelection(1); //pay by cash by default with amount 0
            }
          }
        }
      } else {
        if (!this.state.isCompanyChecked) {
          console.log(
            "check ----------------- 222222222222------",
            this.state.deliveryChargeResult
          );
          if (this.state.selectedAddress["address1"] == null) {
            this.setState({
              isAlertVisible: true,
              alertMessage: Strings.select_add_delivery_address,
              isMultiButtom: false,
              btnLeft: Strings.Ok_web,
              alertType: 0,
            });
            return;
          } else if (
            this.state.deliveryChargeResult != null &&
            this.state.deliveryChargeResult.status != 1
          ) {
            this.setState({
              isAlertVisible: true,
              alertType: 0,
              alertMessage: this.state.deliveryChargeResult.message,
              isMultiButtom: false,
              btnLeft: Strings.Ok_web,
            });
            return;
          } else {
            if (billamountWithoutDeliveryCharge > 0)
              this.setState({ isPaymentMethodVisible: true });
            else {
              this.onPaymentSelection(1); //pay by cash by default with amount 0
            }
          }
        } else {
          console.log("check ----------------- ");
          if (this.state.deliveryChargeResult.status != 1) {
            this.setState({
              isAlertVisible: true,
              alertType: 0,
              alertMessage: this.state.deliveryChargeResult.message,
              isMultiButtom: false,
              btnLeft: Strings.Ok_web,
            });
            return;
          } else {
            if (billamountWithoutDeliveryCharge > 0)
              this.setState({ isPaymentMethodVisible: true });
            else {
              this.onPaymentSelection(1); //pay by cash by default with amount 0
            }
          }
        }
      }
    } else if (getSecureData(Constants.ORDERTYPE) == "SitDown") {
      if (
        this.state.tablePreBookingObj == null &&
        (this.state.tableNo == 0 ||
          this.state.tableNo == "" ||
          this.state.tableNo == null)
      ) {
        this.setState({
          isAlertVisibleTable: true,
          alertMessage: "Please Enter Table No.",
          isMultiButtom: false,
          btnLeft: "Ok",
        });
        return;
      }

      // if (billamountWithoutDeliveryCharge > 0)
      //     this.setState({ isPaymentMethodVisible: true })
      // else {
      if (this.state.tablePreBookingObj == null) {
        if (billamountWithoutDeliveryCharge > 0) {
          console.log("deliverif");
          this.setState({ isPaymentMethodVisible: true });
        } else {
          console.log("deliverelse");
          this.onPaymentSelection(1); //pay by cash by default with amount 0
        }
      } else {
        console.log(
          "checkPreAmount",
          this.state.tablePreBookingObj.bookingDepositAmount,
          finalTotalCheck
        );
        if (
          this.state.tablePreBookingObj.bookingDepositAmount > finalTotalCheck
        ) {
          console.log("i callorder");
          this.callAddOrder(1);
        } else {
          console.log("else callorder");
          this.setState({
            isPaymentMethodVisible: true,
            isPayPal: true,
            paymentMode: 1,
          });
        }
      }
      //pay by cash by default with amount 0
      // }
    } else {
      console.log("else order type ----- ", getSecureData(Constants.ORDERTYPE));
    }

    /*if (this.state.orderTimeType == 2) {
                
            let uk = moment(this.state.uktime,'HH:mm')
            let custom = moment(this.state.customTime,'HH:mm')

            if(!uk.isBefore(custom)) {
                this.setState({isAlertVisible: true, alertMessage:Strings.past_time, isMultibutton: false, btnLeft:Strings.Ok_web })
                return
            } else if (!this.state.isCompanyChecked) {
                if (this.state.selectedAddress['address1'] == null) {
                    this.setState({isAlertVisible: true, alertMessage:Strings.select_add_delivery_address, isMultiButtom: false, btnLeft:Strings.Ok_web, alertType: 0})
                    return
                } else 
                    this.setState({isPaymentMethodVisible: true})
            } else 
                this.setState({isPaymentMethodVisible: true})
                
        } else if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery') {
            if (!this.state.isCompanyChecked) {
                if (this.state.selectedAddress['address1'] == null) {
                    this.setState({isAlertVisible: true, alertMessage:Strings.select_add_delivery_address, isMultiButtom: false, btnLeft:Strings.Ok_web, alertType: 0})
                    return
                } else 
                    this.setState({isPaymentMethodVisible: true})
            } else 
                this.setState({isPaymentMethodVisible: true})
        } else 
            this.setState({isPaymentMethodVisible: true})*/
  }

  callDeliveryCharge() {
    console.log(
      "getSecureData(Constants.ORDERTYPE)",
      getSecureData(Constants.ORDERTYPE)
    );
    if (
      getSecureData(Constants.ORDERTYPE) == "TakeAway" ||
      getSecureData(Constants.ORDERTYPE) == "TakeAway Order" ||
      getSecureData(Constants.ORDERTYPE) == "SitDown"
    ) {
      this.onApplyVoucher();
      return;
    }
    console.log("address --------- ", this.state.selectedAddress);
    var addid = 0;
    if (this.state.isCompanyChecked) {
      addid = this.state.objCompanyAddress.deliveryaddressId;
    } else {
      if (!this.state.selectedAddress.address1) {
        return;
      } else {
        addid = this.state.selectedAddress
          ? this.state.selectedAddress.deliveryaddressId
          : 0;
      }
    }

    let customerid = getSecureData(Constants.USEROBJECT).customerId;
    let locationid = getSecureData(Constants.LOCATIONOBJECT).locationId;

    let itemTotal = 0;
    if (this.state.arrayCartData) {
      this.state.arrayCartData.map((item) => {
        itemTotal += item.totalPrice;
      });
    }

    this.props
      .calculateDeliveryCharge(
        addid,
        customerid,
        locationid,
        itemTotal,
        this.state.languageType
      )
      .then(() => {
        console.log(
          "calculateDeliveryCharge RESPONSE --------- ",
          this.props.data
        );
        // return
        if (this.props.data.status == 1) {
          if (this.props.data.result != null) {
            this.setState({
              objDeliveryCharge: this.props.data.result,
              deliveryChargeResult: this.props.data,
            });
          }
        } else {
          this.setState({
            objDeliveryCharge: {},
            alertType: 0,
            deliveryChargeResult: this.props.data,
            isAlertVisible: true,
            alertMessage: this.props.data.message,
            isMultiButtom: false,
            btnLeft: Strings.Ok_web,
          });
        }

        setTimeout(() => {
          this.onApplyVoucher();
        }, 500);
      });
  }

  checkVocherData = () => {
    if (
      this.state.memberOfferDiscount.trim() == "" ||
      this.state.memberOfferDiscount.length < 0
    ) {
      this.setState({
        isAlertVisible: true,
        alertMessage: "Please apply voucher code.",
        isMultiButtom: false,
        btnLeft: Strings.Ok_web,
        alertType: 111,
      });
      return;
    }
  };

  onApplyVoucher() {
    if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
      let userObject = getSecureData(Constants.USEROBJECT);
      if (userObject == null) {
        return;
      }
    } else {
      return;
    }

    // if (!this.state.isVoucherApplied) {
    // if (this.state.strVoucher == '') {
    //     this.setState({ isAlertVisible: true, alertMessage: Strings.errorMsgVoucherCode, isMultiButtom: false, btnLeft: Strings.Ok_web, alertType: 0, })
    // } else {

    //total amount logic same as calculate method
    var finalTotal = 0;
    let itemTotal = 0;
    if (this.state.arrayCartData) {
      this.state.arrayCartData.map((item) => {
        itemTotal += item.totalPrice;
      });
    }
    finalTotal = itemTotal;

    var discount = 0;
    var discountValue = "";
    if (this.state.objOffers) {
      //Check offers available or not
      if (this.state.objOffers.isfirstOrder == 1) {
        //Check discount type 1=Percentage 2=Amount
        if (this.state.objOffers.firstOrder.discountType == 1) {
          let str = this.state.objOffers.firstOrder.discountPercentage;
          let arrStr = str.split(".");
          if (arrStr[1] == 0) {
            discountValue = "(" + arrStr[0] + "%)";
          } else {
            discountValue = "(" + str + "%)";
          }
          let temp = (finalTotal * Number(str)) / 100;
          discount = temp;
          finalTotal -= temp; //Number(this.state.objOffers.firstOrder.discount)
        } else {
          discount = Number(this.state.objOffers.firstOrder.discount);
          finalTotal -= Number(this.state.objOffers.firstOrder.discount);
        }
      }
    }

    // let objLocation = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT))
    let objLocation = getSecureData(Constants.LOCATIONOBJECT);

    //Count Company discount
    // var cmpDiscount = 0
    // let obj = this.state.objCompanyCredit
    // let compy = obj.objScoreDetails
    // if (compy) {
    //     // if (compy.isLinked && JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery') {
    //     if (compy.isLinked && getSecureData(Constants.ORDERTYPE) == 'Delivery') {
    //         if (this.state.isCompanyChecked) {
    //             if (finalTotal >= obj.objScoreDetails.perDayCredit) {
    //                 finalTotal -= obj.objScoreDetails.perDayCredit
    //                 cmpDiscount = obj.objScoreDetails.perDayCredit
    //             } else {
    //                 cmpDiscount = finalTotal
    //                 finalTotal = 0
    //             }
    //         } else {

    //         }

    //     }
    // }

    //Count avocado point
    var avocadoDiscount = 0;
    if (
      this.state.objAvocadoDetails.useAvocadoPoint >=
      this.state.objAvocadoDetails.minredemptionrequired
    ) {
      if (this.state.isAvocadoChecked) {
        if (
          finalTotal >=
          this.state.objAvocadoDetails.minredemptionrequired *
            this.state.objAvocadoDetails.discountof1avocado
        ) {
          finalTotal -=
            this.state.objAvocadoDetails.minredemptionrequired *
            this.state.objAvocadoDetails.discountof1avocado;
          avocadoDiscount =
            this.state.objAvocadoDetails.minredemptionrequired *
            this.state.objAvocadoDetails.discountof1avocado;
        } else {
          avocadoDiscount = finalTotal;
          finalTotal -= finalTotal;
        }
      }
    }

    let cartItems = getSecureData(Constants.USERCART);

    var arrOrderItems = [];
    if (cartItems) {
      cartItems.map((item) => {
        // console.log('Item data ----- ',item)

        var objItem = {};
        let selectedPrice = item.PriceList.filter(
          (priceData) => priceData.isSelect == true
        );

        var price = {};
        price["typeid"] = selectedPrice[0].typeid;
        price["type"] = selectedPrice[0].type;
        price["price"] = selectedPrice[0].price;

        var optList = [];
        selectedPrice[0].OptionList.map((option) => {
          let dict = {};
          dict["type"] = option.type;
          dict["labelText"] = option.labelText;
          dict["thumbimgurl"] = option.thumbimgurl;
          dict["typeid"] = option.typeid;
          dict["sizepid"] = option.sizepid;
          dict["parameterId"] = option.parameterId;
          dict["price"] = option.price;
          dict["isSelect"] = option.isSelect;
          dict["isSelectAll"] = option.isSelectAll;

          if (option.isSelect) {
            optList.push(dict);
          } else {
            if (option.isSelectAll) {
              optList.push(dict);
            }
          }
        });

        objItem["itemname"] = item.menuItemName;
        objItem["orderitemId"] = item.menuitemId;
        objItem["catId"] = item.catId;
        objItem["qty"] = item.totalQty;
        objItem["rewardpoints"] = 0;
        objItem["ToppingsList"] = [];
        objItem["price"] = price;
        objItem["OptionList"] = optList;

        arrOrderItems.push(objItem);
      });
    }

    // public enum OrderType
    // {
    //     TakeAway = 1,
    //     Delivery = 2,
    //     SitDown = 3,
    //     InWaiting = 4
    // }

    let customer = getSecureData(Constants.USEROBJECT).customerId;
    let orderType =
      getSecureData(Constants.ORDERTYPE) == "Delivery" ||
      getSecureData(Constants.ORDERTYPE) == "Delivery Order"
        ? 2
        : getSecureData(Constants.ORDERTYPE) == "TakeAway"
        ? 1
        : 3;
    // let orderType = getSecureData(Constants.ORDERTYPE) == 'Delivery' ? 2 : 1
    console.log(
      "getSecureData(Constants.MEMBEROFFERS)",
      getSecureData(Constants.MEMBEROFFERS)
    );
    if (this.state.isMemebrDiscountVoucherApplied) {
      this.props
        .applyCouponCodeByMemberOfferCode(
          customer,
          finalTotal,
          this.state.memberOfferDiscount,
          orderType,
          arrOrderItems,
          this.state.languageType
        )
        .then(() => {
          console.log(
            "getMemberofferData RESPONSE --------- ",
            this.props.data
          );
          // return
          if (this.props.data.status == 1) {
            if (this.props.data.result != null) {
              var Ddata = this.props.data.result;
              //voucher available
              this.setState({
                isMemberVoucherApplied: true,
                memberOfferDiscountVoucher: Ddata,
                objVoucher: {},
                isVoucherApplied: false,
                discountString: "Reward Discount",
              });
            }
          } else if (this.props.data.status == 2) {
            memberOfferDiscountVoucher = 0;
            this.setState({
              offerType: 3,
              isMemberVoucherApplied: false,
              memberOfferDiscount: "",
              isMemebrDiscountVoucherApplied: false,
              alertType: 0,
              isAlertVisible: true,
              alertMessage: this.props.data.message,
              isMultiButtom: false,
              btnLeft: Strings.Ok_web,
            });
          } else {
          }
        });
    } else {
      this.props
        .ApplyVoucherCode(
          customer,
          finalTotal,
          this.state.strVoucher,
          orderType,
          arrOrderItems,
          this.state.languageType
        )
        .then(() => {
          console.log("ApplyVoucherCode RESPONSE --------- ", this.props.data);
          // return
          if (this.props.data.status == 1) {
            if (this.props.data.result != null) {
              //voucher available
              this.setState({
                isVoucherApplied: true,
                objVoucher: this.props.data.result,
                discountString: "Discount",
                isMemberVoucherApplied: false,
                memberOfferDiscountVoucher: {},
              });
            }
          } else if (this.props.data.status == 2) {
            this.setState({
              isVoucherApplied: false,
              alertType: 0,
              isAlertVisible: true,
              alertMessage: this.props.data.message,
              isMultiButtom: false,
              btnLeft: Strings.Ok_web,
            });
          } else {
          }
        });
    }

    // }
    // } else {
    //     this.setState({ isVoucherApplied: false, objVoucher: {}, strVoucher: '' })
    // }
  }

  onOrderTypeSelectionSet = (val, key) => {
    // dhruvin setting time with extra time
    var extra = 0;
    let uktime = moment(this.state.currentTime, "HH:mm").format("HH:mm");
    console.log("currentTime", this.state.currentTime, uktime);
    if (val == 2) {
      //delivery
      setTimeout(() => {
        // let cartItems = getSecureData(Constants.USERCART);
        // if (cartItems.length != 0) {
        this.callDeliveryCharge();
        // }
      }, 300);
      extra = getSecureData(Constants.LOCATIONOBJECT).ordersubmittime;
      console.log("dhruvin checking extra3", extra);
      let uktime1 = "";
      if (extra === undefined) {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss").format(
          "HH:mm"
        );
        console.log("uktimeif", extra, uktime1);
      } else {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss")
          .add(extra, "m")
          .format("HH:mm");
        console.log("uktimeelse", this.state.currentTime, extra, uktime1);
      }
      // let uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss")
      //   .add(extra, "m")
      //   .format("HH:mm");
      // console.log(
      //   "time ------------- ",
      //   extra,
      //   uktime1,
      //   this.state.currentTime
      // );
      this.setState({ tableNo: "" });
      //Unset custom time if previous sets
      if (this.state.orderTimeType != 2) {
        this.setState({ orderType: 2, uktime: uktime1, customTime: uktime1 });
      } else {
        this.setState({ orderType: 2, uktime: uktime1 });
      }
      setSecureData(Constants.ORDERTYPE, "Delivery Order");
      setSecureData(Constants.ORDERTYPEID, 2);
    } else if (val == 1) {
      //takeaway
      extra = getSecureData(Constants.LOCATIONOBJECT).takeawayextratime;
      let uktime1 = "";
      if (extra === undefined) {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss").format(
          "HH:mm"
        );
        console.log("uktimeif", extra, uktime1);
      } else {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss")
          .add(extra, "m")
          .format("HH:mm");
        console.log("uktimeelse", this.state.currentTime, extra, uktime1);
      }

      this.setState({ tableNo: "" });
      //Unset custom time if previous sets
      if (this.state.orderTimeType != 2) {
        this.setState({ orderType: 1, uktime: uktime1, customTime: uktime1 });
      } else {
        this.setState({ orderType: 1, uktime: uktime1 });
      }
      setSecureData(Constants.ORDERTYPE, "TakeAway Order");
      setSecureData(Constants.ORDERTYPEID, 1);
      // console.log("uktime1",uktime1,extra,getSecureData(Constants.LOCATIONOBJECT))
    } else if (val == 3) {
      //SitDown
      extra = getSecureData(Constants.LOCATIONOBJECT).bookingTimeLimit;
      let uktime1 = "";
      if (extra === undefined) {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss").format(
          "HH:mm"
        );
        console.log("uktimeif", extra, uktime1);
      } else {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss")
          .add(extra, "m")
          .format("HH:mm");
        console.log("uktimeelse", this.state.currentTime, extra, uktime1);
      }
      //Unset custom time if previous sets
      if (this.state.orderTimeType != 2) {
        this.setState({ orderType: 3, uktime: uktime1, customTime: uktime1 });
      } else {
        this.setState({ orderType: 3, uktime: uktime1 });
      }
      setSecureData(Constants.ORDERTYPE, "SitDown");
      setSecureData(Constants.ORDERTYPEID, 3);
    }
    // dhruvin setting time with extra time

    console.log("i am dhruvin in order yes");
    var ordType = 1;
    if (val == 2) {
      ordType = Strings.Delivery_web;
      Constants.OrderTypeID = 2;
      // this.setState({orderImg : 'deliveryIcon.png'})
    } else if (val == 1) {
      ordType = Strings.lbl_takeaway_react;
      Constants.OrderTypeID = 1;
      // this.setState({orderImg : 'takeAwayIcon.png'})
      this.setState({
        objDeliveryCharge: { distance: 0, deliveryCharges: 0 },
      });
    } else if (val == 3) {
      ordType = Strings.str_sitDown;
      Constants.OrderTypeID = 3;
      // this.setState({orderImg : 'tableOrderIcon.png'})
      this.setState({
        objDeliveryCharge: { distance: 0, deliveryCharges: 0 },
      });
    }
    setSecureData(Constants.ORDERTYPE, ordType);
    setSecureData(Constants.ORDERTYPEID, Constants.OrderTypeID);
    this.setState({ orderType: Constants.OrderTypeID });
    localStorage.removeItem(Constants.USERCART);
    // localStorage.removeItem('tablePreBookingObj')
    // window.location.href = '/menuItems'
    return;
  };

  onOrderTypeSelection(val, cartClr = "") {
    var extra = 0;
    // var val = val
    // var cartClr = cartClr
    // console.log("i am dhruvin 1");
    console.log(
      "order type ------ ",
      this.state.orderType,
      val,
      cartClr,
      getSecureData(Constants.ORDERTYPE)
    );

    if (val != this.state.orderType && cartClr == "") {
      console.log("if in popup show");
      this.setState({ onOrderTypeSelection: val });
      this.onReorderClick(val);
      return;
    }

    if (getSecureData(Constants.LOCATIONOBJECT) == null) {
      console.log(
        "getSecureData(Constants.LOCATIONOBJECT)if",
        getSecureData(Constants.LOCATIONOBJECT)
      );
      return;
    }
    console.log(
      "getSecureData(Constants.LOCATIONOBJECT)else",
      getSecureData(Constants.LOCATIONOBJECT)
    );

    // return
    console.log("i am dhruvin 1");
    let uktime = moment(this.state.currentTime, "HH:mm").format("HH:mm");
    console.log("currentTime", this.state.currentTime, uktime);
    if (val == 2) {
      //delivery
      setTimeout(() => {
        // let cartItems = getSecureData(Constants.USERCART);
        // if (cartItems.length != 0) {
        this.callDeliveryCharge();
        // }
      }, 300);
      extra = getSecureData(Constants.LOCATIONOBJECT).ordersubmittime;
      console.log("dhruvin checking extra3", extra);
      let uktime1 = "";
      if (extra === undefined) {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss").format(
          "HH:mm"
        );
        console.log("uktimeif", extra, uktime1);
      } else {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss")
          .add(extra, "m")
          .format("HH:mm");
        console.log("uktimeelse", this.state.currentTime, extra, uktime1);
      }
      // let uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss")
      //   .add(extra, "m")
      //   .format("HH:mm");
      // console.log(
      //   "time ------------- ",
      //   extra,
      //   uktime1,
      //   this.state.currentTime
      // );
      this.setState({ tableNo: "" });
      //Unset custom time if previous sets
      if (this.state.orderTimeType != 2) {
        this.setState({ orderType: 2, uktime: uktime1, customTime: uktime1 });
      } else {
        this.setState({ orderType: 2, uktime: uktime1 });
      }
      setSecureData(Constants.ORDERTYPE, "Delivery Order");
      setSecureData(Constants.ORDERTYPEID, 2);
    } else if (val == 1) {
      //takeaway
      extra = getSecureData(Constants.LOCATIONOBJECT).takeawayextratime;
      let uktime1 = "";
      if (extra === undefined) {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss").format(
          "HH:mm"
        );
        console.log("uktimeif", extra, uktime1);
      } else {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss")
          .add(extra, "m")
          .format("HH:mm");
        console.log("uktimeelse", this.state.currentTime, extra, uktime1);
      }

      this.setState({ tableNo: "" });
      //Unset custom time if previous sets
      if (this.state.orderTimeType != 2) {
        this.setState({ orderType: 1, uktime: uktime1, customTime: uktime1 });
      } else {
        this.setState({ orderType: 1, uktime: uktime1 });
      }
      setSecureData(Constants.ORDERTYPE, "TakeAway Order");
      setSecureData(Constants.ORDERTYPEID, 1);
      // console.log("uktime1",uktime1,extra,getSecureData(Constants.LOCATIONOBJECT))
    } else if (val == 3) {
      //SitDown
      extra = getSecureData(Constants.LOCATIONOBJECT).bookingTimeLimit;
      let uktime1 = "";
      if (extra === undefined) {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss").format(
          "HH:mm"
        );
        console.log("uktimeif", extra, uktime1);
      } else {
        uktime1 = moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss")
          .add(extra, "m")
          .format("HH:mm");
        console.log("uktimeelse", this.state.currentTime, extra, uktime1);
      }
      //Unset custom time if previous sets
      if (this.state.orderTimeType != 2) {
        this.setState({ orderType: 3, uktime: uktime1, customTime: uktime1 });
      } else {
        this.setState({ orderType: 3, uktime: uktime1 });
      }
      setSecureData(Constants.ORDERTYPE, "SitDown");
      setSecureData(Constants.ORDERTYPEID, 3);
    }

    console.log("if after return");
    if (this.state.onOrderTypeClearCart == true && cartClr == "yes") {
      localStorage.removeItem(Constants.USERCART);
      console.log("if after return remove cart");
      this.componentWillMountRenderFunction();
      if (
        getSecureData(Constants.LOCATIONOBJECT) != null &&
        getSecureData(Constants.LOCATIONOBJECT) != undefined
      ) {
        this.getRestInfo();
      }

      this.props.callGetCategoryListApi();
    }
  }

  setOrderTypeData = (e, type) => {
    e.preventDefault();
    console.log("setOrderTypeData 1 checkTimeApi : true");
    this.setState({ orderTimeType: type, checkTimeApi: true }, () => {
      this.getRestInfo1();
    });
  };

  getRestInfo1 = () => {
    if (this.state.checkTimeApi == true) {
      console.log("setOrderTypeData 3");
      this.props.getRestoInfo(this.state.languageType).then(() => {
        console.log(
          "resto info api response -------------------- ",
          this.props.data
        );
        if (this.props.data.status == 1) {
          let uktime = moment(
            this.props.data.result.ukdatetime,
            "YYYY-MM-dd HH:mm:ss"
          ).format("HH:mm");
          // this.setState({currentTime: this.props.data.result.ukdatetime})
          var extra = 0;
          if (this.state.orderType == 2) {
            //delivery
            extra = getSecureData(Constants.LOCATIONOBJECT).ordersubmittime;
            console.log("dhruvin checking extra1", extra);
          } else if (this.state.orderType == 1) {
            //takeaway
            extra = getSecureData(Constants.LOCATIONOBJECT).takeawayextratime;
          } else if (this.state.orderType == 3) {
            //SitDown
            extra = getSecureData(Constants.LOCATIONOBJECT).bookingTimeLimit;
          }
          uktime = moment(uktime, "HH:mm").add(extra, "m").format("HH:mm");

          this.setState({
            currentTime: this.props.data.result.ukdatetime,
            uktime: uktime,
            customTime: uktime,
          });
          console.log("setOrderTypeData 4");
          this.setState({ checkTimeApi: false });
        }
      });
    }
  };

  renderOrderTypes1() {
    let objLocation = getSecureData(Constants.LOCATIONOBJECT);

    return (
      <div>
        {objLocation.isOrderExtras ? (
          <div
            onClick={() => {
              this.onOrderTypeSelection(3, "");
            }}
            className="custom-control custom-radio custom-control-inline"
          >
            <input
              type="radio"
              className="custom-control-input"
              name="customRadio15"
              id="customRadio16"
              checked={this.state.orderType == 3 ? true : false}
            />

            {this.state.orderType == 3 ? (
              <label className="custom-control-label" for="customRadio16">
                <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                {"  "}
                {Strings.str_collect_drink}
              </label>
            ) : (
              <label className="custom-control-label" for="customRadio16">
                <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                {Strings.str_collect_drink}
              </label>
            )}
          </div>
        ) : null}
        {objLocation.isTakeAway ? (
          <div
            onClick={() => {
              this.onOrderTypeSelection(1, "");
            }}
            className="custom-control custom-radio custom-control-inline"
          >
            <input
              type="radio"
              className="custom-control-input"
              name="customRadio15"
              id="customRadio14"
              checked={this.state.orderType == 1 ? true : false}
            />
            {/* <label className="custom-control-label" for="customRadio14">{Strings.lbl_takeaway_react}</label> */}
            {this.state.orderType == 1 ? (
              <label className="custom-control-label" for="customRadio14">
                <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                {"  "}
                {Strings.lbl_takeaway_react}
              </label>
            ) : (
              <label className="custom-control-label" for="customRadio14">
                <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                {Strings.lbl_takeaway_react}
              </label>
            )}
          </div>
        ) : null}
        {objLocation.isDelivery ? (
          <div
            onClick={() => {
              this.onOrderTypeSelection(2, "");
            }}
            className="custom-control custom-radio custom-control-inline"
          >
            <input
              type="radio"
              className="custom-control-input"
              name="customRadio15"
              id="customRadio15"
              checked={this.state.orderType == 2 ? true : false}
            />
            {/* <label className="custom-control-label" for="customRadio15">{Strings.Delivery_web}</label> */}
            {this.state.orderType == 2 ? (
              <label className="custom-control-label" for="customRadio15">
                <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                {"  "}
                {Strings.Delivery_web}
              </label>
            ) : (
              <label className="custom-control-label" for="customRadio15">
                <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                {Strings.Delivery_web}
              </label>
            )}
          </div>
        ) : null}
      </div>
    );
  }
  renderOrderTypes() {
    let objLocation = getSecureData(Constants.LOCATIONOBJECT);

    return (
      <div className="row">
        {objLocation.isOrderExtras ? (
          <div
            onClick={() => {
              this.onOrderTypeSelection(3, "");
            }}
            className="custom-control custom-radio custom-control-inline"
          >
            <input
              type="radio"
              className="custom-control-input"
              name="customRadio15"
              id="customRadio16"
              checked={this.state.orderType == 3 ? true : false}
            />

            {this.state.orderType == 3 ? (
              <img
                src="./images/tableOrderIconGreen.png"
                className="orderTYpeimage"
              />
            ) : (
              <img
                src="./images/tableOrderIcon.png"
                className="orderTYpeimage"
              />
            )}
          </div>
        ) : null}
        {objLocation.isTakeAway ? (
          <div
            onClick={() => {
              this.onOrderTypeSelection(1, "");
            }}
            className="custom-control custom-radio custom-control-inline"
          >
            <input
              type="radio"
              className="custom-control-input"
              name="customRadio15"
              id="customRadio14"
              checked={this.state.orderType == 1 ? true : false}
            />
            {/* <label className="custom-control-label" for="customRadio14">{Strings.lbl_takeaway_react}</label> */}
            {this.state.orderType == 1 ? (
              <img
                src="./images/takeAwayIconGreen.png"
                className="orderTYpeimage"
              />
            ) : (
              <img src="./images/takeAwayIcon.png" className="orderTYpeimage" />
            )}
          </div>
        ) : null}
        {objLocation.isDelivery ? (
          <div
            onClick={() => {
              this.onOrderTypeSelection(2, "");
            }}
            className="custom-control custom-radio custom-control-inline"
          >
            <input
              type="radio"
              className="custom-control-input"
              name="customRadio15"
              id="customRadio15"
              checked={this.state.orderType == 2 ? true : false}
            />
            {/* <label className="custom-control-label" for="customRadio15">{Strings.Delivery_web}</label> */}
            {this.state.orderType == 2 ? (
              <img
                src="./images/deliveryIconGreen.png"
                className="orderTYpeimage"
              />
            ) : (
              <img src="./images/deliveryIcon.png" className="orderTYpeimage" />
            )}
          </div>
        ) : null}
      </div>
    );
  }

  noItemView() {
    let locationObject = getSecureData(Constants.LOCATIONOBJECT);
    return (
      <div className="checkoutCompoent">
        <div className="check-div">
          <label>{Strings.cart_empty}</label>
        </div>
        <p className="delivery-div mt-5">{Strings.lbl_order_type_react}</p>
        <div className="delivery-div mt-4">
          <div className="radio-div margin-bottom10">
            {this.state.tablePreBookingObj == null && locationObject != null
              ? this.renderOrderTypes()
              : null}
          </div>
          {this.state.orderType == 2 ? (
            <div className="divlocation" style={{ margin: "20px 0px" }}>
              <div>
                <h1>{Strings.lbl_location_react_web}</h1>
                <div className="">
                  <label className="control">
                    {locationObject.locationName}
                  </label>
                </div>
              </div>
              <div className="form-group">{this.renderAddressView()}</div>
            </div>
          ) : null}

          {/* commented for making invisible table number while order type selection popup */}
          {/* {this.state.orderType == 3 &&
          this.state.tablePreBookingObj == null ? (
            <div className=" form-group">
              {console.log("dhr", this.state.orderType)}
              <input
                type="text"
                placeholder="Table Number"
                className="form-control"
                name="tableNo"
                value={this.state.tableNo}
                onChange={(e) => this.setState({ tableNo: e.target.value })}
              />
            </div>
          ) : null} */}
          {this.state.orderType == 2 ? (
            <div className="radio-div">
              <a
                onClick={(e) => {
                  this.setOrderTypeData(e, 1);
                }}
              >
                <div className="custom-control custom-radio custom-control-inline min-width-300">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="customRadio1"
                    id="customRadio3"
                    checked={this.state.orderTimeType == 1 ? true : false}
                  />
                  {this.state.orderTimeType == 1 ? (
                    <label className="custom-control-label" for="customRadio3">
                      <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                      {Strings.str_order_now} - {this.state.uktime}
                    </label>
                  ) : (
                    <label className="custom-control-label" for="customRadio3">
                      <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                      {Strings.str_order_now} - {this.state.uktime}
                    </label>
                  )}
                </div>
              </a>
              {/* <br />
              <a
                onClick={(e) => {
                  this.setOrderTypeData(e, 2);
                }}
              >
                <div className="custom-control custom-radio custom-control-inline min-width-300">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="customRadio1"
                    id="customRadio4"
                    checked={this.state.orderTimeType == 2 ? true : false}
                  />
                  {this.state.orderTimeType == 2 ? (
                    <label className="custom-control-label" for="customRadio4">
                      <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                      {Strings.str_order_later}
                    </label>
                  ) : (
                    <label className="custom-control-label" for="customRadio4">
                      <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                      {Strings.str_order_later}
                    </label>
                  )}
                </div>
              </a> */}
            </div>
          ) : null}

          {this.state.orderType == 1 ? (
            <div className="radio-div">
              <a
                onClick={(e) => {
                  this.setOrderTypeData(e, 1);
                }}
              >
                <div className="custom-control custom-radio custom-control-inline min-width-300">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="customRadio1"
                    id="customRadio3"
                    checked={this.state.orderTimeType == 1 ? true : false}
                  />
                  {this.state.orderTimeType == 1 ? (
                    <label className="custom-control-label" for="customRadio3">
                      <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                      {Strings.str_takeaway_now} - {this.state.uktime}
                    </label>
                  ) : (
                    <label className="custom-control-label" for="customRadio3">
                      <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                      {Strings.str_takeaway_now} - {this.state.uktime}
                    </label>
                  )}
                </div>
              </a>
              {/* <br />
              <a
                onClick={(e) => {
                  this.setOrderTypeData(e, 2);
                }}
              >
                <div className="custom-control custom-radio custom-control-inline min-width-300">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="customRadio1"
                    id="customRadio4"
                    checked={this.state.orderTimeType == 2 ? true : false}
                  />
                  {this.state.orderTimeType == 2 ? (
                    <label className="custom-control-label" for="customRadio4">
                      <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                      {Strings.str_takeaway_later}
                    </label>
                  ) : (
                    <label className="custom-control-label" for="customRadio4">
                      <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                      {Strings.str_takeaway_later}
                    </label>
                  )}
                </div>
              </a> */}
            </div>
          ) : null}

          {/* {this.state.orderTimeType == 2 &&
          (this.state.orderType == 2 || this.state.orderType == 1) ? (
            <div className="select-time" style={{ display: "flex" }}>
              <label className="lblTime">{Strings.time_select}</label>
              <div className="timerDiv">
                <Dropdown
                  onSelect={(e) => {
                    this.onTimeSelect(1, e);
                  }}
                >
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {moment(this.state.customTime, "HH:mm").format("HH")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    as={CustomMenu}
                    style={{ height: "200px", overflow: "scroll" }}
                  >
                    {this.renderTimeView(1)}
                  </Dropdown.Menu>
                </Dropdown>
                <label style={{ fontSize: "19px", marginTop: "4px" }}>
                  {" "}
                  :{" "}
                </label>
                <Dropdown
                  onSelect={(e) => {
                    this.onTimeSelect(2, e);
                  }}
                >
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {moment(this.state.customTime, "HH:mm").format("mm")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    as={CustomMenu}
                    style={{ height: "200px", overflow: "scroll" }}
                  >
                    {this.renderTimeView(2)}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : null} */}

          {this.state.objAvocadoDetails.minredemptionrequired > 0 ? (
            this.state.objAvocadoDetails.minredemptionrequired <=
            this.state.objAvocadoDetails.useAvocadoPoint ? (
              <div className="radio-div">
                <a
                  onClick={() => {
                    this.setState({
                      isAvocadoChecked: !this.state.isAvocadoChecked,
                    });
                  }}
                >
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="customRadioo"
                      id="customRadio5"
                      checked={this.state.isAvocadoChecked}
                    />

                    <label className="custom-control-label">
                      {Strings.str_redeem_avocado_discount
                        .replace(
                          "#point#",
                          this.state.objAvocadoDetails.minredemptionrequired
                        )
                        .replace(
                          "#value#",
                          getPriceFormat(
                            this.state.objAvocadoDetails.minredemptionrequired *
                              this.state.objAvocadoDetails.discountof1avocado.toFixed(
                                2
                              )
                          )
                        )}
                    </label>
                  </div>
                </a>
              </div>
            ) : null
          ) : null}
        </div>

        <div className="total-div">
          <div className="row">
            <div className="col1">{Strings.sub_total}</div>
            <div className="col2">{getPriceFormat((0).toFixed(2))}</div>
          </div>
          <div className="row total-last">
            <div className="col1">{Strings.Total_web}</div>
            <div className="col2">{getPriceFormat((0).toFixed(2))}</div>
          </div>
        </div>

        <div className="textArea-label-checkout">
          <label>LEAVE A NOTE</label>
          <textarea
            type="text"
            className="form-control"
            placeholder={Strings.comments_web}
            value={this.state.strNotes}
            onChange={(text) => {
              this.setState({ strNotes: text.target.value });
            }}
          />
        </div>

        <div className="checkout-div">
          <div className="prod-img">
            <div className="buttons-checkout">
              <button
                className="btn btn-primary btn-medium"
                onClick={() => {
                  this.onCheckOutClickedEstimatedTimePop();
                }}
              >
                {Strings.Checkout_web}
              </button>
            </div>
          </div>
        </div>
        <div className="checkout-div-warning">
          <p>
            Allergy Advice: If you have an allergy that could harm your health
            we strongly advise you to contact the restaurant before you place
            your order.
          </p>
        </div>
      </div>
    );
  }

  renderRightView() {
    console.log("renderRightView", this.state.arrayCartData);
    let locationObject = getSecureData(Constants.LOCATIONOBJECT);

    if (this.state.arrayCartData == null) {
      return this.noItemView();
    }
    if (this.state.arrayCartData.length == 0) {
      // return (
      //     <label>{Strings.cart_empty}</label>
      // )
      return this.noItemView();
    }
    return (
      <div className="checkoutCompoent">
        <div className="check-div">{this.renderBoxCartItems()}</div>
        <p className="delivery-div">{Strings.lbl_order_type_react}</p>
        <div className="delivery-div">
          <div className="radio-div margin-bottom10">
            {this.state.tablePreBookingObj == null
              ? this.renderOrderTypes()
              : null}
          </div>
          {this.state.orderType == 2 ? (
            <div className="divlocation" style={{ margin: "20px 0px" }}>
              <div>
                <h1>{Strings.lbl_location_react_web}</h1>
                <div className="">
                  <label className="control">
                    {locationObject.locationName}
                  </label>
                  {/* <a href="javascript:void(0)" className="btn btn-primary btn-small" onClick={() => { this.props.history.push({ pathname: 'Categories', isChangeLocation: true }) }}>{Strings.btn_change_location}</a> */}
                </div>
              </div>
              <div className="form-group">{this.renderAddressView()}</div>
            </div>
          ) : null}

          {this.state.orderType == 3 &&
          this.state.tablePreBookingObj == null ? (
            <div className=" form-group">
              {/* <label className="custom-control-label" ></label> */}
              <input
                type="text"
                placeholder="Table No."
                className="form-control"
                name="tableNo"
                value={this.state.tableNo}
                onChange={(e) => this.setState({ tableNo: e.target.value })}
              />
            </div>
          ) : null}

          {this.state.orderType == 2 ? (
            <div className="radio-div">
              <a
                onClick={(e) => {
                  this.setOrderTypeData(e, 1);
                }}
              >
                <div className="custom-control custom-radio custom-control-inline min-width-300">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="customRadio1"
                    id="customRadio3"
                    checked={this.state.orderTimeType == 1 ? true : false}
                  />
                  {this.state.orderTimeType == 1 ? (
                    <label className="custom-control-label" for="customRadio3">
                      <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                      {Strings.str_order_now} - {this.state.uktime}
                    </label>
                  ) : (
                    <label className="custom-control-label" for="customRadio3">
                      <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                      {Strings.str_order_now} - {this.state.uktime}
                    </label>
                  )}
                </div>
              </a>
              {/* <br />
              <a
                onClick={(e) => {
                  this.setOrderTypeData(e, 2);
                }}
              >
                <div className="custom-control custom-radio custom-control-inline min-width-300">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="customRadio1"
                    id="customRadio4"
                    checked={this.state.orderTimeType == 2 ? true : false}
                  />
                  {this.state.orderTimeType == 2 ? (
                    <label className="custom-control-label" for="customRadio4">
                      <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                      {Strings.str_order_later}
                    </label>
                  ) : (
                    <label className="custom-control-label" for="customRadio4">
                      <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                      {Strings.str_order_later}
                    </label>
                  )}
                </div>
              </a> */}
            </div>
          ) : null}

          {this.state.orderType == 1 ? (
            <div className="radio-div">
              <a
                onClick={(e) => {
                  this.setOrderTypeData(e, 1);
                }}
              >
                <div className="custom-control custom-radio custom-control-inline min-width-300">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="customRadio1"
                    id="customRadio3"
                    checked={this.state.orderTimeType == 1 ? true : false}
                  />
                  {this.state.orderTimeType == 1 ? (
                    <label className="custom-control-label" for="customRadio3">
                      <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                      {Strings.str_takeaway_now} - {this.state.uktime}
                    </label>
                  ) : (
                    <label className="custom-control-label" for="customRadio3">
                      <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                      {Strings.str_takeaway_now} - {this.state.uktime}
                    </label>
                  )}
                </div>
              </a>
              {/* <br />
              <a
                onClick={(e) => {
                  this.setOrderTypeData(e, 2);
                }}
              >
                <div className="custom-control custom-radio custom-control-inline min-width-300">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="customRadio1"
                    id="customRadio4"
                    checked={this.state.orderTimeType == 2 ? true : false}
                  />
                  {this.state.orderTimeType == 2 ? (
                    <label className="custom-control-label" for="customRadio4">
                      <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                      {Strings.str_takeaway_later}
                    </label>
                  ) : (
                    <label className="custom-control-label" for="customRadio4">
                      <i class="fa fa-circle-o" aria-hidden="true"></i> {"  "}
                      {Strings.str_takeaway_later}
                    </label>
                  )}
                </div>
              </a> */}
            </div>
          ) : null}

          {/* {this.state.orderTimeType == 2 &&
          (this.state.orderType == 2 || this.state.orderType == 1) ? (
            <div className="select-time" style={{ display: "flex" }}>
              <label className="lblTime">{Strings.time_select}</label>
              <div className="timerDiv">
                <Dropdown
                  onSelect={(e) => {
                    this.onTimeSelect(1, e);
                  }}
                >
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {moment(this.state.customTime, "HH:mm").format("HH")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    as={CustomMenu}
                    style={{ height: "200px", overflow: "scroll" }}
                  >
                    {this.renderTimeView(1)}
                  </Dropdown.Menu>
                </Dropdown>
                <label style={{ fontSize: "19px", marginTop: "4px" }}>
                  {" "}
                  :{" "}
                </label>
                <Dropdown
                  onSelect={(e) => {
                    this.onTimeSelect(2, e);
                  }}
                >
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {moment(this.state.customTime, "HH:mm").format("mm")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    as={CustomMenu}
                    style={{ height: "200px", overflow: "scroll" }}
                  >
                    {this.renderTimeView(2)}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : null} */}

          {/* {(this.state.objAvocadoDetails.minredemptionrequired > this.state.objAvocadoDetails.discountof1avocado) ? */}
          {this.state.objAvocadoDetails.minredemptionrequired > 0 ? (
            this.state.objAvocadoDetails.minredemptionrequired <=
            this.state.objAvocadoDetails.useAvocadoPoint ? (
              <div className="radio-div">
                <a
                  onClick={() => {
                    this.setState({
                      isAvocadoChecked: !this.state.isAvocadoChecked,
                    });
                  }}
                >
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="customRadioo"
                      id="customRadio5"
                      checked={this.state.isAvocadoChecked}
                    />
                    <label className="custom-control-label">
                      {Strings.str_redeem_avocado_discount
                        .replace(
                          "#point#",
                          this.state.objAvocadoDetails.minredemptionrequired
                        )
                        .replace(
                          "#value#",
                          getPriceFormat(
                            this.state.objAvocadoDetails.minredemptionrequired *
                              this.state.objAvocadoDetails.discountof1avocado.toFixed(
                                2
                              )
                          )
                        )}
                    </label>
                  </div>
                </a>
              </div>
            ) : null
          ) : null}
          {/*Voucher code comment*/}
          {/* <div className="couponDiv" style={{marginTop: '20px'}}>
                            <div className="inpwrap">
                                <input type="text" className="form-control" placeholder={Strings.hint_voucher_code} readOnly={this.state.isVoucherApplied} maxLength={16} value={this.state.strVoucher} onChange={text => {this.setState({strVoucher: text.target.value})}}/>
                            </div>
                            <div className="btnwrap">
                                <a href="javascript:void(0)" className="btn btn-small trans-btn tiny" onClick={()=>{this.onApplyVoucher()}}>{this.state.isVoucherApplied ? Strings.clear : Strings.btn_apply}</a>   
                            </div>
                        </div> */}
          <div className="form-group offerType">
            {getSecureData(Constants.USEROBJECT) != undefined ? (
              <>
                <div class="form-check form-check-inline">
                  <input
                    className="custom-control-input"
                    id="inlineRadio3"
                    type="radio"
                    name="offerType"
                    value={3}
                    onChange={(e) => this.setState({ offerType: 3 })}
                    checked={this.state.offerType === 3}
                  />
                  <label
                    onClick={() => {
                      this.setState(
                        { isMemebrDiscountVoucherApplied: false },
                        () => {
                          this.onApplyVoucher();
                        }
                      );
                    }}
                    className="form-check-label"
                    for="inlineRadio3"
                  >
                    <i
                      class={
                        this.state.offerType == 3
                          ? "fas fa-check-circle"
                          : "fa fa-circle-o"
                      }
                      aria-hidden="true"
                    ></i>
                    Discount
                  </label>
                </div>

                {this.state.getMemberofferData.length > 0 ? (
                  <div class="form-check form-check-inline">
                    <input
                      className="custom-control-input"
                      id="inlineRadio1"
                      type="radio"
                      name="offerType"
                      value={1}
                      onChange={(e) => this.setState({ offerType: 1 })}
                      checked={this.state.offerType === 1}
                    />
                    <label
                      onClick={() => {
                        this.checkOfferList();
                      }}
                      className="form-check-label"
                      for="inlineRadio1"
                    >
                      <i
                        class={
                          this.state.offerType == 1
                            ? "fas fa-check-circle"
                            : "fa fa-circle-o"
                        }
                        aria-hidden="true"
                      ></i>
                      Loyalty Offers
                    </label>
                  </div>
                ) : null}
                <div class="form-check form-check-inline">
                  <input
                    className="custom-control-input"
                    id="inlineRadio2"
                    type="radio"
                    name="offerType"
                    value={2}
                    onChange={(e) => this.setState({ offerType: 2 })}
                    checked={this.state.offerType === 2}
                  />
                  <label
                    onClick={() => {
                      this.setState({ offerType: 2, memberOfferDiscount: "" });
                    }}
                    className="form-check-label"
                    for="inlineRadio2"
                  >
                    <i
                      class={
                        this.state.offerType == 2
                          ? "fas fa-check-circle"
                          : "fa fa-circle-o"
                      }
                      aria-hidden="true"
                    ></i>
                    Promo Code
                  </label>
                </div>
              </>
            ) : null}

            {this.state.offerType === 2 ? (
              <div className="form-group offerType">
                <input
                  type="text"
                  className="form-control promoCode"
                  placeholder="Promo Code"
                  value={this.state.memberOfferDiscount}
                  onChange={(e) => {
                    this.setState({
                      memberOfferDiscount: e.target.value,
                      isMemebrDiscountVoucherApplied: true,
                    });
                  }}
                />
                <button
                  className="btn btn-primary btn-lg btn-block"
                  onClick={() => {
                    this.state.memberOfferDiscount == ""
                      ? this.checkVocherData()
                      : this.onApplyVoucher();
                  }}
                >
                  Apply
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {this.calculateFinalPrice()}
        <div className="textArea-label-checkout">
          <label>LEAVE A NOTE</label>
          <textarea
            type="text"
            className="form-control"
            placeholder={Strings.comments_web}
            value={this.state.strNotes}
            onChange={(text) => {
              this.setState({ strNotes: text.target.value });
            }}
          />
        </div>
        <div className="checkout-div">
          <div className="prod-img">
            <div className="buttons-checkout">
              <button
                className="btn btn-primary btn-medium"
                onClick={() => {
                  this.onCheckOutClickedEstimatedTimePop();
                }}
              >
                {Strings.Checkout_web}
              </button>
            </div>
          </div>
        </div>
        <div className="checkout-div-warning">
          <p>
            Allergy Advice: If you have an allergy that could harm your health
            we strongly advise you to contact the restaurant before you place
            your order.
          </p>
        </div>
      </div>
    );
  }

  renderDrinkButton() {
    if (this.state.arrayCartData) {
      if (this.state.arrayCartData.length > 0) {
        if (!this.state.objCheckInfo) {
          return;
        }
        var drinkId = this.state.objCheckInfo.drinksCatId;
        var isShow = true;
        for (var i = 0; i < this.state.arrayCartData.length; i++) {
          let obj = this.state.objCheckInfo.drinksSubCatIds.filter(
            (ids) => ids == this.state.arrayCartData[i].catId
          );
          // console.log('arrr --- ---- ',this.state.objCheckInfo.drinksSubCatIds)
          if (obj.length > 0) {
            isShow = false;
            break;
          }
        }
        if (isShow) {
          return (
            // <a href="#" className="btn btn-primary btn-small" onClick={()=>{localStorage.setItem(Constants.PRE_CAT_ID,JSON.stringify(this.state.objCheckInfo.drinksCatId)); this.props.history.push({ pathname: '/MenuItems', catId: this.state.objCheckInfo.drinksCatId})}}>{Strings.str_add_drink}</a>
            <a
              href="#"
              className="btn btn-primary btn-small"
              onClick={() => {
                setSecureData(
                  Constants.PRE_CAT_ID,
                  this.state.objCheckInfo.drinksCatId
                );
                this.props.history.push({
                  pathname: "/MenuItems",
                  catId: this.state.objCheckInfo.drinksCatId,
                });
              }}
            >
              {Strings.str_add_drink}
            </a>
          );
        }
      }
    }
  }

  onLogin() {
    this.setState({ isLoginEnabled: false, isSignUpEnabled: false }, () => {
      setTimeout(() => {
        if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
          let userObject = getSecureData(Constants.USEROBJECT);
          if (userObject != null) {
            let customer = getSecureData(Constants.USEROBJECT).customerId;
            let id = getSecureData(Constants.LOCATIONOBJECT).locationId;
            this.callGetDeliveryAddress(id, customer);
            if (
              getSecureData(Constants.LOCATIONOBJECT) != null &&
              getSecureData(Constants.LOCATIONOBJECT) != undefined
            ) {
              // this.getMemberofferList()
            }
          } else {
          }
        } else {
        }
      }, 200);
    });
  }

  getMemberofferList = () => {
    let locationId = getSecureData(Constants.LOCATIONOBJECT).locationId;
    let userObject = getSecureData(Constants.USEROBJECT);
    console.log("userObject", userObject);
    this.setState({ locationId: locationId });
    var data = {
      locationId: locationId,
      customerId: userObject.customerId,
      languageId: this.state.languageType,
    };
    console.log("MemberOfferList data", data);
    if (
      userObject.customerId != 0 &&
      this.state.languageType != "" &&
      locationId != ""
    ) {
      this.props
        .getMemberofferList(data)
        .then(() => {
          console.log("MemberOfferList :", this.props.data);
          if (this.props.data.status == 1) {
            this.setState({
              getMemberofferData: this.props.data.result.MemberOfferList,
              offerLevelPoint: this.props.data.result.offerLevelPoint,
              level: this.props.data.result.level,
            });
          } else {
            // alert('Something went wrong')
          }
        })
        .catch((err) => {
          console.log("error ", err);
        });
    }
  };
  offerListModal = () => {
    return (
      <Modal
        show={this.state.showOfferModal}
        onHide={() => {
          this.setState({ showOfferModal: false });
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="offerMEmberClass">
          <div className="listOffersTtl">
            <div className="row">
              <div className="col">
                <p>REWARDS : {this.state.offerLevelPoint}</p>
              </div>
              <div className="col">
                <p>LEVEL {this.state.level}</p>
              </div>
            </div>
          </div>

          <h3 className="text-center">{"Apply Voucher Code"}</h3>

          <div className="listOffersCHKrow">
            {this.state.getMemberofferData.length > 0
              ? this.state.getMemberofferData.map((data) => {
                  return (
                    <div className="row">
                      <div className="col">
                        <p>{data.MemberOfferRewardTitle}</p>
                      </div>
                      <div className="col">
                        <p
                          className="btn btn-primary"
                          onClick={() => {
                            this.setState(
                              {
                                memberOfferDiscount: data.MemberOfferCode,
                                showOfferModal: false,
                                isMemebrDiscountVoucherApplied: true,
                              },
                              () => {
                                this.onApplyVoucher();
                              }
                            );
                          }}
                        >
                          Apply
                        </p>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  checkOfferList = () => {
    this.setState({ offerType: 1, showOfferModal: true });
  };

  onReorderClick(val) {
    if (this.state.arrayCartData == null) {
      this.setState({
        isAlertVisibleForReorder: true,
        alertMessage: "Are you want to change the order type?",
        isMultiButtom: true,
        btnLeft: "No",
        btnRight: "Yes",
        // offerType : val
      });
    } else if (this.state.arrayCartData.length == 0) {
      this.setState({
        isAlertVisibleForReorder: true,
        alertMessage: "Are you want to change the order type?",
        isMultiButtom: true,
        btnLeft: "No",
        btnRight: "Yes",
        // offerType : val
      });
    } else {
      this.setState({
        isAlertVisibleForReorder: true,
        alertMessage:
          "If you wish to select a different Order type, this will reset your order. do you want to remove your items and continue with your order from a different order type?",
        isMultiButtom: true,
        btnLeft: "No",
        btnRight: "Yes",
        // offerType : val
      });
    }
  }

  addRemoveFavItem = (data, url) => {
    console.log("addRemoveFavItem", data, url);
    // return
    var data = {
      restaurantId: 147,
      languageId: getSecureData(Constants.PRE_LANGUAGE_TYPE),
      customerId:
        getSecureData(Constants.USEROBJECT) != undefined
          ? getSecureData(Constants.USEROBJECT).customerId
          : 0,
      locationId:
        getSecureData(Constants.LOCATIONOBJECT) != undefined
          ? getSecureData(Constants.LOCATIONOBJECT).locationId
          : 1,
      menuitemId: data.menuitemId,
      url: url,
    };

    var MenuItemList = getSecureData("arrayWholeMenuItems");

    // console.log('arrayWholeMenuItems',MenuItemList)
    // return false
    var likedData = MenuItemList.map((item) => {
      if (item.menuitemId == data.menuitemId) {
        if (url == "addFavorite") {
          item.isFavoriteMenu = true;
        } else {
          item.isFavoriteMenu = false;
        }
      }
      return item;
    });

    this.setState({ arrayMenuItems: likedData });

    this.props.addRemoveFavItem(data).then(() => {
      if (this.props.data.status == 1) {
        if (this.props.data.result != null) {
        }
      }
    });
  };

  render() {
    return (
      <>
        {console.log(
          "userCartData",
          this.state.userCartData,
          this.state.arrayCartData,
          this.state.cartUpdate
        )}

        {/* {this.state.cartUpdate ? this.componentWillMountRenderFunction() : null} */}

        {this.state.isAddAddressVisible ? (
          <AddAddressPopup
            isShow={this.state.isAddAddressVisible}
            onHideAddAddress={(data) => {
              this.onAddNewAddress(data);
            }}
          />
        ) : null}

        {this.state.isAddressVisible ? (
          <AddressPopup
            isShow={this.state.isAddressVisible}
            addressList={this.state.objDeliveryAddresses}
            onHideAddressView={(data) => {
              this.onCloseAddress(data);
            }}
            onAddNewAddress={() => {
              this.onAddAddress();
            }}
            onDeleteAddress={() => {
              this.onDeleteAddress();
            }}
          />
        ) : null}

        <AlertDialog
          isAlertVisible={this.state.isAlertVisible}
          msgTitle={this.state.alertMessage}
          isMultibutton={this.state.isMultiButtom}
          btnLeftClick={() => {
            this.onLeftClick();
          }}
          btnRightClick={() => {
            this.onRightClick();
          }}
          btnLeftTitle={this.state.btnLeft}
          btnRightTitle={this.state.btnRight}
        />

        {this.state.isItemPopupVisible ? (
          <ItemPopup
            itemData={this.state.editItem}
            editIndex={this.state.editAt}
            isEdit={true}
            onClose={(popup) => {
              this.onPopupClose(popup);
            }}
            isFavShow={false}
            isVisible={this.state.isItemPopupVisible}
          />
        ) : null}

        <AlertDialog
          isAlertVisible={this.state.isAlertVisibleForReorder}
          msgTitle={this.state.alertMessage}
          isMultibutton={this.state.isMultiButtom}
          btnLeftClick={() => {
            this.setState({ isAlertVisibleForReorder: false });
          }}
          btnRightClick={() => {
            this.setState(
              { isAlertVisibleForReorder: false, arrayCartData: [] },
              () => {
                this.onOrderTypeSelectionSet(
                  this.state.onOrderTypeSelection,
                  "yes"
                );
              }
            );
          }}
          btnLeftTitle={this.state.btnLeft}
          btnRightTitle={this.state.btnRight}
          orderDetailId={""}
          history={this.props.history}
        />

        <AlertDialog
          isAlertVisible={this.state.isAlertVisibleTable}
          msgTitle={this.state.alertMessage}
          isMultibutton={this.state.isMultiButtom}
          btnLeftClick={() => {
            this.setState({ isAlertVisibleTable: false });
          }}
          btnRightClick={() => {
            return;
          }}
          btnLeftTitle={this.state.btnLeft}
          btnRightTitle={this.state.btnRight}
          orderDetailId={this.state.orderDetailId}
          history={this.props.history}
        />

        {this.state.isPaymentMethodVisible ? (
          <PaymentMethod
            amount={billamountWithoutDeliveryCharge}
            isShow={this.state.isPaymentMethodVisible}
            isHide={(type) => {
              this.onPaymentSelection(type);
            }}
            isCard={this.state.isPayByCard}
            isCash={this.state.isPayByCash}
          />
        ) : null}
        {
          <LoginForm
            isShowForm={this.state.isLoginEnabled}
            onResponse={() => this.onLogin()}
            onSignUp={() => {
              this.setState({ isLoginEnabled: false, isSignUpEnabled: true });
            }}
            onForget={() => {
              this.setState({ isLoginEnabled: false, isForgetEnabled: true });
            }}
          ></LoginForm>
        }
        {
          <SignUpForm
            isShowForm={this.state.isSignUpEnabled}
            onResponse={() => this.onLogin()}
          ></SignUpForm>
        }
        {
          <ForgetForm
            isShowForm={this.state.isForgetEnabled}
            onResponse={() =>
              this.setState({
                isLoginEnabled: false,
                isSignUpEnabled: false,
                isForgetEnabled: false,
              })
            }
          ></ForgetForm>
        }

        <div className="checkout">
          <div className="row" style={{ display: "block" }}>
            {this.renderRightView()}
          </div>
          {/* <!--row end--> */}
        </div>
        {/* {this.offerListModal()} */}
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log("isLoaging Checkout ------- " + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getOffersAPI: (amount, customerId, languageType) =>
    dispatch(
      HGActions.initCheckSpecialOffersAPI(amount, customerId, languageType)
    ),
  getRestoInfo: (languageType) =>
    dispatch(HGActions.initGetRestoInfoAPI(languageType)),
  getCompanyCreditAPI: (customerId, languageType) =>
    dispatch(HGActions.initCompanyCreditScoreAPI(customerId, languageType)),
  addCompnayAddressAPI: (locationid, address, languageType) =>
    dispatch(
      HGActions.initAddCompanyAddressAPI(locationid, address, languageType)
    ),
  getDeliveryAddressAPI: (locationId, customerId, languageType) =>
    dispatch(
      HGActions.initGetDeliveryAddressAPI(locationId, customerId, languageType)
    ),
  addOrderAPI: (OrderInfo, languageType) =>
    dispatch(HGActions.initAddOrdersAPI(OrderInfo, languageType)),
  getItemDetailsAPI: (menuitemId, languageType) =>
    dispatch(HGActions.initGetItemDetailsAPI(menuitemId, languageType)),
  getAvocadoDetailsAPI: (languageType, customerId) =>
    dispatch(HGActions.initGetAvocadoDetailsAPI(languageType, customerId)),
  AppMessageApi: (languageType) =>
    dispatch(HGActions.GetAppMessageListApi(languageType)),
  callCancelAPI: (TransactionInfo, languageType) =>
    dispatch(HGActions.initCancelOrderAPI(TransactionInfo, languageType)),
  calculateDeliveryCharge: (
    addressId,
    customerId,
    locationId,
    totalAmount,
    languageId
  ) =>
    dispatch(
      HGActions.initDeliveryChargeAPI(
        addressId,
        customerId,
        locationId,
        totalAmount,
        languageId
      )
    ),
  checkNextDayOrder: (customerId, comapanyId, locationId, time, languageId) =>
    dispatch(
      HGActions.initCheckNextDayOrderAPI(
        customerId,
        comapanyId,
        locationId,
        time,
        languageId
      )
    ),
  ApplyVoucherCode: (
    customerId,
    totalAmount,
    offerCode,
    orderType,
    OrderedItems,
    languageType
  ) =>
    dispatch(
      HGActions.initApplyVoucherCode(
        customerId,
        totalAmount,
        offerCode,
        orderType,
        OrderedItems,
        languageType
      )
    ),
  getMemberofferList: (data) => dispatch(HGActions.getMemberofferList(data)),
  applyCouponCodeByMemberOfferCode: (
    customerId,
    totalAmount,
    offerCode,
    orderType,
    OrderedItems,
    languageType
  ) =>
    dispatch(
      HGActions.applyCouponCodeByMemberOfferCode(
        customerId,
        totalAmount,
        offerCode,
        orderType,
        OrderedItems,
        languageType
      )
    ),
  MenuTimeCheckingBeforeOrderPlace: (OrderInfo, languageType) =>
    dispatch(
      HGActions.MenuTimeCheckingBeforeOrderPlace(OrderInfo, languageType)
    ),
  addRemoveFavItem: (data) => dispatch(HGActions.addRemoveFavItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDelivery);
