import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import { Strings } from '../constants/Localization';
import FooterMenu from '../Components/FooterMenu';
// import MenuItems_categories from './MenuItems_categories'
import Constant from '../constants/baseConstants';
import baseConstants from '../constants/baseConstants';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
import AlertDialog from '../Components/AlertDialog';
import { getPriceFormat } from "../Utils/utils";
import {setSecureData, getSecureData} from '../Utils/utils';
import CMS from '../Components/CMS';
import {getCurrencySign,bindhttps} from "../Utils/utils";
import ItemPopup from '../Components/ItemPopup';
import moment from 'moment';



import AOS from 'aos';
import 'aos/dist/aos.css';

var mOrderId = 0;
class OrderDetails extends Component {

    constructor(props) {
        super(props)

        //Get order id from order listing page
        //mOrderId = this.props.location.orderId;
        //Getting order id from local storage
        // mOrderId = localStorage.getItem(baseConstants.PRE_ORDER_ID);
        mOrderId = getSecureData(baseConstants.PRE_ORDER_ID);
        console.log("orderId :" + mOrderId)
        this.state = {
            orderDetailArray: [],
            orderStatusHistory : {},
            historyObject: {},
            errorMessage: '',
            languageType: 1, //default language type
            isItemPopupVisible: false,
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            shouldMove: false,
            objItem: {},
            objectAt : {},

            isShowCMS: false,
            cmsHref:'',
            arrayMainCategories : [],
            isAlertVisibleForReorder : false,
            isReorderCartEmpty : false,
            currentTime: 0, 
            ukdatetime : 0
        }
    }

    componentDidMount() {
        //Init animation duration
        AOS.init({
            duration: 1000
        })
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // let userObject = localStorage.getItem(Constants.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
        }
        else {
            this.props.history.push({ pathname: '/' })
            return
        }
        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        setTimeout(() => {
            console.log("call order details api :")
            this.props.orderDetailsApi(mOrderId, this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var result = this.props.data.result;

                    console.log("this.props.data.result",this.props.data.result)
                    if (result != null) {
                        var orderArray = result.orderedItems;
                        var orderStatusHistory = result.orderStatusHistory
                        if (orderArray != null && orderArray.length > 0) {
                            this.setState({ orderDetailArray: orderArray, historyObject: result, cmsHref: 'https://stuart.followmy.delivery/127764036/78151d518a339a84810cf67125261fa6', orderStatusHistory: orderStatusHistory })
                        }
                        else {
                            this.setState({ errorMessage: Strings.lbl_no_order_react })
                        }
                    }
                }
                else {
                    this.setState({ errorMessage: this.props.error })
                }
                this.callGetCategoryListApi()
                this.getRestoInfo()
            })
        }, 500);
    }

    getRestoInfo = () => {
        this.props.getRestoInfo(this.state.languageType).then(() => {
              console.log('resto info api response -------------------- ', this.props.data)
              if (this.props.data.status == 1) {
                  let uktime = moment(this.props.data.result.ukdatetime, "YYYY-MM-dd HH:mm:ss").format("HH:mm")
              
                  this.setState({currentTime: uktime, ukdatetime : this.props.data.result.ukdatetime })
                    
              }
            })
    
      }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my order page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        console.log('language type  :' + languageType);
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType });
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    onReorderClick() {

        if (!getSecureData(Constant.USERCART)) {

            this.setState({isReorderCartEmpty : true},()=>{this.reorderConfirm()})
            
        }
        else if (getSecureData(Constant.USERCART).length > 0) {

            console.log("LOCATIONOBJECT",this.state.orderDetailArray[0],getSecureData(Constant.ORDERTYPE),getSecureData(Constant.ORDERTYPEID))

            if(getSecureData(Constant.ORDERTYPEID) == this.state.orderDetailArray[0].ordertype){
                this.setState({isReorderCartEmpty : true},()=>{this.reorderConfirm()})
            }
            else{
                this.setState({ isAlertVisibleForReorder: true, alertMessage: 'Do you want to remove your cart item?', isMultiButton: true, btnLeft: 'No', btnRight: 'Yes' })
            }
            
        }
        else{
            this.setState({isReorderCartEmpty : true},()=>{this.reorderConfirm()})
        }

        
        // this.reorderConfirm()

        
    }

    reorderConfirm(){
        if(this.state.isReorderCartEmpty){
            // return

            console.log("mOrderId",mOrderId,this.state.orderDetailArray[0])
            console.log("LOCATIONOBJECT",this.state.orderDetailArray[0],getSecureData(Constant.ORDERTYPE),getSecureData(Constant.ORDERTYPEID))
            // return

            if(getSecureData(Constant.ORDERTYPEID) != this.state.orderDetailArray[0].ordertype){

                
                localStorage.removeItem(Constant.USERCART)
                localStorage.removeItem('tablePreBookingObj')
                if(this.state.orderDetailArray[0].ordertype == 1){
                    if(getSecureData(Constant.LOCATIONOBJECT).isTakeAway == true){
                        setSecureData(Constant.ORDERTYPE,'TakeAway')
                        setSecureData(Constant.ORDERTYPEID,1)
                    }
                    else{
                        this.setState({isAlertVisible: true, alertMessage:'Takeaway is not available now.', isMultiButton: false, btnLeft:Strings.Ok_web})
                        return
                    }
                    
                }
                else if(this.state.orderDetailArray[0].ordertype == 2){

                    if(getSecureData(Constant.LOCATIONOBJECT).isDelivery == true){
                        setSecureData(Constant.ORDERTYPE,'Delivery')
                        setSecureData(Constant.ORDERTYPEID,2)
                    }
                    else{
                        this.setState({isAlertVisible: true, alertMessage:'Delivery is not available now.', isMultiButton: false, btnLeft:Strings.Ok_web})
                        return
                    }
                    
                }
                else if(this.state.orderDetailArray[0].ordertype == 3){

                    if(getSecureData(Constant.LOCATIONOBJECT).isOrderExtras == true){
                        setSecureData(Constant.ORDERTYPE,'SitDown')
                        setSecureData(Constant.ORDERTYPEID,3)
                    }
                    else{
                        this.setState({isAlertVisible: true, alertMessage:'Table Order is not available now.', isMultiButton: false, btnLeft:Strings.Ok_web})
                        return
                    }
                    
                }
            }
            
            
                
          
    
            
            // console.log("orderitemId",item,orderitemId)
            let myCatData = this.state.arrayMainCategories.filter(data => (data.catId == this.state.orderDetailArray[0].catId))
    
            // console.log("myCatData", myCatData, this.state.arrayMainCategories)
            let uktime = moment(this.state.ukdatetime, "YYYY-MM-dd HH:mm:ss").format("HH:mm:ss")
            let ukdate = moment(this.state.ukdatetime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")
    
            // this.props.checkItemTimeAPI(uktime,ukdate,this.state.orderDetailArray[0].catId,this.state.languageType).then(() => {
            
            // if (this.props.data.status == 1) {
                //call reorder
                this.props.callReOrderInfoAPI(mOrderId, this.state.languageType).then(() => {
                    if (this.props.data.status == 1) {
                        var result = this.props.data.result;
        
                        if (result.orderedItems.length > 0) {
                            //check cart item location and reorder item location 
                            if (result.orderedItems[0].locationId == getSecureData(Constant.LOCATIONOBJECT).locationId) {
                                result.orderedItems.map(items => {
        
                                    console.log("checkReorderFood",items)
                                    // let cartData = JSON.parse(localStorage.getItem(Constant.USERCART))
                                    let cartData = getSecureData(Constant.USERCART)
        
                                    items.PriceList[0].isSelect = true
                                    // items['PriceList'] = items.PriceList
                                    items['menuItemName'] = items['itemname']
                                    items['menuitemId'] = items['orderitemId']
                                    items['imgurl'] = items['imageurl']
                                    items['totalQty'] = items['qty']
                                    items['totalPrice'] = items['totalprice']
                                    items['isReOrderedItem'] = true

                                    if(items.isLoyaltyOfferItem == undefined || items.isLoyaltyOfferItem == false)
                                    {
        
                                    if (cartData != null) {
                                        if (cartData.length > 0) {
                                            // cartData.push(items)
                                            // setSecureData(Constant.USERCART, cartData)
        
                                            console.log('Cart Data --- ', JSON.stringify(cartData))
                                            console.log('itemObject --- ', JSON.stringify(items))
        
                                            var shouldUpdate = false
                                            var updateAt = 0
        
                                            //check menu item id available and matched 
                                            cartData.map((cartObject, index) => {
                                                if (cartObject.menuitemId == items.menuitemId &&  (items.isLoyaltyOfferItem == undefined || items.isLoyaltyOfferItem == false)) {
                                                    let cartPriceType = cartObject.PriceList.filter(data => (data.isSelect == true))
                                                    let itemPriceType = items.PriceList.filter(data => (data.isSelect == true))
                                                    //compare selected price type
                                                    if (cartPriceType[0].typeid == itemPriceType[0].typeid) {
                                                    //get selected options list type 
                                                    let cartOptions = cartPriceType[0].OptionList.filter(opt => (opt.isSelect == true))
                                                    let itemOptions = itemPriceType[0].OptionList.filter(opt => (opt.isSelect == true))
        
                                                    var arrayCartOpt = []
                                                    cartOptions.map(cartopt => (
                                                        arrayCartOpt.push(cartopt.typeid)
                                                    ))
                                                    var arrayItemOpt = []
                                                    itemOptions.map(itemopt => (
                                                        arrayItemOpt.push(itemopt.typeid)
                                                    ))
                                                    console.log('arrayCartOpt ----- ', arrayCartOpt)
                                                    console.log('arrayItemOpt ----- ', arrayItemOpt)
                                                    console.log('index ----- ', index)
                                                    //compare selected options list
                                                    // if (JSON.stringify(arrayCartOpt) == JSON.stringify(arrayItemOpt)) {
                                                    if (JSON.stringify(cartPriceType[0].OptionList) == JSON.stringify(itemPriceType[0].OptionList)) {
                                                        shouldUpdate = true
                                                        updateAt = index
                                                        console.log('Both object are same......')
                                                    } else {
                                                        console.log('Both object are not same......')
                                                    }
                                                    }
                                                }
                                            })
        
                                            if (shouldUpdate) {
        
                                                let oldQty = cartData[updateAt].totalQty
                                                let oldPrice = cartData[updateAt].totalPrice
        
                                                console.log('item data should update at --- ', updateAt,)
                                                //add new qty and price in cart item
                                                cartData[updateAt].totalQty += items.totalQty
                                                cartData[updateAt].totalPrice += items.totalPrice
        
                                                setSecureData(Constant.USERCART, cartData)
                                            } else {
        
                                                console.log('add item in cart')
                                                cartData.push(items)
                                                setSecureData(Constant.USERCART, cartData)
                                            }
        
                                        } else {

                                            console.log("cartData null else", getSecureData(Constant.LOCATIONOBJECT))
                                            setSecureData(Constant.USERCART, [items])
                                        }
                                    } else {
                                        console.log("cartData not null else", getSecureData(Constant.LOCATIONOBJECT))
                                        setSecureData(Constant.USERCART, [items])
                                    }
                                    }
        
                                })
                                this.setState({ shouldMove: true, isAlertVisible: true, alertMessage: Strings.error_msg_item_added_react, isMultiButton: true, btnLeft: Strings.Ok_web, btnRight: Strings.btn_view_order_react })
                                // if (!getSecureData(Constant.ORDERTYPE)) {
                                //     // localStorage.setItem(Constant.ORDERTYPE, JSON.stringify('Delivery'))
                                //     setSecureData(Constant.ORDERTYPE, 'Delivery')
                                // }
                            } else {
        
                                result.orderedItems.map(items => {
        
                                    items.PriceList[0].isSelect = true
                                    items['menuItemName'] = items['itemname']
                                    items['menuitemId'] = items['orderitemId']
                                    items['imgurl'] = items['imageurl']
                                    items['totalQty'] = items['qty']
                                    items['totalPrice'] = items['totalprice']
                                    items['isReOrderedItem'] = true
        
                                    this.setState({ objItem: items })
                                })
        
                                this.setState({ isAlertVisible: true, alertMessage: Strings.cart_from_different_location, isMultiButton: true, btnLeft: Strings.str_no, btnRight: Strings.yes })
                            }
                        }
        
                    } else {
                        this.setState({isAlertVisible: true, alertMessage:this.props.data.message, isMultiButton: false, btnLeft:Strings.Ok_web})
                    }
                })  
            // } else {
            //     this.setState({isAlertVisible: true, alertMessage:this.props.data.message, isMultiButton: false, btnLeft:Strings.Ok_web})
            // }
            // })
            }
    }

    onTrackOrder() {
        if(this.state.cmsHref) {
            this.setState({isShowCMS:true})
        }
    }

    onRightClick() {
        if (this.state.shouldMove) {
            this.props.history.push({ pathname: '/menuItems' })
        } else {
            localStorage.removeItem(Constant.USERCART)
            // localStorage.setItem(Constant.USERCART, JSON.stringify([this.state.objItem]))
            setSecureData(Constant.USERCART, [this.state.objItem])

            // let locationList = JSON.parse(localStorage.getItem(Constant.LOCATIONLISTOBJECT))
            let locationList = getSecureData(Constant.LOCATIONLISTOBJECT)
            if (locationList) {
                let obj = locationList.filter(location => (location.locationId == this.state.objItem.locationId))
                console.log('location list ------ ', obj)
                if (obj.length > 0) {
                    // localStorage.setItem(Constant.LOCATIONOBJECT, JSON.stringify(obj[0]))
                    setSecureData(Constant.LOCATIONOBJECT, obj[0])
                }
            }

            // if (!JSON.parse(localStorage.getItem(Constant.ORDERTYPE))) {
            if (!getSecureData(Constant.ORDERTYPE)) {
                // localStorage.setItem(Constant.ORDERTYPE, JSON.stringify('Delivery'))
                setSecureData(Constant.ORDERTYPE, 'Delivery')
            }
            this.setState({ isAlertVisible: false })
        }

    }
    

    render() {
        return (
            <div className="white dishes">

                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}

                {/* Old header menu */}
                {/* <HeaderMenu /> */}
                {/* <HeaderWhiteMenu logoType={1}
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true} /> */}

                <HeaderWhiteMenuHalf isHideOrderType={true} logoType={1} headerLbl={'Order Details'} isCategory={true} changeLanguageEvent={this.languageChangeClickEvent}/>

                <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={this.state.isMultiButton} btnLeftClick={() => { this.setState({ isAlertVisible: false }) }} btnRightClick={() => { }} btnLeftTitle={this.state.btnLeft} btnRightTitle={this.state.btnRight} btnRightClick={() => { this.onRightClick() }} />

                <AlertDialog 
                    isAlertVisible={this.state.isAlertVisibleForReorder} 
                    msgTitle={this.state.alertMessage} 
                    isMultibutton={this.state.isMultiButton} 
                    btnLeftClick={() => { this.setState({ isAlertVisibleForReorder: false }) }}  
                    btnLeftTitle={this.state.btnLeft} 
                    btnRightTitle={this.state.btnRight} 
                    btnRightClick={() => { this.setState({isAlertVisibleForReorder:false, isReorderCartEmpty:true,},()=>{this.reorderConfirm()}) }} 
                />
                {/* <div className="inner-banner">
                    <img src="/images/dishes-banner.jpg" className="w-100" />
                </div> */}

                {this.state.isItemPopupVisible ? <ItemPopup itemData={this.state.objectAt} onClose={(popup) => { console.log(popup); this.setState({ isItemPopupVisible: false, isAlertVisible: popup.isHide, alertMessage: popup.isNewItem ? Strings.error_msg_item_added_react : Strings.error_msg_item_update_react, btnLeft: Strings.Ok_web, isMultiButton: true, btnRight: Strings.btn_view_order_react }) }} isVisible={this.state.isItemPopupVisible} /> : null}

                <CMS isShow={this.state.isShowCMS} href={this.state.cmsHref} onDisable={()=>this.setState({isShowCMS:false})}/>
                <div className="content_wrap">
                    <div class="container container_orderDetails" style={{maxWidth:'1600px'}}>
                        <div className="myorderdetail">
                        <   div className="myorderlistTitle">
                                <h1 class="borderTitleBottom"><a href="/" >Home </a>&gt;&gt;<a href="/MyOrders" > My Orders </a>&gt;&gt; Details</h1>
                            </div>
                            {/* <div className="row rowTitle-dd">
                                <h1>View Orders</h1>
                            </div> */}
                            <div class="all-order">
                            <div class="row" >
                                    <div class="col-md-9  col-lg-9" 
                                    // style={{paddingRight:'150px'}}
                                    >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="header-center">{Strings.lbl_order_react}</th>
                                                    <th class="header-center">{Strings.lbl_date_react}</th>
                                                    <th class="header-center">{Strings.lbl_location_react}</th>
                                                    <th class="header-center">{Strings.lbl_order_status_react}</th>
                                                    <th class="header-center">{Strings.lbl_order_type_react}</th>
                                                    {/* <th class="header-center">{Strings.lbl_price_react}</th> */}

                                                </tr>
                                            </thead>
                                            {
                                                this.setOrderDetailsTableView()
                                            }
                                        </table>
                                    </div>
                                    <div class="col-md-3  col-lg-3">
                                        <div className="backBtnOrderDetails" >
                                            {this.state.orderDetailArray.length > 0 ? 
                                                (
                                                    <div className="buttons-checkout cntr">

                                                        {
                                                            this.state.orderDetailArray.length == 1  && this.state.orderDetailArray[0].isLoyaltyOfferItem != undefined && this.state.orderDetailArray[0].isLoyaltyOfferItem == true
                                                            ?
                                                            null
                                                            : 
                                                            <a href="#" className="btn btn-primary btn-medium reorderBtn" onClick={() => this.onReorderClick()}>{Strings.str_reorder}</a>

                                                        }


                                                        {this.state.orderDetailArray[0].trckURL ? <a href={this.state.orderDetailArray[0].trckURL} target='_blank' className="btn btn-primary btn-medium">{Strings.str_trackorder}</a> : null}
                                                    </div>
                                                )
                                            : null}
                                        </div>
                                    </div>
                            </div>
                            </div>
     
                            <div className="row">
                            <div className="col-lg-9 col-md-9 orderDetailSideLine">
                                <div class="tab-pane myorderDetailMeitem">
                                    {/* <div class="tab-pane active" id="home-v">
                                        <div class="row"> */}
                                            {this.setOrderDetailsView()}
                                        {/* </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="orderDetailSubTotal" >

                                <div className="SubTotalod" ><h4>Sub Total</h4> <p>{this.setSubTotalView()}</p></div>
                                        {this.setDiscount()}
                                        {this.setOffersDiscount()}
                                        {
                                            this.setCompanyDiscountView()
                                        }
                                        {
                                            this.setDeliveryCharges()
                                        }

                                        {
                                            this.setPackagingCharges()
                                        }     

                                         {
                                            this.bookingDipositAmount()
                                        }      
                                      
                                        <div className="totalod" ><h4>Total</h4> <p>{this.setTotalAmount()}</p></div>

                                        <div className="ddNote" ><p> {this.state.orderStatusHistory.note != '' && this.state.orderStatusHistory.note != undefined ? "Note : "+this.state.orderStatusHistory.note : null}</p></div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                

                {/* <div className="content_wrap">
                    <div className="dishes-page">
                        <div className="row">
                            <div className="col-md-12 col-md-12">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="home-v">
                                        <div className="row">
                                            {
                                                this.setOrderDetailsView()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.renderAddress()
                        }
                        {
                            this.setSubTotalView()
                        }
                        {
                            this.setDiscount()
                        }
                        {
                            this.setOffersDiscount()
                        }
                        {
                            this.setCompanyDiscountView()
                        }
                        {
                            this.setDeliveryCharges()
                        }
                        {
                            this.setAvocadoDiscount()
                        }
                        {
                            this.setPackagingCharges()
                        }
                        {
                            this.setTotalAmount()
                        }
                        {this.state.orderDetailArray.length > 0 ? 
                            (
                                <div className="buttons-checkout cntr">
                                    <a href="#" className="btn btn-primary btn-medium" onClick={() => this.onReorderClick()}>{Strings.str_reorder}</a>
                                    {this.state.orderDetailArray[0].trckURL ? <a href={this.state.orderDetailArray[0].trckURL} target='_blank' className="btn btn-primary btn-medium">{Strings.str_trackorder}</a> : null}
                                </div>
                            )
                        : null}
                    </div>
                </div> */}
                <FooterMenu />
            </div>
        )
    }

    renderAddress =()=> {
        if (this.state.historyObject.deliveryaddress)
            return (
                <div>
                    <p className="dlvTo">Deliver to</p>
                    <p className='totalAmount'>{this.state.historyObject.deliveryaddress}</p>
                </div>
            )
    }

    //Setting SubTotal Amount
    setSubTotalView = () => {
        let mSubTotal = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            for (let i = 0; i < this.state.orderDetailArray.length; i++) {
                if (mSubTotal == 0) {
                    mSubTotal = this.state.orderDetailArray[i].totalprice;
                }
                else {
                    mSubTotal = mSubTotal + this.state.orderDetailArray[i].totalprice;
                }
            }

            return getPriceFormat(mSubTotal.toFixed(2))
                // <div>
                //     <h3 className='totalAmount'>{Strings.sub_total_order_amount_web + " : " + getPriceFormat(mSubTotal.toFixed(2))} </h3>
                // </div>
            
        }
    }

    //Setting First Order Discount
    setDiscount = () => {
        let discount = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            if (this.state.orderDetailArray[0].discount > 0) {
                discount = this.state.orderDetailArray[0].discount;
                return (<div className="discountDod" ><h4>Discount</h4>  <p>{ "-" + getPriceFormat(discount)}</p></div>)
            }
            else {
                return;
            }
        }
    }

     //Setting offers Discount
     setOffersDiscount = ()  =>{
        let discount = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            if (this.state.orderDetailArray[0].offerCodeDiscount > 0) {
                discount = this.state.orderDetailArray[0].offerCodeDiscount;
                console.log("Discount",discount);
                return (<div className="discountDod" ><h4>Offer Discount</h4> <p>{"-" + getPriceFormat(discount)}</p></div>)
            }
            else {
                return;
            }
        }
    }

          //set bookingDipositAmount 
          bookingDipositAmount () {
            let bookingDipositAmount = 0;
            if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
                if (this.state.orderDetailArray[0].bookingDipositAmount > 0) {
                    bookingDipositAmount = this.state.orderDetailArray[0].bookingDipositAmount;
    
                    // return (<div className="chargesTotalod" >
                    // <h4>{Strings.packaging_charge_web}</h4> 
                    // <p>{getPriceFormat(bookingDipositAmount.toFixed(2)) }</p>
                    // </div>)
    
                    return (<div className="discountDod" ><h4>Booking Deposit</h4> <p>{"-" + getPriceFormat(bookingDipositAmount)}</p></div>)
                }
                else {
                    return;
                }
            }
        }

    //Setting company discount
    setCompanyDiscountView() {
        let totalAmount = 0;
        let discount = 0;
        let companyDiscount = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            discount = this.state.orderDetailArray[0].discount;
            companyDiscount = this.state.orderDetailArray[0].payAmountByCompany;
            for (let i = 0; i < this.state.orderDetailArray.length; i++) {
                if (totalAmount == 0) {
                    totalAmount = this.state.orderDetailArray[i].totalprice;
                }
                else {
                    totalAmount = totalAmount + this.state.orderDetailArray[i].totalprice;
                }
            }
            totalAmount = totalAmount - discount;
            if (totalAmount >= companyDiscount) {
                if (companyDiscount > 0) {
                    return (<div className="chargesTotalod" ><h4>{Strings.str_company_discount}</h4> <p>{getPriceFormat(companyDiscount.toFixed(2))}</p></div>)
                }
                else {
                    return;
                }
            }
            else {

                return (<div className="chargesTotalod" ><h4>{Strings.str_company_discount}</h4> <p>{getPriceFormat(totalAmount.toFixed(2))}</p></div>)
            }
        }
    }

    setAvocadoDiscount() {
        if(this.state.orderDetailArray.length > 0) {
            let disc = this.state.orderDetailArray[0].avacadoRedeemDiscount
            if(disc > 0) {
                return (
                    <div>
                            <h3 className='totalAmount'>{Strings.str_redeemed_avocados + " : -" + getPriceFormat(disc.toFixed(2))} </h3>
                        </div>
                )
            }
        }

    }

     //Setting Delivery Charges
     setDeliveryCharges() {
        let deliveryCharges = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            if (this.state.orderDetailArray[0].deliveryCharges > 0) {
                deliveryCharges = this.state.orderDetailArray[0].deliveryCharges;

                return (<div className="chargesTotalod" >
                <h4>{Strings.lbl_delivery_charge_react}</h4> 
                <p>{getPriceFormat(deliveryCharges.toFixed(2)) }</p>
                </div>)
                
            } else {
                return;
            }
        }

    }

    //Setting Packaging Charges
    setPackagingCharges() {
        let packgingCharges = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            if (this.state.orderDetailArray[0].packagingCharge > 0) {
                packgingCharges = this.state.orderDetailArray[0].packagingCharge;

                return (<div className="chargesTotalod" >
                <h4>{Strings.packaging_charge_web}</h4> 
                <p>{getPriceFormat(packgingCharges.toFixed(2)) }</p>
                </div>)
            }
            else {
                return;
            }
        }
    }

    //Setting total amount
    setTotalAmount() {
        let totalAmount = 0;
        let deliveryCharges = 0;
        let packgingCharges = 0;
        let discount = 0;
        let offerDiscount = 0;
        let companyDiscount = 0;
        let bookingDipositAmount = 0
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            deliveryCharges = this.state.orderDetailArray[0].deliveryCharges;
            packgingCharges = this.state.orderDetailArray[0].packagingCharge;
            discount = this.state.orderDetailArray[0].discount;
            offerDiscount = this.state.orderDetailArray[0].offerCodeDiscount;
            companyDiscount = this.state.orderDetailArray[0].payAmountByCompany;
            bookingDipositAmount = this.state.orderDetailArray[0].bookingDipositAmount
            let avocadoDiscount =  this.state.orderDetailArray[0].avacadoRedeemDiscount;
            for (let i = 0; i < this.state.orderDetailArray.length; i++) {
                if (totalAmount == 0) {
                    totalAmount = this.state.orderDetailArray[i].totalprice;
                }
                else {
                    totalAmount = totalAmount + this.state.orderDetailArray[i].totalprice;
                }
            }

            totalAmount = totalAmount - discount - offerDiscount - bookingDipositAmount;
            if (totalAmount >= companyDiscount) {
                totalAmount = totalAmount - companyDiscount - avocadoDiscount + deliveryCharges + packgingCharges;
                return getPriceFormat(totalAmount.toFixed(2));
                // return (
                //     <div>
                //         <h3 className='totalAmount'>{Strings.total_order_amount_web + " : " + getPriceFormat(totalAmount.toFixed(2))} </h3>
                //     </div>
                // )
            }
            else {
                totalAmount = 0;
                totalAmount = totalAmount - avocadoDiscount + deliveryCharges + packgingCharges;
                return getPriceFormat(totalAmount.toFixed(2));
                // return (
                //     <div>
                //         <h3 className='totalAmount'>{Strings.total_order_amount_web + " : " + getPriceFormat(totalAmount.toFixed(2))} </h3>
                //     </div>
                // )
            }
        }
    }

    renderMenuStatus(status) {
        let obj = []
        if(status.length > 0){
            status.map((data,index) => (
                obj.push(
                  <li key={index}>
                    <img src={bindhttps(data.iconurl)} />
                    {/* <a href="#">
                     
                    </a> */}
                  </li>
                )
              ))
        }
        
        return obj
      }

      callGetCategoryListApi() {
        this.props.getCategoriesAPICall(this.state.languageType).then(() => {
          console.log('Category response ---- ',this.props.data)
            if (this.props.data.status == 1) {
                if (this.props.data.result != null) {
                    setTimeout(() => {
                        // if (this.props.data.result.length > 0) {
                          console.log(this.props.data.result.MenuCategoryList)
                            this.setState({ arrayMainCategories: this.props.data.result.MenuCategoryList})
                            // localStorage.setItem(Constants.CATEGORIESDATA,JSON.stringify(this.props.data.result))
                            // setSecureData(Constants.CATEGORIESDATA,this.props.data.result.MenuCategoryList)
                            // window.location.reload(false)
                            // this.getCategoryItem()
                        // }
                    }, 100)
                }
            } else {
                alert(this.props.data.message)
                console.log('Category status 2 ---- ',this.props.data)
            }
          })
      }
    
      onAddClick(i, orderitemId,item) {
        console.log("orderitemId",item,orderitemId)
        // let myCatData = this.state.arrayMainCategories.filter(data => (data.catId == item.catId))

        // console.log("myCatData", myCatData, this.state.arrayMainCategories)
        let uktime = moment(this.state.ukdatetime, "YYYY-MM-dd HH:mm:ss").format("HH:mm:ss")
        let ukdate = moment(this.state.ukdatetime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")

        this.props.checkItemTimeAPI(uktime,ukdate,item.catId,this.state.languageType).then(() => {
        
        if (this.props.data.status == 1) {
            //call reorder
                this.props.callReOrderInfoAPI(mOrderId, this.state.languageType).then(() => {
                    if (this.props.data.status == 1) {
                        var result = this.props.data.result;

                        if (result.orderedItems.length > 0) {
                            //check cart item location and reorder item location 
                            if (result.orderedItems[0].locationId == getSecureData(Constant.LOCATIONOBJECT).locationId) {
                                result.orderedItems.map(items => {
                                    console.log("checkReorderFoodnAdd",items,orderitemId)
                                    if(orderitemId ===  items.orderitemId){
                                        // let cartData = JSON.parse(localStorage.getItem(Constant.USERCART))
                                    let cartData = getSecureData(Constant.USERCART)

                                    items.PriceList[0].isSelect = true
                                    items['menuItemName'] = items['itemname']
                                    items['menuitemId'] = items['orderitemId']
                                    items['imgurl'] = items['imageurl']
                                    items['totalQty'] = items['qty']
                                    items['totalPrice'] = items['totalprice']
                                    items['isReOrderedItem'] = true

                                    if (cartData != null) {
                                        if (cartData.length > 0) {
                                            // cartData.push(items)
                                            // setSecureData(Constant.USERCART, cartData)

                                            console.log('Cart Data --- ', JSON.stringify(cartData))
                                            console.log('itemObject --- ', JSON.stringify(items))

                                            var shouldUpdate = false
                                            var updateAt = 0

                                            //check menu item id available and matched 
                                            cartData.map((cartObject, index) => {
                                                if (cartObject.menuitemId == items.menuitemId) {
                                                    let cartPriceType = cartObject.PriceList.filter(data => (data.isSelect == true))
                                                    let itemPriceType = items.PriceList.filter(data => (data.isSelect == true))
                                                    //compare selected price type
                                                    if (cartPriceType[0].typeid == itemPriceType[0].typeid) {
                                                    //get selected options list type 
                                                    let cartOptions = cartPriceType[0].OptionList.filter(opt => (opt.isSelect == true))
                                                    let itemOptions = itemPriceType[0].OptionList.filter(opt => (opt.isSelect == true))

                                                    var arrayCartOpt = []
                                                    cartOptions.map(cartopt => (
                                                        arrayCartOpt.push(cartopt.typeid)
                                                    ))
                                                    var arrayItemOpt = []
                                                    itemOptions.map(itemopt => (
                                                        arrayItemOpt.push(itemopt.typeid)
                                                    ))
                                                    console.log('arrayCartOpt ----- ', arrayCartOpt)
                                                    console.log('arrayItemOpt ----- ', arrayItemOpt)
                                                    console.log('index ----- ', index)
                                                    //compare selected options list
                                                    if (JSON.stringify(arrayCartOpt) == JSON.stringify(arrayItemOpt)) {
                                                        shouldUpdate = true
                                                        updateAt = index
                                                        console.log('Both object are same......')
                                                    } else {
                                                        console.log('Both object are not same......')
                                                    }
                                                    }
                                                }
                                            })

                                            if (shouldUpdate) {

                                                let oldQty = cartData[updateAt].totalQty
                                                let oldPrice = cartData[updateAt].totalPrice

                                                console.log('item data should update at --- ', updateAt,)
                                                //add new qty and price in cart item
                                                cartData[updateAt].totalQty += items.totalQty
                                                cartData[updateAt].totalPrice += items.totalPrice

                                                setSecureData(Constant.USERCART, cartData)
                                            } else {

                                                console.log('add item in cart')
                                                cartData.push(items)
                                                setSecureData(Constant.USERCART, cartData)
                                            }

                                        } else {
                                            setSecureData(Constant.USERCART, [items])
                                        }
                                    } else {
                                        setSecureData(Constant.USERCART, [items])
                                    }
                                    }
                                    
                                    

                                })
                                this.setState({ shouldMove: true, isAlertVisible: true, alertMessage: Strings.error_msg_item_added_react, isMultiButton: true, btnLeft: Strings.Ok_web, btnRight: Strings.btn_view_order_react })
                                if (!getSecureData(Constant.ORDERTYPE)) {
                                    // localStorage.setItem(Constant.ORDERTYPE, JSON.stringify('Delivery'))
                                    setSecureData(Constant.ORDERTYPE, 'Delivery')
                                }
                            } else {

                                result.orderedItems.map(items => {

                                    if(orderitemId ===  items.orderitemId){
                                        items.PriceList[0].isSelect = true
                                        items['menuItemName'] = items['itemname']
                                        items['menuitemId'] = items['orderitemId']
                                        items['imgurl'] = items['imageurl']
                                        items['totalQty'] = items['qty']
                                        items['totalPrice'] = items['totalprice']
                                        items['isReOrderedItem'] = true

                                        this.setState({ objItem: items })
                                    }
                                    
                                })

                                this.setState({ isAlertVisible: true, alertMessage: Strings.cart_from_different_location, isMultiButton: true, btnLeft: Strings.str_no, btnRight: Strings.yes })
                            }
                        }

                    } else {
                        alert(this.props.data.message)
                    }
                })
        } else {
            this.setState({isAlertVisible: true, alertMessage:this.props.data.message, isMultiButton: false, btnLeft:Strings.Ok_web})
        }
        })
        // return

        
      }

    //This method use bind order details 
    //like menuname,price,qty
    setOrderDetailsView1() {
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) 
        {
            console.log("this.state.orderDetailArray",this.state.orderDetailArray)
            const itemDiv = this.state.orderDetailArray.map((item, index) => (
            <div className="col-lg-4 col-md-12 mb-5" key={index}>
                <div className="prd-s">
                <div className="prd-img">
                    {/* <img src="../images/prdmainimg.jpg"  onClick={() => { this.onAddClick(index, item.menuitemId) }}/> */}
                    {/* {item.imgurl ? <img src={bindhttps(item.imgurl)}  onClick={() => { this.onAddClick(index, item.menuitemId) }}/> : null} */}
                    <img src={item.imageurl ? bindhttps(item.imageurl) : "../images/noimage.png"}   
                    // onClick={() => { this.onAddClick(index, item.orderitemId, item) }}
                    />
                </div>
                <div className="prd-cont">
                    <ul className="vegi">
                    {this.renderMenuStatus(item.MenuStausList ? item.MenuStausList : [])}
                    </ul>
        
                    <h3  
                    // onClick={() => { this.onAddClick(index, item.orderitemId, item) }} 
                    className="prdTitle">{item.itemname}</h3>
                    <p className="prdDesc">{item.description}</p>
                    <div className="price-btns-details">
                    <p className="lbl">{getCurrencySign()}</p>
                    <p className="prdPriceAmt">{item.PriceList[0].price.toFixed(2)}</p>
                    <a
                        href=""
                        // className="btn btn-primary btn-medium btn-price"
                        type="button"
                        // className="btn btn-primary btn-price"
                        className="greenBtn smallBtnLbl qty"
                        data-toggle="modal"
                        data-target="#myModal"
                        key={index}
                        onClick={() => { this.onAddClick(index, item.orderitemId, item) }}
                    >
                        {Strings.lbl_edit_add_react}
                    </a>
                    </div>
                </div>
                </div>
            </div>
            ));
            return itemDiv
        }
        else {
            return (
                <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <h2 style={{ textAlign: 'center' }}>{this.state.errorMessage}</h2>
                </div>
            )
        }
      
    }

    renderOptions = (menuData) => {
        var arrayTitle = []


        let subOption;
        let objPriceList = menuData.PriceList[0].OptionList.map((data) => {
            if(!arrayTitle.includes(data.labelText)){
                arrayTitle.push(data.labelText)
            }
            
        })


        var arrData = []
        arrayTitle.map((data, i) => {
            var str = []
            var subOptionFlt = []
            
            let obj = menuData.PriceList[0].OptionList.map((flt) =>{
                if(flt.labelText == data){
            
                    str.push(`${flt.type} (£${flt.price})`)
                }
            })

            arrData.push(
                str.length > 0 
                ?

                <div key={i} className='optSubOption'>
                    <h5 className='optTitle'>{data}</h5>
                    <p className='optValue'>{str.join(', ')}</p>
                </div>
                : null
            )
        })
        
        return arrData
    }

    renderPriceList = (menuItem) => {
        let objPriceList = menuItem.PriceList
        return objPriceList[0].type.replace("Price", "Regular")
    }

    setOrderDetailsView() {
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) 
        {
            console.log("this.state.orderDetailArray",this.state.orderDetailArray)
            const itemDiv = this.state.orderDetailArray.map((item, index) => (
            
                    <li>
                    <div className="row" >
                        <div className="col-md-6 col-sm-6 col-xs-6" >

                        {
                            item.isLoyaltyOfferItem == undefined || item.isLoyaltyOfferItem == false
                            ?
                                <p>{item.itemname} ({this.renderPriceList(item)})  {this.renderOptions(item)}  </p>

                            :
                                <p>{item.itemname} (Loyalty)</p>
                        }  
                            
                        </div>

                       
                        <div className="col-md-6 col-sm-6  col-xs-6 text-right" >
                        {
                            this.state.customerId > 0
                            ?
                            <div className="favPageLi" >
                                { 
                                
                                item.isFavoriteMenu
                                ?
                                <div onClick={() => {this.addRemoveFavItem(item,'DeleteFavorite')}} className="heartIconDiv "><i class="fa fa-heart" style={{color:'#c1e1d5'}} aria-hidden="true"></i></div>
                                :
                                <div onClick={() => {this.addRemoveFavItem(item,'addFavorite')}} className="heartIconDiv "><i class="fa fa-heart-o" style={{color:'#c1e1d5'}} aria-hidden="true"></i></div>
                                
                                }
                            </div>
                            :
                            null  
                        }

                            {
                            item.isLoyaltyOfferItem == undefined || item.isLoyaltyOfferItem == false
                            ?
                                <>
                                    <spna className="priceCurrencyBox priceCurrencyOrderDetailBox" >
                                        <p className="itemQty" >QTY : {item.qty}</p>
                                        <p className="lbl">{getCurrencySign()}</p>
                                        <p className="prdPriceAmt">{item.totalprice.toFixed(2)}</p>
                                    </spna>
                                    <a
                                    href=""
                                    class="btn btn-light cartAddBtn" 
                                    type="button" 
                                    data-toggle="modal"
                                    data-target="#myModal"
                                    key={index}
                                    onClick={() => { this.onAddClick(index, item.orderitemId, item) }}
                                    > 
                                        {/* <i class="fa fa-plus"></i>  */}
                                    </a>
                                </>

                            :
                                <spna className="priceCurrencyBox priceCurrencyOrderDetailBox" >
                                    <p className="itemQty" >QTY : {item.qty}</p>
                                    <p className="prdPriceAmt">
                                        {item.loyaltyPoints*item.qty} Points
                                    </p>
                                </spna>
                            }
                            
            
                            
                        </div>
                    </div>
                    </li>
                ));
                return <ul className="subCatFoodList" >{itemDiv}</ul>;
        }
        else {
            return (
                <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <h2 style={{ textAlign: 'center' }}>{this.state.errorMessage}</h2>
                </div>
            )
        }
    }

    setOrderDetailsTableView() {
        if ('orderNo' in this.state.orderStatusHistory) {
            
                console.log("this.state.orderStatusHistory",this.state.orderStatusHistory)
                return (

                            <tbody>
                                <tr>
                                    <td className="row-center" data-column="ORDER #">{this.state.orderStatusHistory.orderNo}</td>
                                    <td className="row-center" data-column="DATE">{this.state.orderStatusHistory.contactDatetime}</td>
                                    {/* <td class="row-left" data-column="LOCATION">{item.locationName}</td> */}
                                    <td className="row-center" data-column="LOCATION">{this.state.orderStatusHistory.locationName}</td>
                                    <td className="row-center" data-column="ORDER STATUS">{this.state.orderStatusHistory.orderstatus}</td>
                                    <td className="row-center" data-column="ORDER TYPE">{this.state.orderStatusHistory.ordertypeValue}</td>
                                    {/* <td class="row-right" data-column="PRICE">{getPriceFormat(item.totalamount)}</td> */}
                                    {/* <td class="row-center" data-column="PRICE">{item.PriceList[0].price}</td> */}
                                </tr>
                            </tbody>

                )
            
        }
        else {
            return (
                <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <h2 style={{ textAlign: 'center' }}>{this.state.errorMessage}</h2>
                </div>
            )
        }
    }

    //This method use bind option parameter list with sorting
    setOptionListView = (OptionList) => {
        if (OptionList != null && OptionList.length > 0) {
            var subArray = [];
            for (let j = 0; j < OptionList.length; j++) {
                if (j == 0) {
                    subArray.push(OptionList[j].labelText)
                } else if (subArray.includes(OptionList[j].labelText) == false) {
                    subArray.push(OptionList[j].labelText)
                }
            }

            var arrReturn = []
            for (let k = 0; k < subArray.length; k++) {
                let myOptionList = OptionList.filter(data => (subArray[k] == data.labelText))
                var arrOpt = []
                myOptionList.map(opt => (
                    arrOpt.push(opt.type)
                ))
                arrReturn.push(
                    <div className="prod-cont" key={k}>
                        <div className="prd-s">
                            <div className="prd-img"></div>
                            <div className="prd-cont">
                                <h6>{subArray[k]}</h6>
                                <p>{arrOpt.join(',')}</p>
                            </div>
                        </div>
                    </div>
                )
            }
            return arrReturn
        }
        else {
            return;
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}
const mapDispatchToProps = dispatch => ({
    orderDetailsApi: (mOrderId, languageType) => dispatch(HGActions.GetOrderDetailsApi(mOrderId, languageType)),
    callReOrderInfoAPI: (OrderId, languageType) => dispatch(HGActions.initGetReOrderInfoAPI(OrderId, languageType)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    checkItemTimeAPI: (contactTime,orderDate,catId,languageType) => dispatch(HGActions.initCheckMenuItemTimeAPI(contactTime,orderDate,catId,languageType)),
    getCategoriesAPICall: (languageType) => dispatch(HGActions.initGetAllCategoriesCall(languageType)),
    getRestoInfo: (languageType) => dispatch(HGActions.initGetRestoInfoAPI(languageType)),

})
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);


