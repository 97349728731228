import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import Constants from '../constants/baseConstants';
import HeaderSignin from '../Components/HeaderSignin';
import Modal from 'react-bootstrap/Modal'
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import queryString from 'query-string';
import { ThemeProvider } from 'react-bootstrap';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

class LoyaltyRewards extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId : 0,
            activeCardID : -1,
            isLoading:false,
            errors: {
                errBookingDate: '',
                errBookingTime: '',  
                errBookingSlotTime : '',              
            },
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1, 
            languageType: 1, //default language type
            minBookingDate : '', 
            minBookingTime : '',
            totalPerson : 1, 
            bookingDate: '',
            bookingTime: '',
            componentLoadStep : 'step2',
            bookingTimeSloat  :[],
            bckBtnTable : '',
            payNowResponse : {},
            fromTime : '',
            toTime : '',
            noOfPeople : 1,
            tableNoOfPeople : 1,
            restaurantId : '',
            locationId : 1,
            languageId: 1,
            date : '',
            tableBookingPerPersonCharge : 5,
            virtualQueData : [],
            getMemberofferData : [],
            LoyaltyPoint :[],
            offerLevelPoint : getSecureData('offerLevelPoint') != undefined ? getSecureData('offerLevelPoint') : 0,
            level : 0,
            levelName : '',
            MemberOfferPointAmount : 0,
            isOrderTypeVisible: false,
            arrayOrderTypes: [],
            locationArray: [],

        }
    }
    componentDidMount(){
        
        let params = queryString.parse(this.props.location.search)
        if (params.customerId) {
            console.log('query string -------------> ', params)
        }
        

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        var today = new Date().toISOString().split('T')[0];
        this.setState({ minBookingDate : today})

        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.setState({ minBookingTime : today})

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                if (userObject != null) {
                     this.setState({customerId : userObject.customerId});
                    console.log('login user :' + JSON.stringify(userObject));
                }
            }
        }

        this.checkUserLogin()
        
    }

    checkUserLogin = () => {
        // this.setState({isContentLoading:true})
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
            if (userObject !== null) {
                this.getCustomerDetailById()
                this.fetchLocationList()
            }
            else {
                this.props.history.push({ pathname: '/' })
                return
            }

        }
        else {
            this.props.history.push({ pathname: '/' })
        }
    }

    getCustomerDetailById = () => {

        let customerId = getSecureData(Constant.USEROBJECT).customerId
        let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
        this.props.getCustomerDetails(customerId, locationId)
        .then(() => {
            console.log('get Customer Details', this.props.data)
            if (this.props.data.status == 1) {
                var userObject = this.props.data.result
                this.setState({
                    offerLevelPoint : this.props.data.result.customerDetails.avocadoPoint,
                    level : this.props.data.result.customerDetails.level,
                    levelName : this.props.data.result.customerDetails.levelName,
                    MemberOfferPointAmount : this.props.data.result.customerDetails.MemberOfferPointAmount
                    
                })

                setSecureData('offerLevelPoint',this.props.data.result.customerDetails.avocadoPoint)
                setTimeout(() => {
                    this.checkPoint()
                }, 2000);
            }
        })
        .catch((error) => {

        })

        // this.getMemberofferList()
        this.getLoyaltyPointList()
        
    }

    getLoyaltyPointList = () => {
        let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
        let userObject = getSecureData(Constant.USEROBJECT);
        this.setState({locationId : locationId})
        var data = {
            locationId: locationId,
            customerId: userObject.customerId,
            languageId: this.state.languageType,
        }
        console.log("MemberOfferList data",data)
        if(userObject.customerId != 0  && this.state.languageType != '' && locationId != ''){
            this.props.getLoyaltyPointList(data).then((res) => {

                console.log("MemberOfferList :",res,this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({
                        LoyaltyPoint : this.props.data.result.LoyaltyPoint.length > 0 ? this.props.data.result.LoyaltyPoint : [],
                    })
                    
                    
    
                }
                else{
                    // alert('Something went wrong')
                }
    
                
    
            })
            .catch((err)=>{
                console.log('error ',err)
            })
        }
    }

    checkPoint = () => {
        var rewardpoints = getSecureData('offerLevelPoint')
        console.log("offerLevelPoint", rewardpoints, this.state.offerLevelPoint)
        // var rewardpoints= 0
        if (localStorage.hasOwnProperty(Constants.USERCART)) {
                    let userObject = getSecureData(Constants.USERCART);
            
                    if (userObject !== null) {
                        let cartData = getSecureData(Constants.USERCART)
                
                        console.log('Cart Data --- ', JSON.stringify(cartData))
                        // console.log('itemObject --- ', JSON.stringify(itemObject))
                
                        var shouldUpdate = false
                        
                        var updateAt = 0
                        if (cartData.length > 0) {

                            console.log('add item in cart')

                            cartData.map((cartObject, index) => {
                                console.log("rewardpoints", cartObject.rewardpoints,cartObject.totalQty)
                                if (cartObject.isLoyaltyOfferItem != undefined && cartObject.isLoyaltyOfferItem == true ) {
                                    shouldUpdate = true
                                    updateAt = index

                                    var tempQuantity = cartObject.totalQty
                                    var tempRewardpoints = cartObject.rewardpoints
                                    // var singlePoint = tempRewardpoints/tempQuantity

                                    var tPoint = Number(tempQuantity) * Number(tempRewardpoints)

                                    // console.log('add rewardpoints mul',rewardpoints, tPoint, cartObject.rewardpoints, cartObject.totalQty)

                                    rewardpoints = rewardpoints - tPoint

                                    console.log('add rewardpoints',rewardpoints)
                                }
                            
                            })

                            // if(shouldUpdate){
                            //     //add new qty and price in cart item
                            //     rewardpoints = rewardpoints - cartData[updateAt].rewardpoints 
                            // }

                            

                            this.setState({offerLevelPoint : rewardpoints})
                            setSecureData('offerLevelPoint', rewardpoints)
                            console.log("addToCart", rewardpoints)  
                        }
                }
                
            }
    }
    getMemberofferList = () => {
        let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
        let userObject = getSecureData(Constant.USEROBJECT);
        this.setState({locationId : locationId})
        var data = {
            locationId: locationId,
            customerId: userObject.customerId,
            languageId: this.state.languageType,
        }
        console.log("MemberOfferList data",data)
        if(userObject.customerId != 0  && this.state.languageType != '' && locationId != ''){
            this.props.getMemberofferList(data).then(() => {

                console.log("MemberOfferList :",this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({
                        getMemberofferData : this.props.data.result.MemberOfferList.length ? this.props.data.result.MemberOfferList : [],
                    })
    
    
                }
                else{
                    // alert('Something went wrong')
                }
    
                
    
            })
            .catch((err)=>{
                console.log('error ',err)
            })
        }
    }

    GetLocationListApi = () => {
        this.props.AppMessageApi(this.state.languageType).then(() => {

            if (this.props.data.status == 1) {
                this.setState({tableBookingPerPersonCharge : this.props.data.result.tableBookingPerPersonCharge})
            }

        })
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my account page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    fetchLocationList() {
        this.props.locationListApi(this.state.languageType).then(() => {
            if (this.props.data.status == 1) {
                if (this.props.data.result != null) {
                    var locationList = this.props.data.result;
                    if (locationList != null && locationList.length > 0) {
      
                      if (getSecureData(Constants.LOCATIONOBJECT)) {
      
      
                        let objLocation = locationList.filter(obj => {
                          return (obj.locationId == getSecureData(Constants.LOCATIONOBJECT).locationId)
                        })
      
                      
      
                        this.setState({locationObj: objLocation[0]}, ()=>{
                          setSecureData(Constants.LOCATIONOBJECT,objLocation[0])
                        //   this.setLocationData()
                        })
                      } else {
      
                        if(locationList.length == 1){
                          this.setState({locationObj: locationList[0]}, ()=>{
                            setSecureData(Constants.LOCATIONOBJECT,locationList[0])
                            this.setLocationData()
                          })
                        }
                        else{
                          setSecureData(Constants.LOCATIONLISTOBJECT,locationList)
                          this.setState({ locationArray: locationList, isVisible: true })
                        }
                        
                        
                      }
                    }
                }
            } else {
                    alert(this.props.data.message)
            }
        })
      }

      setLocationData() {
        setTimeout(() => {
            if (this.state.locationObj == null) {
            // if (item == null) {
                alert(Strings.lbl_select_location_react)
            } else {
                
                // console.log('prev location =------ ',getSecureData(Constants.ORDERTYPE))
                
                let locationObj =this.state.locationObj
                var arry = []
                if (locationObj.isDelivery) {
                    arry.push(Strings.Delivery_web)
                }
                if (locationObj.isTakeAway) {
                    arry.push(Strings.lbl_takeaway_react)
                }
                if (locationObj.isOrderExtras) {
                  arry.push(Strings.str_sitDown)
                }
                // console.log('current type =------ ',arry)
                if (arry.includes(getSecureData(Constants.ORDERTYPE))) {
                  setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj)
                  Constants.locationId = this.state.locationObj.locationId
                  this.setState({ isVisible: false },()=> {
                    setTimeout(() => {
                    //   this.callGetCategoryListApi()
                    }, 500);
                  })
                } else {
                  setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj)
                  Constants.locationId = this.state.locationObj.locationId
                  
                  var arry = []
                  if (locationObj.isDelivery) {
                      arry.push({ title: Strings.Delivery_web, isSelect: false })
                  }
                  if (locationObj.isTakeAway) {
                      arry.push({ title: Strings.lbl_takeaway_react, isSelect: false })
                  }
                  if (locationObj.isOrderExtras) {
                    arry.push({ title: Strings.str_collect_drink, isSelect: false })
                  } 
                  this.setState({ isVisible: false, isOrderTypeVisible: true, arrayOrderTypes: arry})
                }
                
      
                return
      
                // localStorage.setItem(Constants.LOCATIONOBJECT, JSON.stringify(this.state.locationObj))
                setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj)
                // setSecureData(Constants.LOCATIONOBJECT, item)
                Constants.locationId = this.state.locationObj.locationId
                // Constants.locationId = item.locationId
      
                let locationObject =this.state.locationObj
                var arry = []
                if (locationObject.isDelivery) {
                    arry.push({ title: Strings.Delivery_web, isSelect: false })
                }
                if (locationObject.isTakeAway) {
                    arry.push({ title: Strings.lbl_takeaway_react, isSelect: false })
                }
                /* comment for order type */
                if (locationObject.isOrderExtras) {
                  arry.push({ title: Strings.str_collect_drink, isSelect: false })
                } 
                {/* comment for order type */}
                // this.setState({ isVisible: false, arrayOrderTypes: arry},()=>{
                //   setTimeout(() => {
                //     this.callGetCategoryListApi()
                //   }, 500);
                // })
                this.setState({ isVisible: false, isOrderTypeVisible: true, arrayOrderTypes: arry})
            }
          }, 200);
        }


    CheckCartEmptyOrNot = (offerDetail) => {

        console.log("getSecureData(Constants.USERCART)",getSecureData(Constant.USERCART), offerDetail)

        var cartLength = getSecureData(Constant.USERCART)

        if(cartLength) {
            setSecureData(Constant.MEMBEROFFERS,offerDetail)
            this.props.history.push({
            pathname: '/CheckoutDelivery',
            state: { offerDetail: offerDetail }
        })
        }
        else{
            this.setState({isAlertVisible: true, alertMessage: 'Your Cart Is Empty.', isMultiButton: false, btnLeft: Strings.Ok_web})
        }
        // this.props.history.push({
        //     pathname: '/template',
        //     search: '?query=abc',
        //     state: { detail: response.data }
        // })
    }

    addToCart = (data) => {

        

        if(this.state.offerLevelPoint >= data.loyaltyPoint){

            var itemObject = {
                "isLoyaltyOfferItem": true,
                "menuitemId": data.id,
                "catId": 0,
                "menuItemName": data.title,
                "itemname": data.title,
                "description": data.description,
                "imgurl": data.image,
                "thumbimgurl": data.image,
                "totallikes": "0",
                "rewardpoints": data.loyaltyPoint,
                "rank": 0,
                "healthBenefitsDesc": "",
                "instock": false,
                "associateMenuitemId": 0,
                "buttontext": "",
                "buttonlabel": "Make a Meal",
                "parentCategoryName": null,
                "price": 0,
                "MenuStausList": [],
                "PriceList": [
                    {
                        "menuitemid": data.id,
                        "typeid": 0,
                        "locationId": 0,
                        "type": "",
                        "price": 0,
                        "istoppings": 0,
                        "isMultiselect": false,
                        "isSelectAll": false,
                        "labelText": "",
                        "parameterId": 1,
                        "selectallmsg": "",
                        "customeOfferDiscount": 0,
                        "showIn": 0,
                        "sortorder": 0,
                        "flag": "",
                        "isCompulsory": false,
                        "showinblock": false,
                        "thumbimgurl": "",
                        "maxselection": 0,
                        "subparsortorder": 0,
                        "sizepid": 0,
                        "isselect": false,
                        "OptionList": [],
                        "isSelect": true
                    }
                ],
                "isFavoriteMenu": false,
                "hasSuggestedItem": false,
                "contentPage": null,
                "isTableDescription": false,
                "shortDescription": null,
                "totalQty": 1,
                "totalPrice": 0
            }
            var rewardpoints = this.state.offerLevelPoint
            console.log("localStorage.hasOwnProperty(Constants.USERCART)",localStorage.hasOwnProperty(Constants.USERCART))
                if (localStorage.hasOwnProperty(Constants.USERCART)) {
                    // let userObject = localStorage.getItem(Constants.USERCART);
                    let userObject = getSecureData(Constants.USERCART);
            
                    if (userObject !== null) {
                    // userObject = JSON.parse(userObject);
                    // let cartData = JSON.parse(localStorage.getItem(Constants.USERCART))
                    let cartData = getSecureData(Constants.USERCART)
            
                    console.log('Cart Data --- ', JSON.stringify(cartData))
                    console.log('itemObject --- ', JSON.stringify(itemObject))
            
                    var shouldUpdate = false
                    
                    var updateAt = 0
                    if (cartData.length > 0) {

                        console.log('add item in cart')

                        cartData.map((cartObject, index) => {
                            if (cartObject.menuitemId == itemObject.menuitemId && itemObject.isLoyaltyOfferItem != undefined && itemObject.isLoyaltyOfferItem == true ) {
                                // cartObject.totalQty += itemObject.totalQty
                                shouldUpdate = true
                                updateAt = index
                            }
                           
                        })

                        if(shouldUpdate){
                            console.log('item data should update at --- ', updateAt,)
                            //add new qty and price in cart item
                             
                            cartData[updateAt].totalQty += itemObject.totalQty

                            console.log("cartQty",cartData[updateAt].totalQty, rewardpoints)

                            rewardpoints = rewardpoints - itemObject.rewardpoints
                            // cartData[updateAt].rewardpoints += itemObject.rewardpoints

                            console.log("shouldUpdate", cartData[updateAt].totalQty, rewardpoints, itemObject.rewardpoints, (Number(itemObject.rewardpoints)*Number(cartData[updateAt].totalQty)))
                        }
                        else{
                            rewardpoints = rewardpoints - itemObject.rewardpoints 
                            cartData.push(itemObject)
                        }
                        

                        this.setState({offerLevelPoint : rewardpoints})
                        setSecureData('offerLevelPoint', rewardpoints)
                        // localStorage.setItem(Constants.USERCART,JSON.stringify(cartData))
                        setSecureData(Constants.USERCART, cartData)
                        //close item popup
                        //   this.props.onClose({ isHide: true, isNewItem: !shouldUpdate })
                    
                    } else {

                        rewardpoints = rewardpoints -itemObject.rewardpoints 
                        this.setState({offerLevelPoint : rewardpoints})
                        setSecureData('offerLevelPoint', rewardpoints)
                    // localStorage.setItem(Constants.USERCART,JSON.stringify([this.state.itemObject]))
                    setSecureData(Constants.USERCART, [itemObject])
                    //close item popup
                    //   this.props.onClose({ isHide: true, isNewItem: true })
                    }
                } else {
                    rewardpoints = rewardpoints - itemObject.rewardpoints 
                    this.setState({offerLevelPoint : rewardpoints})
                    setSecureData('offerLevelPoint', rewardpoints)
                    // localStorage.setItem(Constants.USERCART,JSON.stringify([this.state.itemObject]))
                    setSecureData(Constants.USERCART, [itemObject])
                    //close item popup
                    // this.props.onClose({ isHide: true, isNewItem: true })
                }
                console.log("addToCart", data, rewardpoints)
            }
            else{
                rewardpoints = rewardpoints - itemObject.rewardpoints 
                this.setState({offerLevelPoint : rewardpoints})
                setSecureData('offerLevelPoint', rewardpoints)
                // localStorage.setItem(Constants.USERCART,JSON.stringify([this.state.itemObject]))
                setSecureData(Constants.USERCART, [itemObject])
            }

            this.setState({isAlertVisible: true, alertMessage: 'You loyalty point used.', isMultiButton: false, btnLeft: Strings.Ok_web})
        }
        else{
            this.setState({isAlertVisible: true, alertMessage: 'You have not sufficient point.', isMultiButton: false, btnLeft: Strings.Ok_web})
        }
        
    }

    /* comment for order type */
  onContinueOrder(order) {
    if (order.length > 0) {
      if (order[0].title == Strings.Delivery_web) {
          Constants.OrderType = Strings.Delivery_web
          Constants.OrderTypeID = 2
      } else if (order[0].title == Strings.lbl_takeaway_react) {
          Constants.OrderType = Strings.lbl_takeaway_react
          Constants.OrderTypeID = 1
      } else if (order[0].title == Strings.str_collect_drink) {
        Constants.OrderType = Strings.str_sitDown
        Constants.OrderTypeID = 3
      }
  }
  setSecureData(Constants.ORDERTYPE, Constants.OrderType)
  setSecureData(Constants.ORDERTYPEID, Constants.OrderTypeID)
    // return
    this.setState({ isOrderTypeVisible: false },()=> {
      setTimeout(() => {
        // this.callGetCategoryListApi()
        window.location.href="/Rewards"
      }, 500);
    })
  }

  onSelectTypes(order) {
    this.state.arrayOrderTypes.map(data => {
        if (data.title == order.title) {
            data.isSelect = true
            Constants.OrderType = data.title
        } else {
            data.isSelect = false
        }
    })
    this.setState({ arrayOrderTypes: this.state.arrayOrderTypes })

    let obj = this.state.arrayOrderTypes.filter((data) => (data.isSelect == true))

    if (obj.length > 0) {
        this.onContinueOrder(obj)
        // Constants.OrderType = obj[0].title
        // console.log('Constants.OrderType = obj[0].title --------- ', Constants.OrderType)
        // return (
        //     <div className="continue-btn">
        //         <button className="btn btn-primary btn-small order-btn" onClick={() => { this.onContinueOrder(obj) }}>{Strings.btn_continue_react}</button>
        //     </div>
        // )
      }
  }

    renderDeliveryMsg() {
        if (this.state.arrayOrderTypes.length > 0) {
          if (this.state.locationObj.isDelivery) {
            return(
              <p className="infomsg">{Strings.msgDeliverylabel}</p>
            )
          } else {
            return(
              <p className="infomsg">{Strings.msgSelectlabel}</p>
            )
          }
        }
      }
    
      renderOrderType() {
        if (this.state.arrayOrderTypes.length == 0) {
            return (<label>{Strings.error_msg_no_order_type_react}</label>)
        }
        var arry = []
        this.state.arrayOrderTypes.map((data,index) => {
            arry.push(
                <div className="optList" onClick={() => { this.onSelectTypes(data) }} key={index}>
                    <h3>{data.title}</h3>
                </div>
              )
          })
          return arry.reverse()
      }
    
      renderButton() {
    
          let obj = this.state.arrayOrderTypes.filter((data) => (data.isSelect == true))
          if (obj.length > 0) {
              Constants.OrderType = obj[0].title
              return (
                  <div className="continue-btn">
                      <button className="btn btn-primary btn-small order-btn" onClick={() => { this.onContinueOrder(obj) }}>{Strings.btn_continue_react}</button>
                  </div>
              )
          }
      }
      renderOrderSelectionView() {
        return (
            <Modal
                show={this.state.isOrderTypeVisible}
                onHide={()=>{}}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                
                <Modal.Body>
                    <div className="popupHeader">
                    <label className="headerTitle">{Strings.lbl_select_order_type_react}</label>
                        
                    </div>
                    <div className="pop loc-popup" id="mypop">
                        <div className="pop-dialog">
                            <div className="pop-content">
                                <div className="pop-body">
                                    <div className="prd-s">
                                        <div className="prd-cont">
                                            {this.renderDeliveryMsg()}
                                            {this.renderOrderType()}
                                        </div>
                                    </div>
                                    {this.renderButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
          )
      }


  

    render() {
        return (
            <div className="loginpage white nonsticy" style={{}}> 
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
               <HeaderWhiteMenuHalf isHideOrderType={true} headerLbl={'Rewards'} isCategory={true} 
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/> 
                <AlertDialog 
                isAlertVisible={this.state.isAlertVisible} 
                msgTitle={this.state.alertMessage} 
                isMultibutton={this.state.isMultiButton} 
                btnLeftClick={() => { 
                    this.setState({ isAlertVisible: false });
                    // window.location.href = Constant.mainURL
                    return
                 }} 
                 btnRightClick={() => { 
                     this.onRightClick() }} 
                btnLeftTitle={this.state.btnLeft} 
                btnRightTitle={this.state.btnRight} />
                {/* <HeaderSignin logoType={1} headerLbl={Strings.reset_pass_web} isCategory={true} /> */}
                {this.state.isOrderTypeVisible ? this.renderOrderSelectionView() : null}
                <div className="content_wrap">
                    <div className="container">
                        {/* <div className="book-tab-backbtn" style={{float: 'right'}}>
                                
                                <a  className="btn btn-primary order-btn book-back-btn" href="/BookTableList" >Back</a>
                        </div> */}
                        {/* <nav class="braedCrumbs" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/Rewards">Rewards</a></li>
                                <li class="breadcrumb-item"><a href="/MyRewards">My Rewards</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Enjoy Rewards</li>
                            </ol>
                        </nav> */}
                        <div className="user booktable-wrp fo-btm-spc">

                            <div className="row">
                                <div className="col-md-12  col-lg-6 center" style={{margin:'0 auto'}}>
                                    <h1 className="text-center">{'Loyalty Rewards'}</h1>
                                    <hr />

                                    
                                    <div className="ROImgBox">
                                        <img  className="congImg" src="images/bg/unusedLoyltyround.png" alt="congratulations" />
                                        <div className="rewardPoints">
                                            <h3 className="text-center" >{this.state.levelName}</h3>
                                            <h2 className="text-center" >{ this.state.offerLevelPoint }</h2>
                                            <h3 className="text-center" >1 Points = £{this.state.MemberOfferPointAmount}</h3>
                                        </div>
                                    </div>      


                                    {/* <div className="ROImgBox">
                                        <div className="rewardPoints">
                                            <h3 className="text-center" >{'Loyalty Points'}</h3>
                                            <h3 className="text-center" >{this.state.levelName}</h3>
                                            <h2 className="text-center" >{ this.state.offerLevelPoint }</h2>
                                            <h3 className="text-center" >1 Points = £{this.state.MemberOfferPointAmount}</h3>
                                        </div>
                                    </div> */}
                                    <br />
                                    <div className="enjoyRewardRow" >
                                    {
                                    this.state.getMemberofferData.length > 0 
                                    ?
                                    this.state.getMemberofferData.map((data, index) => {
                                        return(
                                            <div className="row EROfferBox" >
                                                <div className="col-md-4 "> 
                                                    <img className="" src={data.memberOfferImage} alt="" />
                                                </div>
                                                <div className="col-md-8 EROfferTitle"> 
                                                    <h3>{data.MemberOfferRewardTitle}</h3>
                                                    <p>{data.MemberOfferName}</p>
                                                </div>
                                                <div className="checkoutBtn" style={{margin:'auto'}} >
                                                    <div className="buttons-checkout">
                                                        <button className="btn btn-primary btn-medium" 
                                                        onClick={() => { this.CheckCartEmptyOrNot(data) }}>Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    
                                    :
                                    null
                                    }
                                    {
                                    this.state.LoyaltyPoint.length > 0 
                                    ?
                                    this.state.LoyaltyPoint.map((data, index) => {
                                        return(
                                            <div className="row EROfferBox" >
                                                <div className="col-md-4 "> 
                                                    <img className="" src={data.image} alt="" />
                                                </div>
                                                <div className="col-md-8 EROfferTitle"> 
                                                    <h3>{data.title}</h3>
                                                    <p>{data.loyaltyPoint} Points</p>
                                                </div>
                                                <div className="checkoutBtn" style={{margin:'auto'}} >
                                                    <div className="buttons-checkout">
                                                        <button className="btn btn-primary btn-medium" 
                                                        onClick={() => { this.addToCart(data) }}>Add To Order</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    
                                    :
                                    null
                                    }
                                    </div>
                                    <br />
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }




}

function mapStateToProps(state) {
    console.log('isLoaging ResetPassword ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    getTableReservationTimeSlotList: (customerId,locationId, languageId, bookingDate, BookingTime,totalPerson) => dispatch(HGActions.getTableReservationTimeSlotList(customerId,locationId, languageId, bookingDate, BookingTime, totalPerson)),
    addTableReservation: (data) => dispatch(HGActions.addTableReservation(data)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    GetLocationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),
    locationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),
    getMemberofferList : (data) => dispatch(HGActions.getMemberofferList((data))),
    getLoyaltyPointList : (data) => dispatch(HGActions.getLoyaltyPointList((data))),
    getCustomerDetails : (customerId,locationId) => dispatch(HGActions.getCustomerDetails(customerId,locationId)),
    deleteTableVirtualWaiting : (data) => dispatch(HGActions.deleteTableVirtualWaiting((data))),
    addTableVirtualWaiting : (data) => dispatch(HGActions.addTableVirtualWaiting((data))),  

})
export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyRewards);