import React, { Component } from "react";
import Constants from "../constants/baseConstants";
import Modal from "react-bootstrap/Modal";

import {
  Route,
  NavLink,
  BrowserRouter as Router,
  Switch,
  Link,
  history,
} from "react-router-dom";
import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";
import { Strings } from "../constants/Localization";
import Constant from "../constants/baseConstants";
import { setSecureData, getSecureData } from "../Utils/utils";
import { Dropdown } from "react-bootstrap";
import CMS from "./CMS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import moment from "moment";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      countryCode: "+44",
      phoneNumber: "",
      email: "",
      password: "",
      gender: 1,
      birthDate: "",
      languageType: 1, //default language type
      isTandCSelect: false,
      isConsentCSelect: false,
      errors: {
        nameError: "",
        countryCodeError: "",
        phoneNumberError: "",
        emailError: "",
        passwordError: "",
        locationError: "",
        tAndCError: "",
        consentError: "",
        birthDateError: "",
      },

      isSocialSignUp: false,
      locationArray: [],
      locationIndex: -1,

      isShowCMS: false,
      cmsHref: "",
    };
  }

  onSignUpClick = (event) => {
    event.preventDefault();

    // this.props.history.push({pathname: '/MobileVerification'})
    // return
    var nmError =
      this.state.name.length == 0 ? Strings.error_msg_name_react : "";
    var ccodeerror =
      this.state.countryCode == "0" ? Strings.error_msg_country_code_react : "";
    var phError =
      this.state.phoneNumber.length < 9 ? Strings.error_msg_phone_no_react : "";
    var emlError =
      this.state.email.length == 0 ? Strings.error_msg_email_react : "";
    var passError =
      this.state.password.length == 0 ? Strings.error_msg_password_react : "";
    var locError = ""; //this.state.locationIndex == -1 ? Strings.please_select_location : '';
    var birthDateError =
      this.state.birthDate == "" ? Strings.error_msg_birth_react : "";
    var terror = this.state.isTandCSelect ? "" : Strings.msgTandC;
    var cerror = this.state.isConsentCSelect ? "" : Strings.msgConsent;

    if (emlError.length == 0) {
      validEmailRegex.test(this.state.email) == false
        ? (emlError = Strings.error_msg_valid_email_react)
        : (emlError = "");
    }

    this.setState({
      errors: {
        locationError: locError,
        nameError: nmError,
        countryCodeError: ccodeerror,
        phoneNumberError: phError,
        emailError: emlError,
        passwordError: passError,
        tAndCError: terror,
        consentError: cerror,
        birthDateError: birthDateError,
      },
    });

    setTimeout(() => {
      if (
        this.state.errors.nameError == "" &&
        this.state.errors.countryCodeError == "" &&
        this.state.errors.phoneNumberError == "" &&
        this.state.errors.emailError == "" &&
        this.state.errors.passwordError == "" &&
        this.state.errors.locationError == "" &&
        this.state.errors.tAndCError == "" &&
        this.state.errors.consentError == "" &&
        this.state.errors.birthDateError == ""
      ) {
        console.info("Valid form");

        this.props
          .SignUpAPICall(
            getSecureData(Constant.LOCATIONOBJECT).locationId,
            0,
            this.state.name,
            this.state.countryCode,
            this.state.phoneNumber,
            this.state.email,
            this.state.password,
            this.state.languageType,
            this.state.gender,
            this.state.birthDate
          )
          .then(() => {
            console.log("SIGNUP RESPONSE --------- ", this.props.data);
            if (this.props.data.status == 1) {
              if (this.props.data.result != null) {
                setTimeout(() => {
                  let result = this.props.data.result;
                  setSecureData(Constant.USEROBJECT, this.props.data.result);
                  this.props.onResponse();
                }, 100);
              }
            } else {
              alert(this.props.data.message);
              console.log("sign up status else...");
            }
          });
      } else {
        console.error("Not valid form");
      }
    }, 100);
  };

  onMobileEnter(str) {
    if (str.match("^[0-9]*$") != null) {
      this.setState({ phoneNumber: str });
    }
  }

  onTandC(i) {
    if (i == 1) {
      this.setState({ isTandCSelect: !this.state.isTandCSelect });
    } else if (i == 2) {
      this.setState({ isConsentCSelect: !this.state.isConsentCSelect });
    }
  }

  render() {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="form-control"
      >
        {children}
        &#x25bc;
      </a>
    ));

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
      ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        // const [value, setValue] = useState('');
        const value = "";
        return (
          <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
          >
            {/* <FormControl
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Type to filter..."
                onChange={(e) => alert(e.target.value)}
                value={value}
            /> */}
            <ul>
              {React.Children.toArray(children).filter(
                (child) =>
                  !value || child.props.children.toLowerCase().startsWith(value)
              )}
            </ul>
          </div>
        );
      }
    );
    return (
      <Modal
        show={this.props.isShowForm}
        onHide={() => {
          this.props.onResponse();
        }}
        size="sm"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            class="signupwrap signupwrap aos-init aos-animate"
            data-aos="fade-up"
          >
            {/* <h1>{Strings.btn_signup_react}</h1> */}
            <p>
              <label href="#" className="dont">
                Sign Up, If you haven't an account
              </label>
            </p>
            <CMS
              isShow={this.state.isShowCMS}
              href={this.state.cmsHref}
              onDisable={() => this.setState({ isShowCMS: false })}
            />
            <div class="row">
              <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 sngUp">
                <div class="form-group">
                  <label>{Strings.lbl_name_react}*</label>
                  <input
                    type="text"
                    class="form-control"
                    maxLength={64}
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    tabIndex="1"
                  />
                  <div className="errorDiv">
                    <span className="errorMsg">
                      {this.state.errors.nameError}
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label>{Strings.lbl_email_react}*</label>
                  <input
                    type="text"
                    class="form-control"
                    maxLength={64}
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    tabIndex="3"
                  />
                  <div className="errorDiv">
                    <span className="errorMsg">
                      {this.state.errors.emailError}
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label>{Strings.lbl_phone_number_react}*</label>
                  <div class="row">
                    <div class="col-5 col-md-3 pr-0 smDiv">
                      {/* <label type="text" className="form-control lblPhone">+44</label> */}
                      <input
                        readonly
                        type="text"
                        className="form-control text-center"
                        value="+44"
                        style={{ pointerEvents: "none" }}
                      />
                      {/* <select class="form-control" onChange={e => this.setState({ countryCode: e.target.value })}>
                                
                                <option value="+44">+44</option>
                            </select> */}
                      {/* <Dropdown onSelect={(e) => this.setState({ countryCode: e })}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    {this.state.countryCode == 0 ? "Code " : this.state.countryCode}
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu} style={{ height: "200px", overflow: "scroll" }}>
                                    <Dropdown.Item eventKey="+7">+7</Dropdown.Item>
                                    <Dropdown.Item eventKey="+30">+30</Dropdown.Item>
                                    <Dropdown.Item eventKey="+31">+31</Dropdown.Item>
                                    <Dropdown.Item eventKey="+32">+32</Dropdown.Item>
                                    <Dropdown.Item eventKey="+33">+33</Dropdown.Item>
                                    <Dropdown.Item eventKey="+34">+34</Dropdown.Item>
                                    <Dropdown.Item eventKey="+36">+36</Dropdown.Item>
                                    <Dropdown.Item eventKey="+39">+39</Dropdown.Item>
                                    <Dropdown.Item eventKey="+40">+40</Dropdown.Item>
                                    <Dropdown.Item eventKey="+41">+41</Dropdown.Item>
                                    <Dropdown.Item eventKey="+43">+43</Dropdown.Item>
                                    <Dropdown.Item eventKey="+44">+44</Dropdown.Item>
                                    <Dropdown.Item eventKey="+45">+45</Dropdown.Item>
                                    <Dropdown.Item eventKey="+46">+46</Dropdown.Item>
                                    <Dropdown.Item eventKey="+47">+47</Dropdown.Item>
                                    <Dropdown.Item eventKey="+48">+48</Dropdown.Item>
                                    <Dropdown.Item eventKey="+49">+49</Dropdown.Item>
                                    <Dropdown.Item eventKey="+90">+90</Dropdown.Item>
                                    <Dropdown.Item eventKey="+298">+298</Dropdown.Item>
                                    <Dropdown.Item eventKey="+350">+350</Dropdown.Item>
                                    <Dropdown.Item eventKey="+351">+351</Dropdown.Item>
                                    <Dropdown.Item eventKey="+352">+352</Dropdown.Item>
                                    <Dropdown.Item eventKey="+353">+353</Dropdown.Item>
                                    <Dropdown.Item eventKey="+354">+354</Dropdown.Item>
                                    <Dropdown.Item eventKey="+355">+355</Dropdown.Item>
                                    <Dropdown.Item eventKey="+356">+356</Dropdown.Item>
                                    <Dropdown.Item eventKey="+357">+357</Dropdown.Item>
                                    <Dropdown.Item eventKey="+358">+358</Dropdown.Item>
                                    <Dropdown.Item eventKey="+359">+359</Dropdown.Item>
                                    <Dropdown.Item eventKey="+370">+370</Dropdown.Item>
                                    <Dropdown.Item eventKey="+371">+371</Dropdown.Item>
                                    <Dropdown.Item eventKey="+372">+372</Dropdown.Item>
                                    <Dropdown.Item eventKey="+373">+373</Dropdown.Item>
                                    <Dropdown.Item eventKey="+374">+374</Dropdown.Item>
                                    <Dropdown.Item eventKey="+375">+375</Dropdown.Item>
                                    <Dropdown.Item eventKey="+376">+376</Dropdown.Item>
                                    <Dropdown.Item eventKey="+377">+377</Dropdown.Item>
                                    <Dropdown.Item eventKey="+378">+378</Dropdown.Item>
                                    <Dropdown.Item eventKey="+379">+379</Dropdown.Item>
                                    <Dropdown.Item eventKey="+380">+380</Dropdown.Item>
                                    <Dropdown.Item eventKey="+381">+381</Dropdown.Item>
                                    <Dropdown.Item eventKey="+382">+382</Dropdown.Item>
                                    <Dropdown.Item eventKey="+383">+383</Dropdown.Item>
                                    <Dropdown.Item eventKey="+385">+385</Dropdown.Item>
                                    <Dropdown.Item eventKey="+386">+386</Dropdown.Item>
                                    <Dropdown.Item eventKey="+387">+387</Dropdown.Item>
                                    <Dropdown.Item eventKey="+389">+389</Dropdown.Item>
                                    <Dropdown.Item eventKey="+420">+420</Dropdown.Item>
                                    <Dropdown.Item eventKey="+421">+421</Dropdown.Item>
                                    <Dropdown.Item eventKey="+423">+423</Dropdown.Item>
                                    <Dropdown.Item eventKey="+994">+994</Dropdown.Item>
                                    <Dropdown.Item eventKey="+995">+995</Dropdown.Item>
                                    <Dropdown.Item eventKey="+3491">+3491</Dropdown.Item>
                                    <Dropdown.Item eventKey="+91">+91</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
                    </div>
                    <div class="col-7 col-md-9 lmDiv">
                      <input
                        type="text"
                        class="form-control"
                        maxLength={11}
                        value={this.state.phoneNumber}
                        onChange={(e) => this.onMobileEnter(e.target.value)}
                        tabIndex="2"
                      />
                    </div>
                  </div>
                  <div className="errorDiv">
                    <span className="errorMsg">
                      {this.state.errors.countryCodeError}
                    </span>
                    <span className="errorMsg">
                      {this.state.errors.phoneNumberError}
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <label>BirthDate:*</label>
                  {/* <input type="date" name="birthDate" className="form-control" value={this.state.birthDate}  onChange={e => this.setState({ birthDate: e.target.value })} /> */}
                  <DatePicker
                    onChange={(date) => {
                      this.setState({ birthDate: date });
                    }}
                    selected={this.state.birthDate}
                    name="birthDate"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                  />
                  {/* <i className="fa fa-calendar calenderIcon" aria-hidden="true"></i> */}
                  <div className="errorDiv">
                    <span className="errorMsg">
                      {this.state.errors.birthDateError}
                    </span>
                  </div>
                </div>

                <div class="form-group">
                  <label>{Strings.lbl_password_react_sign_in}*</label>
                  <input
                    type="password"
                    class="form-control"
                    maxLength={64}
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    tabIndex="4"
                  />
                  <div className="errorDiv">
                    <span className="errorMsg">
                      {this.state.errors.passwordError}
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <label>Gender*</label>
                  <br />
                  <div class="form-check form-check-inline">
                    <input
                      className="custom-control-input"
                      id="inlineRadio1"
                      type="radio"
                      name="gender"
                      value={1}
                      onChange={(e) => this.setState({ gender: 1 })}
                      checked={this.state.gender === 1}
                    />
                    <label
                      onClick={() => {
                        this.setState({ gender: 1 });
                      }}
                      className="form-check-label"
                      for="inlineRadio1"
                    >
                      <i
                        class={
                          this.state.gender == 1
                            ? "fas fa-check-circle"
                            : "fa fa-circle-o"
                        }
                        aria-hidden="true"
                      >
                        {" "}
                      </i>{" "}
                      {"  "} Male
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      className="custom-control-input"
                      id="inlineRadio2"
                      type="radio"
                      name="gender"
                      value={2}
                      onChange={(e) => this.setState({ gender: 2 })}
                      checked={this.state.gender === 2}
                    />
                    <label
                      onClick={() => {
                        this.setState({ gender: 2 });
                      }}
                      className="form-check-label"
                      for="inlineRadio2"
                    >
                      <i
                        class={
                          this.state.gender == 2
                            ? "fas fa-check-circle"
                            : "fa fa-circle-o"
                        }
                        aria-hidden="true"
                      >
                        {" "}
                      </i>{" "}
                      {"  "}Female
                    </label>
                  </div>
                  {/* <div className="errorDiv">
                        <span className='errorMsg'>{this.state.errors.emailError}</span>
                    </div> */}
                </div>

                <div class="form-group">
                  <div className="custom-control custom-radio custom-control-inline">
                    {/* <input type="radio" className="custom-control-input" name="customRadio1122" id="customRadio1122" checked={this.state.isTandCSelect ? true : false} onClick={() => { this.onTandC(1) }}/> */}
                    {this.state.isTandCSelect ? (
                      <FontAwesomeIcon
                        size="20px"
                        icon={faCheckCircle}
                        onClick={() => {
                          this.onTandC(1);
                        }}
                      >
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="customRadio1"
                          id="customRadio1"
                          checked={this.state.isTandCSelect ? true : false}
                        />
                      </FontAwesomeIcon>
                    ) : (
                      <i
                        class="fa fa-circle-o"
                        aria-hidden="true"
                        onClick={() => {
                          this.onTandC(1);
                        }}
                      >
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="customRadio1"
                          id="customRadio1"
                          checked={this.state.isTandCSelect ? true : false}
                        />
                      </i>
                    )}
                    <label
                      className="custom-control-label hlink"
                      onClick={() => {
                        this.setState({
                          cmsHref:
                            "http://culto.smartrestaurants.com/frmtermsandcondition.aspx",
                          // isShowCMS: true,
                        });
                        return window
                          .open(
                            "http://culto.smartrestaurants.com/frmtermsandcondition.aspx",
                            "_blank"
                          )
                          .focus();
                      }}
                    >
                      {Strings.lblTandC}
                    </label>
                  </div>
                  <div className="errorDiv">
                    <span className="errorMsg">
                      {this.state.errors.tAndCError}
                    </span>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    {/* <input type="radio" className="custom-control-input" name="customRadio2" id="customRadio2" checked={this.state.isConsentCSelect ? true : false} onClick={() => { this.onTandC(2) }}/> */}
                    {this.state.isConsentCSelect ? (
                      <FontAwesomeIcon
                        onClick={() => {
                          this.onTandC(2);
                        }}
                        size="20px"
                        icon={faCheckCircle}
                      >
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="customRadio2"
                          id="customRadio2"
                          checked={this.state.isConsentCSelect ? true : false}
                        />
                      </FontAwesomeIcon>
                    ) : (
                      <i
                        onClick={() => {
                          this.onTandC(2);
                        }}
                        class="fa fa-circle-o"
                        aria-hidden="true"
                      >
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="customRadio2"
                          id="customRadio2"
                          checked={this.state.isConsentCSelect ? true : false}
                        />
                      </i>
                    )}

                    <label
                      className="custom-control-label hlink"
                      onClick={() => {
                        this.setState({
                          cmsHref:
                            "http://culto.smartrestaurants.com/frmPrivacyNotice.aspx",
                          // isShowCMS: true,
                        });
                        return window
                          .open(
                            "http://culto.smartrestaurants.com/frmPrivacyNotice.aspx",
                            "_blank"
                          )
                          .focus();
                      }}
                    >
                      {Strings.lblConsent}
                    </label>
                  </div>
                  <div className="errorDiv">
                    <span className="errorMsg">
                      {this.state.errors.consentError}
                    </span>
                  </div>
                </div>
                {/* <div class="form-group">
                    <label>{Strings.lbl_select_location_react}*</label>
                    {this.renderLocationList()}
                    <div className="errorDiv">
                        <span className='errorMsg'>{this.state.errors.locationError}</span>
                    </div>
                </div> */}
                <div class="signup">
                  {/* <a href="#" class="btn btn-primary btn-big" onClick={this.onSignUpClick} tabIndex="5">{Strings.btn_signup_react}</a> */}
                  <a
                    href="#"
                    className="btn btn-primary btn-large btnSignUpForm"
                    onClick={this.onSignUpClick}
                    tabIndex="5"
                  >
                    {Strings.btn_signup_react}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  console.log("isLoaging SignUp ------- " + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  SignUpAPICall: (
    locationId,
    customerId,
    name,
    countrycode,
    phone,
    email,
    password,
    languageType,
    gender,
    birthDate
  ) =>
    dispatch(
      HGActions.initRegistrationCall(
        locationId,
        customerId,
        name,
        countrycode,
        phone,
        email,
        password,
        languageType,
        gender,
        birthDate
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
