import React, { Component } from "react";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";
import { Strings } from "../constants/Localization";
import FooterMenu from "../Components/FooterMenu";
import Constant from "../constants/baseConstants";
import HeaderWhiteMenu from "../Components/HeaderWhiteMenu";
import { setSecureData, getSecureData } from "../Utils/utils";
import AOS from "aos";
import "aos/dist/aos.css";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

var mainObj;
class UserFeedBack extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerId: 0,
      name: "",
      countryCode: "+44",
      phoneNumber: "",
      email: "",
      comment: "",
      languageType: 1, //default language type
      errors: {
        nameError: "",
        countryCodeError: "",
        phoneNumberError: "",
        emailError: "",
        commentError: "",
      },
    };
  }

  componentDidMount() {
    //Init animation duration
    AOS.init({
      duration: 1000,
    });

    if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
      // get the key's value from localStorage
      // let userObject = localStorage.getItem(Constant.USEROBJECT);
      let userObject = getSecureData(Constant.USEROBJECT);
      console.log("userObject=" + userObject);
      if (userObject == null) {
        this.props.history.push({ pathname: "/" });
        return;
      }
      if (userObject !== null) {
        // userObject = JSON.parse(userObject);
        this.setState({
          customerId: userObject.customerId,
          name: userObject.firstname,
          countryCode: userObject.countryCode,
          phoneNumber: userObject.phone,
          email: userObject.emailId,
        });
      }
    } else {
      this.props.history.push({ pathname: "/" });
      return;
    }

    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
    let session = getSecureData(Constant.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
    let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }
  }

  //Handle change language click event
  //English language type: 1
  //Spanish language type: 2
  languageChangeClickEvent = (type) => {
    console.log("my order page language type :" + type);
    // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
    setSecureData(Constant.PRE_LANGUAGE_TYPE, type);
    this.setState({ languageType: type });
    setTimeout(() => {
      this.props.AppMessageApi(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          var messageListArray = this.props.data.result;
          if (messageListArray != null && messageListArray.length > 0) {
            console.log("message list  :" + JSON.stringify(messageListArray));
            // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
            setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
            // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
            let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
            // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
            setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType);
            // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
            let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
            if (languageType == 1) {
              //English language type: 1
              Strings.setLanguage("en"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "en-GB",
              });
            } else {
              Strings.setLanguage("es"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "es",
              });
            }
            console.log("language type  :" + languageType);
            Strings.setContent(localMessage);
            // this.setState({ languageType: languageType });
            window.location.reload(false);
          }
        } else {
          alert(this.props.error);
        }
      });
    }, 700);
  };

  //Handle validation and call user feedback api
  isValidate = (event) => {
    event.preventDefault();

    var nmError =
      this.state.name.length == 0 ? Strings.error_msg_name_react : "";
    var ccodeerror =
      this.state.countryCode == "0" ? Strings.error_msg_country_code_react : "";
    var phError =
      this.state.phoneNumber.length < 9 ? Strings.error_msg_phone_no_react : "";
    var emlError =
      this.state.email.length == 0 ? Strings.error_msg_email_react : "";
    var commentErrors =
      this.state.comment.length == 0 ? Strings.error_msg_feedback_react : "";

    if (emlError.length == 0) {
      validEmailRegex.test(this.state.email) == false
        ? (emlError = Strings.error_msg_valid_email_react)
        : (emlError = "");
    }

    this.setState({
      errors: {
        nameError: nmError,
        countryCodeError: ccodeerror,
        phoneNumberError: phError,
        emailError: emlError,
        commentError: commentErrors,
      },
    });

    setTimeout(() => {
      if (
        this.state.errors.nameError == "" &&
        this.state.errors.countryCodeError == "" &&
        this.state.errors.phoneNumberError == "" &&
        this.state.errors.emailError == "" &&
        this.state.errors.commentError == ""
      ) {
        {
          this.prepareRequest();
        }
      }
    }, 100);
  };

  //Call user feedback point api
  prepareRequest() {
    var requestParam = {
      customerId: this.state.customerId,
      phone: this.state.countryCode + this.state.phoneNumber,
      name: this.state.name,
      email: this.state.email,
      feedback: this.state.comment,
    };

    mainObj = {
      restaurantId: 147,
      languageId: this.state.languageType,
      OrderReviewInfo: requestParam,
    };

    this.props.userFeedbackApi(mainObj).then(() => {
      if (this.props.data.status == 1) {
        if (this.props.data.result != null) {
          setTimeout(() => {
            let result = this.props.data.result;
            if (result != null) {
              alert(result.message);
            }
          }, 100);
        }
      } else {
        alert(this.props.error);
      }
    });
  }

  render() {
    return (
      <div>
        {this.props.isLoading ? (
          <div class="loadermain" style={{ display: "block" }}>
            <div class="loader"></div>
          </div>
        ) : (
          <div class="loadermain" style={{ display: "none" }}>
            <div class="loader"></div>
          </div>
        )}
        <HeaderWhiteMenu
          changeLanguageEvent={this.languageChangeClickEvent}
          isCategory={true}
        />

        {/* <h2>{this.props.match.params.id}</h2> */}
        <div class="content_wrap">
          <div class="container">
            <div class="signupwrap" data-aos="fade-up">
              <h1>{Strings.lbl_user_feedback_react}</h1>
              <div class="row">
                <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div class="form-group">
                    <label>{Strings.lbl_name_react}*</label>
                    <input
                      type="text"
                      class="form-control"
                      maxLength={75}
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      tabIndex="1"
                    />
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.nameError}
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{Strings.lbl_phone_number_react}*</label>
                    <div class="row">
                      <div class="col-5 col-md-3 pr-0">
                        <select
                          class="form-control"
                          value={this.state.countryCode}
                          onChange={(e) =>
                            this.setState({ countryCode: e.target.value })
                          }
                        >
                          {/* <option value="0">Country Code</option>
                                                <option value="+35">+35</option> */}
                          <option value="+44">+44</option>
                        </select>
                      </div>
                      <div class="col-7 col-md-9">
                        <input
                          type="text"
                          class="form-control"
                          maxLength={11}
                          value={this.state.phoneNumber}
                          onChange={(e) =>
                            this.setState({ phoneNumber: e.target.value })
                          }
                          tabIndex="2"
                        />
                      </div>
                    </div>
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.countryCodeError}
                      </span>
                      <span className="errorMsg">
                        {this.state.errors.phoneNumberError}
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{Strings.lbl_email_react}*</label>
                    <input
                      type="text"
                      class="form-control"
                      maxLength={75}
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      tabIndex="3"
                    />
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.emailError}
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{Strings.lbl_user_feedback_react}*</label>
                    <textarea
                      type="text"
                      class="form-control"
                      maxLength={200}
                      value={this.state.comment}
                      onChange={(e) =>
                        this.setState({ comment: e.target.value })
                      }
                      tabIndex="4"
                    />
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.commentError}
                      </span>
                    </div>
                  </div>
                  <div class="signup">
                    <a
                      href="#"
                      class="btn btn-primary btn-big"
                      onClick={this.isValidate}
                      tabIndex="5"
                    >
                      {Strings.Submit_web}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </div>
    );
  }
}
function mapStateToProps(state) {
  console.log("isLoaging SignUp ------- " + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  userFeedbackApi: (mainObj) => dispatch(HGActions.callAddFeedbackApi(mainObj)),
  AppMessageApi: (languageType) =>
    dispatch(HGActions.GetAppMessageListApi(languageType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFeedBack);
