import React, {Component} from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";

class HeaderMenu extends Component {

    constructor(props) {
        super(props)

    }

    render() {
        return (
            <header> 
                    <div class="container">
                        <div class="hg_logo"><a href="#"><img src={ this.props.backgroundType == 'black' ? "/images/logo-white.png" : "/images/logo.png"} alt=""/></a></div>    
                            
                            <div class="navigation"> <a href="#" class="lines-button x2"><span class="lines"></span></a>
                                <div class="inner-header-menu">
                                    <ul class="firstul">
                                        <li class="current_page_item"><a href="#">products </a></li>
                                        <li><a href="#" onClick={()=>this.props.history.push('/MyAccount')}>my account</a></li>
                                        {/* <Link to='/MyAccount'><a href="#" >SIGN UP</a></Link> */}
                                    </ul>
                                </div>
                                <div class="hg_cart"><a href="#"></a>
                                </div>
                                <div class="lang_drop">
                                    <div class="dropdown">
                                        <a href="#" class="" type="button" data-toggle="dropdown">ES <span class="caret"></span></a>
                                        <ul class="dropdown-menu">
                                            <li><a className="dropdown-item">ES</a></li>
                                        </ul>
                                    </div> 
                        
                                </div> 
                            </div> 
                    </div>
                </header> 
        )
    }
}

export default HeaderMenu