import React, { Component } from "react";
import { history } from "react-router-dom";
// import { createBrowserHistory } from "history";

import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";
import Constants from "../constants/baseConstants";
import HeaderWhiteMenuHalf from "../Components/HeaderWhiteMenuHalf";
import Modal from "react-bootstrap/Modal";
import FooterMenu from "../Components/FooterMenu";
import ItemPopup from "../Components/ItemPopup";
import AlertDialog from "../Components/AlertDialog";
import { Strings } from "../constants/Localization";
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import { getCurrencySign, bindhttps } from "../Utils/utils";
import { setSecureData, getSecureData } from "../Utils/utils";
import baseConstants from "../constants/baseConstants";
import queryString from "query-string";
import CheckoutDelivery from "./CheckoutDelivery";
import Collapsible from "react-collapsible";
var categoryName = "";

class MenuItems extends Component {
  constructor(props) {
    super(props);
    this.rerenderParentCallback = this.rerenderParentCallback.bind(this);
    this.state = {
      arrayWholeMenuItems: [],
      arrayMenuItems: [],
      arraySubCategory: [],
      arrayFilterTypes: [],
      isFilter: false,
      isItemPopupVisible: false,
      objectAt: 0,
      // catId: JSON.parse(localStorage.getItem(Constants.PRE_CAT_ID)),//this.props.location.catId,
      // arrayMainCategories: JSON.parse(localStorage.getItem(Constants.CATEGORIESDATA)),//this.props.location.Categorieslist,
      catId: getSecureData(Constants.PRE_CAT_ID), //this.props.location.catId,
      arrayMainCategories: getSecureData(Constants.CATEGORIESDATA), //this.props.location.Categorieslist,

      isAlertVisible: false,
      alertMessage: "",
      btnLeft: "",
      btnRight: "",
      isMultiButton: false,
      languageType: 1,

      isVisible: false,
      locationArray: [],

      /* comment for order type */
      isOrderTypeVisible: false,
      arrayOrderTypes: [],

      isNo: false,
      cartUpdate: false,
      currentTime: 0,
      ukdatetime: 0,
      customerId:
        getSecureData(Constants.USEROBJECT) != null
          ? getSecureData(Constants.USEROBJECT).customerId
          : 0,
      subCatActiveId: "",
      noProFound: false,
      orderTypePro: 0,
    };
  }

  componentWillMount() {
    if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
      let userObject = getSecureData(Constants.USEROBJECT);
      if (userObject != null) {
        let customer = getSecureData(Constants.USEROBJECT).customerId;
        console.log("customer", customer);
        this.setState({ customerId: customer });
      }
    }
  }

  componentDidMount() {
    this.componentDidMountCustFun();
  }

  componentDidMountCustFun() {
    AOS.init({
      duration: 1000,
    });

    console.log("orderTypePro", this.state.orderTypePro);

    // if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
    //   let userObject = getSecureData(Constants.USEROBJECT);
    //   if (userObject == null) {
    //     this.props.history.push({ pathname: '/' })
    //     return
    //   }
    // }
    // else {
    //   this.props.history.push({ pathname: '/' })
    //   return
    // }

    let params = queryString.parse(this.props.location.search);
    if (params.isRemove) {
      console.log("query string -------------> ", params);
      localStorage.removeItem(Constants.USERCART);
      localStorage.removeItem(Constants.PLACEDORDEROBJECT);
      localStorage.removeItem(Constants.ISCANCEL);
    }

    let session = getSecureData(Constants.PRE_MESSAGE_LIST);
    let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
    // let mLanguageCode = localStorage.getItem(Constants.PRE_LANGUAGE_CODE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }

    // setTimeout(() => {
    //   if (!localStorage.hasOwnProperty(Constants.CHECKINFO)) {
    //     this.props
    //       .callCheckVersion(0, this.state.languageType)
    //       .then(() => {
    //         if (this.props.data.status == 1) {
    //           setTimeout(() => {
    //             setSecureData(Constants.CHECKINFO, this.props.data.result);
    //             this.callLocationListingAPI();
    //           }, 500);
    //         } else {
    //           setTimeout(() => {
    //             this.callLocationListingAPI();
    //           }, 500);
    //         }
    //       })
    //       .catch((error) => {
    //         setTimeout(() => {
    //           this.callLocationListingAPI();
    //         }, 500);
    //       });
    //   } else {
    //     this.callLocationListingAPI();
    //   }

    //   // this.getCategoryItem()
    //   // this.getRestoInfo()
    // }, 200);

    this.fetchLocationList();
  }

  getRestoInfo = () => {
    var GETRESTOINFO = getSecureData(Constants.GETRESTOINFO);

    console.log(
      "getSecureData(Constants.LOCATIONOBJECT)",
      getSecureData(Constants.LOCATIONOBJECT)
    );

    if (GETRESTOINFO != "" && GETRESTOINFO != null) {
      let uktime = moment(
        GETRESTOINFO.ukdatetime,
        "YYYY-MM-dd HH:mm:ss"
      ).format("HH:mm");

      this.setState({
        currentTime: uktime,
        ukdatetime: GETRESTOINFO.ukdatetime,
      });
    } else {
      this.props.getRestoInfo(this.state.languageType).then(() => {
        console.log(
          "resto info api response -------------------- ",
          this.props.data
        );
        if (this.props.data.status == 1) {
          setSecureData(Constants.GETRESTOINFO, this.props.data.result);
          let uktime = moment(
            this.props.data.result.ukdatetime,
            "YYYY-MM-dd HH:mm:ss"
          ).format("HH:mm");
          setSecureData(Constants.GETRESTOINFO, this.props.data.result);
          this.setState({
            currentTime: uktime,
            ukdatetime: this.props.data.result.ukdatetime,
          });
        }
      });
    }
  };
  //Handle change language click event
  //English language type: 1
  //Spanish language type: 2
  languageChangeClickEvent = (type) => {
    console.log("menuitem page language type :" + type);
    // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, type)
    setSecureData(Constants.PRE_LANGUAGE_TYPE, type);
    this.setState({ languageType: type });
    setTimeout(() => {
      this.props.AppMessageApi(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          var messageListArray = this.props.data.result;
          if (messageListArray != null && messageListArray.length > 0) {
            console.log("message list  :" + JSON.stringify(messageListArray));
            // localStorage.setItem(Constants.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
            setSecureData(Constants.PRE_MESSAGE_LIST, messageListArray);
            // let localMessage = JSON.parse(localStorage.getItem(Constants.PRE_MESSAGE_LIST));
            let localMessage = getSecureData(Constants.PRE_MESSAGE_LIST);
            // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, this.state.languageType)
            setSecureData(Constants.PRE_LANGUAGE_TYPE, this.state.languageType);
            // let languageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
            let languageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
            console.log("language type  :" + languageType);
            if (languageType == 1) {
              //English language type: 1
              Strings.setLanguage("en"); //Setting selected language code here
              this.setState({ languageType: languageType });
              // localStorage.setItem(Constants.PRE_LANGUAGE_CODE, "en-GB");
            } else {
              Strings.setLanguage("es"); //Setting selected language code here
              this.setState({ languageType: languageType });
              // localStorage.setItem(Constants.PRE_LANGUAGE_CODE, "es");
            }
            Strings.setContent(localMessage);
            // this.setState({ languageType: languageType })
            //Call Menu items API while change language
            setTimeout(() => {
              this.callGetCategoryListApi();
            }, 500);
          }
        } else {
          alert(this.props.error);
        }
      });
    }, 700);
  };

  //This method use show location dialog
  showLocationListDialog() {
    if (!this.state.isVisible) {
      return;
    }
    if (
      this.state.locationArray != null &&
      this.state.locationArray.length > 0
    ) {
      return (
        <Modal
          show={this.state.isVisible}
          onHide={() => {}}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="popupHeader">
              <label className="headerTitle">
                {Strings.lbl_select_location_react}
              </label>
            </div>
            {/* <!-- The pop --> */}
            <div className="pop loc-popup" id="mypop">
              <div className="pop-dialog">
                <div className="pop-content">
                  <div className="pop-body">
                    <div className="prd-s">
                      <div className="prd-cont">
                        {this.setLocationList(this.state.locationArray)}
                      </div>
                    </div>
                    {/* {this.state.locationObj != null ? (
                                      <div className="continue-btn"> <button className="btn btn-primary btn-small order-btn" onClick={() => { this.onContinue() }}>{Strings.btn_continue_react}</button></div>
                                  ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    } else {
      return;
    }
  }

  //This method use bind location data
  setLocationList(itemData) {
    if (itemData != null && itemData.length > 0) {
      return itemData.map((item, index) => (
        <div key={index}>
          <div
            className="chek-new"
            onClick={() => {
              this.onContinue(item, index);
            }}
          >
            <label className="container1">
              <h3>{item.locationName}</h3>
              <p>{item.distance > 0 ? item.distance : null}</p>
              {/* <input
                              type="checkbox"
                              defaultChecked={true} />
                          <span className={this.state.selectIndex == index ? "checkmark" : ''}></span> */}
            </label>
          </div>
        </div>
      ));
    }
  }

  onContinue = (item, index) => {
    if (getSecureData(Constants.USERCART)) {
      // console.log('location object  ----------- ',getSecureData(Constants.LOCATIONOBJECT))
      // console.log('select location object  ----------- ',item)

      if (
        getSecureData(Constants.LOCATIONOBJECT).locationId != item.locationId
      ) {
        // console.log('hdfuehduehduheudheudhuehdu')
        this.setState({
          isVisible: false,
          selectIndex: index,
          locationObj: item,
          orderType: true,
          isAlertVisible: true,
          alertMessage: Strings.empty_cart_message,
          isMultiButtom: true,
          btnLeft: Strings.btn_cancel,
          btnRight: Strings.btn_confirm,
        });
      } else {
        this.setState(
          { selectIndex: index, locationObj: item, orderType: true },
          () => {
            this.setLocationData();
          }
        );
      }
    } else {
      this.setState(
        { selectIndex: index, locationObj: item, orderType: true },
        () => {
          this.setLocationData();
        }
      );
    }
  };

  setLocationData() {
    setTimeout(() => {
      if (this.state.locationObj == null) {
        // if (item == null) {
        alert(Strings.lbl_select_location_react);
      } else {
        // console.log('prev location =------ ',getSecureData(Constants.ORDERTYPE))

        let locationObj = this.state.locationObj;
        var arry = [];
        if (locationObj.isDelivery) {
          arry.push(Strings.Delivery_web);
        }
        if (locationObj.isTakeAway) {
          arry.push(Strings.lbl_takeaway_react);
        }
        if (locationObj.isOrderExtras) {
          arry.push(Strings.str_sitDown);
        }
        console.log(
          "current type =------ ",
          arry,
          getSecureData(Constants.ORDERTYPE)
        );
        if (arry.includes(getSecureData(Constants.ORDERTYPE))) {
          setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj);
          Constants.locationId = this.state.locationObj.locationId;
          this.setState({ isVisible: false }, () => {
            setTimeout(() => {
              this.callGetCategoryListApi();
            }, 500);
          });
        } else {
          setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj);
          Constants.locationId = this.state.locationObj.locationId;

          var arry = [];
          if (locationObj.isDelivery) {
            arry.push({ title: Strings.Delivery_web, isSelect: false });
          }
          if (locationObj.isTakeAway) {
            arry.push({ title: Strings.lbl_takeaway_react, isSelect: false });
          }
          if (locationObj.isOrderExtras) {
            arry.push({ title: Strings.str_collect_drink, isSelect: false });
          }
          this.setState({
            isVisible: false,
            isOrderTypeVisible: true,
            arrayOrderTypes: arry,
          });
        }

        return;

        // localStorage.setItem(Constants.LOCATIONOBJECT, JSON.stringify(this.state.locationObj))
        setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj);
        // setSecureData(Constants.LOCATIONOBJECT, item)
        Constants.locationId = this.state.locationObj.locationId;
        // Constants.locationId = item.locationId

        let locationObject = this.state.locationObj;
        var arry = [];
        if (locationObject.isDelivery) {
          arry.push({ title: Strings.Delivery_web, isSelect: false });
        }
        if (locationObject.isTakeAway) {
          arry.push({ title: Strings.lbl_takeaway_react, isSelect: false });
        }
        /* comment for order type */
        if (locationObject.isOrderExtras) {
          arry.push({ title: Strings.str_collect_drink, isSelect: false });
        }
        {
          /* comment for order type */
        }
        // this.setState({ isVisible: false, arrayOrderTypes: arry},()=>{
        //   setTimeout(() => {
        //     this.callGetCategoryListApi()
        //   }, 500);
        // })
        this.setState({
          isVisible: false,
          isOrderTypeVisible: true,
          arrayOrderTypes: arry,
        });
      }
    }, 200);
  }

  callLocationListingAPI() {
    if (this.props.location.isChangeLocation) {
      this.fetchLocationList();
    } else {
      this.fetchLocationList();
      return;
      //check location is selected or not
      if (getSecureData(Constants.LOCATIONOBJECT)) {
        let locationObject = getSecureData(Constants.LOCATIONOBJECT);
        /* comment for order type */
        var arry = [];
        if (locationObject.isDelivery) {
          arry.push({ title: Strings.Delivery_web, isSelect: false });
        }
        if (locationObject.isTakeAway) {
          arry.push({ title: Strings.lbl_takeaway_react, isSelect: false });
        }
        if (locationObject.isOrderExtras) {
          arry.push({ title: Strings.str_collect_drink, isSelect: false });
        }
        // this.setState({isVisible: false, arrayOrderTypes: arry })
        this.setState({ isVisible: false, arrayOrderTypes: arry }, () => {
          setTimeout(() => {
            this.callGetCategoryListApi();
          }, 500);
        });
      } else {
        this.fetchLocationList();
      }
    }
  }

  fetchLocationList() {
    this.props.locationListApi(this.state.languageType).then(() => {
      if (this.props.data.status == 1) {
        if (this.props.data.result != null) {
          var locationList = this.props.data.result;
          if (locationList != null && locationList.length > 0) {
            if (getSecureData(Constants.LOCATIONOBJECT)) {
              // console.log('location object  ----------- ',getSecureData(Constants.LOCATIONOBJECT))
              // console.log('locationList  ----------- ',locationList)

              let objLocation = locationList.filter((obj) => {
                return (
                  obj.locationId ==
                  getSecureData(Constants.LOCATIONOBJECT).locationId
                );
              });

              // console.log('locationList  ----------- ',objLocation)

              // if (getSecureData(Constants.LOCATIONOBJECT).locationId != item.locationId) {
              //   console.log('hdfuehduehduheudheudhuehdu')
              //     this.setState({isVisible:false, selectIndex: index, locationObj: item, orderType: true, isAlertVisible:true, alertMessage:Strings.empty_cart_message, isMultiButtom:true, btnLeft:Strings.btn_cancel, btnRight:Strings.btn_confirm, })

              // }

              // console.log('location object  ----------- ',getSecureData(Constants.LOCATIONOBJECT))
              // console.log('select location object  ----------- ',item)

              // if (getSecureData(Constants.LOCATIONOBJECT).locationId != item.locationId) {
              //   console.log('hdfuehduehduheudheudhuehdu')
              //     this.setState({isVisible:false, selectIndex: index, locationObj: item, orderType: true, isAlertVisible:true, alertMessage:Strings.empty_cart_message, isMultiButtom:true, btnLeft:Strings.btn_cancel, btnRight:Strings.btn_confirm, })

              // } else {

              //     this.setState({ selectIndex: index, locationObj: item, orderType: true },()=>{
              //         this.setLocationData()
              //     })

              // }

              this.setState({ locationObj: objLocation[0] }, () => {
                setSecureData(Constants.LOCATIONOBJECT, objLocation[0]);

                if (getSecureData(Constants.ORDERTYPE) == undefined) {
                  this.setLocationData();
                }

                setTimeout(() => {
                  this.callGetCategoryListApi();
                }, 500);
              });
            } else {
              if (locationList.length == 1) {
                this.setState({ locationObj: locationList[0] }, () => {
                  setSecureData(Constants.LOCATIONOBJECT, locationList[0]);

                  if (getSecureData(Constants.ORDERTYPE) == undefined) {
                    this.setLocationData();
                  }
                });
              } else {
                setSecureData(Constants.LOCATIONLISTOBJECT, locationList);
                console.log("");

                //set location bydefault Culto not-veranda 363
                let item = locationList.find((obj) => {
                  return obj.locationId == 363;
                });
                let index = 1;
                console.log("VNK ITEM", item);
                if (getSecureData(Constants.USERCART)) {
                  // console.log('location object  ----------- ',getSecureData(Constants.LOCATIONOBJECT))
                  // console.log('select location object  ----------- ',item)

                  if (
                    getSecureData(Constants.LOCATIONOBJECT).locationId !=
                    item.locationId
                  ) {
                    // console.log('hdfuehduehduheudheudhuehdu')
                    this.setState({
                      isVisible: false,
                      selectIndex: index,
                      locationObj: item,
                      orderType: true,
                      isAlertVisible: true,
                      alertMessage: Strings.empty_cart_message,
                      isMultiButtom: true,
                      btnLeft: Strings.btn_cancel,
                      btnRight: Strings.btn_confirm,
                    });
                  } else {
                    this.setState(
                      {
                        selectIndex: index,
                        locationObj: item,
                        orderType: true,
                      },
                      () => {
                        this.setLocationData();
                      }
                    );
                  }
                } else {
                  this.setState(
                    { selectIndex: index, locationObj: item, orderType: true },
                    () => {
                      this.setLocationData();
                    }
                  );
                }
                this.setState({ locationArray: locationList });
                //VNK
              }
            }
          }
        }
      } else {
        // alert(this.props.data.message)
        console.log("Location else 405 method call here");
      }
    });
  }

  callGetCategoryListApi() {
    console.log(
      "callGetCategoryListApi",
      getSecureData(Constants.LOCATIONOBJECT)
    );
    if (
      getSecureData(Constants.LOCATIONOBJECT) == null ||
      getSecureData(Constants.LOCATIONOBJECT) == undefined
    ) {
      return;
    }

    this.props
      .getCategoriesAPICall(this.state.languageType, this.state.customerId)
      .then(() => {
        console.log("Category response ---- ", this.props.data);
        if (this.props.data.status == 1) {
          if (this.props.data.result != null) {
            // setTimeout(() => {
            console.log(
              "MenuCategoryList",
              this.props.data.result.MenuCategoryList
            );
            if (this.props.data.result.MenuCategoryList?.length > 0) {
              console.log(this.props.data.result.MenuCategoryList);
              this.setState({
                arrayMainCategories: this.props.data.result.MenuCategoryList,
                catId: this.props.data.result.MenuCategoryList[0].catId,
                arraySubCategory:
                  this.props.data.result.MenuCategoryList[0].subCategory,
                arrayMenuItems: this.props.data.result.MenuItemList,
                noProFound: true,
              });
              // console.log("hiiii arrayWholeMenuItems")
              setSecureData(
                "arrayWholeMenuItems",
                this.props.data.result.MenuItemList
              );
              // localStorage.setItem(Constants.CATEGORIESDATA,JSON.stringify(this.props.data.result))
              if (this.props.data.result.MenuCategoryList.length > 0) {
                if (
                  this.props.data.result.MenuCategoryList[0].subCategory
                    .length > 0
                ) {
                  this.setState({
                    subCatActiveId:
                      this.props.data.result.MenuCategoryList[0].subCategory[0]
                        .catId,
                  });
                } else {
                  // this.props.data.result.MenuItemList.map((val,i)=>{
                  //     if(val.catId == this.props.data.result.MenuCategoryList[0].catId){
                  // this.setState({subCatActiveId : this.props.data.result.MenuCategoryList[0].catId})
                  //     }
                  // })
                }
              }
              setSecureData(
                Constants.CATEGORIESDATA,
                this.props.data.result.MenuCategoryList
              );
              // window.location.reload(false)
              // this.getCategoryItem()
              this.getRestoInfo();
            }
            // }, 100);
          }
        } else {
          // alert(this.props.data.message);
          console.log("Category status 2 ---- ", this.props.data);
        }
      });
  }

  getCategoryItem() {
    this.state.arraySubCategory = [];
    this.state.arrayFilterTypes = [];

    if (!this.state.arrayMainCategories) {
      return;
    }
    let myCatData = this.state.arrayMainCategories.filter(
      (data) => data.catId == this.state.catId
    );
    if (myCatData.length == 0) {
      return;
    }
    categoryName = myCatData[0].catName;
    setTimeout(() => {
      this.props
        .getCategoriesMenuItemsAPICall(
          this.state.catId,
          this.state.languageType
        )
        .then(() => {
          console.log(
            "CATEGORIES MENU ITEMS RESPONSE --------- ",
            this.props.data.result
          );
          if (this.props.data.status == 1) {
            if (this.props.data.result != null) {
              setTimeout(() => {
                if (
                  typeof this.props.data.result.MenuCategoryList.length !==
                    "undefined" &&
                  this.props.data.result.MenuCategoryList.length == 0
                ) {
                  this.setState({
                    isNo: true,
                    arrayMenuItems: this.props.data.result.MenuItemList,
                    arrayWholeMenuItems: this.props.data.result.MenuItemList,
                  });

                  // setSecureData('arrayWholeMenuItems',this.props.data.result.MenuItemList)

                  var filterTitle = [];
                  this.state.arrayMenuItems.map((menuData) =>
                    menuData.MenuStausList.map((status) => {
                      var obj = filterTitle.filter(
                        (flt) => flt.title == status.title
                      );
                      if (obj.length == 0) {
                        let fObj = {
                          title: status.title,
                          iconurl: status.iconurl,
                          iconurlDark: status.iconurlDark,
                          isSelected: true,
                        };
                        filterTitle.push(fObj);
                      }
                    })
                  );
                  console.log("resource ------------------ ", filterTitle);
                  this.setState({ arrayFilterTypes: filterTitle });
                } else if (this.props.data.result.MenuCategoryList.length > 0) {
                  this.setState({
                    arraySubCategory: this.props.data.result.MenuCategoryList,
                    arrayMenuItems: this.props.data.result.MenuItemList,
                    arrayWholeMenuItems: this.props.data.result.MenuItemList,
                  });
                  // console.log("hiiii arrayWholeMenuItems")
                  // setSecureData('arrayWholeMenuItems',this.props.data.result.MenuItemList)
                }
                if (window.screen.width < 768) {
                  window.scrollTo(0, 880);
                } else {
                  window.scrollTo(0, 0);
                }
              }, 100);
            }
          } else {
            console.log("api errrrror");
          }
        });
    }, 500);
  }

  addRemoveFavItem = (data, url) => {
    console.log("addRemoveFavItem", data, url);
    // return
    var data = {
      restaurantId: 147,
      languageId: getSecureData(Constants.PRE_LANGUAGE_TYPE),
      customerId:
        getSecureData(Constants.USEROBJECT) != undefined
          ? getSecureData(Constants.USEROBJECT).customerId
          : 0,
      locationId:
        getSecureData(Constants.LOCATIONOBJECT) != undefined
          ? getSecureData(Constants.LOCATIONOBJECT).locationId
          : 1,
      menuitemId: data.menuitemId,
      url: url,
    };

    var MenuItemList = this.state.arrayMenuItems;

    var likedData = MenuItemList.map((item) => {
      if (item.menuitemId == data.menuitemId) {
        if (url == "addFavorite") {
          item.isFavoriteMenu = true;
        } else {
          item.isFavoriteMenu = false;
        }
      }
      return item;
    });

    this.setState({ arrayMenuItems: likedData });

    this.props.addRemoveFavItem(data).then(() => {
      if (this.props.data.status == 1) {
        if (this.props.data.result != null) {
        }
      }
    });
  };

  onAddClick(i, menuitemId, subCatId) {
    // catData.categotytime[0].openingtime > this.state.currentTime  || catData.categotytime[0].closingtime < this.state.currentTime

    // this.setState({ isItemPopupVisible: true, objectAt: i })
    // return

    console.log("arrayMenuItems", subCatId);

    // let myCatData = this.state.arrayMainCategories.filter(data => (data.catId == this.state.catId))
    var GETRESTOINFO = getSecureData(Constants.GETRESTOINFO);
    console.log(
      "getSecureData(Constants.GETRESTOINFO)",
      getSecureData(Constants.GETRESTOINFO)
    );
    if (GETRESTOINFO == undefined) {
      console.log("ukdate time not found");
      return;
    }
    // console.log("myCatData", catData, menuitemId, myCatData,this.state.currentTime)
    let uktime = moment(GETRESTOINFO.ukdatetime, "YYYY-MM-dd HH:mm:ss").format(
      "HH:mm:ss"
    );
    let ukdate = moment(GETRESTOINFO.ukdatetime, "YYYY-MM-DD HH:mm:ss").format(
      "DD-MM-YYYY"
    );

    // let uktime = moment(myCatData[0].ukdatetime, "YYYY-MM-dd HH:mm:ss").format("HH:mm:ss")
    // let ukdate = moment(myCatData[0].ukdatetime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")

    this.props
      .checkItemTimeAPI(uktime, ukdate, subCatId, this.state.languageType)
      .then(() => {
        if (this.props.data.status == 1) {
          if (this.props.data.result != null) {
            // if(catData[0].openingtime <= this.state.currentTime  <= catData[0].closingtime){
            setTimeout(() => {
              console.log("Clicked at menuitemId ", menuitemId);
              if (this.state.arraySubCategory.length > 0) {
                for (var k = 0; k < this.state.arrayMenuItems.length; k++) {
                  if (this.state.arrayMenuItems[k].menuitemId == menuitemId) {
                    i = k;
                    break;
                  }
                }
              } else {
                for (var k = 0; k < this.state.arrayMenuItems.length; k++) {
                  if (this.state.arrayMenuItems[k].menuitemId == menuitemId) {
                    i = k;
                    break;
                  }
                }
              }
              console.log("Clicked at ", i);
              this.setState({ isItemPopupVisible: true, objectAt: i });
            }, 100);

            // }
            // else{
            // var msg = 'Sorry you can not add this item to your bascket as opening time is from '+catData[0].openingtime+' to '+catData[0].closingtime
            // this.setState({isAlertVisible: true, alertMessage:msg, isMultiButton: false, btnLeft:Strings.Ok_web})
            // }
          }
        } else {
          this.setState({
            isAlertVisible: true,
            alertMessage: this.props.data.message,
            isMultiButton: false,
            btnLeft: Strings.Ok_web,
          });
        }
      });
  }

  renderOptions(optlist, title) {
    let filrt = optlist.filter((data) => data.labelText == title);

    var arrOpt = [];
    for (var i = 0; i < filrt.length; i++) {
      arrOpt.push(
        <li key={i}>
          <label className="container1">
            <input type="checkbox" checked="checked" />
            <span className="checkmark"></span>
            {filrt[i].type}
          </label>
        </li>
      );
    }
    return arrOpt;
  }

  renderOptionMenu(item, index) {
    console.log("index ------ ", item.menuItemName, index);

    var arrayTitle = [];
    let optList = this.state.arrayMenuItems[index].PriceList[0].OptionList;

    optList.map((data) => {
      var temp = arrayTitle.filter((obj) => obj == data.labelText);
      if (temp.length == 0) {
        arrayTitle.push(data.labelText);
      }
    });
    console.log("arrayTitle ------ ", arrayTitle.length, arrayTitle);

    var arrReturn = [];
    for (var k = 0; k <= arrayTitle.length - 1; k++) {
      console.log("plate-div ----- ", k, optList);
      arrReturn.push(
        <div className="plate-div" key={k}>
          <h4>{arrayTitle[k]}</h4>
          <ul>{/* {this.renderOptions(optList,arrayTitle[k])} */}</ul>
        </div>
      );
    }
    return arrReturn;
  }

  renderMenuStatus(status) {
    let obj = [];
    status.map((data, index) =>
      obj.push(
        <li key={index}>
          <img src={bindhttps(data.iconurl)} />
          {/* <a href="#">
           
          </a> */}
        </li>
      )
    );
    return obj;
  }

  renderMenuItems(menuItem) {
    // console.log("menuItem",menuItem)
    const itemDiv = menuItem.map((item, index) => (
      <div className="col-lg-4 col-md-12 mb-5" key={index}>
        {/* { console.log("menuitemdata",item) } */}
        <div className="prd-s">
          <div className="prd-img">
            {/* <img src="../images/prdmainimg.jpg"  onClick={() => { this.onAddClick(index, item.menuitemId) }}/> */}
            {/* {item.imgurl ? <img src={bindhttps(item.imgurl)}  onClick={() => { this.onAddClick(index, item.menuitemId) }}/> : null} */}
            <img
              src={item.imgurl ? bindhttps(item.imgurl) : "../images/no.jpg"}
              onClick={() => {
                this.onAddClick(index, item.menuitemId);
              }}
            />
            {/* <a href="">
              
            </a> */}
          </div>
          <div className="prd-cont">
            <ul className="vegi">
              {this.renderMenuStatus(item.MenuStausList)}
            </ul>

            <h3
              onClick={() => {
                this.onAddClick(index, item.menuitemId);
              }}
              className="prdTitle"
            >
              {item.menuItemName}
            </h3>
            <p className="prdDesc">{item.description}</p>
            <div className="price-btns-details">
              <p className="lbl">{getCurrencySign()}</p>
              <p className="prdPriceAmt">
                {item.PriceList[0].price.toFixed(2)}
              </p>
              <a
                href=""
                // className="btn btn-primary btn-medium btn-price"
                type="button"
                // className="btn btn-primary btn-price"
                className="greenBtn smallBtnLbl qty"
                data-toggle="modal"
                data-target="#myModal"
                key={index}
                onClick={() => {
                  this.onAddClick(index, item.menuitemId);
                }}
              >
                {Strings.lbl_edit_add_react}
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
    return itemDiv;
  }

  renderMenuItemsUlLi(menuItem, subCat) {
    const itemDiv = menuItem.map((item, index) => (
      <li>
        {console.log("menuItemSubCatId", item)}
        <div className="row align-items-center">
          <div className="col-md-6 col-sm-6 col-xs-6">
            <p>{item.menuItemName}</p>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 text-right">
            {this.state.customerId > 0 ? (
              <div className="menuItemFavLi">
                {item.isFavoriteMenu ? (
                  <div
                    onClick={() => {
                      this.addRemoveFavItem(item, "DeleteFavorite");
                    }}
                    className="heartIconDiv "
                  >
                    <i
                      class="fa fa-heart"
                      style={{ color: "#c1e1d5" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      this.addRemoveFavItem(item, "addFavorite");
                    }}
                    className="heartIconDiv "
                  >
                    <i
                      class="fa fa-heart-o"
                      style={{ color: "#c1e1d5" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                )}
              </div>
            ) : null}
            <spna className="priceCurrencyBox">
              <p className="lbl">{getCurrencySign()}</p>
              <p className="prdPriceAmt">
                {item.PriceList[0].price.toFixed(2)}
              </p>
            </spna>

            <button
              class="btn btn-light cartAddBtn"
              type="button"
              id="button-minus"
              key={index}
              onClick={() => {
                this.onAddClick(index, item.menuitemId, item.catId);
              }}
            >
              {/* <i class="fa fa-plus"></i>  */}
            </button>
          </div>
        </div>
      </li>
    ));
    return <ul className="subCatFoodList">{itemDiv}</ul>;
  }

  onFilterClick(filterData) {
    filterData.isSelected = !filterData.isSelected;
    this.setState({ arrayFilterTypes: this.state.arrayFilterTypes });

    let flt = this.state.arrayFilterTypes.filter(
      (data) => data.isSelected == false
    );

    if (flt.length == this.state.arrayFilterTypes.length) {
      // this.setState({ arrayMenuItems: this.state.arrayWholeMenuItems })

      let menuItem = this.state.arrayWholeMenuItems;
      var filteredItem = [];

      let newArrList = [];
      for (var i = 0; i < menuItem.length; i++) {
        const ele = menuItem[i].MenuStausList;
        // console.log('elements -------- ',ele)

        const arrList1 = ele.map((item) => item.title);
        const arrList2 = flt.map((item) => item.title);
        let isAdded = false;
        if (arrList1.length == 0) {
          newArrList.push(menuItem[i]);
        }
      }

      this.setState({ arrayMenuItems: newArrList });
    } else {
      if (flt.length > 0) {
        let menuItem = this.state.arrayWholeMenuItems;
        var filteredItem = [];

        let filtItem = menuItem.filter((objItem) => objItem.MenuStausList);
        // console.log('filter item ------- ',filtItem)

        let newArrList = [];
        for (var i = 0; i < menuItem.length; i++) {
          const ele = menuItem[i].MenuStausList;
          // console.log('elements -------- ',ele)

          const arrList1 = ele.map((item) => item.title);
          const arrList2 = flt.map((item) => item.title);
          let isAdded = false;
          if (arrList1.length == 0) {
            newArrList.push(menuItem[i]);
          }
          for (let index = 0; index < arrList1.length; index++) {
            const element = arrList1[index];
            let contains = arrList2.includes(element);
            if (contains == false) {
              isAdded = true;
            } else {
              isAdded = false;
              break;
            }
          }

          if (isAdded) {
            newArrList.push(menuItem[i]);
          }
        }

        this.setState({ arrayMenuItems: newArrList });

        return;
        var mStatus = [];
        var sStatus = [];
        for (var a = 0; a < flt.length; a++) {
          for (var i = 0; i < menuItem.length; i++) {
            for (var k = 0; k < menuItem[i].MenuStausList.length; k++) {
              if (flt[a].title == menuItem[i].MenuStausList[k].title) {
                let aObj = filteredItem.filter(
                  (aData) => aData.menuitemId == menuItem[i].menuitemId
                );
                if (aObj.length == 0) {
                  menuItem[i].MenuStausList.map((obj) => {
                    if (!mStatus.includes(obj.title)) mStatus.push(obj.title);
                  });
                  console.log("mStatus ------- ", mStatus);

                  flt.map((obj) => {
                    if (!sStatus.includes(obj.title)) sStatus.push(obj.title);
                  });
                  console.log("sStatus ------- ", sStatus);

                  if (mStatus == sStatus) filteredItem.push(menuItem[i]);
                  // if(menuItem[i].MenuStausList.length == flt.length) {
                  //   console.log('filteredItem if ------------ ',menuItem[i].MenuStausList,flt)

                  //   flt.map(obj => {
                  //     if (!mStatus.includes(obj.title))
                  //       mStatus.push(obj.title)
                  //   })
                  //   console.log('mStatus ------- ',mStatus)

                  //   menuItem[i].MenuStausList.map(obj => {
                  //     if (!sStatus.includes(obj.title))
                  //       sStatus.push(obj.title)
                  //   })
                  //   console.log('mStatus ------- ',sStatus)
                  //   // for (var mi = 0; mi < menuItem[i].MenuStausList.length; mi++) {
                  //   //   for (var vo = 0; vo < menuItem.length; vo++) {

                  //   //   }
                  //   // }
                  // } else {
                  //   console.log('filteredItem else ------------ ',menuItem[i].MenuStausList,flt)
                  // }
                }
              }
            }
          }
        }

        this.setState({ arrayMenuItems: filteredItem });
      } else {
        this.setState({ arrayMenuItems: this.state.arrayWholeMenuItems });
      }
    }
  }

  renderFilter() {
    // console.log('filter view ------ ',this.state.arrayFilterTypes)
    let obj = this.state.arrayFilterTypes.map((data, index) => (
      <li className={data.isSelected == true ? "active" : ""}>
        <a
          onClick={() => {
            this.onFilterClick(data);
          }}
          key={index}
        >
          {/* {data.title} {(data.isSelected == true) ? <img src={data.iconurlDark} /> : <img src={data.iconurl} />} */}
          {data.isSelected == true ? (
            <img src={data.iconurl} />
          ) : (
            <img src={data.iconurl} />
          )}{" "}
          {data.title}
        </a>
      </li>
    ));
    return <ul className="list-btn">{obj}</ul>;
  }

  onSelectCategory(id) {
    this.setState({ catId: id, isNo: false });
    // localStorage.setItem(Constants.PRE_CAT_ID,JSON.stringify(id))
    setSecureData(Constants.PRE_CAT_ID, id);
    // setTimeout(() => {
    //   this.getCategoryItem()
    // }, 200)

    var arraySubCategory = this.state.arrayMainCategories.filter(
      (item) => item.catId == id
    );

    this.setState({
      catId: id,
      arraySubCategory:
        arraySubCategory.length > 0 ? arraySubCategory[0].subCategory : [],
    });

    setSecureData(Constants.CATEGORIESDATA, this.state.arrayMainCategories);
    // localStorage.setItem(Constants.CATEGORIESDATA,JSON.stringify(this.props.data.result))
    // setSecureData(Constants.CATEGORIESDATA,this.props.data.result.MenuCategoryList)
  }

  renderCategoryTitle() {
    if (!this.state.arrayMainCategories) {
      return;
    }
    return this.state.arrayMainCategories.map((category) =>
      this.state.catId == category.catId ? (
        <>
          <h1 className="stylMmenuTitle">
            {"Home >>"} {category.catName}
          </h1>
        </>
      ) : null
    );
  }

  getCountMenuItemByCatID = (catID) => {
    let subCategory = this.state.arrayMainCategories.find(
      (value) => value.catId === catID
    )?.subCategory;
    const allSubCategoryIds = subCategory.map((value) => value.catId);
    let count = 0;
    this.state.arrayMenuItems.forEach((value) => {
      if (allSubCategoryIds.includes(value.catId)) {
        count++;
      }
    });

    return ` (${count})`;
  };

  renderCategoryView() {
    console.log(
      "this.state.arrayMainCategories",
      this.state.arrayMainCategories
    );
    let catArray = [];
    this.state.arrayMainCategories.map((category) =>
      catArray.push(
        <li key={category.catId}>
          {this.state.catId == category.catId ? (
            <a
              data-toggle="tab"
              className="active"
              onClick={() => {
                this.onSelectCategory(category.catId);
              }}
            >
              <p className="active">
                {category.catName}
                {/* {category.subCategory.length > 0 ? `(${category.subCategory.length})` : '' } */}
                {this.getCountMenuItemByCatID(category.catId)}
              </p>
            </a>
          ) : (
            <a
              data-toggle="tab"
              onClick={() => {
                this.onSelectCategory(category.catId);
              }}
            >
              <p>
                {category.catName}
                {/* {category.subCategory.length > 0 ? `(${category.subCategory.length})` : '' } */}
                {this.getCountMenuItemByCatID(category.catId)}
              </p>
            </a>
          )}
        </li>
      )
    );
    return <ul className="nav tabs-left sideways mt-5">{catArray}</ul>;
  }

  renderSubCategoryView1() {
    let catObjects = [];
    this.state.arraySubCategory.map((catData) => {
      var filt = this.state.arrayMenuItems.filter(
        (menuItem) => menuItem.catId == catData.catId
      );
      catObjects.push({
        subCatId: catData.catId,
        catTitle: catData.catName,
        categotytime: catData.categotytime,
        menuItems: filt,
      });
    });
    // {
    //   category.subCategory.map(subCategory =>(
    //     <p>{subCategory.catName}</p>
    //   ))
    // }
    var objFinal = [];
    catObjects.map((catData, index) => {
      {
        var minOpeningTime1 =
          catData.categotytime.length > 1
            ? catData.categotytime.reduce((prev, curr) =>
                prev.openingtime < curr.openingtime
                  ? prev.openingtime
                  : curr.openingtime
              )
            : catData.categotytime[0].openingtime;
      }
      {
        var maxClosingTime1 =
          catData.categotytime.length > 1
            ? catData.categotytime.reduce((prev, curr) =>
                prev.closingtime > curr.closingtime
                  ? prev.closingtime
                  : curr.closingtime
              )
            : catData.categotytime[0].closingtime;
      }
      objFinal.push(
        <div className="dr1">
          {/* {console.log("dhruvv", catData)} */}
          {/* <h3 className="subCategoryTitle">{catData.catTitle}</h3> */}
          {catData.categotytime.length > 0 &&
          minOpeningTime1 &&
          maxClosingTime1 ? (
            minOpeningTime1 > this.state.currentTime ||
            maxClosingTime1 < this.state.currentTime ? (
              <>
                <Collapsible
                  trigger={catData.catTitle + " (Currenty Closed)"}
                  id={index}
                  open={index == 0 ? true : false}
                >
                  {this.renderMenuItemsUlLi(catData.menuItems, catData)}
                </Collapsible>
              </>
            ) : (
              <Collapsible
                trigger={catData.catTitle}
                id={index}
                open={index == 0 ? true : false}
              >
                {this.renderMenuItemsUlLi(catData.menuItems, catData)}
              </Collapsible>
            )
          ) : (
            <Collapsible
              trigger={catData.catTitle}
              id={index}
              open={index == 0 ? true : false}
            >
              {this.renderMenuItemsUlLi(catData.menuItems, catData)}
            </Collapsible>
          )}
        </div>
      );
    });
    return objFinal;
  }

  renderSubCategoryView() {
    let catObjects = [];
    if (this.state.arraySubCategory.length > 0) {
      this.state.arraySubCategory.map((catData) => {
        var filt = this.state.arrayMenuItems.filter(
          (menuItem) => menuItem.catId == catData.catId
        );
        catObjects.push({
          subCatId: catData.catId,
          catTitle: catData.catName,
          categotytime: catData.categotytime,
          menuItems: filt,
        });
      });
    } else {
      this.state.arrayMainCategories.map((catData) => {
        if (catData.catId == this.state.catId) {
          var filt = this.state.arrayMenuItems.filter(
            (menuItem) => menuItem.catId == this.state.catId
          );
          catObjects.push({
            subCatId: catData.catId,
            catTitle: catData.catName,
            categotytime: catData.categotytime,
            menuItems: filt,
          });
        }
      });
    }
    var objFinal = [];
    catObjects.forEach((catData, index) => {
      {
        var minOpeningTime =
          catData.categotytime?.length > 1
            ? catData.categotytime.reduce((prev, curr) =>
                prev.openingtime < curr.openingtime
                  ? prev.openingtime
                  : curr.openingtime
              )
            : catData.categotytime[0]?.openingtime;
      }
      {
        var maxClosingTime =
          catData.categotytime.length > 1
            ? catData.categotytime?.reduce((prev, curr) =>
                prev.closingtime > curr.closingtime
                  ? prev.closingtime
                  : curr.closingtime
              )
            : catData.categotytime[0]?.closingtime;
      }
      catData.menuItems.length > 0 &&
        objFinal.push(
          <div className="dr1">
            {/* <h3 className="subCategoryTitle">{catData.catTitle}</h3> */}
            {console.log("dhruvv2", catData)}
            {console.log("dhruvv2 current time", this.state.currentTime)}
            {console.log("dhruvv2 minOpeningTime", minOpeningTime)}
            {console.log("dhruvv2 maxClosingTime", maxClosingTime)}
            {catData.categotytime.length > 0 &&
            minOpeningTime &&
            maxClosingTime ? (
              minOpeningTime > this.state.currentTime ||
              maxClosingTime < this.state.currentTime ? (
                <>
                  <Collapsible
                    trigger={catData.catTitle + " (Currenty Closed)"}
                    id={index}
                    open={index == 0 ? true : false}
                  >
                    {this.renderMenuItemsUlLi(catData.menuItems, catData)}
                  </Collapsible>
                </>
              ) : (
                <Collapsible
                  trigger={catData.catTitle}
                  id={index}
                  open={index == 0 ? true : false}
                >
                  {this.renderMenuItemsUlLi(catData.menuItems, catData)}
                </Collapsible>
              )
            ) : (
              <Collapsible
                trigger={catData.catTitle}
                id={index}
                open={index == 0 ? true : false}
              >
                {this.renderMenuItemsUlLi(catData.menuItems, catData)}
              </Collapsible>
            )}
          </div>
        );
    });
    return objFinal;
  }

  renderShoppingBasket() {
    return (
      <div className="shoppingCart">
        <div className="cartFoodList">
          <p>You shopping basket is empty!</p>
        </div>
        <hr />
        <div className=""></div>
      </div>
    );
  }

  onViewOrderClick() {
    this.setState({ isAlertVisible: false }, () => {
      this.props.history.push({ pathname: "/menuItems" });
    });
  }

  renderBanner() {
    if (!this.state.arrayMainCategories) {
      return;
    }
    let myCatData = this.state.arrayMainCategories.filter(
      (data) => data.catId == this.state.catId
    );

    if (myCatData.length > 0) {
      return myCatData[0].bannerfullimgurl ? (
        <img src={bindhttps(myCatData[0].bannerfullimgurl)} className="w-100" />
      ) : (
        <img src="/images/dishes-banner.jpg" className="w-100" />
      );
    }
  }

  /* comment for order type */
  onContinueOrder(order) {
    if (order.length > 0) {
      if (order[0].title == Strings.Delivery_web) {
        Constants.OrderType = Strings.Delivery_web;
        Constants.OrderTypeID = 2;
      } else if (order[0].title == Strings.lbl_takeaway_react) {
        Constants.OrderType = Strings.lbl_takeaway_react;
        Constants.OrderTypeID = 1;
      } else if (order[0].title == Strings.str_collect_drink) {
        Constants.OrderType = Strings.str_sitDown;
        Constants.OrderTypeID = 3;
      }
    }
    setSecureData(Constants.ORDERTYPE, Constants.OrderType);
    setSecureData(Constants.ORDERTYPEID, Constants.OrderTypeID);
    // return
    this.setState({ isOrderTypeVisible: false, cartUpdate: true }, () => {
      setTimeout(() => {
        this.callGetCategoryListApi();
      }, 500);
    });
  }

  onSelectTypes(order) {
    this.state.arrayOrderTypes.map((data) => {
      if (data.title == order.title) {
        data.isSelect = true;
        Constants.OrderType = data.title;
      } else {
        data.isSelect = false;
      }
    });
    this.setState({ arrayOrderTypes: this.state.arrayOrderTypes });

    let obj = this.state.arrayOrderTypes.filter(
      (data) => data.isSelect == true
    );

    if (obj.length > 0) {
      this.onContinueOrder(obj);
      // Constants.OrderType = obj[0].title
      // console.log('Constants.OrderType = obj[0].title --------- ', Constants.OrderType)
      // return (
      //     <div className="continue-btn">
      //         <button className="btn btn-primary btn-small order-btn" onClick={() => { this.onContinueOrder(obj) }}>{Strings.btn_continue_react}</button>
      //     </div>
      // )
    }
  }

  renderDeliveryMsg() {
    if (this.state.arrayOrderTypes.length > 0) {
      if (this.state.locationObj.isDelivery) {
        return <p className="infomsg">{Strings.msgDeliverylabel}</p>;
      } else {
        return <p className="infomsg">{Strings.msgSelectlabel}</p>;
      }
    }
  }

  renderOrderType() {
    if (this.state.arrayOrderTypes.length == 0) {
      return <label>{Strings.error_msg_no_order_type_react}</label>;
    }
    var arry = [];
    this.state.arrayOrderTypes.map((data, index) => {
      arry.push(
        <div
          className="optList"
          onClick={() => {
            this.onSelectTypes(data);
          }}
          key={index}
        >
          <h3>{data.title}</h3>
        </div>
      );
    });
    return arry.reverse();
  }

  renderButton() {
    let obj = this.state.arrayOrderTypes.filter(
      (data) => data.isSelect == true
    );
    if (obj.length > 0) {
      Constants.OrderType = obj[0].title;
      return (
        <div className="continue-btn">
          <button
            className="btn btn-primary btn-small order-btn"
            onClick={() => {
              this.onContinueOrder(obj);
            }}
          >
            {Strings.btn_continue_react}
          </button>
        </div>
      );
    }
  }
  renderOrderSelectionView() {
    return (
      <Modal
        show={this.state.isOrderTypeVisible}
        onHide={() => {}}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popupHeader">
            <label className="headerTitle">
              {Strings.lbl_select_order_type_react}
            </label>
          </div>
          <div className="pop loc-popup" id="mypop">
            <div className="pop-dialog">
              <div className="pop-content">
                <div className="pop-body">
                  <div className="prd-s">
                    <div className="prd-cont">
                      {this.renderDeliveryMsg()}
                      {this.renderOrderType()}
                    </div>
                  </div>
                  {this.renderButton()}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  renderMenuItemWithSubCategory = () => {
    return this.state.arrayMenuItems.length > 0 ? (
      <div className="tab-pane drinks active if" id="settings-2">
        {this.renderSubCategoryView()}
      </div>
    ) : this.state.noProFound ? (
      <label className="noitem">No products found</label>
    ) : null;
  };

  rerenderParentCallback() {
    this.forceUpdate();
  }

  render() {
    return (
      <>
        {this.props.isLoading ? (
          <div className="loadermain" style={{ display: "block" }}>
            <div className="loader"></div>
          </div>
        ) : (
          <div className="loadermain" style={{ display: "none" }}>
            <div className="loader"></div>
          </div>
        )}

        <HeaderWhiteMenuHalf
          logoType={1}
          headerLbl={"Menu"}
          isMenu={true}
          changeLanguageEvent={this.languageChangeClickEvent}
          isCategory={true}
        />
        <AlertDialog
          isAlertVisible={this.state.isAlertVisible}
          msgTitle={this.state.alertMessage}
          isMultibutton={this.state.isMultiButton}
          btnLeftClick={() => {
            this.setState({ isAlertVisible: false });
          }}
          btnRightClick={() => {
            this.onViewOrderClick();
          }}
          btnLeftTitle={this.state.btnLeft}
          btnRightTitle={this.state.btnRight}
        />

        {this.state.isItemPopupVisible ? (
          <ItemPopup
            itemData={this.state.arrayMenuItems[this.state.objectAt]}
            onClose={(popup) => {
              console.log(popup);
              this.setState({
                isItemPopupVisible: false,
                isAlertVisible: popup.isHide,
                alertMessage: popup.isNewItem
                  ? Strings.error_msg_item_added_react
                  : Strings.error_msg_item_update_react,
                btnLeft: Strings.Ok_web,
                isMultiButton: false,
                cartUpdate: true,
                btnRight: Strings.btn_view_order_react,
              });
            }}
            isVisible={this.state.isItemPopupVisible}
            isEdit={false}
            isFavShow={true}
            onAddDlt={(menuItem, url) => {
              this.addRemoveFavItem(menuItem, url);
            }}
          />
        ) : null}

        {this.state.isVisible ? this.showLocationListDialog() : null}
        {/* comment for order type */}
        {this.state.isOrderTypeVisible ? this.renderOrderSelectionView() : null}
        <div className="inner-banner">
          {/* {this.renderBanner()} */}
          <div className="inner-banner-cont">
            <div className="container">
              {/* <h1>{categoryName}</h1> */}
              {/* {this.renderFilter()} */}
            </div>
          </div>
        </div>
        <div className="content_wrap">
          <div className="dishes-page cat-page">
            <div class="container">
              <div className="row">
                <div className="col-xl-2 col-lg-12 col-md-12 ">
                  <div className="leftSideBox">
                    <h4>CATEGORIES</h4>
                    <span className="zig-zag-ui"></span>
                    {this.state.arrayMainCategories
                      ? this.renderCategoryView()
                      : null}
                  </div>
                </div>

                <div className="col-xl-7 col-lg-7 col-md-7">
                  <div className="centerItemBox">
                    {this.renderCategoryTitle()}
                    <div className="tab-content-menuitem mt-5">
                      {this.renderMenuItemWithSubCategory()}
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-5 col-md-5">
                  <div className="rightSideBox">
                    <h4>ORDER SUMMARY</h4>
                    <span className="zig-zag-ui"></span>

                    <div className="mt-5">
                      <CheckoutDelivery
                        cartUpdate={this.state.cartUpdate}
                        callGetCategoryListApi={() =>
                          this.callGetCategoryListApi()
                        }
                        onResponse={() => this.setState({ cartUpdate: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log(
    "isLoaging Categories MENU Items------- " + state.HGReducers.isLoading
  );
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  locationListApi: (languageType) =>
    dispatch(HGActions.GetLocationListApi(languageType)),
  getCategoriesAPICall: (languageType, customerId) =>
    dispatch(HGActions.initGetAllCategoriesCall(languageType, customerId)),
  checkItemTimeAPI: (contactTime, orderDate, catId, languageType) =>
    dispatch(
      HGActions.initCheckMenuItemTimeAPI(
        contactTime,
        orderDate,
        catId,
        languageType
      )
    ),
  getCategoriesMenuItemsAPICall: (categories, languageType) =>
    dispatch(
      HGActions.initGetCategoriesMenuItemsCall(categories, languageType)
    ),
  AppMessageApi: (languageType) =>
    dispatch(HGActions.GetAppMessageListApi(languageType)),
  callCheckVersion: (customerId, languageType) =>
    dispatch(HGActions.callApiCheckVersionAPI(customerId, languageType)),
  getRestoInfo: (languageType) =>
    dispatch(HGActions.initGetRestoInfoAPI(languageType)),
  addRemoveFavItem: (data) => dispatch(HGActions.addRemoveFavItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItems);
