import React, { Component } from "react";
import { Strings } from "../constants/Localization";

export default class FooterMenu extends Component {
  render() {
    return (
      <footer>
        <div class="homepage-footer container">
          <div class="hmapover-social footerSocial">
            <ul>
              <li>
                <a href="https://www.facebook.com/Cultoitalian" target="_blank">
                  {/* <a href="https://www.facebook.com/Cultoitalian/" target="_blank"> */}
                  <i class="fa fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/cultoitalian" target="_blank">
                  {/* <a href="https://twitter.com/cultoitalian"  target="_blank"> */}
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/cultoitalian"
                  target="_blank"
                >
                  {/* <a href="https://www.instagram.com/cultoitalian"  target="_blank"> */}
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                {/* <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g186411-d8015118-Reviews-CULTO_ex_That_s_Amore-Leeds_West_Yorkshire_England.html"  target="_blank"> */}
                <a
                  href="https://www.tripadvisor.co.uk/Restaurant_Review-g186411-d8015118-Reviews-CULTO_ex_That_s_Amore-Leeds_West_Yorkshire_England.html"
                  target="_blank"
                >
                  <i class="fab fa-tripadvisor"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-address">
            <p>(T) +44 (0) 113 3454434</p>
            <p>(E) info@cultoitalian.co.uk</p>
            <p>97 Stainbeek Road, Leeds, LS7 2PR</p>
          </div>
        </div>
      </footer>
    );
  }
}
