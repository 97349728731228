import React, { Component } from "react";

import { withRouter } from 'react-router-dom';

import Constants from '../constants/baseConstants';
import Modal from 'react-bootstrap/Modal'
import { Strings } from "../constants/Localization";
import { getPriceFormat, getCurrencySign, bindhttps } from "../Utils/utils";
import { setSecureData, getSecureData } from '../Utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'


class ItemPopup extends Component {

  constructor(props) {
    super(props);

    let objItem = this.props.itemData
    var itemQty = 1
    var allPrice = 0.0

    if (this.props.isEdit) {
      itemQty = objItem.totalQty
      allPrice = objItem.totalPrice
      console.log('edit at ---- ', this.props.editIndex, objItem)
    } else {
      for (let i = 0; i <= objItem.PriceList.length - 1; i++) {

        if (i == 0) {
          objItem.PriceList[i].isSelect = true
          allPrice = objItem.PriceList[i].price
        } else {
          objItem.PriceList[i].isSelect = false
        }

        var objOptions = []
        objItem.PriceList[i].OptionList.map((data, index) => {
          //Check is all select or not
          if (data.isSelectAll) {
            data.isSelect = true
          } else {
            if (data.isCompulsory) {
              if (index == 0) {
                data.isSelect = true
              } else {
                data.isSelect = false
              }
            } else {
            }
          }
          objOptions.push(data)
        })
        objItem.PriceList[i].OptionList = objOptions
      }
    }

    this.state = {
      itemObject: objItem,
      qty: itemQty,
      totalPrice: allPrice,

      isAlertVisible: false,
      alertMessage: '',
      setPriceListActiveKey : 0,
      customerId : getSecureData(Constants.USEROBJECT) != null ? getSecureData(Constants.USEROBJECT).customerId : 0
    }

    // const mLanguageCode="en-GB"; 
  }

  componentDidMount() {
    // mLanguageCode = localStorage.getItem(Constants.PRE_LANGUAGE_CODE); 
    console.log('componentDidMount called from item popup ----- ', this.state.itemObject)
  }

  getNumberOfOptionsSelections = (item) => {
    console.log("item", item)
    if (item.length == 0) {
      return
    }
    var counter = 0
    for (let k = 0; k <= item.length - 1; k++) {
      if (item[k].isSelect) {
        counter += 1
      }
    }
    return counter
  }

  onOptClick(optData, l, index) {
    let objItem = this.state.itemObject
    console.log("setPriceListActiveKey",this.state.setPriceListActiveKey, l, index, optData, objItem)
    

    for (let i = 0; i < objItem.PriceList.length; i++) {

      if (objItem.PriceList[i].isSelect) {

        let options = objItem.PriceList[i].OptionList

        console.log("optionsList",options)
        
        for (let k = 0; k < options.length; k++) {

          for(let o = 0; o < options[k].subOption.length; o++) {
            if (optData.isSelectAll) {
              if (optData.isSelect) {
                if (options[k].subOption[o].typeid == optData.typeid) {
                  options[k].subOption[o].isSelect = false
                }
              } else {
                if (options[k].subOption[o].typeid == optData.typeid) {
                  options[k].subOption[o].isSelect = true
                  console.log('else optData.isSelect ----- ', k, options[k].subOption[o].typeid)
                }
              }
            } else {
              if (optData.isMultiselect) {    
                console.log('is multiselect')
                if (optData.maxselection > 0) {
                  let no = this.getNumberOfOptionsSelections(options[k].subOption)
                  console.log("max number of selection ---> " + no, optData.maxselection,optData.isSelect)
                  
                  if (optData.maxselection > no) {
                    if (optData.isSelect) {
                      console.log("in maxselection",optData)
                      // alert('you can not select maximum number of options out of ', optData.maxselection)
                      if (options[k].subOption[o].typeid == optData.typeid) {
                        options[k].subOption[o].isSelect = false
                      }
                    } else {
                      if (options[k].subOption[o].typeid == optData.typeid) {
                        options[k].subOption[o].isSelect = true
                      }
                    }
                  } else {
                    console.log("if")
                    
                    if (options[k].subOption[o].typeid == optData.typeid) {
                      console.log("options[k].subOption[o].typeid", no,options[k].subOption[o], optData,optData.maxselection,optData.isSelect)
                      console.log("if1",optData.isSelect)

                      
                      if (optData.maxselection == no && optData.isSelect == false) {
                        // alert('you can not select maximum number of options out of '+optData.maxselection)
                        alert(`You can select max ${optData.maxselection} options.`)
                      }

                      //   console.log("if2",optData.isSelect)

                      //   options[k].subOption[o].isSelect = false
                      // } else {
                        options[k].subOption[o].isSelect = false

                      //   console.log("if3")
                      // }
                    }
                  }
                } else {
                  console.log('optData.maxselection > 0 else')
                  if (options[k].subOption[o].typeid == optData.typeid) {
                    if (optData.isSelect) {
                      options[k].subOption[o].isSelect = false
                    } else {
                      options[k].subOption[o].isSelect = true
                    }
                  }
                }
              } else {
                console.log('is multiselect else')
                if (options[k].subOption[o].typeid == optData.typeid && options[k].subOption[o].labelText == optData.labelText) {
                  console.log('options[k].subOption[o].typeid == optData.typeid && options[k].subOption[o].labelText == optData.labelText')
                  if (optData.isSelect == true) {
                    options[k].subOption[o].isSelect = false
                  } else {
                    options[k].subOption[o].isSelect = true
                  }
                } else if (options[k].subOption[o].labelText == optData.labelText) {
                  console.log('options[k].subOption[o].labelText == optData.labelText')
                  options[k].subOption[o].isSelect = false
                }
              }
            }
          }
            
        }
        objItem.PriceList[i].OptionList = options
        console.log("optData updated", options)
        this.setState({ itemObject: objItem, totalPrice: this.countPrice() * this.state.qty })
        
      }
    }
  }

  renderOptions(title) {

    for (var i = 0; i < this.state.itemObject.PriceList.length; i++) {
      if (this.state.itemObject.PriceList[i].isSelect) {
        let optList = this.state.itemObject.PriceList[i].OptionList

        let filrt = optList.filter(data => (data.labelText == title))

        var arrOpt = []

        console.log("filrt",filrt,title)

        if(filrt.length > 0) {
          filrt[0].subOption.map((options, index) => {
            arrOpt.push(
              <a onClick={() => { this.onOptClick(options, i, index) }} key={index}>
                <li>
                  <button className="checked-btn">
                    {/* <img src={options.isSelect ? "/images/tick.png" : "/images/untick.png"} /> */}
                    {
                    options.isSelect 
                    ?
                    <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                    :
                    <i class="fa fa-circle-o" aria-hidden="true"></i>
                    }
                  </button>
                    <label className="container1 optValue k">
                    {options.type} {options.price > 0.0 ? getPriceFormat(options.price.toFixed(2)) : null}
                  </label>
                </li>
              </a>
            )
          })
        }

        return arrOpt
        break
      }
    }
  }

  onPriceClick(index) {
    console.log('select price type --- ', index)

    this.setState({setPriceListActiveKey : index})
    let obj = this.state.itemObject
    if (obj.PriceList.length == 1) {
      return
    }
    for (let i = 0; i < obj.PriceList.length; i++) {
      if (index != i) {
        obj.PriceList[i].isSelect = false
      } else {
        obj.PriceList[i].isSelect = true
        this.setState({ totalPrice: obj.PriceList[i].price })
      }

      var lst = obj.PriceList[i].OptionList
      for (let k = 0; k <= lst.length - 1; k++) {
        if (lst[k].isSelectAll) {
          obj.PriceList[i].OptionList[k].isSelect = true
        } else {
          if (lst[k].isCompulsory) {
            if (k > 0) {
              if (lst[k - 1].isCompulsory == true && obj.PriceList[i].OptionList[k].labelText == lst[k - 1].labelText) {
                obj.PriceList[i].OptionList[k].isSelect = false
              } else {
                obj.PriceList[i].OptionList[k].isSelect = true
              }
            } else {
              obj.PriceList[i].OptionList[k].isSelect = true
            }
          } else {
            obj.PriceList[i].OptionList[k].isSelect = false
          }
        }
      }
    }

    // let priceFilter = this.state.itemObject.PriceList.filter((price) => (price.isSelect == true))
    // var plPrice = priceFilter[0].price

    // let optionFilter = priceFilter[0].OptionList.filter((option) => (option.isSelect == true))
    // let arryoptions = optionFilter.map((options) => (Number(options.price)))
    // var sum = 0 
    // if (arryoptions.length > 0 ) {
    //     sum = arryoptions.reduce((result,number)=> result+number) + plPrice;
    // } else {
    //     sum += plPrice
    // }

    this.setState({ itemObject: obj, totalPrice: this.countPrice() * this.state.qty })
  }

  renderPriceOption() {
    var arrReturn = []

    this.state.itemObject.PriceList.map(((priceOptions, index) => {
      arrReturn.push(
        <a onClick={() => { this.onPriceClick(index) }} key={index}>
          <li>
            <button className="checked-btn">
              {/* <img src={priceOptions.isSelect ? "/images/tick.png" : "/images/untick.png"} /> */}
              {
                priceOptions.isSelect
                ?
                <FontAwesomeIcon size="20px" icon={faCheckCircle} />
                    :
                    <i class="fa fa-circle-o" aria-hidden="true"></i>
              }
            </button>
            <label className="container1 optValue">
              {priceOptions.type}  {priceOptions.price > 0.0 ? getPriceFormat(priceOptions.price.toFixed(2)) : null}
            </label>
          </li>
        </a>
      )
    }))
    if (arrReturn.length > 1) {
      return (
        <div className="plate-div">
          <ul>
            {arrReturn}
          </ul>
        </div>
      )
    }
    
  }

  renderOptionMenu() {
    // console.log('renderOptionMenu ----- ')

    for (var i = 0; i < this.state.itemObject.PriceList.length; i++) {

      if (this.state.itemObject.PriceList[i].isSelect) {
        var arrayTitle = []
        let optList = this.state.itemObject.PriceList[i].OptionList

        optList.map((data) => {

          var temp = arrayTitle.filter(obj => (obj == data.labelText))
          if (temp.length == 0) {
            arrayTitle.push(data.labelText)
          }
        })
        var arrReturn = []  
        for (var k = 0; k <= arrayTitle.length - 1; k++) {
          arrReturn.push(
            <div className="plate-div" key={k}>
              <h4 className="optTitle">{arrayTitle[k]} </h4>
              <ul>
                {this.renderOptions(arrayTitle[k])}
              </ul>
            </div>
          )
        }
        return arrReturn
        break
      }
    }
  }

  onQtyChanged(type) {
    var q = this.state.qty
    if (type == 0) {
      if (q > 1) {
        q -= 1
      }
    } else {
      q += 1
    }
    this.setState({ qty: q, totalPrice: q * this.countPrice() })

  }

  countPrice = () => {


    //get selected option
    let PriceListFilter = this.state.itemObject.PriceList.filter((price) => (price.isSelect == true))
    let optionPrice = PriceListFilter[0].price


    //get topping price from suboption sum
    let toppoingPrice = 0;
    let optionlist = PriceListFilter[0].OptionList

    for (let i = 0; i < optionlist.length; i++) {
        
        if (optionlist[i].subOption.length > 0) {
            for (let j = 0; j < optionlist[i].subOption.length; j++) {
                if (optionlist[i].subOption[j].isSelect) {
                    toppoingPrice = toppoingPrice + optionlist[i].subOption[j].price;
                }
            }
        }
    }


    var sum = 0
    if(PriceListFilter[0].isSelect == true){
      sum = optionPrice + toppoingPrice //sum of toppingprice + option price
    }
    else{
      sum += optionPrice //Only Option price
    }
    return sum

  }


  renderMenuStatus(status) {
    if(!status) {
      return
    }
    let obj = []
    status.map((data,index) => (
      obj.push(
        <li key={index}>
          <img src={bindhttps(data.iconurl)} />
          {/* <a href="#">
           
          </a> */}
        </li>
      )
    ))
    return obj
  }

  onAddToCart() {

    
    //Check for add or add to cart
    if (!this.props.isEdit) {

      if(!this.getSelectedItems()){
        return false
      }

      //add to cart item
      this.state.itemObject.totalQty = this.state.qty
      this.state.itemObject.totalPrice = this.countPrice() * this.state.qty

      if (localStorage.hasOwnProperty(Constants.USERCART)) {
        // let userObject = localStorage.getItem(Constants.USERCART);
        let userObject = getSecureData(Constants.USERCART);

        if (userObject !== null) {
          // userObject = JSON.parse(userObject);
          // let cartData = JSON.parse(localStorage.getItem(Constants.USERCART))
          let cartData = getSecureData(Constants.USERCART)

          console.log('Cart Data --- ', JSON.stringify(cartData))
          console.log('itemObject --- ', JSON.stringify(this.state.itemObject))

          var shouldUpdate = false
          var updateAt = 0
          if (cartData.length > 0) {

            //check menu item id available and matched 
            cartData.map((cartObject, index) => {
              if (cartObject.menuitemId == this.state.itemObject.menuitemId) {
                let cartPriceType = cartObject.PriceList.filter(data => (data.isSelect == true))
                let itemPriceType = this.state.itemObject.PriceList.filter(data => (data.isSelect == true))

                console.log('cartPriceType ----- ', cartPriceType)
                  console.log('itemPriceType ----- ', itemPriceType)

                //compare selected price type
                if (cartPriceType[0].typeid == itemPriceType[0].typeid) {
                  //get selected options list type 
                  let cartOptions = cartPriceType[0].OptionList.filter(opt => (opt.isSelect == true))
                  let itemOptions = itemPriceType[0].OptionList.filter(opt => (opt.isSelect == true))

                  var arrayCartOpt = []
                  cartOptions.map(cartopt => (
                    arrayCartOpt.push(cartopt.typeid)
                  ))
                  var arrayItemOpt = []
                  itemOptions.map(itemopt => (
                    arrayItemOpt.push(itemopt.typeid)
                  ))
                  console.log('arrayCartOpt ----- ', arrayCartOpt)
                  console.log('arrayItemOpt ----- ', arrayItemOpt)

                  console.log('itemOptions1 ----- ', cartOptions)
                  console.log('itemOption2 ----- ', itemOptions)
                  //compare selected options list
                  // if (JSON.stringify(arrayCartOpt) == JSON.stringify(arrayItemOpt) ) {

                  // if (JSON.stringify(arrayCartOpt) == JSON.stringify(arrayItemOpt) && JSON.stringify(cartPriceType[0].OptionList) == JSON.stringify(itemPriceType[0].OptionList)) {
                    
                    if (JSON.stringify(cartPriceType[0].OptionList) == JSON.stringify(itemPriceType[0].OptionList)) {
                    shouldUpdate = true
                    updateAt = index
                    console.log('Both object are same......')
                  } else {
                    console.log('Both object are not same......')
                  }
                }
              }
            })
          }

          if (shouldUpdate) {

            let oldQty = cartData[updateAt].totalQty
            let oldPrice = cartData[updateAt].totalPrice

            console.log('item data should update at --- ', updateAt,)
            //add new qty and price in cart item
            cartData[updateAt].totalQty += this.state.itemObject.totalQty
            cartData[updateAt].totalPrice += this.state.itemObject.totalPrice

            // localStorage.setItem(Constants.USERCART,JSON.stringify(cartData))
            setSecureData(Constants.USERCART, cartData)
          } else {

            console.log('add item in cart')
            cartData.push(this.state.itemObject)
            // localStorage.setItem(Constants.USERCART,JSON.stringify(cartData))
            setSecureData(Constants.USERCART, cartData)
          }
          //close item popup
          // this.props.onClose({ isHide: true, isNewItem: !shouldUpdate })
          this.props.onClose({ isHide: true, isNewItem: true })
        } else {
          // localStorage.setItem(Constants.USERCART,JSON.stringify([this.state.itemObject]))
          setSecureData(Constants.USERCART, [this.state.itemObject])
          //close item popup
          this.props.onClose({ isHide: true, isNewItem: true })
        }
      } else {
        // localStorage.setItem(Constants.USERCART,JSON.stringify([this.state.itemObject]))
        setSecureData(Constants.USERCART, [this.state.itemObject])
        //close item popup
        this.props.onClose({ isHide: true, isNewItem: true })
      }

    } else {
      //edit item
      console.log('edit existing item')
      // let cartData = JSON.parse(localStorage.getItem(Constants.USERCART))

     if(!this.getSelectedItems()){
        return false
      }
      let cartData = getSecureData(Constants.USERCART)

      cartData[this.props.editIndex].PriceList = this.state.itemObject.PriceList
      cartData[this.props.editIndex].isReOrderedItem = false

      console.log('item data should edit at --- ', this.props.editIndex, this.state.qty, this.state.totalPrice)
      //add new qty and price in cart item
      cartData[this.props.editIndex].totalQty = this.state.qty
      cartData[this.props.editIndex].totalPrice = this.state.totalPrice
      console.log('updated data ----- ', cartData)
      // localStorage.setItem(Constants.USERCART,JSON.stringify(cartData))
      setSecureData(Constants.USERCART, cartData)
      setTimeout(() => {
        //close item popup
        this.props.onClose({ isHide: true })
      }, 200);

    }




  }

  getSelectedItems = () => {
    let i = 0;

    let isAnySingleItemSelecte = false;

    // create tempOption for added only selected items
    let localSelectedItemObject = this.state.itemObject;

    console.log("localSelectedItemObject",localSelectedItemObject)
    // return
    //  isValidaMinItemSeelcted = false
    //for (let i = 0; i < localSelectedItemObject.PriceList.length; i++) {
    for (
      let k = 0;
      k < localSelectedItemObject.PriceList[i].OptionList.length;
      k++
    ) {
      
      if (
        localSelectedItemObject.PriceList[i].OptionList[k].minselection != 0
      ) {
        let minValue =
          localSelectedItemObject.PriceList[i].OptionList[k].minselection;
        let optionParamName =
          localSelectedItemObject.PriceList[i].OptionList[k].labelText;
        // let minValue = 2

        let minCounter = 0;
        for (
          let j = 0;
          j <
          localSelectedItemObject.PriceList[i].OptionList[k].subOption.length;
          j++
        ) {
          if (
            localSelectedItemObject.PriceList[i].OptionList[k].subOption[j]
              .isSelect
          ) {
            isAnySingleItemSelecte = true;
            minCounter = minCounter + 1;
          }
        }
        if (isAnySingleItemSelecte) {
          if (minCounter < minValue) {
            let msg = `Please select a minimum of ${minValue} options from '${optionParamName}'`;
            // msg = msg.replace("%", minValue);
            // msg = msg.replace("@", optionParamName);

            // this.setState({
            //   alertMessage: msg,
            //   isDialogVisible: true,
            // });
            alert(msg);
            return false
          }
          else{
            i++
            return true
          }
        }
        else{
          i++
          return true
        }
        
      }
      else{
        i++
        return true
      }

      
    }

    return true
  
  };

  getSelectedItems1 = () => {
    // console.log(JSON.stringify(this.state.itemlist))
    let i = 0;//selectedMenuParentPostion;

    let isAnySingleItemSelecte = false;

    // create tempOption for added only selected items
    let localSelectedItemObject = this.state.itemObject;

    console.log("localSelectedItemObject",localSelectedItemObject)
    // return
    //  isValidaMinItemSeelcted = false
    //for (let i = 0; i < localSelectedItemObject.PriceList.length; i++) {
    for (
      let k = 0;
      k < localSelectedItemObject.PriceList[i].OptionList.length;
      k++
    ) {
      if (
        localSelectedItemObject.PriceList[i].OptionList[k].minselection != 0
      ) {
        let minValue =
          localSelectedItemObject.PriceList[i].OptionList[k].minselection;
        let optionParamName =
          localSelectedItemObject.PriceList[i].OptionList[k].labelText;
        // let minValue = 2

        let minCounter = 0;
        for (
          let j = 0;
          j <
          localSelectedItemObject.PriceList[i].OptionList[k].subOption.length;
          j++
        ) {
          if (
            localSelectedItemObject.PriceList[i].OptionList[k].subOption[j]
              .isSelect
          ) {
            isAnySingleItemSelecte = true;
            minCounter = minCounter + 1;
          }
        }
        if (isAnySingleItemSelecte) {
          if (minCounter < minValue) {
            let msg = `Please select a minimum of ${minValue} options from '${optionParamName}'`;
            // msg = msg.replace("%", minValue);
            // msg = msg.replace("@", optionParamName);

            // this.setState({
            //   alertMessage: msg,
            //   isDialogVisible: true,
            // });
            alert(msg);
            return false
          }
          else{
            return true
          }
        }
      }
    }
  
  };

  render() {

    console.log('Popup Item Object ----- ', this.state.itemObject)

    return (

      <Modal
        show={this.props.isVisible}
        onHide={() => this.props.onClose({ isHide: false })}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Header closeButton>
          {/* <div className="pop-header">
          
          <button type="button" className="close" data-dismiss="pop" onClick={()=>this.props.onClose({isHide: false})}>&times;</button>
        </div> */}
        </Modal.Header>
        <Modal.Body>
          {/* <div>
          <h3>{this.props.itemData.menuItemName}</h3>
        </div>
        <button onClick={()=>this.props.onClose()}>X</button> */}

          {/* <!-- The pop --> */}

          <div className="pop" id="mypop">
            <div className="pop-dialog">
              <div className="pop-content">



                {/* <!-- pop body --> */}
                <div className="pop-body">
                  {/* <div className="prd-s">                  
              <div className="prd-cont">                   
                <h3>{this.state.itemObject.menuItemName}</h3>
                <p>{this.state.itemObject.description}</p>
                {/* <div className="prd-img"><a href="#"><img src={bindhttps(this.state.itemObject.imgurl)}/></a></div> 
                <div className="prd-img">
                  <a href="#"><img src={'../images/prdmainimg.jpg'}/></a>
                </div>
                {this.renderPriceOption()}
                {this.renderOptionMenu()}
              </div>
            </div> */}
                  <div class="prd-s">
                    <div class={this.state.itemObject.imgurl.length > 0 ? "prd-img" : "prd-noimg"}>
                      <img src={bindhttps(this.state.itemObject.imgurl)} />
                      {
                        this.state.customerId > 0 && this.props.isFavShow == true
                        ?
                        <div className="itempopup_favHeart" >
                        {
                                this.state.itemObject.isFavoriteMenu
                                ?
                                <div onClick={() => {this.props.onAddDlt(this.state.itemObject,'DeleteFavorite')}} className="itempopup_heartIcon rounded-circle"><i class="fa fa-heart" style={{color:'#8eeeca'}} aria-hidden="true"></i></div>
                                :
                                <div onClick={() => {this.props.onAddDlt(this.state.itemObject,'addFavorite')}} className="itempopup_heartIcon rounded-circle"><i class="fa fa-heart-o" style={{color:'#8eeeca'}} aria-hidden="true"></i></div>

                              }
                        </div>
                        :
                        null
                      }
                      
                      <h4 class="prdTitle">{this.state.itemObject.menuItemName}</h4>
                    </div>
                    <div class="prd-cont">
                      <ul class="vegi">
                        {this.renderMenuStatus(this.state.itemObject.MenuStausList)}
                      </ul>
                      <label class="prdDesc">{this.state.itemObject.description}</label>
                      {this.renderPriceOption()}
                      {this.renderOptionMenu()}
                      <div class="itemFooter">
                        <div class="QtyDiv">
                          {/* <label>Quantity</label> */}
                          <button href="" type="button" class="btn" data-toggle="modal" data-target="#myModal" onClick={() => { this.onQtyChanged(0) }}><img src="../images/minus-icon.png" /></button>
                          <label class="QTYLabel">{this.state.qty}</label>
                          <button href="" type="button" class="btn" data-toggle="modal" data-target="#myModal" onClick={() => { this.onQtyChanged(1) }}><img src="../images/plus-icon.png" /></button>
                        </div>
                        {/* <label class="prdPriceAmt">{getPriceFormat(this.state.totalPrice.toFixed(2))}</label> */}
                        <label className="lbl">{getCurrencySign()}</label>
                        <label className="prdPriceAmt">{this.state.totalPrice.toFixed(2)}</label>
                        <button onClick={() => { this.onAddToCart() }} type="button" class="btn btn-SignIn btn-price" data-toggle="modal" data-target="#myModal">Add</button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- pop footer --> */}
                {/* <div className="pop-footer">
          <div className="add-qntity">
            <p>{Strings.lbl_quantity_react}</p>
            <div className="number">
                <span className="minus" onClick={()=>{this.onQtyChanged(0)}}>-</span>
                <label>{this.state.qty}</label>
                <span className="plus" onClick={()=>{this.onQtyChanged(1)}}>+</span>
            </div>
          </div>
          <p>{Strings.Total_web}:  <span>
            {getPriceFormat(this.state.totalPrice.toFixed(2))}
            </span></p>
          <button type="button" className="btn big btn-primary btn-price" data-dismiss="pop" onClick={()=>{this.onAddToCart()}}>{this.props.isEdit ? Strings.str_btn_update_react : Strings.lbl_edit_add_react}</button>
        </div> */}

              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>


    )
  }

}

export default withRouter(ItemPopup);