import axios from 'axios';
// import apiPath from '../Constants/APIPath';
import baseConstants from '../../src/constants/baseConstants';

export default axios.create({
  // baseURL: 'http://honestgreens.mgtdemo.co.uk/RestaurantAPIOnlineV1.asmx/',//DEMO
  // baseURL: 'https://honestgreens.smartrestaurants.com/RestaurantAPIOnlineV1.asmx/',//LIVE

  baseURL: baseConstants.mainURL + 'RestaurantAPIOnlineV1.asmx/',
  responseType: 'json',
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Authorization': 'fd559f719645be483bc941f7ef3ab5be',
  //   'Access-Control-Allow-Origin': 'http://localhost:3002',
  //   'Access-Control-Allow-Origin': '*',
  // 'Access-Control-Allow-Headers': '*',
  },
});



