import React, { Component } from "react";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";
import { Strings } from "../constants/Localization";
import FooterMenu from "../Components/FooterMenu";
import Constant from "../constants/baseConstants";
import HeaderWhiteMenu from "../Components/HeaderWhiteMenu";
import { setSecureData, getSecureData } from "../Utils/utils";
import AOS from "aos";
import "aos/dist/aos.css";
import AlertDialog from "../Components/AlertDialog";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { Dropdown } from "react-bootstrap";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="form-control"
  >
    {children}
    &#x25bc;
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    // const [value, setValue] = useState('');
    const value = "";
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {/* <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => alert(e.target.value)}
            value={value}
        /> */}
        <ul>
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

class OfficeRegistration extends Component {
  constructor(props) {
    super(props);

    var userName = this.props.location.name;
    var emailId = this.props.location.emailId;
    var isFromSocialMedia = this.props.location.isFromSocialMedia;
    console.log("userName :" + userName + "  " + emailId);
    this.state = {
      name: "",
      countryCode: "0",
      phoneNumber: "",
      email: "",
      address: "",
      comments: "",
      languageType: 1, //default language type
      lat: 0.0,
      long: 0.0,
      cityname: "",
      zipcode: "",
      errors: {
        nameError: "",
        countryCodeError: "",
        phoneNumberError: "",
        emailError: "",
        addressError: "",
      },

      isAlertVisible: false,
      alertMessage: "",
      btnLeft: "",
      btnRight: "",
      isMultiButton: false,
      dialogType: 1,

      locationArray: [],
      locationIndex: -1,
    };
    setTimeout(() => {
      if (isFromSocialMedia) {
        this.setState({ name: userName, email: emailId });
      }
    }, 200);
  }

  componentDidMount() {
    AOS.init({
      duration: 1000,
    });
    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
    let session = getSecureData(Constant.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
    let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }
  }

  //Handle change language click event
  //English language type: 1
  //Spanish language type: 2
  languageChangeClickEvent = (type) => {
    console.log("sign up page language type :" + type);
    // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
    setSecureData(Constant.PRE_LANGUAGE_TYPE, type);
    this.setState({ languageType: type });
    setTimeout(() => {
      this.props.AppMessageApi(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          var messageListArray = this.props.data.result;
          if (messageListArray != null && messageListArray.length > 0) {
            console.log("message list  :" + JSON.stringify(messageListArray));
            // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
            setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
            // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
            let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
            // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
            setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType);
            // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
            let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
            console.log("language type  :" + languageType);
            if (languageType == 1) {
              //English language type: 1
              Strings.setLanguage("en"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "en-GB",
              });
            } else {
              Strings.setLanguage("es"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "es",
              });
            }
            Strings.setContent(localMessage);
            // this.setState({ languageType: languageType })
            window.location.reload(false);
          }
        } else {
          alert(this.props.error);
        }
      });
    }, 700);
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        console.log(results);
        if (results.length > 0) {
          console.log("addsress ----- ", results[0].formatted_address);
          this.setState({ address: results[0].formatted_address });
          let addComp = results[0].address_components;
          for (let i = 0; i < addComp.length; i++) {
            let flt = addComp[i].types.includes("locality"); //indexOf('locality') > -1
            let flt1 = addComp[i].types.includes("postal_code");
            if (flt) {
              console.log("flt------- ", i);
              console.log("flt------- ", flt, addComp[i].short_name);
              this.setState({ cityname: addComp[i].short_name });
            }
            if (flt1) {
              console.log("flt------- ", flt1, addComp[i].short_name);
              this.setState({ zipcode: addComp[i].short_name });
            }
          }
        }
      })
      .catch((error) => console.error(error));
    // .then(results => getLatLng(results[0]))
    // .then((latLng) => {console.log('Success', latLng,address); this.setState({ address:address,lat:latLng.lat,long:latLng.lng }) })
    // .catch((error) => {console.error('Error', error)})
  };

  onSignUpClick = (event) => {
    event.preventDefault();

    var nmError =
      this.state.name.length == 0 ? Strings.str_msg_enter_company : "";
    var ccodeerror =
      this.state.countryCode == "0" ? Strings.error_msg_country_code_react : "";
    var phError =
      this.state.phoneNumber.length < 9 ? Strings.error_msg_phone_no_react : "";
    var emlError =
      this.state.email.length == 0 ? Strings.error_msg_email_react : "";
    var passError =
      this.state.address.length == 0 ? Strings.error_msg_address : "";

    if (emlError.length == 0) {
      validEmailRegex.test(this.state.email) == false
        ? (emlError = Strings.error_msg_valid_email_react)
        : (emlError = "");
    }

    this.setState({
      errors: {
        nameError: nmError,
        countryCodeError: ccodeerror,
        phoneNumberError: phError,
        emailError: emlError,
        addressError: passError,
      },
    });

    setTimeout(() => {
      if (
        this.state.errors.nameError == "" &&
        this.state.errors.countryCodeError == "" &&
        this.state.errors.phoneNumberError == "" &&
        this.state.errors.emailError == "" &&
        this.state.errors.addressError == ""
      ) {
        console.info(
          "Valid form------ ",
          this.state.name,
          this.state.countryCode,
          this.state.phoneNumber,
          this.state.email,
          this.state.address,
          this.state.cityname,
          this.state.zipcode,
          this.state.comments
        );
        this.props
          .initOfficeRegister(
            this.state.name,
            this.state.countryCode,
            this.state.phoneNumber,
            this.state.email,
            this.state.address,
            this.state.comments,
            this.state.cityname,
            this.state.zipcode,
            this.state.languageType
          )
          .then(() => {
            console.log("office register RESPONSE --------- ", this.props.data);
            if (this.props.data.status == 1) {
              this.setState({
                isAlertVisible: true,
                alertMessage: this.props.data.message,
                isMultiButton: false,
                btnLeft: Strings.Ok_web,
              });
            } else {
              this.setState({
                isAlertVisible: true,
                alertMessage: this.props.data.message,
                isMultiButton: false,
                btnLeft: Strings.Ok_web,
              });
            }
          });
      } else {
        console.log("Not valid form");
      }
    }, 100);
  };

  onSelectLocation(e) {
    if (e != "") {
      this.setState({ locationIndex: e });
      // localStorage.setItem(Constant.LOCATIONOBJECT, JSON.stringify(this.state.locationArray[e.target.value]))
      setSecureData(Constant.LOCATIONOBJECT, this.state.locationArray[e]);
    } else {
      this.setState({ locationIndex: -1 });
    }
  }

  renderLocationList() {
    var arrReturn = [];
    this.state.locationArray.map((location, index) => {
      arrReturn.push(
        // <option value={index}>{location.locationName}</option>
        <Dropdown.Item eventKey={index}>{location.locationName}</Dropdown.Item>
      );
    });

    return (
      // <select className="form-control" onChange={e => this.onSelectLocation(e)}>
      //     <option value="">{Strings.lbl_select_location_react}</option>
      //     {arrReturn}
      // </select>

      <Dropdown onSelect={(e) => this.onSelectLocation(e)}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {this.state.locationIndex == -1
            ? Strings.lbl_select_location_react
            : this.state.locationArray[this.state.locationIndex].locationName}
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={CustomMenu}
          style={{ height: "150px", overflow: "scroll" }}
        >
          {arrReturn}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  onQtyChanged(val) {
    if (val.match("^[0-9]*$") != null) {
      this.setState({ phoneNumber: val });
    }
  }

  render() {
    return (
      <div>
        {this.props.isLoading ? (
          <div className="loadermain" style={{ display: "block" }}>
            <div className="loader"></div>
          </div>
        ) : (
          <div className="loadermain" style={{ display: "none" }}>
            <div className="loader"></div>
          </div>
        )}
        {/* Old header */}
        {/* <HeaderMenu /> */}

        <HeaderWhiteMenu changeLanguageEvent={this.languageChangeClickEvent} />
        <AlertDialog
          isAlertVisible={this.state.isAlertVisible}
          msgTitle={this.state.alertMessage}
          isMultibutton={this.state.isMultiButton}
          btnLeftClick={() => {
            this.setState({ isAlertVisible: false }, () => {
              this.props.history.goBack();
            });
          }}
          btnRightClick={() => {
            this.setState({ isAlertVisible: false });
          }}
          btnLeftTitle={this.state.btnLeft}
          btnRightTitle={this.state.btnRight}
        />
        {/* <h2>{this.props.match.params.id}</h2> */}
        <div className="content_wrap">
          <div className="container">
            <div className="signupwrap" data-aos="fade-up">
              <h1>{Strings.str_company_registration}</h1>
              <div className="row">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="form-group">
                    <label>{Strings.lable_compnayname_web}:*</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={75}
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      tabIndex="1"
                    />
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.nameError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{Strings.lbl_phone_number_react}*</label>
                    <div className="row">
                      <div className="col-5 col-md-3 pr-0">
                        {/* <select className="form-control" onChange={e => this.setState({ countryCode: e.target.value })}>
                                                    
                                                    <option value="+44">+44</option>
                                                </select> */}
                        <Dropdown
                          onSelect={(e) => this.setState({ countryCode: e })}
                        >
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          >
                            {this.state.countryCode == 0
                              ? "Code "
                              : this.state.countryCode}
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            as={CustomMenu}
                            style={{ height: "200px", overflow: "scroll" }}
                          >
                            <Dropdown.Item eventKey="+7">+7</Dropdown.Item>
                            <Dropdown.Item eventKey="+30">+30</Dropdown.Item>
                            <Dropdown.Item eventKey="+31">+31</Dropdown.Item>
                            <Dropdown.Item eventKey="+32">+32</Dropdown.Item>
                            <Dropdown.Item eventKey="+33">+33</Dropdown.Item>
                            <Dropdown.Item eventKey="+34">+34</Dropdown.Item>
                            <Dropdown.Item eventKey="+36">+36</Dropdown.Item>
                            <Dropdown.Item eventKey="+39">+39</Dropdown.Item>
                            <Dropdown.Item eventKey="+40">+40</Dropdown.Item>
                            <Dropdown.Item eventKey="+41">+41</Dropdown.Item>
                            <Dropdown.Item eventKey="+43">+43</Dropdown.Item>
                            <Dropdown.Item eventKey="+44">+44</Dropdown.Item>
                            <Dropdown.Item eventKey="+45">+45</Dropdown.Item>
                            <Dropdown.Item eventKey="+46">+46</Dropdown.Item>
                            <Dropdown.Item eventKey="+47">+47</Dropdown.Item>
                            <Dropdown.Item eventKey="+48">+48</Dropdown.Item>
                            <Dropdown.Item eventKey="+49">+49</Dropdown.Item>
                            <Dropdown.Item eventKey="+90">+90</Dropdown.Item>
                            <Dropdown.Item eventKey="+298">+298</Dropdown.Item>
                            <Dropdown.Item eventKey="+350">+350</Dropdown.Item>
                            <Dropdown.Item eventKey="+351">+351</Dropdown.Item>
                            <Dropdown.Item eventKey="+352">+352</Dropdown.Item>
                            <Dropdown.Item eventKey="+353">+353</Dropdown.Item>
                            <Dropdown.Item eventKey="+354">+354</Dropdown.Item>
                            <Dropdown.Item eventKey="+355">+355</Dropdown.Item>
                            <Dropdown.Item eventKey="+356">+356</Dropdown.Item>
                            <Dropdown.Item eventKey="+357">+357</Dropdown.Item>
                            <Dropdown.Item eventKey="+358">+358</Dropdown.Item>
                            <Dropdown.Item eventKey="+359">+359</Dropdown.Item>
                            <Dropdown.Item eventKey="+370">+370</Dropdown.Item>
                            <Dropdown.Item eventKey="+371">+371</Dropdown.Item>
                            <Dropdown.Item eventKey="+372">+372</Dropdown.Item>
                            <Dropdown.Item eventKey="+373">+373</Dropdown.Item>
                            <Dropdown.Item eventKey="+374">+374</Dropdown.Item>
                            <Dropdown.Item eventKey="+375">+375</Dropdown.Item>
                            <Dropdown.Item eventKey="+376">+376</Dropdown.Item>
                            <Dropdown.Item eventKey="+377">+377</Dropdown.Item>
                            <Dropdown.Item eventKey="+378">+378</Dropdown.Item>
                            <Dropdown.Item eventKey="+379">+379</Dropdown.Item>
                            <Dropdown.Item eventKey="+380">+380</Dropdown.Item>
                            <Dropdown.Item eventKey="+381">+381</Dropdown.Item>
                            <Dropdown.Item eventKey="+382">+382</Dropdown.Item>
                            <Dropdown.Item eventKey="+383">+383</Dropdown.Item>
                            <Dropdown.Item eventKey="+385">+385</Dropdown.Item>
                            <Dropdown.Item eventKey="+386">+386</Dropdown.Item>
                            <Dropdown.Item eventKey="+387">+387</Dropdown.Item>
                            <Dropdown.Item eventKey="+389">+389</Dropdown.Item>
                            <Dropdown.Item eventKey="+420">+420</Dropdown.Item>
                            <Dropdown.Item eventKey="+421">+421</Dropdown.Item>
                            <Dropdown.Item eventKey="+423">+423</Dropdown.Item>
                            <Dropdown.Item eventKey="+994">+994</Dropdown.Item>
                            <Dropdown.Item eventKey="+995">+995</Dropdown.Item>
                            <Dropdown.Item eventKey="+3491">
                              +3491
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="+91">+91</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-7 col-md-9">
                        {/* <input type="text" className="form-control" maxLength={11} value={this.state.phoneNumber} onChange={e => this.setState({ phoneNumber: e.target.value })} tabIndex="2" /> */}
                        <input
                          type="text"
                          className="form-control"
                          maxLength={11}
                          value={this.state.phoneNumber}
                          onChange={(e) => {
                            this.onQtyChanged(e.target.value);
                          }}
                          tabIndex="2"
                        />
                      </div>
                    </div>
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.countryCodeError}
                      </span>
                      <span className="errorMsg">
                        {this.state.errors.phoneNumberError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{Strings.lbl_email_react}*</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={75}
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      tabIndex="3"
                    />
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.emailError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{Strings.str_address}:*</label>
                    <PlacesAutocomplete
                      value={this.state.address}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                      searchOptions={{
                        // types: ['geocode'],
                        address_components: ["address", "locality"],
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="location-popup">
                          {/* <h5>Your Location</h5> */}
                          {/* <div className="Loc"> */}
                          <div className="form-group loc-input">
                            <input
                              type="text"
                              className="form-control"
                              value="Enter Location"
                              tabIndex="4"
                              {...getInputProps({
                                // placeholder: Strings.enter_address,
                                className: "form-control",
                              })}
                            />
                            <div className="errorDiv">
                              <span className="errorMsg">
                                {this.state.errors.addressError}
                              </span>
                            </div>
                          </div>
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>{Strings.str_loading}</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    margin: "0px 5px",
                                    borderBottom: "solid 1px",
                                    marginBottom: "10px",
                                  }
                                : {
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    margin: "0px 5px",
                                    borderBottom: "solid 1px",
                                    marginBottom: "10px",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                          {/* </div> */}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className="form-group">
                    <label>{Strings.comments_web}:</label>
                    {/* {this.renderLocationList()} */}
                    <input
                      type="text"
                      className="form-control"
                      placeholder={Strings.address_comment}
                      value={this.state.comments}
                      onChange={(e) =>
                        this.setState({ comments: e.target.value })
                      }
                      tabIndex="5"
                    />
                    {/* <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.locationError}</span>
                                        </div> */}
                  </div>
                  <div className="signup">
                    <a
                      href="#"
                      className="btn btn-primary btn-big"
                      onClick={this.onSignUpClick}
                      tabIndex="5"
                    >
                      {Strings.btn_signup_react}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log(
    "isLoaging OfficeRegistration ------- " + state.HGReducers.isLoading
  );
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  initOfficeRegister: (
    name,
    countrycode,
    phone,
    email,
    address,
    comment,
    city,
    zipcode,
    languageType
  ) =>
    dispatch(
      HGActions.initOfficeRegistrationAPI(
        name,
        countrycode,
        phone,
        email,
        address,
        comment,
        city,
        zipcode,
        languageType
      )
    ),
  AppMessageApi: (languageType) =>
    dispatch(HGActions.GetAppMessageListApi(languageType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficeRegistration);
