import React, { Component } from "react";

import {withRouter} from 'react-router-dom';

import Constants from '../constants/baseConstants';
import {Strings} from '../constants/Localization';

import Modal from 'react-bootstrap/Modal'


class PaymentMethod extends Component {
  
  constructor(props) {
    super(props);
        this.state = {
          paymentType: 0,
        }
  }

  onSelectTypes(index) {
    // this.setState({paymentType: index})
    setTimeout(() => {
      this.props.isHide(index)
    }, 100);
  }

  render() {

    console.log('this.props',this.props)
    return (
        <Modal
            show={this.props.isShow}
            onHide={()=>{this.props.isHide()}}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
            </Modal.Header>
        <Modal.Body>
          <div className="popupHeader">
              <label className="headerTitle">{Strings.payment_type}</label>
          </div>
          <div className="pop loc-popup" id="mypop">
              <div className="pop-dialog">
                  <div className="pop-content">
                        <div className="pop-body">
                          <div className="prd-s">
                              {/* <p style={{ textAlign: 'center' }}>{Strings.payment_type}</p> */}
                              <div className="prd-cont">
                                {/* <div className="chek-new" onClick={() => {this.onSelectTypes(1)}}>
                                  <label className="container1">
                                      <h3>{Strings.pay_by_cash}</h3>
                                      <input
                                          type="checkbox"
                                          checked="checked" />
                                      <span className={this.state.paymentType == 1 ? "checkmark" : ''}></span>
                                  </label>
                                </div>
                                <div className="chek-new" onClick={() => {this.onSelectTypes(3)}}>
                                  <label className="container1">
                                      <h3>{Strings.pay_by_card}</h3>
                                      <input
                                          type="checkbox"
                                          checked="checked" />
                                      <span className={this.state.paymentType == 3 ? "checkmark" : ''}></span>
                                  </label>
                                </div> */}

                                {(this.props.isCash) ? <div className="optList" onClick={() => { this.onSelectTypes(1)}}>
                                    <h3>{Strings.pay_by_cash}</h3>
                                </div> : null}
                               {(this.props.isCard && this.props.amount > 0) ?  <div className="optList" onClick={() => { this.onSelectTypes(3)}}>
                                    <h3>{Strings.pay_by_card}</h3>
                                </div> : null}
                              </div>
                          </div>
                          {this.state.paymentType > 0 ? (
                            <div className="continue-btn">
                              <button className="btn btn-primary btn-small order-btn" onClick={()=>{this.props.isHide(this.state.paymentType)}}>{Strings.btn_continue_react}</button>
                          </div>
                          ) : null}
                          
                      </div>
                  </div>
              </div>
          </div>
        </Modal.Body>
        </Modal>
    )
  }

}

export default PaymentMethod;