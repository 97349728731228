import React, { Component } from "react";
import Constants from "../constants/baseConstants";
import Modal from "react-bootstrap/Modal";

import {
  Route,
  NavLink,
  BrowserRouter as Router,
  Switch,
  Link,
  history,
} from "react-router-dom";
import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";
import { Strings } from "../constants/Localization";
import Constant from "../constants/baseConstants";
import { setSecureData, getSecureData } from "../Utils/utils";
import { Dropdown } from "react-bootstrap";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode: "+44",
      phoneNumber: "",
      password: "",
      forgetPhoneNo: "",
      forgetCountryCode: "+44",
      socialId: "",
      loginType: 0,

      errors: {
        countryCodeError: "",
        phoneNumberError: "",
        passwordError: "",
        forgetCountryCodeError: "",
        forgetPhoneNumberError: "",
      },

      languageType: 1,
    };
  }

  // componentWillMount() {
  //     this.setState({countryCodeError: '',
  //     phoneNumberError: '',
  //     passwordError: ''})
  // }

  onEnterPassword(event) {
    if (event.key === "Enter") {
      this.onSignInClick();
    }
  }

  onMobileEnter(str, type) {
    console.log(str);
    if (str.match("^[0-9]*$") != null) {
      if (type == 1) {
        this.setState({ phoneNumber: str });
      } else if (type == 2) {
        this.setState({ forgetPhoneNo: str });
      }
    }
  }

  onSignInClick = () => {
    var ccodeerror =
      this.state.countryCode == "0" ? Strings.error_msg_country_code_react : "";
    var phError =
      this.state.phoneNumber.length < 9 ? Strings.error_msg_phone_no_react : "";
    var passError =
      this.state.password.length == 0 ? Strings.error_msg_password_react : "";

    this.setState({
      errors: {
        countryCodeError: ccodeerror,
        phoneNumberError: phError,
        passwordError: passError,
      },
    });

    setTimeout(() => {
      if (
        this.state.errors.countryCodeError == "" &&
        this.state.errors.phoneNumberError == "" &&
        this.state.errors.passwordError == ""
      ) {
        this.props
          .SignInAPICall(
            this.state.phoneNumber,
            this.state.countryCode,
            this.state.password,
            this.state.languageType
          )
          .then(() => {
            if (this.props.data.status == 1) {
              if (this.props.data.result != null) {
                setTimeout(() => {
                  setSecureData(Constant.USEROBJECT, this.props.data.result);
                  // this.props.history.goBack()
                  this.props.onResponse();
                }, 100);
              }
            } else {
              alert(this.props.data.message);
            }
          });
      } else {
        console.error("Not valid form");
      }
    }, 100);
  };

  onEnterForgotPassword = (event) => {
    console.log(event);
    if (event.key === "Enter") {
      this.validateForgetPassword();
    }
  };

  //Call Forget Passoword Api
  validateForgetPassword = () => {
    // event.preventDefault();

    var ccodeerror =
      this.state.forgetCountryCode == "0"
        ? Strings.error_msg_country_code_react
        : "";
    var phError =
      this.state.forgetPhoneNo.length < 9
        ? Strings.error_msg_phone_no_react
        : "";

    this.setState({
      errors: {
        forgetCountryCodeError: ccodeerror,
        forgetPhoneNumberError: phError,
      },
    });

    setTimeout(() => {
      if (
        this.state.errors.forgetCountryCodeError == "" &&
        this.state.errors.forgetPhoneNumberError == ""
      ) {
        this.props
          .forgetPasswordApi(
            this.state.forgetPhoneNo,
            this.state.forgetCountryCode,
            this.state.languageType
          )
          .then(() => {
            setTimeout(() => {
              if (this.props.data.status == 1) {
                this.setState({ forgetPhoneNo: "" });
                alert(this.props.data.message);
              } else {
                alert(this.props.error);
              }
            }, 100);
          });
      }
    }, 100);
  };

  render() {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="form-control"
      >
        {children}
        &#x25bc;
      </a>
    ));

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
      ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        // const [value, setValue] = useState('');
        const value = "";
        return (
          <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
          >
            {/* <FormControl
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Type to filter..."
                onChange={(e) => alert(e.target.value)}
                value={value}
            /> */}
            <ul>
              {React.Children.toArray(children).filter(
                (child) =>
                  !value || child.props.children.toLowerCase().startsWith(value)
              )}
            </ul>
          </div>
        );
      }
    );
    return (
      <>
        <Modal
          show={this.props.isShowForm}
          onHide={() => {
            this.props.onResponse();
          }}
          size="sm"
          animation={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="loginwrap">
              {/* <p>If you are existing customer then sign in with credentials.</p> */}
              <p>
                <label href="#" className="dont">
                  Sign in, If you already have an account
                </label>
              </p>
              <div className="row">
                <div className="col-md-7 col-lg-5 login">
                  <div className="form-group">
                    <label>{Strings.lbl_phone_number_react}</label>
                    <div className="row">
                      <div className="col-5 col-md-3 pr-0 smDiv">
                        {/* <label type="text" className="form-control lblPhone">+44</label> */}
                        <input
                          readonly
                          type="text"
                          className="form-control text-center"
                          value="+44"
                          style={{ pointerEvents: "none" }}
                        />
                      </div>
                      <div className="col-7 col-md-9 lmDiv">
                        <input
                          type="text"
                          className="form-control"
                          maxLength={11}
                          value={this.state.phoneNumber}
                          onChange={(e) =>
                            this.onMobileEnter(e.target.value, 1)
                          }
                        />
                      </div>
                    </div>
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.countryCodeError}
                      </span>
                      <span className="errorMsg">
                        {this.state.errors.phoneNumberError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{Strings.lbl_password_react_sign_in}</label>
                    <input
                      type="password"
                      className="form-control"
                      id="txtpw"
                      onKeyPress={(event) => this.onEnterPassword(event)}
                      maxLength={75}
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    <div className="errorDiv">
                      <span className="errorMsg">
                        {this.state.errors.passwordError}
                      </span>
                    </div>
                  </div>

                  <div className="form-group cust_sign_flex  sign-col forgotmodalbtn">
                    <a
                      href="#"
                      onClick={() => this.props.onForget()}
                      className="forgot"
                    >
                      {Strings.lbl_forget_password_react}
                    </a>
                    <div className="signupsignin">
                      <a
                        href="javascript:void(0);"
                        className="btn btn-primary btn-large btnSignInForm"
                        id="btnSignIn"
                        onClick={() => this.onSignInClick()}
                      >
                        {Strings.btn_signin_react}
                      </a>
                    </div>
                  </div>

                  <div className="form-group cust_sign_flex signup-rw">
                    <label className="dont">
                      {Strings.lbl_no_account_react}
                    </label>
                    <div className="signupsignin">
                      <a
                        href="javascript:void(0);"
                        className="btn btn-primary btn-large btnSignInForm"
                        onClick={() => this.props.onSignUp()}
                      >
                        {Strings.btn_signup_react}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log("isLoaging SignIn ------- " + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  SignInAPICall: (phone, countryCode, password, languageType) =>
    dispatch(
      HGActions.initLoginCall(phone, countryCode, password, languageType)
    ),
  forgetPasswordApi: (forgetPhoneNo, forgetCountryCode, languageType) =>
    dispatch(
      HGActions.CallForgetPasswordApi(
        forgetPhoneNo,
        forgetCountryCode,
        languageType
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
