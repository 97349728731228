import React from 'react';
const Pagination = (props) => {
    const { currentPage, pageNumbers } = props;

    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <li
                className="page-item"
                key={number}
                id={number}
                onClick={() => { props.callChangePage(number) }}
            >
                <a className={number == currentPage ? 'page-link active' : 'page-link '} >{number}</a>
            </li>
        );
    });

    return (
        <div className="pagination-wrapper">
            <ul className="pagination ">

                <li
                    onClick={() => { props.callChangePage(currentPage == 1 ? 1 : currentPage - 1) }} className="page-item"
                >
                    <a className="page-link previous" >
                        <i className="fas fa-arrow-left"></i>
                    </a>
                </li>

                {renderPageNumbers}

                <li
                    onClick={() => { props.callChangePage(pageNumbers.length == currentPage ? currentPage : currentPage + 1) }}
                    className="page-item">
                    <a className="page-link next" >
                        <i className="fas fa-arrow-right"></i>
                    </a>
                </li>

            </ul>
        </div>
    )

}

export default Pagination;